<button class='primaryBtn  mr-4 buttonWidth' pButton type="button" label="Create Issue" (click)='openModal(issueData)'></button>




<ng-template #issueData>
    <div class="modal-header">
        <h4 class="modal-title m-0">{{title(newIndex)}}</h4>
        <span (click)="closeModel()" class="material-icons informationIcon">
            close
        </span>
    </div>
    <ng-container *ngIf="newIndex == 2">

        <app-step3-issue (newIssueData)='setData($event)' [issueFromEvent]='true'></app-step3-issue>
    </ng-container>

    <ng-container *ngIf="newIndex == 3">
        <app-step4-issue (newIssueData)='setData($event)' [issueFromEvent]='true'></app-step4-issue>

    </ng-container>

    <ng-container *ngIf="newIndex == 4">
        <app-step5-issue (newIssueData)='setData($event)' [issueFromEvent]='true'></app-step5-issue>

    </ng-container>
    <ng-container *ngIf="newIndex == 5">
        <app-step7_Roles-issue [issueFromEventObject]="newIssue" (newIssueData)='setData($event)' [issueFromEvent]='true'></app-step7_Roles-issue>

    </ng-container>

    <ng-container *ngIf="newIndex == 6">
        <app-step-6-issue (newIssueData)='setData($event)' [issueFromEvent]='true'></app-step-6-issue>

    </ng-container>


</ng-template>