import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StepperService } from '@shared/services';

@Component({
  selector: 'app-selected-rcsa',
  template: `
    <div class="container" *ngIf="selectedRcsa$ | async as selectedRcsa">
      <div><span class="font-weight-bold">RCSA:</span> {{selectedRcsa.risk_assm_name}} <span *ngIf="selectedRcsa.risk_assm_desc">- {{selectedRcsa.risk_assm_desc}}</span></div>
      <div><span class="font-weight-bold">RCSA Owner:</span> {{selectedRcsa.owner}}</div>
    </div>
  `,
  styles: [
    `
        .container {
          display: flex;
          align-items: center;
          width: 100%;
          margin-bottom: 1rem;
          gap: 2rem;
        }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectedRcsaComponent {
  selectedRcsa$ = this.stepperService.selectedRcsa$;
  constructor(private readonly stepperService: StepperService) {
  }
}
