<div class="containerDiv">
    <div class="w-100" *ngIf="viewStatus === 'mainView'">
        <p>
            Please select the best matching risk:
        </p>

        <small>
            (if no risk matches, please proceed without risk selection)</small>
        <app-custom-search class="d-flex justify-content-center mt-4" (value)="Search($event)" [placeholder]="'Search anything...'"></app-custom-search>

        <div class="radioBtnContainer my-4">
            <div *ngIf="risksLists.length == 0" class="d-flex justify-content-center align-items-center loading">Loading...</div>
            <mat-accordion class="fix-radio-position" hideToggle="true">
                <mat-expansion-panel *ngFor='let item of risksLists | searchPipe: { name: searchValue, detail_description: searchValue, description: searchValue}' [disabled]="riskCase.status != 37">
                    <mat-expansion-panel-header>
                        <mat-panel-description class='d-flex' style="align-items: center;">
                            <mat-radio-button (change)="onChangeRB($event.value)" [id]="item.name" [value]="item" [checked]="selectedRisk ? item.risk_id === selectedRisk.id : false" [disabled]="riskCase.status != 37">
                            </mat-radio-button>
                            <div>{{item.name}}: <span>{{item.description}}</span></div>

                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div>
                        <span class="titleRB">Risk Level 1: </span>
                        <span>{{item.risk_level_1.name}}</span>
                    </div>
                    <div>
                        <span class="titleRB">Risk Level 2: </span>
                        <span>{{item.risk_level_2.name}}</span>
                    </div>
                    <div>
                        <span class="titleRB">Risk Detail Description:</span>
                        <p>{{item.detail_description}}</p>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>


        </div>
        <div *ngIf='selectedRisk' class=" w-100 mt-3 d-flex justify-content-start align-items-end">

            <div class="mr-4"> <span class="contantStyle">
                Your Selection:
            </span> </div>
            <div class="d-flex">
                <span>
                    {{selectedRisk.name}}
                </span>

                <span class="clearSelection ml-2 material-icons" matTooltip="Clear your selection" [matTooltipPosition]="'above'" (click)='(riskCase.status == 37) ? onChangeRB(null) : ""'>
                close
            </span></div>

        </div>
        <ng-container *ngIf='viewButton'>
            <div class="row justify-content-end mb-5 w-100 mx-0">
                <button class='primaryBtn mr-4 buttonWidth' pButton type="button" label="Discard Event" [routerLink]="['/discard']" [disabled]="riskCase.status!==RiskCaseConstants.DRAFT_TECHNICAL"></button>

                <button class='primaryBtn mr-4 buttonWidth' pButton type="button" label="Proceed without Risk Selection" [disabled]="selectedRisk" (click)="nextStep(null)"></button>
                <button class='primaryBtn  buttonWidth' pButton type="button" label="Proceed" [disabled]="!selectedRisk" (click)="nextStep(selectedRisk)"></button>
                <div class='d-flex'>
                    <app-information-dialog [accordionIndex]='3'></app-information-dialog>
                </div>

            </div>
        </ng-container>

    </div>
</div>
