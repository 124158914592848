import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { StepperService } from 'src/app/shared/services/stepper.service';
import { environment } from 'src/environments/environment';

export interface Section {
  name: string;
  updated: Date;
}

@Component({
  selector: 'app-hamburgher-menu',
  templateUrl: './hamburgher-menu.component.html',
  styleUrls: ['./hamburgher-menu.component.scss']
})
export class HamburgherMenuComponent implements OnInit {
 showMenu = true;
 environmentVariables = environment;
 requestControlChange = environment.requestControlChange;
 updateRCSA = environment.updateRCSA;
  folders: Section[] = [
    {
      name: 'Photos',
      updated: new Date('1/1/16'),
    },
    {
      name: 'Recipes',
      updated: new Date('1/17/16'),
    },
    {
      name: 'Work',
      updated: new Date('1/28/16'),
    }
  ];
  items: MenuItem[];

  constructor( private stepperService: StepperService,
               private router: Router) { }

  ngOnInit() {
      this.items = [
        {
          label: 'File',
          // icon: 'pi pi-fw pi-plus',
          items: [
              {label: 'New', icon: 'pi pi-fw pi-plus'},
              {label: 'Download', icon: 'pi pi-fw pi-download'}
          ]
      },
      {
          label: 'Edit',
          items: [
              {label: 'Add User', icon: 'pi pi-fw pi-user-plus'},
              {label: 'Remove User', icon: 'pi pi-fw pi-user-minus'}
          ]
      }];
  }
  handler(data){
    this.showMenu = data;
  }

  newCase() {
    this.stepperService.resetRiskCase();
    this.router.navigate(['/new-risk/mandate']);
  }


  goOutsideApp(url){
    window.open(url, '_blank');


  }

  onFaqLinkClicked() {
    window.open(environment.faqUrl, '_blank');
  }
}
