import { Component, TemplateRef, OnInit, ViewChild, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { FilterUtils } from 'primeng/utils';
import { RiskCase } from 'src/app/shared/models/dto/risk-case.dto';
import { UserValidationRoles as UserValidation } from 'src/app/shared/models/validation/userValidations';

import { Table } from 'primeng/table/table';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { BaseService } from 'src/app/shared/services/base.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

import * as moment from 'moment';
import { MsalService, StepperService } from 'src/app/shared/services';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { RiskCaseConstants, UserRoleConstants } from 'src/app/modules/new-risk/common-constants';
import { RiscCaseRequest as RiskCaseMessage } from 'src/app/shared/models/dto/risk-case-request.dto';
import { concatMap } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-my-risk-cases',
  templateUrl: './my-risk-cases.component.html',
  styleUrls: ['./my-risk-cases.component.scss']
})
export class MyRiskCasesComponent implements OnInit , OnChanges {
  @Input() url: string ;
  @Input() all: boolean;
  @Input() filterAttr: string;
  @Input() loggedUserRole: string;

  @Input() statusListDefault = [];
  @Input() statusListDefaultIssue = [];
  @Input() statusList = [];
  @Input() issueStatusList = [];
  hasData = false;

  oneOrm;
  loading = false;
  @Output() userRole = new EventEmitter();
  @Output() refreshTabs = new EventEmitter();
  allCasesList = [];
  pageNumber: number = 0;
  filterHistory = {};


  modalRef: BsModalRef;
  allUserRole = UserRoleConstants;
  caseType = 'Event';
  defaultColsEvent = [
    { field: 'rc_source_name', header: 'ID', filter: '', mode: '', isVisible: true, index: 0 },
    { field: 'title', header: 'Title', filter: '', mode: '', isVisible: true, index: 1 },
    { field: 'days_until_deadline', header: 'Remaining Days to Next Deadline', filter: '31', mode: '', isVisible: true, index: 2},
    { field: 'status', header: 'Case Status', filter: '', mode: '', isVisible: true, index: 3 },
    { field: 'loss', header: 'Net Loss Amount', filter: '', mode: '', isVisible: false, index: 5 },
    { field: 'creation_date', header: 'Creation Date', filter: '', mode: '', isVisible: false, index: 6 },
    { field: 'submission_date', header: 'Submission Date', filter: '', mode: '', isVisible: true, index: 7 },
    { field: 'closure_date', header: 'Closure Date', filter: '', mode: '', isVisible: false, index: 8},
    { field: 'cancellation_date', header: 'Cancellation Date', filter: '', mode: '', isVisible: false, index: 9},
    { field: 'creator_name', header: 'Creator', filter: '', mode: '', isVisible: false, index: 10, subfield: 'user_name' },
    { field: 'owner_name', header: 'Owner', filter: '', mode: '', isVisible: true, index: 15, subfield: 'user_name' },
    { field: 'rcsa_owner_name', header: 'RCSA Owner', filter: '', mode: '', isVisible: false, index: 11, subfield: 'user_name' },
    { field: 'delegate_name', header: 'Delegate', filter: '', mode: '', isVisible: false, index: 11, subfield: 'user_name' },
    { field: 'risk_manager_name', header: 'Risk Manager', filter: '', mode: '', isVisible: false, index: 12, subfield: 'user_name' },
    { field: 'accounting_adjustments', header: 'Accounting Adjustments', filter: '', mode: '', isVisible: false, index: 13, subfield: 'user_name' },
    { field: 'operational_gains', header: 'Operational Gains', filter: '', mode: '', isVisible: false, index: 14, subfield: 'user_name' },
    { field: 'bu_gf', header: 'BU/GF', filter: '', mode: '', isVisible: false, index: 16 },

    { field: 'delete', header: '', filter: '', mode: '', isVisible: true, index: 17, subfield: '' },
  ];
  lodList = [
    {label: 'Yes, self-identified.', value:'Yes, self-identified.'},
    {label: 'No, identified by a 2nd line function.', value:'No, identified by a 2nd line function.'},
    {label: 'No, identified by a 3rd line function.', value:'No, identified by a 3rd line function.'},
  ];
  defaultColsIssue = [
    { field: 'rc_source_name', header: 'ID', filter: '', mode: '', isVisible: true, index: 0 },

    { field: 'title', header: 'Title', filter: '', mode: '', isVisible: true, index: 1 },
    { field: 'days_until_deadline', header: 'Remaining Days to Next Deadline', filter: '31', mode: '', isVisible: true, index: 2 },
    { field: 'status', header: 'Case Status', filter: '', mode: '', isVisible: true, index: 3 },

    { field: 'creation_date', header: 'Creation Date', filter: '', mode: '', isVisible: false, index: 6 },
    { field: 'submission_date', header: 'Submission Date', filter: '', mode: '', isVisible: false, index: 7 },
    { field: 'closure_date', header: 'Completion Date', filter: '', mode: '', isVisible: false, index: 8 },
    { field: 'cancellation_date', header: 'Cancellation Date', filter: '', mode: '', isVisible: false, index:9 },
    { field: 'target_due_date', header: 'Target Due Date', filter: '', mode: '', isVisible: true, index:9 },
    { field: 'delegate_name', header: 'Delegate', filter: '', mode: '', isVisible: false, index: 11, subfield: 'user_name' },

    { field: 'creator_name', header: 'Creator', filter: '', mode: '', isVisible: false, index: 10, subfield: 'user_name' },
    { field: 'owner_name', header: 'Owner', filter: '', mode: '', isVisible: true, index: 11, subfield: 'user_name' },
    { field: 'rcsa_owner_name', header: 'RCSA Owner', filter: '', mode: '', isVisible: false, index: 12, subfield: 'user_name' },
    { field: 'risk_manager_name', header: 'Risk Manager', filter: '', mode: '', isVisible: false, index: 13, subfield: 'user_name' },

    { field: 'lod', header: 'LoD', filter: '', mode: '', isVisible: false, index: 14 },
    { field: 'source', header: 'Source', filter: '', mode: '', isVisible: false, index:15 },
    { field: 'impact_rating', header: 'Impact rating', filter: '', mode: '', isVisible: false, index:16 },

    // { field: 'bu_gf', header: 'Type', filter: '', mode: '', isVisible: false, index: 17 },
    { field: 'bu_gf', header: 'BU/GF', filter: '', mode: '', isVisible: false, index: 18 },



    { field: 'delete', header: '', filter: '', mode: '', isVisible: true, index: 19, subfield: '' },


  ];
  classNames: any[];


  RiskCaseConstants = RiskCaseConstants;
  loadData = true;
  cols: any[];
  backupCols: any[];
  riskCaseId;
  risks: RiskCase[] = [];
  nextDeadlineFilter: number;
  nextDeadlineTimeout;
  hasFilters = false;
  isConfigView = false;
  selectedColumns = [];
  selectedColumnsBackup = [];
  updating = false;
  timeout: any;
  comment = '';
  loggedUser: string;
  changeStatusBody = {'id': null,    'rcId': null,    'rcsc_status_id': null,    'rcsc_status_after_id': null,    'rcsc_action_id': null,    'rcsc_action_date' :null,    'rcsc_actor_id': '',    'rcsc_action_comment': ''  };

  @ViewChild('dt') table: Table;

  constructor(private baseService: BaseService,
              public stepperService: StepperService,
              private modalService: BsModalService,
              private router: Router, private spinner: NgxSpinnerService,
              private confirmationService: ConfirmationService,
              private msalService: MsalService) {
    this.filterHistory =  this.stepperService.filtteredData[this.filterAttr+this.caseType] ;


    if (this.msalService.getLoggedUser()) {
      this.loggedUser = this.msalService.getLoggedUser().user_id;
    }
    if(this.stepperService.enumItemsStoredData && this.stepperService.enumItemsStoredData[this.filterAttr] && this.filterAttr){
      this.allCasesList = this.stepperService.enumItemsStoredData[this.filterAttr];
      this.filterCase(this.stepperService.selectedType[this.filterAttr]);

    }
  }

  ngOnChanges(){
    if(this.stepperService.selectedType){
      this.caseType = this.stepperService.selectedType[this.filterAttr];

    }
    console.log(this.statusList);

    this.loadUserSettings();
    if(this.url && !this.hasData){
      this.loading = true;
      this.spinner.show(this.filterAttr);
      if(this.stepperService.enumItemsStoredData && this.stepperService.enumItemsStoredData[this.filterAttr] && this.filterAttr){
        this.allCasesList = this.stepperService.enumItemsStoredData[this.filterAttr];
        this.filterCase(this.stepperService.selectedType[this.filterAttr]);




      }
      this.hasData = true;
      this.baseService.get(this.url).subscribe(res => {
        this.allCasesList = res.body.data;
        this.stepperService.enumItemsStoredData[this.filterAttr] = this.allCasesList;
        this.filterCase(this.stepperService.selectedType[this.filterAttr]);



      });
    }

    if (this.msalService.getLoggedUser()) {
      this.loggedUser = this.msalService.getLoggedUser().user_id;
    }
  }
  ngOnInit(): void {

    FilterUtils['dateInBetween'] = (value, dateFilters): boolean => {
      if (moment(value, environment.dateFormat).isBetween(dateFilters[0], dateFilters[1])) {
        return true;
      }
      return false;
    };

    FilterUtils['numbersInBetween'] = (value, dateFilters): boolean => {
      if (value >= dateFilters[0] && value <= dateFilters[1]) {
        return true;
      }
      return false;
    };

    this.classNames = [
      { label: 'Issue', value: 'Issue' },
      { label: 'Event', value: 'Event' }
    ];

    this.loadUserSettings()

    FilterUtils['custom'] = (value, filter): boolean => {
      if (filter === undefined || filter === null || filter.trim() === '') {
        return true;
      }

      if (value === undefined || value === null) {
        return false;
      }

      // tslint:disable-next-line:radix
      return parseInt(filter) > value;
    };
  }

  // reloads the record every time the tab is selected
  refresh() {
    this.spinner.show(this.filterAttr);
    this.baseService.get(this.url).subscribe(res => {
      this.allCasesList = res.body.data;
      this.filterCase(this.stepperService.selectedType[this.filterAttr]);
    });
  }
  filterCase(caseType){
    this.caseType = caseType;
    this.stepperService.selectedType[this.filterAttr] = caseType;
    if(this.table){
      this.table.reset();
    }
    this.filterHistory =  this.stepperService.filtteredData[this.filterAttr+this.caseType] ;
    this.loadUserSettings();
    this.risks = [];
    if(this.caseType){
      this.allCasesList.forEach(element => {
        if(element.class_name === caseType){
          this.risks.push(element);
        }
      });
      Object.keys(this.filterHistory).forEach(key => {
        console.log(key);

        this.filterTable(this.filterHistory[key].valueT, this.filterHistory[key].fieldT, this.filterHistory[key].typeT)
      });
    }else{
      this.risks = this.allCasesList;

    }

    if (this.msalService.getLoggedUser()) {
      this.loggedUser = this.msalService.getLoggedUser().user_id;
    }
    if(this.table) {this.table.pageLinks = 5;}
    this.spinner.hide(this.filterAttr);

  }

  getDate(date) {
    date = new Date(date * 1000);
    return date;
  }
  newDate(date){
    let newDate;
    if(typeof date == 'object' && date.length > 0){
      newDate = date.join('/');
    }else if(typeof date == 'string' && date.length > 0){
      newDate = date.substring(0, 10);
      newDate = newDate.replace(/-/g, "/");
      return new Date(newDate);

    }

    else{
      newDate = date;
    }
    return new Date(newDate + ' 12:00:00');
  }
  onDeadlineChange(event, field, filterMode) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.table.filter(event.value, field, filterMode);
    }, 250);
    if (event.value !== 31) {
      this.hasFilters = true;
    }
  }

  clearFilters() {
    this.statusListDefaultIssue = [];
    this.statusListDefault = [];
    this.cols = this.cols.map(col => {
      col.filter = '';
      if (col.field === 'nextDeadline') {
        col.filter = 31;
      }
      return col;
    });
    this.hasFilters = false;
    this.filterHistory = {};
    this.stepperService.filtteredData[this.filterAttr+this.caseType] = {};
    this.table.reset();
  }

  filterTable(value: any, field: string, type: string) {
    this.hasFilters = true;
    this.stepperService.filtteredData[this.filterAttr+this.caseType][field] = {valueT: value, fieldT: field, typeT: type};
    this.filterHistory =  this.stepperService.filtteredData[this.filterAttr+this.caseType] ;
    if(!this.cols){ return;}

    if(this.table) {this.table.filter(value, field, type);}


  }

  configureTable() {
    this.isConfigView = !this.isConfigView;
    this.backupCols = JSON.parse(JSON.stringify(this.cols));
    this.selectedColumnsBackup = this.selectedColumns;

  }

  getVisibleCols = (cols: any[]) => cols.filter(col => this.selectedColumns.indexOf(col.field) !== -1);

  updateSelectedColumns() {
    this.cols.map(col => {
      col.isVisible = this.selectedColumns.indexOf(col.field) !== -1;
      return col;
    });
  }

  drop(event: any) {
    this.updating = true;
    const colNames = this.cols.map(col => col.field);
    moveItemInArray(colNames, event.previousIndex, event.currentIndex);
    this.cols.map(col => {
      col.index = colNames.indexOf(col.field);
      return col;
    });
    this.cols.sort(this.sortCols);
    this.updating = false;

  }

  private sortCols(a, b) {
    return a.index - b.index;
  }

  cancelConfiguration() {
    this.cols = this.backupCols;
    this.selectedColumns = this.selectedColumnsBackup;
    this.isConfigView = !this.isConfigView;
  }

  saveConfiguration() {
    let settingsColumn = this.filterAttr == 'myCases' ? 'my_cases' : 'all_cases';
    settingsColumn += '_' + this.caseType.toLowerCase();
    this.baseService.saveUserSettings(settingsColumn, this.cols);
    this.configureTable();
  }

  onRowSelect(event: any): void {
    this.spinner.show(this.filterAttr);
    this.stepperService.loadRiskCaseById(event.data.risk_case_id);
  }

  showDiscardDialog(event: any, id: number, templet) {
    event.preventDefault();
    event.stopPropagation();
    if(!id){
      this.router.navigate(['/']);
      return;
    }else{
      this.riskCaseId = id;
    }
    this.openModal(templet);

  }

  showCancelDialog(event: any, id: number, templet) {
    event.preventDefault();
    event.stopPropagation();
    if(!id){
      this.router.navigate(['/']);
      return;
    }else{
      this.riskCaseId = id;
    }
    this.openModal(templet);
  }

  setDeleteStyle(fieldName) {
    let styles = {};
    if(fieldName === 'title'){
      styles={
        'width': '30%',

      };
      return styles;

    }
    if (fieldName === 'delete') {
      styles = {
        'width': '50px',
        'padding': '0',
        'text-align': 'center',
        'padding-top': ' 5px',
      };
      return styles;
    }

  }



  openModal(template: TemplateRef<any>) {
    this.comment = '';
    const configs = {
      keyboard: false,
      ignoreBackdropClick: true,
      animated: true,
      class: 'modal-lg modal-primary RCSAModal',
    };
    this.modalRef = this.modalService.show(template, configs);
  }

  deleteEvent(id){
    if(!id){
      this.router.navigate(['/']);
      return;
    }
    this.closeModel();
    this.spinner.show(this.filterAttr);
    this.baseService.get('risk-case/' + id).pipe(
      concatMap(res => {
        this.stepperService.etag = res.headers.get('ETag').replace(/"/g, '');
        return this.baseService.delete('risk-case/' + id, this.stepperService.etag)
      })).subscribe(
        success => { this.refresh();this.refreshTabs.emit(this.filterAttr); },
        errorData => { console.log('error: ' + errorData) }
      );
  }
  cancelEvent(id){
    if(!id){
      this.router.navigate(['/']);
      return;
    }
    this.spinner.show(this.filterAttr);
    this.changeStatusBody.rcsc_action_comment = this.comment;

    this.closeModel();
    this.baseService.get('risk-case/' + id).pipe(
      concatMap(res => {
        this.stepperService.etag = res.headers.get('ETag').replace(/"/g, '');
        const riskCaseUpdatedData = res.body.risk_case;
        this.changeStatusBody.rcId = riskCaseUpdatedData.id;
        this.changeStatusBody.rcsc_action_date = new Date();
        this.changeStatusBody.rcsc_action_id = ((this.caseType === 'Event') ? 219 : 274);
        this.changeStatusBody.rcsc_actor_id = this.msalService.getLoggedUser().user_id;
        this.changeStatusBody.rcsc_status_after_id =  ((this.caseType === 'Event') ? 11 : 36);
        this.changeStatusBody.rcsc_status_id = riskCaseUpdatedData.status.id;
        riskCaseUpdatedData.risk_assessments = [];
        if(res.body.risk_assessment){
          riskCaseUpdatedData.risk_assessments.push(res.body.risk_assessment);
        }
        if(res.body.risks_primary_path){riskCaseUpdatedData.risk =  res.body.risks_primary_path; }
        if(this.checkList(res.body.risk_assessments_primary_path)){riskCaseUpdatedData.risk_assessments =  riskCaseUpdatedData.risk_assessments.concat(res.body.risk_assessments_primary_path);}
        const riskCase = RiskCaseMessage.toModel(riskCaseUpdatedData);
        if(riskCase.event){
          riskCase.status = RiskCaseConstants.CANCELLED;

        }
        if(riskCase.issue){
          riskCase.status = 36;

        }
        riskCase.lastStep = 99;
        riskCase.cancel_date = new Date();
        riskCase.cancel_comment = this.comment;
        riskCase.cancelled_by = this.msalService.getLoggedUser();





        return this.baseService.put('risk-case', RiskCaseMessage.toDto(riskCase), this.stepperService.etag, id);
      })).subscribe(
        success => {
          this.baseService.post('status-change', this.changeStatusBody).subscribe(datachangeStatus => {
            console.log(datachangeStatus);
            this.refresh(); this.closeModel();this.refreshTabs.emit(this.filterAttr);
          }, errordatachangeStatus => {
              console.log(errordatachangeStatus);
          },
          () => {

          });
          },
        errorData => { this.closeModel(); console.log('error: ' + errorData)}
      );
  }
  closeModel(){
    this.modalRef.hide();

  }
  userRoles(user, list){
    const isUser = UserValidation.userRole(user, list);
    return  isUser;
  }
  checkAllLists(list){
    for (const iterator of list) {
      if(this.userRoles(iterator.user,iterator.list)){
        return  true;
      }
    }
    return  false;
  }



changeSort(sortedField){
  console.log(sortedField);
  this.stepperService.sortedColumn[this.filterAttr+this.caseType] = sortedField;
}
sortedColumnNme(){
  if(this.stepperService.sortedColumn){
    if(this.stepperService.sortedColumn[this.filterAttr+this.caseType]){
      return this.stepperService.sortedColumn[this.filterAttr+this.caseType];

    }else{
      return 'days_until_deadline';
    }
  }
  return 'days_until_deadline';

}
paginate(page){
  console.log(page);
  this.stepperService.pageNumber[this.filterAttr+this.caseType] = page.first;


}
getPageNumber(){
  if(this.stepperService.pageNumber){
    if(this.stepperService.pageNumber[this.filterAttr+this.caseType]){
      return this.stepperService.pageNumber[this.filterAttr+this.caseType];

    }else{
      return 0;
    }
  }
  return 0;

}
getColumnValueSearch(value,attr){
  if(value && value[attr]){
    return  value[attr].valueT;

  }
  return '';
}

  private loadUserSettings(){
    let settingsColumn = this.filterAttr == 'myCases' ? 'my_cases' : 'all_cases';
    settingsColumn += '_' + this.caseType.toLowerCase();
    console.log('loading settings for ' +settingsColumn);
    if(this.stepperService.enumItemsStoredData && this.stepperService.enumItemsStoredData[settingsColumn]){
      if(this.caseType === 'Event'){
        this.cols = this.stepperService.enumItemsStoredData[settingsColumn] && this.stepperService.enumItemsStoredData[settingsColumn].length > 0 ? this.stepperService.enumItemsStoredData[settingsColumn] : this.defaultColsEvent;
      } else {
        this.cols = this.stepperService.enumItemsStoredData[settingsColumn] && this.stepperService.enumItemsStoredData[settingsColumn].length > 0 ? this.stepperService.enumItemsStoredData[settingsColumn] : this.defaultColsIssue;
      }
      this.cols.forEach(element => {
        if(!element.header.includes('(CET)')){
          element.header = element.header.replace('Date', 'Date (CET)');
        }

      });

      this.selectedColumns = this.cols.filter( col => col.isVisible);
    }else{
      if(this.caseType === 'Event'){
        this.cols = this.defaultColsEvent;
      } else {
        this.cols = this.defaultColsIssue;
      }
      this.baseService.loadUserSettings(settingsColumn).subscribe( res =>{
        this.stepperService.enumItemsStoredData[settingsColumn] = res.body;
        if(this.caseType === 'Event'){
          this.cols = res.body && res.body.length > 0 ? res.body : this.defaultColsEvent;
        } else {
          this.cols = res.body && res.body.length > 0 ? res.body : this.defaultColsIssue;
        }
        this.cols.forEach(element => {
          if(!element.header.includes('(CET)')){
            element.header = element.header.replace('Date', 'Date (CET)');
          }

        });

        this.selectedColumns = this.cols.filter( col => col.isVisible);
      });
    }


  }
byteCount(textValue, maxlength) {
  if(!textValue){
    return {valid:true,length:0 };

  }
  if(encodeURI(textValue).split(/%..|./).length - 1 > maxlength){
    return {valid:false,length:encodeURI(textValue).split(/%..|./).length - 1 };

  }
  return {valid:true,length:encodeURI(textValue).split(/%..|./).length - 1 };
}
checkList(list){
  if(!list){return false;}
  if(list.length ==0){return false;}
  return true;
  }
}
