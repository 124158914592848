<div class="containerDiv">
    <div *ngIf="viewStatus === 'mainView'">
        <form [formGroup]="form">
            <p class="mb-2 t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.Description.Title)">
                Event Title:
            </p>

            <div class="mb-3 w-50 inputField ">
                <small class="form-text  text-right" [ngClass]="(!Utils.byteCount(eventTitleInput.value,120).valid)?'warningTxt':''">{{eventTitleInput.value.length}} / 120 </small>

                <textarea id="eventTitleInput" #eventTitleInput cols="50" maxlength='120' rows="2" placeholder="(Event Title)" formControlName="title">
                   </textarea>
                <small>
                    <span *ngIf='!Utils.byteCount(eventTitleInput.value,120).valid' class="warningTxt">
                        The text entered exceeds the maximum number of characters allowed. Please reduce the text length up to the defined limit.
                    </span>
                     </small>

            </div>




            <p class="mb-2  t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.Description.DiscoveryDate)">
                When was this event discovered?
            </p>
            <div class="mb-2 w-100 d-flex justify-content-start">
                <div>
                    <div class="input-container position-relative">
                        <div (click)='discoveryDate.open()' class=" inputIcon material-icons">
                            date_range
                        </div>
                        <input class="input-field" (keypress)="cantEdit($event)" placeholder="DD-MMM-YYYY" [disabled]='true' class="notDisabledInput" [ngModelOptions]="{standalone: true}" [ngModel]="form.value.discoveryDate | date: dateFormat" name="event.discoveryDate">

                        <input [max]="today" [matDatepicker]='discoveryDate' class="input-field matrialInputH" placeholder="dd-mm-yyyy" formControlName="discoveryDate" name="event.discoveryDate" (dateChange)="dateChanged($event.value, 'discoveryDate')">
                        <mat-datepicker #discoveryDate></mat-datepicker>
                    </div>
                </div>
                <div *ngIf='showError' class="ml-4 errorMsg">
                    <p class="mb-1">
                        You have already entered one or more monetary impact items with ‘Date of Acknowledgement’ BEFORE this Discovery Date.

                    </p>
                    <p class="mb-1">
                        You need to update the ‘Date of Acknowledgement’ of these items before you can proceed with this ‘Discovery Date’.
                    </p>
                </div>
            </div>

            <p class="mb-2  t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.Description.OccurrenceDate)">
                When did this event occur?
            </p>

            <div class="mb-2 w-100 d-flex justify-content-start">
                <div>
                    <div class="input-container position-relative">
                        <div (click)='occurredDate.open()' class=" inputIcon material-icons">
                            date_range
                        </div>
                        <input (keypress)="cantEdit($event)" class="input-field" placeholder="DD-MMM-YYYY" [disabled]='true' class="notDisabledInput" [ngModelOptions]="{standalone: true}" [ngModel]="form.value.occurredDate | date: dateFormat" name="event.occurredDate">

                        <input [max]="form.value.discoveryDate" [matDatepicker]='occurredDate' class="input-field matrialInputH" placeholder="dd-mm-yyyy" formControlName="occurredDate" (dateChange)="dateChanged($event.value, 'occurredDate')">
                        <mat-datepicker #occurredDate></mat-datepicker>
                    </div>
                </div>
            </div>
            <p class="mb-2 t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.Description.DetailDescription)">
                Please provide a more detailed description:
            </p>
            <ul class="mb-2 dULDesc">
                <li>what happened?</li>
                <li>where did it happen?</li>
                <li>how was it discovered? </li>
            </ul>
            <small>(please do not include personal and sensitive information such as names, gender, salaries, etc.)</small>

            <app-text-analysis [control]="form.controls['description']"></app-text-analysis>

            <div class="d-flex justify-content-end w-100 mb-5">
                <button pButton type="button" label="Proceed" class='primaryBtn  buttonWidth ' [disabled]='canGo()||!Utils.byteCount(form.controls["description"].value,4000).valid||!Utils.byteCount(eventTitleInput.value,120).valid' (click)="nextStep()"></button>
                <app-information-dialog [accordionIndex]='2'></app-information-dialog>
            </div>
        </form>

    </div>
</div>
