

<div *ngIf="riskCase.event;else other_content">
    <app-step7-event></app-step7-event>
</div>

<ng-template #other_content>
    <app-step7_Roles-issue></app-step7_Roles-issue>

</ng-template>





