<div class="w-100">

    <div class="py-4 contantStyle  t-demo__btn">
        Attachments
    </div>

    <ng-container *ngIf='uploadedList.length > 0'>
        <p-table sortField="creation_date" [sortOrder]="-1" [columns]="cols" scrollHeight="26.4rem" class="flexcolumn" [value]="uploadedList" [paginator]="true" [scrollable]="true"
            [reorderableColumns]="false" [rows]="10" #dt>
            <ng-template pTemplate="caption">
                <div style="display: flex; justify-content: space-around; align-items: center;">
                    <span> Total Records: {{dt.totalRecords}}</span>


                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr style="height:5rem">
                    <ng-container *ngFor="let col of cols" [ngSwitch]="col.field">
                        <th *ngIf="col.isVisible" class="columnStyle" [ngStyle]="setDeleteStyle(col.field)">

                            {{col.header}}



                        </th>
                    </ng-container>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData" class="cursor-pointer">
                    <ng-container *ngFor="let col of cols">
                        <td *ngIf="col.isVisible" class="columnStyle" [ngStyle]="setDeleteStyle(col.field)">

                            <ng-container *ngIf="col.field==='description' || col.field==='file_name'">
                                {{rowData[col.field] }}
                            </ng-container>

                            <ng-container *ngIf="col.field==='creation_date'">
                                {{rowData[col.field] | oneOrmDate}}
                            </ng-container>

                            <ng-container *ngIf="col.field==='creator'">
                                {{rowData[col.field].user_name}}
                            </ng-container>

                            <ng-container *ngIf="col.field==='type'">
                                {{getControl(rowData[col.field]).label }}
                            </ng-container>


                            <ng-container *ngIf="col.field==='icons'">
                                <span (click)="downloadAttachment(rowData.id, rowData.file_name)" class="tableAttachmentIcon  material-icons">
                                    get_app
                                </span>
                                <span *ngIf="canModify()" (click)="deleteFile(rowData.id)" class="tableAttachmentIcon  material-icons">
                                    delete
                                </span>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>

            </ng-template>
        </p-table>

    </ng-container>
    <ng-container *ngIf='uploadedList.length == 0'>
        <div class="noRecord">
            No records available!
        </div>
    </ng-container>

    <ng-container *ngIf="canModify()">
        <div class="my-4 row">
            <div class="col-md-5">
                <div [ngClass]="!type?'required':''">
                    Attachment Type

                </div>
                <p-dropdown [options]="documentTypeList" placeholder="Select document type" [(ngModel)]="type" class="filter-input" optionLabel="label">
                </p-dropdown>


            </div>



            <div class="col-md-5">
                <div class="counterWidth d-flex justify-content-between">

                    <div>
                        Description

                    </div>

                    <small class="counterText " [ngClass]="(!byteCount(descriptionAttachment.value,200).valid)?'warningTxt':''">{{byteCount(descriptionAttachment.value,200).length}} / 200 </small>

                </div>




                <textarea cols="50" id="filedescription" #descriptionAttachment [(ngModel)]="description" rows="2" maxlength="200">
                </textarea>
                <p *ngIf='!byteCount(descriptionAttachment.value,200).valid' class="warningTxt">
                    The text entered exceeds the maximum number of characters allowed. Please reduce the text length up to the defined limit.
                </p>
            </div>
        </div>
      <div class="my-4 row">
        <div class="col-md-12 ">

          <div class=" mt-4 browsBtn" [ngStyle]="setDeleteStyle('uploadBtn')">

            <p-fileUpload  #fu id="fileUpload" name="fileNames[]" mode="basic" chooseLabel="Choose files to upload" [multiple]="true" (onUploadPdf)="onUploadFile($event)"
                          (onSelect)="onSelect($event, fu)" withCredentials="true" maxFileSize="26214400" invalidFileSizeMessageSummary="{0}: Invalid file size"
                          invalidFileSizeMessageDetail="maximum upload size is {0}." invalidFileTypeMessageSummary="{0}: Invalid file type" accept="{{acceptedFiles}}">
            </p-fileUpload>
            <div class="mt-2">
              <p-message severity="warn" text="When attaching documents, make sure that they do not contain any sensitive or personal information."></p-message>
            </div>
          </div>
          <div class=" mt-4 row" *ngIf="this.toUploadList.length > 0">
            <ng-container *ngFor="let item of toUploadList; let i = index">
              <div class="col-md-10 ">
                {{item.file_name}} ({{item.file_size}})
              </div>

              <div class="col-md-2 ">
                            <span (click)="removeFile(i,fu)" class="tableAttachmentIcon  material-icons">
                                delete
                            </span>

              </div>

            </ng-container>
            <div class="mt-2 col-md-12">
              <p-message severity="warn" text="When attaching documents, make sure that they do not contain any sensitive or personal information."></p-message>
            </div>


          </div>

        </div>
      </div>

        <div class="d-flex justify-content-end mr-4">
            <button pButton class=' primaryBtn mr-4  buttonWidth' icon="pi pi-trash" id="clearFilterButton" label="Clear" (click)="clear(fu)" [disabled]="this.toUploadList.length == 0"></button>
            <button pButton class=' primaryBtn   buttonWidth' icon="pi pi-upload " id="uploadFileButton" label="Upload" (click)="upload(fu)" [disabled]="isUploadDisabled()"></button>
            <ng-container *ngIf="stepperService.newRisk.event">
                <app-information-dialog [accordionIndex]='16'></app-information-dialog>

            </ng-container>
            <ng-container *ngIf="stepperService.newRisk.issue">
                <app-information-dialog [accordionIndex]='14'></app-information-dialog>

            </ng-container>
        </div>

    </ng-container>





    <div class="d-flex justify-content-end mt-4 mr-30px">
        <button class='primaryBtn mr-4  buttonWidth' pButton type="button" label="Close" (click)="hideDialog()"></button>
    </div>
</div>
