import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'searchPipe'
})
export class SearchPipe implements PipeTransform {
    transform(list: any[], filterBy: any, defaultFilter?: boolean): any[] {
        if (!filterBy || !Object.values(filterBy)[0]) {
            return list;
        }

        if (!Array.isArray(list)) {
            return list;
        }
        if (filterBy && Array.isArray(list)) {
            const filterKeys = Object.keys(filterBy);
            if (defaultFilter) {
                return list.filter(item =>
                    filterKeys.reduce((x, keyName) =>
                        (x && new RegExp(filterBy[keyName], 'gi').test(item[keyName])) || filterBy[keyName] == "", true));
            } else {
                return list.filter(item => {
                    return filterKeys.some((keyName) => {
                        return new RegExp(filterBy[keyName], 'gi').test(item[keyName]) || filterBy[keyName] == "";
                    });
                });
            }
        }
    }
}