import { Component, OnInit } from '@angular/core';
import {BaseService} from '@shared/services';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit {

  message = 'Loading attachment';
  messageClass = 'bg-info';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private baseService: BaseService) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe((params) => {
      this.baseService.getBlob(decodeURIComponent(params['path']), params['file_name']).subscribe(res => {
        this.message = 'Attachment loaded successfully';
        this.messageClass = 'bg-success';
        window.open(res, '_this');
      }, err => {
        this.message = 'Attachment load failed';
        this.messageClass = 'bg-danger';
      });
    });
  }

}
