<div class="containerDiv">
    <div class="w-100" *ngIf="viewStatus === 'mainView'">
        <ul class="mb-3 dUL draftList">
            <li> Thank you for recording this issue. It is now in status <span>draft</span>.

            </li>
            <li>
                Unresolved issues put Swiss Re at risk. We therefore encourage you document the remaining information and agreed resolution approach as quickly as feasible, so that resolution can start.

            </li>

            <li>
                Note that
                <ng-container *ngIf='riskCase.roles.eventOwner.user_id === loggedUser'> you</ng-container>, <span>{{riskCase.roles.eventOwner.user_name}}</span> <span *ngIf='riskCase.roles.eventDelegate'> or {{ riskCase.roles.eventDelegate.user_name}}
                </span> should submit the issue to the assigned Risk Manager <span>{{riskCase.risk_manager.user_name}}</span> by <span>{{getDate(today30) | oneOrmDate}}</span>.

            </li>
            <li>
                You can also cancel the issue as long as it is in status draft.
            </li>
            <li>
                Do not hesitate to contact your colleagues from Governance or Risk Management in case you need support.
            </li>
        </ul>
        <div class="row justify-content-end mb-5 w-100 mx-0">


            <button class='primaryBtn mr-4 buttonWidth' pButton type="button" label="Continue" (click)="nextStep()"></button>

        </div>
    </div>
</div>
