import { Component, OnInit,OnDestroy } from '@angular/core';
import { NewRisk } from 'src/app/shared/models/new-risk.model';
import { StepperService } from '../../../../shared/services/stepper.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-step11',
  templateUrl: './step11.component.html',
  styleUrls: ['./step11.component.scss']
})
export class Step11Component implements OnInit, OnDestroy {
  viewStatus: string;
  today30 = new Date(); // Now
  riskCase: NewRisk;
  subscription: Subscription = new Subscription();
  caseType;
  constructor(private router: Router,private stepperService: StepperService) {
    this.caseType = stepperService.caseType;

    this.viewStatus = 'mainView';
    this.today30.setDate(this.today30.getDate() + 30); // Set now + 30 days as the new date
    this.riskCase = this.stepperService.newRisk;
  }

  ngOnInit(): void {
    this.subscription.add(  this.stepperService.saveCurrentStep.subscribe(() =>   this.router.navigate(['/risk-case/' + (this.riskCase.issue ? 'issue' : 'event') + '/' + this.riskCase.riskCaseId])));


  }

  nextStep() {
    this.riskCase.lastStep = 99;
    this.stepperService.updateRiskCase(this.riskCase);
  }

  changeViewStatus(status: string) {
    this.viewStatus = status;
  }
  ngOnDestroy(){
     this.subscription.unsubscribe();
  }
}
