<div class="row">
    <div class="col-md-9 mb-3 inputField ">
        <small class="form-text  text-right" [ngClass]="(!Utils.byteCount(description.value,4000).valid)?'warningTxt':''">{{Utils.byteCount(description.value,4000).length}} / 4000 </small>
        <div mwlTextInputHighlightContainer>
            <textarea mwlTextInputElement class="mb-3" rows="10" cols="50" [formControl]="control" maxlength="4000" #description (click)="resetTags()"></textarea>
            <mwl-text-input-highlight [tags]="tags" [tagCssClass]="'bg-blue'" [textInputElement]="description" (tagMouseEnter)="addDarkClass($event.target)"
                (tagMouseLeave)="removeDarkClass($event.target)">
            </mwl-text-input-highlight>
        </div>
        <br>
        <small>
            <span *ngIf='!Utils.byteCount(description.value,4000).valid' class="warningTxt">
                The text entered exceeds the maximum number of characters allowed. Please reduce the text length up to the
                defined limit.
            </span>
        </small>
    </div>
    <ng-container>
        <div class="col-md-3 mt-4 inputField ">
            <button pButton type="button" label="Check Text Quality" (click)="analyze(description.value)" ngxTippy
                [tippyProps]="tooltip('Check Text Quality is an experimental feature to support improving data quality when describing an issue or event.', 'bottom')"></button>

            <div *ngIf='analysisResult'>
                <div class="relative">
                    <canvas baseChart [data]="doughnutChartData" [chartType]="'doughnut'" [options]="options" [colors]="chartColors"
                    class="canvas" [plugins]="plugins" ></canvas>
                </div>
                <div class="expand-right">
                    <small>
                        {{analysisResult.Readability_text}}
                    </small>
                </div>

                <div>
                    <div class="d-flex align-items-center">
                        <div class="rectangle bg-blue"></div><small class="ml-1">Abbreviations</small>
                    </div>
                    <div class="d-flex align-items-center">
                        <div class="rectangle bg-pink"></div><small class="ml-1">Spelling mistakes</small>
                    </div>
                    <div class="d-flex align-items-center">
                        <div class="rectangle bg-yellow"></div><small class="ml-1">Sensitive information</small>
                    </div>
                </div>

                <div class="mt-2 expand-right">
                    <small>Hints: {{analysisResult.Readability_length}}</small>
                </div>
            </div>
        </div>
    </ng-container>
</div>