import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseService } from 'src/app/shared/services/base.service';
import { UserValidationRoles as UserValidation } from 'src/app/shared/models/validation/userValidations';
import {  StepperService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import {RETURN_BACK_TO_RCSA_PARAM, RISK_CASE_ID_MAX_LENGTH, RISK_CASE_ID_PARAM_MARK} from '@shared/common/constants';

@Component({
  selector: 'home-tabs',
  templateUrl: './home-tabs.component.html',
  styleUrls: ['./home-tabs.component.scss']
})
export class HomeTabsComponent implements OnInit {
  @ViewChild('myriskcases') myriskcases;
  @ViewChild('allriskcases') allriskcases;
  showRCSATab = environment.rcsaUrl + RETURN_BACK_TO_RCSA_PARAM;

  selectedTab = 0;
  loggedUserRole = '';
  statusList = [];
  issueStatusList = [];
  statusListDefault = [];
  statusListDefaultIssue = [];
  userRole;
  constructor(private router: Router, private baseService: BaseService, public stepperService: StepperService,
    ) {


      if(localStorage.getItem('tabSelected') === 'rcsa' || this.router.url.includes('rcsa')){
        this.stepperService.savedTab = 3;
        this.selectedTab = 3;
        localStorage.removeItem('tabSelected');
      }else{
        if( localStorage.getItem('routerUrl')){
          if(localStorage.getItem('routerUrl').indexOf(RISK_CASE_ID_PARAM_MARK) !== -1){
            const riskId =  localStorage.getItem('routerUrl').substring(localStorage.getItem('routerUrl').indexOf(RISK_CASE_ID_PARAM_MARK) + RISK_CASE_ID_MAX_LENGTH);
            this.stepperService.loadRiskCaseById(+riskId);

          }else{
            this.router.navigate([localStorage.getItem('routerUrl')]);
          }
          localStorage.removeItem('routerUrl');

        }
      }

      if(this.stepperService.savedTab){this.selectedTab = this.stepperService.savedTab;}

  }

  ngOnInit() {
    this.loadData();
    this.baseService.get('authorization/roles').subscribe(res => {

            this.loggedUserRole = UserValidation.defineUserRole(res.body.data);
        });




  }
  onTabChange(index){
    this.stepperService.savedTab = index.index;
    this.selectedTab = index.index;
  }
  refreshTabs(tab){
    if(tab === 'allCases' ){
      this.myriskcases.refresh();
    }
    if( tab === 'myCases' ){
      this.allriskcases.refresh();
    }
  }
  loadData(){
    if(this.stepperService.enumItemsStoredData && this.stepperService.enumItemsStoredData.enum_3){
      this.statusList = this.stepperService.enumItemsStoredData.enum_3;
      this.statusListDefault = this.stepperService.enumItemsStoredData.enum_3Selected;

    }else{
      this.baseService.get('enum/items/3').subscribe(res => {
        this.statusList = [];
        res.body.data.forEach(element => {
          this.statusList.push({ label: element.name, value: element.name })
          if(element.id != 11 && element.id != 10 ){
            this.statusListDefault.push(element.name)

          }

        });
        this.stepperService.enumItemsStoredData.enum_3 = this.statusList;
        this.stepperService.enumItemsStoredData.enum_3Selected = this.statusListDefault;
      });
    }

    if(this.stepperService.enumItemsStoredData && this.stepperService.enumItemsStoredData.enum_6){
      this.issueStatusList = this.stepperService.enumItemsStoredData.enum_6;
      this.statusListDefaultIssue = this.stepperService.enumItemsStoredData.enum_6Selected;

    }else{
    this.baseService.get('enum/items/6').subscribe(res => {
      this.issueStatusList = [];
      res.body.data.forEach(element => {
        this.issueStatusList.push({ label: element.name, value: element.name });
        if(element.id != 30 &&element.id != 36 ){
          this.statusListDefaultIssue.push(element.name)

        }


      });
      this.stepperService.enumItemsStoredData.enum_6 = this.issueStatusList;
      this.stepperService.enumItemsStoredData.enum_6Selected = this.statusListDefaultIssue;
    });
  }

  }



tooltip(toolTipontant, position){
  const tooltipData = {allowHTML: true,   delay: [1200, null], animation: 'shift-away', placement: position,  theme: 'light', content: toolTipontant };
  return tooltipData;
}
}

