import { Component, OnInit, Input, Output,EventEmitter, OnChanges } from '@angular/core';
import { BaseService, StepperService } from '../../../../../../shared/services';

@Component({
  selector: 'app-risk-impact',
  templateUrl: './risk-impact.component.html',
  styleUrls: ['./risk-impact.component.scss']
})
export class RiskImpactComponent implements OnInit, OnChanges {
  typeRateList = [];
  impactRateList = [];
  @Input() viewButton: boolean = true;
  @Input() riskCaseId;
  @Input() riskCase;
  @Input() primaryControl;

  @Input() controlRisks;
  FieldHelpData;
  @Input() impact_ratings = [] ;
  @Output() newData: EventEmitter<any> = new EventEmitter<any>();
  currentYear = new Date().getFullYear();
  constructor( private baseService: BaseService,private stepperService: StepperService,
    ) {
      this.FieldHelpData = this.stepperService.FieldHelpData;
      console.log(this.currentYear);

     }

  ngOnInit(): void {
    this.loadLists();
  }
  selectUser(data,type,i){
    if(this.impact_ratings.find(x => x.ir_type_id === type.id)){
      for (const iterator of this.impact_ratings) {
        if(iterator.ir_type_id === type.id){
          const index = this.impact_ratings.indexOf(iterator);
          if (index > -1) {
            let removed = this.impact_ratings.splice(index, 1);
            this.impact_ratings.push({"ir_id":removed[0].ir_id,"rc_id":this.riskCaseId,"ir_type_id":type.id,"ir_impact_id":data.id,"ir_insert_date":new Date()});
          }
        }

      }

    }else{
      this.impact_ratings.push({"ir_id":i,"rc_id":this.riskCaseId,"ir_type_id":type.id,"ir_impact_id":data.id,"ir_insert_date":new Date()});
    }
    this.newData.emit({prop: 'impact_ratings', propValue: this.impact_ratings});

    if(this.impact_ratings.find(x => x.ir_impact_id === 18)){

      this.newData.emit({prop: 'overall_impact_rating', propValue:  {"id":18,"enum_type":{"id":5,"name":"Issue Rating","description":null},"name":"High","description":null}});
      return;
    }
    if(this.impact_ratings.find(x => x.ir_impact_id === 19)){
      this.newData.emit({prop: 'overall_impact_rating', propValue: {"id":19,"enum_type":{"id":5,"name":"Issue Rating","description":null},"name":"Elevated","description":null}});
      return;

    }
    if(this.impact_ratings.find(x => x.ir_impact_id === 20)){
      this.newData.emit({prop: 'overall_impact_rating', propValue: {"id":20,"enum_type":{"id":5,"name":"Issue Rating","description":null},"name":"Moderate","description":null}});
      return;

    }
    if(this.impact_ratings.find(x => x.ir_impact_id === 21)){
      this.newData.emit({prop: 'overall_impact_rating', propValue: {"id":21,"enum_type":{"id":5,"name":"Issue Rating","description":null},"name":"Low","description":null}});
      return;

    }
    if(this.impact_ratings.find(x => x.ir_impact_id === 22)){
      this.newData.emit({prop: 'overall_impact_rating', propValue: {"id":22,"enum_type":{"id":5,"name":"Issue Rating","description":null},"name":"Low","description":null}});
      return;

    }
  }
  loadLists(){
    this.baseService.get('enum/items/28').subscribe(res => {
      this.typeRateList = res.body.data;
    });
    this.baseService.get('enum/items/5').subscribe(res => {
      this.impactRateList = res.body.data;

    });
  }


  checkSelection(data,type){
    this.setNewList()
    for (const iterator of this.impact_ratings) {
      if(iterator.ir_type_id == type.id && iterator.ir_impact_id == data.id  ){
        return true;
      }
    }
 
    return false;

      }
  ngOnChanges(){
 

  }
  setNewList(){
      if(!this.impact_ratings.find(x => x.ir_type_id === 262) ){
        this.impact_ratings.push({"rc_id":this.riskCaseId,"ir_type_id":262,"ir_impact_id":261,"ir_insert_date":new Date()});
      }    
      if(!this.impact_ratings.find(x => x.ir_type_id === 263) ){
        this.impact_ratings.push({"rc_id":this.riskCaseId,"ir_type_id":263,"ir_impact_id":261,"ir_insert_date":new Date()});
      }  
      if(!this.impact_ratings.find(x => x.ir_type_id === 264)){
        this.impact_ratings.push({"rc_id":this.riskCaseId,"ir_type_id":264,"ir_impact_id":261,"ir_insert_date":new Date()});
      }  
      if(!this.impact_ratings.find(x => x.ir_type_id === 265)){
        this.impact_ratings.push({"rc_id":this.riskCaseId,"ir_type_id":265,"ir_impact_id":261,"ir_insert_date":new Date()});
      }  
    
    return  this.impact_ratings;

  }
  getResult(){
    if(this.impact_ratings.length == 0){
    return 'not available - minimally 1 answer needed';
  }else{
    if(this.impact_ratings.find(x => x.ir_impact_id === 18)){
      return 'High';
    }
    if(this.impact_ratings.find(x => x.ir_impact_id === 19)){
      return 'Elevated';
    }
    if(this.impact_ratings.find(x => x.ir_impact_id === 20)){
      return 'Moderate';
    }
    if(this.impact_ratings.find(x => x.ir_impact_id === 21)){
      return 'Medium';
    }
    if(this.impact_ratings.find(x => x.ir_impact_id === 22)){
      return 'Low';
    }
    return 'not available - minimally 1 answer needed';
  }
  }


  tooltip(toolTipontant, position){
    const tooltipData = {allowHTML: true,   delay: [1200, null], animation: 'shift-away', placement: position,  theme: 'light', content: toolTipontant };
    return tooltipData;
  }
}
