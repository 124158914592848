import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {BaseService, MsalService, StepperService} from '@shared/services';
import {dateFormat} from '@shared/common/constants';

@Component({
  selector: 'app-action-item',
  templateUrl: './action-item.component.html',
  styleUrls: ['./action-item.component.scss']
})
export class ActionItemComponent implements OnInit, OnChanges {
  @Input() actionItem;
  @Input() riskCaseId;
  @Input() caseDueDate;
  @Output() newData: EventEmitter<any> = new EventEmitter<any>();
  loggedUserData;
  filterUsersSingleList: any[];
  actionStatusList = [];
  today = new Date();
  maxDay = new Date()
  dateFormat = dateFormat;
  form: FormGroup;
  showAction = false;

  constructor(private msalService: MsalService, builder: FormBuilder, private stepperService: StepperService, private baseService: BaseService) {
    this.loggedUserData = msalService.getLoggedUser();

    this.form = builder.group({
      rcId: new FormControl(null, [Validators.required]),
      sourceId: new FormControl(null),
      id: new FormControl(null),
      sourceName: new FormControl(null),
      modifDate: new FormControl(null),
      modifier: new FormControl(null),
      folder: new FormControl(null),
      delegate: new FormControl(null),
      completionDate: new FormControl(null),

      actionTaken: new FormControl(null),
      comments: new FormControl(null),

      title: new FormControl(null, [Validators.required]),
      desc: new FormControl(null, [Validators.required]),
      creationDate: new FormControl(this.correctDate(new Date())),
      creator: new FormControl(null),
      assignee: new FormControl(null, [Validators.required]),
      dueDate: new FormControl(null, [Validators.required]),
      status: new FormControl({id: 266}),

    });

    this.form.valueChanges.subscribe(value => {
    });

    this.baseService.get('enum/items/29').subscribe(res => {

      this.actionStatusList = res.body.data;
    });


  }

  filterUsersSingle(event) {

    const query = event.query;
    if (query.length > 2) {
      this.filterUsersSingleList = [];
      this.baseService.getUsers('user?q=' + query).then(users => {
        this.filterUsersSingleList.push(...users);
      });
    } else {
      this.filterUsersSingleList = [];
    }
  }

  composeName(user: any) {
    return user.user_name + ' (' + user.user_id + ')';
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.caseDueDate) {
      this.maxDay = new Date(this.caseDueDate);
    }
    if (this.riskCaseId) {
      this.form.get('rcId').setValue(this.riskCaseId);
    }
    if (this.actionItem) {
      const controls = this.form.controls;
      Object.keys(controls).forEach(key => {
        if (this.actionItem[key] != null) {

          if (key === 'dueDate' || key === 'completionDate' || key === 'creationDate' || key === 'modifDate') {

            if (this.actionItem[key]) {
              this.form.get(key).setValue(this.correctDate(new Date(this.actionItem[key])));

            }

          } else {
            if (this.actionItem[key]) {

              this.form.get(key).setValue(this.actionItem[key]);
            }
          }
        }
      });
    }
  }


  cantEdit(event): boolean {
    return false;
  }

  dateChanged(evt, row) {
    const selectedDate = new Date(evt);
    if (selectedDate.getTimezoneOffset() < 0) {
      selectedDate.setMinutes(selectedDate.getMinutes() - selectedDate.getTimezoneOffset());
    }
    this.form.get(row).setValue(selectedDate);

  }


  nextStep(actionItem) {
    this.newData.emit(actionItem);
  }

  canGo(status?) {
    if (status === 268) {
      this.form.get('actionTaken').setValidators([Validators.required]);
      this.form.get('actionTaken').updateValueAndValidity();
    }
    if (this.form.status === 'VALID') {
      return false;
    }
    return true;

  }

  checkValidation() {
    this.showAction = true;

  }

  changeActionStatus(status) {
    if (status === 268) {
      this.form.get('completionDate').setValue(this.correctDate(new Date()));

    } else {
      this.form.get('completionDate').setValue(null);

    }
    this.showAction = false;
    this.form.get('status').setValue({id: status});

    this.nextStep(this.form.value);
  }


  correctDate(date) {
    const selectedDate = new Date(date);
    if (selectedDate.getTimezoneOffset() < 0) {
      selectedDate.setMinutes(selectedDate.getMinutes() - selectedDate.getTimezoneOffset());
    }
    return selectedDate;

  }

  byteCount(textValue, maxlength) {
    if (!textValue) {
      return {valid: true, length: 0};

    }
    if (encodeURI(textValue).split(/%..|./).length - 1 > maxlength) {
      return {valid: false, length: encodeURI(textValue).split(/%..|./).length - 1};

    }
    return {valid: true, length: encodeURI(textValue).split(/%..|./).length - 1};
  }

  checkTextList(textList) {
    for (const iterator of textList) {
      if (encodeURI(iterator.txtValue).split(/%..|./).length - 1 > iterator.maxLength) {
        return true;

      }

    }
    return false;
  }


}
