<div class="flexcolumn container">
    <ng-container *ngIf='risks.length >0'>
        <p-table sortField="deadline" [sortOrder]="1" [columns]="cols" scrollHeight="26.4rem" class="flexcolumn" [value]="risks" [scrollable]="true" [reorderableColumns]="isConfigView" #rcsaTable selectionMode="single" (onRowSelect)="onRowSelect($event)">
            <ng-template pTemplate="caption">
                <div style="display: flex; justify-content: space-around; align-items: center;">
                    <div>
                        <span *ngIf="!isConfigView && !rcsaTable.filteredValue"> Total Records: {{rcsaTable.totalRecords}}</span>
                        <span *ngIf="!isConfigView && rcsaTable.filteredValue"> Total Records: {{rcsaTable.filteredValue.length}}</span>

                    </div>
                    <div>




                    </div>
                    <div>
                        <button *ngIf="!isConfigView" pButton icon="pi pi-filter" id="clearFilterButton" class="accent-blue-button" label="CLEAR FILTER" [disabled]="!hasFilters" (click)="clearFilters()"></button>
                    </div>
                    <div>
                        <button pButton icon="pi pi-cog" id="configureTableButton" class="accent-blue-button" label="CONFIGURE TABLE" (click)="configureTable()"></button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr style="height:5rem" *ngIf="!isConfigView && !updating">
                    <ng-container *ngFor="let col of cols" [ngSwitch]="col.field">
                        <th *ngIf="col.isVisible" [ngStyle]="setDeleteStyle(col.field)">


                            <span class="ui-float-label" *ngSwitchDefault>
                                <input id="float-input" type="text" size="30" [ngModel]="getSavedFilterValue(col.field)" class="filter-input"
                                (input)="filterTable($event.target, col.field, 'contains')" pInputText>
                                <label for="float-input">{{col.header}}</label>
                              </span>


                            <div class="centerItem" *ngSwitchCase="'sign_off_date'">
                                {{col.header}}
                                <p-sortIcon [pSortableColumn]="col.field" [field]="'Name'"></p-sortIcon>
                            </div>

                            <span class="ui-float-label" *ngSwitchCase="'risk_manager'">
                                <input id="float-input" type="text" size="30" [ngModel]="getSavedFilterValue('risk_manager')" class="filter-input"
                                  (input)="filterTable($event.target.value, 'risk_manager.user_name', 'contains','risk_manager','user_name')" pInputText>
                                <label for="float-input">{{col.header}}</label>
                              </span>
                            <span class="ui-float-label" *ngSwitchCase="'rcsa_owner'">
                                <input id="float-input" type="text" size="30" [ngModel]="getSavedFilterValue('rcsa_owner')" class="filter-input"
                                  (input)="filterTable($event.target.value, 'rcsa_owner.user_name', 'contains','rcsa_owner','user_name')" pInputText>
                                <label for="float-input">{{col.header}}</label>
                              </span>



                            <div class="centerItem" *ngSwitchCase="'status'">
                                {{col.header}}
                                <p-sortIcon [pSortableColumn]="col.field" [field]="'Name'"></p-sortIcon>
                            </div>




                            <span class="ui-float-label" *ngSwitchCase="'folder'">
                                <input id="float-input" type="text" size="30" [ngModel]="getSavedFilterValue(col.field)" class="filter-input"
                                  (input)="filterTable($event.target.value, col.field, 'contains')" pInputText>
                                <label for="float-input">{{col.header}}</label>
                              </span>
                            <span class="ui-float-label" *ngSwitchCase="'global_function'">
                                <input id="float-input" type="text" size="30" [ngModel]="getSavedFilterValue(col.field)" class="filter-input"
                                  (input)="filterTable($event.target.value, col.field, 'contains')" pInputText>
                                <label for="float-input">{{col.header}}</label>
                              </span>




                            <span class="ui-float-label" *ngSwitchCase="'name'">
              <input id="float-input" type="text" size="30" [ngModel]="getSavedFilterValue(col.field)" class="filter-input"
                (input)="filterTable($event.target.value, col.field, 'contains')" pInputText>
              <label for="float-input">{{col.header}}</label>
            </span>

                            <span class="ui-float-label" *ngSwitchCase="'description'">
              <input id="float-input" type="text" size="30" [ngModel]="getSavedFilterValue(col.field)" class="filter-input"
                (input)="filterTable($event.target.value, col.field, 'contains')" pInputText>
              <label for="float-input">{{col.header}}</label>
            </span>







                            <p-dropdown *ngSwitchCase="'status_name'" [options]="statusList" class="filter-input" placeholder="Case Status" [(ngModel)]="col.filter" appendTo="body" (onChange)="filterTable($event.value, col.field, 'equals')">
                            </p-dropdown>


                            <p-multiSelect *ngSwitchCase="'type'" [options]="classNames" class="filter-input" [ngModel]="getSavedFilterValue('type')" [defaultLabel]="col.header" appendTo="body" (onChange)="filterTable($event.value, 'type.name', 'in','type','name')">
                            </p-multiSelect>

                        </th>
                    </ng-container>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData" *ngIf="!isConfigView" class="cursor-pointer">
                    <ng-container *ngFor="let col of cols">
                        <td *ngIf="col.isVisible" [ngStyle]="setDeleteStyle(col.field)">
                            <ng-container *ngIf="col.field==='sign_off_date' ">
                                <ng-container *ngIf="rowData[col.field]">
                                    {{ getDate(rowData[col.field]) | oneOrmDate}}
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="col.field==='name' || col.field==='description'  || col.field==='status' || col.field==='folder'  ">
                                {{rowData[col.field]}}
                            </ng-container>

                            <ng-container *ngIf="col.field==='type'">
                                {{rowData[col.field].name }}
                            </ng-container>

                            <ng-container *ngIf="  col.field==='global_function' ">
                                {{rowData[col.field]}}
                            </ng-container>


                            <ng-container *ngIf="col.field==='risk_manager' || col.field==='rcsa_owner'">
                                {{rowData[col.field].user_name}}
                            </ng-container>









                        </td>
                    </ng-container>
                </tr>

            </ng-template>
        </p-table>
    </ng-container>
    <ngx-spinner name="rcsaList" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="false">
        <p style="color: white"> Loading... </p>
    </ngx-spinner>

    <ng-container *ngIf='risks.length == 0'>
        <div class="noRecord">
            No records available!
        </div>
    </ng-container>


</div>
<div *ngIf="isConfigView" cdkDropList cdkDropListOrientation="horizontal" class="column-list" (cdkDropListDropped)="drop($event)">
    <ng-container *ngFor="let col of cols">
        <div class="column-configuration-placeholder" *ngIf="col.field != 'delete'" pTooltip="Drag me to the desired position" tooltipPosition="bottom" cdkDrag>
            <p-checkbox name="isVisibleCheckbox" [value]="col.field" [(ngModel)]="col.isVisible" [binary]="true"></p-checkbox>
            <span>
        {{col.header}}
      </span>
        </div>
    </ng-container>
</div>

<div class="configuration-buttons-container" *ngIf="isConfigView">
    <button pButton icon="pi pi-times" id="cancelChangesButton" class="accent-blue-button" label="DISCARD CHANGES" (click)="cancelConfiguration()"></button>

    <button pButton icon="pi pi-save" id="saveButton" class="accent-blue-button" label="SAVE AND CLOSE" (click)="saveConfiguration()"></button>
</div>

<p-confirmDialog #cd>
    <p-footer>
        <button type="button" pButton icon="pi pi-check" label="Yes" (click)="cd.accept()"></button>
        <button type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>
    </p-footer>
</p-confirmDialog>