export class UserValidationRoles {
static userRole(user, listOfUsers){
    if (listOfUsers.find(x => x === user)) {
        return true;
      } else {
        return false;

      }
}


static defineUserRole(userRoleList){
  let userRoleValue = '';
  if (userRoleList.indexOf('GIA') >= 0) {
    userRoleValue = 'GIA';
    return userRoleValue;
  }


  if (userRoleList.indexOf('CORM') >= 0) {
    userRoleValue = 'CORM';
    return userRoleValue;
  }


  if (userRoleList.indexOf('ORM') >= 0) {
    userRoleValue = 'ORM';
    return userRoleValue;
  }
  if (userRoleList.indexOf('ORM II') >= 0) {
    userRoleValue = 'ORMII';
    return userRoleValue;
  }
  if (userRoleList.indexOf('Compliance') >= 0) {
    userRoleValue = 'Comp';
    return userRoleValue;
  }
  if (userRoleList.indexOf('Governance Coordinator') >= 0) {
    userRoleValue = 'GC';
    return userRoleValue;
  }
  return userRoleValue;

}

static isRiskManager(userRoleList){
  if (userRoleList.indexOf('CORM') >= 0 || userRoleList.indexOf('ORM') >= 0 || userRoleList.indexOf('ORM II') >= 0) {
    return true;
  }
  return false;
}

static isGovernanceCoord(userRoleList){
  if (userRoleList.indexOf('Governance Coordinator') >= 0 ) {
    return true;
  }
  return false;
}



}
