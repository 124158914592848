<mat-toolbar-row class="toolbar">

    <div class="d-flex align-items-center">
        <div class="d-flex align-items-center" fxLayout="row" fxLayoutAlign="space-around stretch" style="margin-top:10px;">
            <button pButton icon="pi pi-plus" label="NEW CASE" class="accent-blue-button" (click)='newCase()'></button>

        </div>
        <app-info-video-modal></app-info-video-modal>

    </div>
    <div style="margin-top:10px;">
    </div>
</mat-toolbar-row>
<div class="container  justify-content-between">
    <home-tabs> </home-tabs>

</div>