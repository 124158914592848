<div class="containerDiv">
    <div class="w-100" *ngIf="viewStatus === 'mainView'">
        <p class="t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.IdentifyingLoD)">
            Has this issue been self-identified?

        </p>
        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="selectedIdentification">
            <mat-radio-button (change)="onChangeRB($event.value)" class="example-radio-button" *ngFor="let identification of identificationList" [value]="identification.id">
                <div style="width: 600px;" class="row">

                    <div class="col-md-6">
                        {{identification.name}}

                    </div>
                    <div class="col-md-6">
                        {{identification.description}}

                    </div>


                </div>
            </mat-radio-button>
        </mat-radio-group>

        <ng-container *ngIf='viewButton'>
            <div class="row justify-content-end mb-5 w-100 mx-0">
                <button *ngIf="!issueFromEvent" class='primaryBtn mr-4 buttonWidth' pButton type="button" label="Discard Issue" [routerLink]="['/discard']" [disabled]="riskCase.status!==RiskCaseConstants.Issue_DRAFT_TECHNICAL"></button>

                <button class='primaryBtn  buttonWidth' pButton type="button" label="Proceed" [disabled]="!selectedIdentification" (click)="nextStep(selectedIdentification)"></button>
                <div class='d-flex'>
                    <app-information-dialog [issue]="true" [accordionIndex]='3'></app-information-dialog>
                </div>

            </div>
        </ng-container>

    </div>
</div>
