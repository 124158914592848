import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-custom-search',
  templateUrl: './custom-search.component.html',
  styleUrls: ['./custom-search.component.scss']
})
export class CustomSearchComponent {
  @Input() placeholder: string;
  @Input() searchValue: string;
  @Output() value: EventEmitter<string> = new EventEmitter<string>();
  @Output() showForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  show = false;
  constructor() { }

  onSearchChange(value: string): void {
      this.value.emit(this.searchValue);
  }
  
  showFilter(): void {
    if (this.show === false) {
      this.show = true;
      this.showForm.emit(this.show);
    }
    else {
      this.show = false;
      this.showForm.emit(this.show);
    }
  }
}
