<div class="containerDiv p-1 w-100">
        <p >
            {{title}}:
        </p>

       
            <app-custom-search [placeholder]="placeHolder" class="d-flex justify-content-center"  (value)="Search($event)" ></app-custom-search>

        <div class="radioBtnContainer">
            <div *ngIf="mainList.length == 0" class="d-flex justify-content-center align-items-center loading" >Loading...</div>
            <mat-accordion class="fix-radio-position" hideToggle="true">
                <mat-radio-group  
                aria-label="Select an option">

                    <mat-expansion-panel *ngFor='let item of mainList | searchPipe: { name: searchValue, level_2_name: searchValue, description: searchValue}'>
                        <mat-expansion-panel-header>
                            <mat-panel-description class='d-flex' style="align-items: center;">
                                <mat-radio-button (change)="onChangeRB(item)"  [value]="item[objectValue]"
                                [checked]="selectedItem ? item[objectValue] === selectedItem : false"
                                >
                                </mat-radio-button>
                                <div>{{item[objectValue]}} </div>
    
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                       
                    </mat-expansion-panel>
                </mat-radio-group>
            </mat-accordion>
        </div>

       
</div>


