import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router} from '@angular/router';
import { filter } from 'rxjs/operators';
import { MsalService } from '@shared/services';
import { environment } from '../../../../environments/environment';
import { RCSA_TAB_PARAM, RETURN_BACK_TO_RCSA_PARAM } from '@shared/common/constants';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit {
  private readonly rcsaTabLabelToRcsaUrl: Record<'Events' | 'Issues' | 'My To-Dos', 'events' | 'issues' | 'todos'> = {
    Events: 'events',
    Issues: 'issues',
    'My To-Dos': 'todos'
  };

  public breadcrumbs: Breadcrumb[];

  constructor(private router: Router, private route: ActivatedRoute, private readonly msalService: MsalService) {
  }

  ngOnInit() {
    const breadcrumb: Breadcrumb = {
      label: 'Home',
      url: ''
    };

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      // set breadcrumbs
      const root: ActivatedRoute = this.route.root;
      const rcsaTabBreadcrumb = this.getRcsaTabLabel(root, event as NavigationEnd);

      if (rcsaTabBreadcrumb) {
        localStorage.setItem(RCSA_TAB_PARAM, rcsaTabBreadcrumb);
      }

      this.breadcrumbs = this.getBreadcrumbs(root);
      this.breadcrumbs = [breadcrumb, rcsaTabBreadcrumb ? {
        label: rcsaTabBreadcrumb,
        url: environment.rcsaUrl + `/${this.rcsaTabLabelToRcsaUrl[rcsaTabBreadcrumb]}`
      } : undefined, ...this.breadcrumbs].filter(x => !!x);
    });

  }

  navigateToRoute(route: string): void {
    if (route === '') {
      window.location.href = environment.rcsaUrl + RETURN_BACK_TO_RCSA_PARAM;
      return;
    }

    if (route.includes(environment.rcsaUrl)) {
      window.location.href = route + RETURN_BACK_TO_RCSA_PARAM;
      return;
    }

    this.router.navigate([route]);

    if (route === '') {
      this.msalService.resetRiskCaseId();
    }
  }

  private getRcsaTabLabel(root: ActivatedRoute, event: NavigationEnd): string | undefined {
    const queryLabel = root.snapshot.queryParams[RCSA_TAB_PARAM];
    if (queryLabel) {
      return queryLabel;
    }

    if (event.url.includes('rcsaTab=') && event.url !== '') {
      const splitOfLabel = decodeURIComponent(event.url).split(RCSA_TAB_PARAM + '=');
      const whiteSpaceCode = '%20';
      if (splitOfLabel) {
        return splitOfLabel[1]?.replace(whiteSpaceCode, ' ');
      }
    }

    const savedLabel = localStorage.getItem(RCSA_TAB_PARAM);

    if (savedLabel) {
      return savedLabel;
    }

    return undefined;
  }

  private getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    const ROUTE_DATA_BREADCRUMB = 'title';
    // get the child routes
    const children: ActivatedRoute[] = route.children;

    // return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    // iterate over each children
    for (const child of children) {
      // verify primary route
      // tslint:disable-next-line:triple-equals
      if (child.outlet !== PRIMARY_OUTLET || child.snapshot.url.length == 0) {
        continue;
      }

      // verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      // get the route's URL segment
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');

      // append route URL to URL
      url += `/${routeURL}`;

      // add breadcrumb
      const breadcrumb: Breadcrumb = {
        label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
        url
      };
      breadcrumbs.push(breadcrumb);

      // recursive
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
    return breadcrumbs;
  }


}

export interface Breadcrumb {
  label: string;
  url: string;
}

