<div class="containerDiv">


    <ng-container *ngIf='loading'>
        <div class="d-flex justify-content-center align-items-center loading">Loading...</div>

    </ng-container>

    <ng-container *ngIf='!loading'>
        <div class="w-100" *ngIf="viewStatus === 'mandate'">

            <div class="mb-3 w-100 inputField">
                <mat-radio-group aria-label="Select an option" class="d-flex justify-content-start mt-3" [(ngModel)]='MandateViewValue'>
                    <mat-radio-button (change)='changeMandateView("Pick")' class="mr-4" [value]="'Pick'">Pick a person you report this case for... </mat-radio-button>
                    <mat-radio-button class="ml-4" (change)='changeMandateView("manually")' [value]="'manually'">Select an RCSA manually
                    </mat-radio-button>
                </mat-radio-group>

            </div>

            <ng-container *ngIf="MandateViewValue === 'Pick'">
                <div class="w-100">

                    <div class="mb-3">
                        {{this.selectedUser ? this.selectedUser.composedName : ''}}
                        <p-autoComplete [(ngModel)]="selectedUser" [suggestions]="filterUsersSingleList" (completeMethod)="filterUsersSingle($event)" [field]="composeName" [size]="50" (onSelect)="selectUser(selectedUser)" placeholder="Name of SR Employee" [minLength]="1" [forceSelection]="true"
                            emptyMessage="{{filterUsersSingleList && filterUsersSingleList.length >0 ? '' : 'User Not Found'}}">
                            <ng-template let-user pTemplate="item">
                                <div>
                                    {{user.user_name}} ({{user.user_id}})
                                </div>
                            </ng-template>
                        </p-autoComplete>
                    </div>
                    <div class="row justify-content-end mb-5 w-100 mx-0">
                        <div class='d-flex'>
                            <button class='primaryBtn  buttonWidth' pButton type="button" label="Proceed" [disabled]="!selectedUser" (click)="getRCSADepartmentData('associatedOtherUsers', selectedUser.user_id)"></button>
                            <app-information-dialog [accordionIndex]='0'></app-information-dialog>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="MandateViewValue === 'manually'">
                <div class="mb-3 w-100">

                    <app-custom-search class="d-flex justify-content-center mt-4" (value)="Search($event)" [placeholder]="'Search anything...'"></app-custom-search>

                    <div class="radioBtnContainer my-4">
                        <div *ngIf="rcsaList.length == 0" class="d-flex justify-content-center align-items-center loading">Loading...</div>
                        <mat-accordion class="fix-radio-position" hideToggle="true">
                            <mat-expansion-panel *ngFor='let item of rcsaList | searchPipe: { driver: searchValue, driver_name: searchValue,owner: searchValue, risk_assm_name: searchValue, risk_assm_desc: searchValue, risk_manager: searchValue}'>
                                <mat-expansion-panel-header>
                                    <mat-panel-description class='d-flex align-items-center'>
                                        <mat-radio-button (change)="onChangeRB($event)" [id]="item.name" [value]="item" [checked]="selectedRCSA ? item.risk_assessment_id === selectedRCSA.risk_assessment_id : false">
                                        </mat-radio-button>
                                        <div>{{item.risk_assm_name}}: <span>{{item.risk_assm_desc}}</span></div>

                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <div>
                                    <span class="titleRB">{{item.risk_assm_name}}: </span>
                                    <span>{{item.risk_assm_desc}}</span>
                                </div>
                                <div>
                                    <span class="titleRB"> Owner: </span>
                                    <span>{{item.owner}}</span>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <ng-container *ngIf='viewButton'>
                        <div class="row justify-content-end mb-5 w-100 mx-0">
                            <button class='primaryBtn  buttonWidth' pButton type="button" label="Proceed" [disabled]="!selectedRCSA" (click)="nextStep()"></button>
                            <div class='d-flex'>
                                <app-information-dialog [accordionIndex]='0'></app-information-dialog>
                            </div>

                        </div>

                    </ng-container>

                </div>
            </ng-container>

        </div>
        <ng-container *ngIf="viewStatus=='notReport'">
            <div class="w-100">
                <p class="mb-3">
                    If you leave now, no report will be generated.
                </p>
                <p class="mb-3">
                    Thank you for your time to think this through.
                </p>
                <p class="mb-3">
                    Mindful people like you help Swiss Re to carefully do business.
                </p>
                <div class="row justify-content-end mb-5 w-100 mx-0">
                    <button class='primaryBtn  mr-4 buttonWidth' pButton type="button" label="Return to case" (click)="changeViewStatus('mandate')"></button>
                    <button class='primaryBtn  buttonWidth' pButton type="button" label="Bye for now" routerLink='/'></button>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="viewStatus=='start'">
            <div *ngIf='rcsaList.length > 1' class="mb-3 w-100">
                <div>Based on your affiliation, we suggest to associate your case with one of the following Risk Control Self Assessments (RCSAs).​</div>
                <div>Please choose the applicable RCSA. </div>
            </div>
            <div *ngIf='rcsaList.length == 0' class="mb-3 w-100">
                <div>We cannot find any Risk Control Self Assessment (RCSA).</div>
                <div>Please select the appropriate RCSA manually.​</div>
            </div>
            <div *ngIf='rcsaList.length == 1' class="mb-3 w-100">
                <div>Based on your affiliation, we suggest to associate your case with the following Risk Control Self Assessment (RCSA).</div>
                <div>Please choose the applicable RCSA.</div>
            </div>
            <div class="flex-start row mx-0">
                <label *ngFor='let item of rcsaList' class="container col-xl-6">
                <input type="radio" [checked]="rcsaList.length === 1" (change)='selectUser(item)' name="radio">
                <div class="checkmark ">
                    <div class="t-demo__btn"    
                    ngxTippy
                    [tippyProps]="tooltip(FieldHelpData.Mandate.LeftField)">
                        <p class="radioBtnBodyTitle"> {{item.risk_assm_name}} </p>
                        <p class="radioBtnBodyContainerTitle">{{item.risk_assm_desc}}</p>
                        <p class="radioBtnBodyTitle"> {{item.driver}} </p>
                        <p class="radioBtnBodyContainer">{{item.driver_name}} </p>
                        <p class="radioBtnBodyTitle"> Owner </p>
                        <p class="radioBtnBodyContainer">{{item.owner}}</p>
                    </div>
                </div>
            </label>
                <label class="container col-xl-6">
                <input type="radio" [checked]="false" (click)="getAllRCSAData('mandate')" name="radio">
                <div class="checkmarkOther t-demo__btn"    
                ngxTippy
                [tippyProps]="tooltip(FieldHelpData.Mandate.RightField)">
                    <div>
                        <p class="radioBtnBodyContainerTitle">Other RCSA</p>
                    </div>
                </div>
            </label>
            </div>
            <ng-container *ngIf='viewButton'>
                <div *ngIf='rcsaList.length > 1' class="row justify-content-end mb-5 w-100 mx-0">
                    <button class='primaryBtn  buttonWidth' pButton type="button" label="Proceed " [disabled]="!mandate" (click)="nextStep()"></button>

                    <app-information-dialog [accordionIndex]='0'></app-information-dialog>

                </div>
                <div *ngIf='rcsaList.length == 0' class="row justify-content-end mb-5 w-100 mx-0">
                    <button class='primaryBtn   buttonWidth' pButton type="button" label="Select RCSA " (click)="getAllRCSAData('selectRcsa')"></button>
                    <app-information-dialog [accordionIndex]='0'></app-information-dialog>

                </div>
                <ng-container *ngIf='rcsaList.length == 1'>

                    <div class="row justify-content-end mb-5 w-100 mx-0">
                        <button class='primaryBtn   buttonWidth' pButton type="button" label="Proceed" (click)="nextStep()"></button>
                        <app-information-dialog [accordionIndex]='0'></app-information-dialog>

                    </div>
                </ng-container>

            </ng-container>

        </ng-container>

        <ng-container *ngIf="viewStatus=='associated'">
            <div *ngIf='rcsaList.length > 1' class="mb-3 w-100">
                Based on your selection, we suggest to associate your case with one of the following Risk Control Self Assessments (RCSAs).​
            </div>
            <div *ngIf='rcsaList.length == 0' class="mb-3 w-100">
                <div>We cannot find any Risk Control Self Assessment (RCSA).</div>
                <div>Please select the appropriate RCSA manually.​</div>
            </div>
            <div *ngIf='rcsaList.length == 1' class="mb-3 w-100">
                <div>Based on your selection, we suggest to associate your case with the following Risk Control Self Assessment (RCSA).</div>
                <div>Please choose the applicable RCSA.</div>
            </div>
            <div class="flex-start row mx-0">
                <label *ngFor='let item of rcsaList' class="container col-xl-6">
                <input type="radio" [checked]="rcsaList.length === 1" (change)='selectUser(item)' name="radio">
                <div class="checkmark">
                    <div>
                        <p class="radioBtnBodyTitle"> {{item.risk_assm_name}} </p>
                        <p class="radioBtnBodyContainerTitle">{{item.risk_assm_desc}}</p>
                        <p class="radioBtnBodyTitle"> {{item.driver}} </p>
                        <p class="radioBtnBodyContainer">{{item.driver_name}} </p>
                        <p class="radioBtnBodyTitle"> Owner </p>
                        <p class="radioBtnBodyContainer">{{item.owner}}</p>
                    </div>
                </div>
            </label>
            </div>

            <ng-container *ngIf='viewButton'>
                <div *ngIf='rcsaList.length > 1' class="row justify-content-end mb-5 w-100 mx-0">
                    <button class='primaryBtn  mr-4 buttonWidth' pButton type="button" label="Proceed " [disabled]="!mandate" (click)="nextStep()"></button>

                    <button class='primaryBtn   buttonWidth' pButton type="button" label="Back" (click)="getRCSAData('start')"></button>
                    <app-information-dialog [accordionIndex]='0'></app-information-dialog>

                </div>
                <div *ngIf='rcsaList.length == 0' class="row justify-content-end mb-5 w-100 mx-0">
                    <button class='primaryBtn   buttonWidth' pButton type="button" label="Select RCSA " (click)="getAllRCSAData('selectRcsa')"></button>
                    <app-information-dialog [accordionIndex]='0'></app-information-dialog>

                </div>
                <ng-container *ngIf='rcsaList.length == 1'>

                    <div class="row justify-content-end mb-5 w-100 mx-0">
                        <button class='primaryBtn  mr-4 buttonWidth' pButton type="button" label="Proceed" (click)="nextStep()"></button>
                        <button class='primaryBtn   buttonWidth' pButton type="button" label="Back" (click)="getRCSAData('start')"></button>
                        <app-information-dialog [accordionIndex]='0'></app-information-dialog>

                    </div>
                </ng-container>

            </ng-container>
        </ng-container>

        <ng-container *ngIf="viewStatus=='chooseUser'">
            <div class="w-100">
                <p class="mb-3">
                    Please select that person from the list of Swiss Re employees.
                </p>
                <div class="mb-3">
                    {{this.selectedUser ? this.selectedUser.composedName : ''}}
                    <p-autoComplete [(ngModel)]="selectedUser" [suggestions]="filterUsersSingleList" (completeMethod)="filterUsersSingle($event)" [field]="composeName" [size]="50" (onSelect)="selectUser(selectedUser)" placeholder="Name of SR Employee" [minLength]="1" [forceSelection]="true"
                        emptyMessage="{{filterUsersSingleList && filterUsersSingleList.length >0 ? '' : 'User Not Found'}}">
                        <ng-template let-user pTemplate="item">
                            <div>
                                {{user.user_name}} ({{user.user_id}})
                            </div>
                        </ng-template>
                    </p-autoComplete>
                </div>
                <div class="row justify-content-end mb-5 w-100 mx-0">
                    <div class='d-flex'>
                        <button class='primaryBtn  buttonWidth' pButton type="button" label="Proceed" [disabled]="!selectedUser" (click)="getRCSADepartmentData('associatedOtherUsers', selectedUser.user_id)"></button>
                        <app-information-dialog [accordionIndex]='0'></app-information-dialog>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="viewStatus=='associatedOtherUsers'">
            <div *ngIf='rcsaList.length > 1' class="mb-3 w-100">
                {{selectedUser.user_name}} happens to be associated with more than one Risk Control Self Assessments (RCSAs).​ Please choose the applicable RCSA.
            </div>
            <div *ngIf='rcsaList.length == 0' class="mb-3 w-100">
                <div>We cannot find any Risk Control Self Assessment (RCSA).</div>
                <div>Please select the appropriate RCSA manually.​</div>
            </div>
            <div *ngIf='rcsaList.length == 1' class="mb-3 w-100">
                Based on your selection, we suggest to associate your case with the following Risk Control Self Assessments (RCSAs).​ </div>
            <div class="justify-content-between row ">
                <label *ngFor='let item  of rcsaList' class="container col-md-6">
                <input type="radio"  [checked]="rcsaList.length === 1" (change)='selectUser(item)' name="radio">
                <div class="checkmark">
                    <div>
                        <p class="radioBtnBodyTitle"> {{item.risk_assm_name}} </p>
                        <p class="radioBtnBodyContainerTitle">{{item.risk_assm_desc}}</p>
                        <p class="radioBtnBodyTitle"> {{item.driver}} </p>
                        <p class="radioBtnBodyContainer">{{item.driver_name}} </p>
                        <p class="radioBtnBodyTitle"> Owner </p>
                        <p class="radioBtnBodyContainer">{{item.owner}}</p>
                    </div>
                </div>
            </label>
            </div>


            <ng-container *ngIf='viewButton'>

                <div *ngIf='rcsaList.length > 1' class="row justify-content-end mb-5 w-100 mx-0">
                    <button class='primaryBtn  mr-4 buttonWidth' pButton type="button" label="Proceed " [disabled]="!mandate" (click)="nextStep()"></button>

                    <button class='primaryBtn   buttonWidth' pButton type="button" label="Back​" (click)="getRCSAData('start')"></button>
                    <app-information-dialog [accordionIndex]='0'></app-information-dialog>

                </div>

                <div *ngIf='rcsaList.length == 0' class="row justify-content-end mb-5 w-100 mx-0">
                    <button class='primaryBtn   buttonWidth' pButton type="button" label="Select RCSA " (click)="getAllRCSAData('selectRcsa')"></button>
                    <app-information-dialog [accordionIndex]='0'></app-information-dialog>

                </div>
                <ng-container *ngIf='rcsaList.length == 1'>
                    <div class="row justify-content-end mb-5 w-100 mx-0">
                        <button class='primaryBtn   buttonWidth' pButton type="button" label="Proceed" (click)="nextStep()"></button>
                        <app-information-dialog [accordionIndex]='0'></app-information-dialog>

                    </div>
                </ng-container>

            </ng-container>
        </ng-container>

        <ng-container *ngIf="viewStatus=='selectRcsa'">
            <div class="mb-3 w-100">
                <p>
                    Please select the RCSA this case should be associated with:
                </p>
                <app-custom-search class="d-flex justify-content-center mt-4" (value)="Search($event)" [placeholder]="'Search anything...'"></app-custom-search>

                <div class="radioBtnContainer my-4">
                    <div *ngIf="rcsaList.length == 0" class="d-flex justify-content-center align-items-center loading">Loading...</div>
                    <mat-accordion class="fix-radio-position" hideToggle="true">
                        <mat-expansion-panel *ngFor='let item of rcsaList | searchPipe: { driver: searchValue, driver_name: searchValue,owner: searchValue, risk_assm_name: searchValue, risk_assm_desc: searchValue, risk_manager: searchValue}'>
                            <mat-expansion-panel-header>
                                <mat-panel-description class='d-flex align-items-center'>
                                    <mat-radio-button (change)="onChangeRB($event)" [id]="item.name" [value]="item" [checked]="selectedRCSA ? item.risk_assessment_id === selectedRCSA.risk_assessment_id : false">
                                    </mat-radio-button>
                                    <div>{{item.risk_assm_name}}: <span>{{item.risk_assm_desc}}</span></div>

                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div>
                                <span class="titleRB">{{item.risk_assm_name}}: </span>
                                <span>{{item.risk_assm_desc}}</span>
                            </div>
                            <div>
                                <span class="titleRB"> Owner: </span>
                                <span>{{item.owner}}</span>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <ng-container *ngIf='viewButton'>
                    <div class="row justify-content-end mb-5 w-100 mx-0">
                        <button class='primaryBtn  buttonWidth' pButton type="button" label="Proceed" [disabled]="!selectedRCSA" (click)="nextStep()"></button>
                        <div class='d-flex'>
                            <app-information-dialog [accordionIndex]='0'></app-information-dialog>
                        </div>

                    </div>

                </ng-container>

            </div>
        </ng-container>

        <ng-container *ngIf="viewStatus=='existed'">
            <div class="mb-3 w-100">
                <div>Your selected RCSA is :</div>
            </div>


            <div class="flex-start row mx-0">
                <label *ngFor='let item of rcsaList' class="container col-xl-6">
                <input type="radio" [checked]="true" (change)='selectUser(item)' name="radio">
                <div class="checkmark">
                    <div *ngIf="!item.rcsa_owner">
                        <p class="radioBtnBodyTitle"> {{item.risk_assm_name}} </p>
                        <p class="radioBtnBodyContainerTitle">{{item.risk_assm_desc}}</p>
                        <p class="radioBtnBodyTitle"> {{item.driver}} </p>
                        <p class="radioBtnBodyContainer">{{item.driver_name}} </p>
                        <p class="radioBtnBodyTitle"> Owner </p>
                        <p class="radioBtnBodyContainer">{{item.owner}}</p>
                    </div>
                    <div *ngIf="item.rcsa_owner">
                        <p class="radioBtnBodyTitle"> {{item.name}} </p>
                        <p class="radioBtnBodyContainerTitle">{{item.description}}</p>
                        <p class="radioBtnBodyTitle"> {{item.driver}} </p>
                        <p class="radioBtnBodyContainer">{{item.driver_name}} </p>
                        <p class="radioBtnBodyTitle"> Owner </p>
                        <p class="radioBtnBodyContainer">{{item.rcsa_owner.user_name}}</p>
                    </div>
                </div>
            </label>
            </div>

            <div class="row justify-content-end mb-5 w-100 mx-0">
                <button class='primaryBtn   buttonWidth' pButton type="button" label="Change" [disabled]="lastStep>4 || stepperService.issueFromEvent" (click)="openChangeiaolg(template)"></button>

                <app-information-dialog [accordionIndex]='0'></app-information-dialog>

            </div>
        </ng-container>
    </ng-container>




</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title m-0">Change RCSA</h4>
        <span (click)="modalRef.hide()" class="material-icons informationIcon">
            close
        </span>

    </div>
    <div class="modal-body">
        <div>
            <ul class=" dUL bd-highlight mb-4">
                <li> The change of the RCSA is a major change which requires a restart of the process.
                </li>

                <li> If you need to proceed with the new RCSA and restart the process, all the data from the ‘Event or Issue’ and ‘Description’ steps will be moved to the new case.
                    <br> IMPORTANT: Make sure to revisit the ‘Risk’ and ‘Roles’ steps, as they are affected by the selection of a new RCSA. </li>
            </ul>


            <div class="d-flex justify-content-end w-100">
                <button class='primaryBtn  mr-4 buttonWidth' pButton type="button" label="Proceed" (click)="changeRCSA()"></button>
                <button class='primaryBtn   buttonWidth' pButton type="button" label="Cancel" (click)="modalRef.hide()"></button>
                <app-information-dialog [accordionIndex]='0'></app-information-dialog>
            </div>
        </div>
    </div>
</ng-template>