import { Component,Input,Output,EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { StepperService } from '../../../../shared/services/stepper.service';
import { BaseService } from 'src/app/shared/services';
import { NewRisk } from 'src/app/shared/models/new-risk.model';
import { RiskCaseConstants } from '../../common-constants';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-step4',
  templateUrl: './step4.component.html',
  styleUrls: ['./step4.component.scss']
})
export class Step4Component implements OnInit, OnDestroy {
  viewStatus: string;
  searchValue: string;
  selectedRisk;
  risksLists = [];
  riskCase: NewRisk;
  RiskCaseConstants = RiskCaseConstants;
  @Input() viewButton: boolean = true;
  @Output() newData: EventEmitter<any> = new EventEmitter<any>();
  subscription: Subscription = new Subscription();

  constructor(private stepperService: StepperService,
              private baseService: BaseService) {
    this.viewStatus = 'mainView';
    this.riskCase = stepperService.getNewRisk();
    if(this.riskCase.risk){
      this.selectedRisk = this.riskCase.risk[0];
    }
    if(this.riskCase.mandate && this.riskCase.mandate.risk_assessment_id) {
      this.baseService.get('risks?riskAssessmentId=' + this.riskCase.mandate.risk_assessment_id).subscribe(res => {
        this.risksLists = res.body.data;
      });
    }

  }

  ngOnInit(): void {
    this.subscription.add(this.stepperService.closeCurrentStep.subscribe(index => this.stepperService.updateModel(index, this.selectedRisk, 'risk')));
    this.subscription.add(this.stepperService.saveCurrentStep.subscribe(() =>  this.stepperService.changeIndex(4, this.selectedRisk , 'risk')));
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
  
  nextStep(data) {
    this.stepperService.changeIndex(4, data , 'risk');
  }

  onChangeRB(event) {
    this.selectedRisk = event;
    this.newData.emit({prop: 'risk', propValue: this.selectedRisk});

  }
  emitEvent(eventData){
    this.newData.emit(eventData);

  }
  // search
  Search(payLoad: any): void {
    this.searchValue = payLoad;
  }
}
