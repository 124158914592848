<div class="d-flex justify-content-between">
    <div class="mdl-card mdl-shadow--2dp">



        <div class="mdl-card__supporting-text">

            <div class="mdl-stepper-horizontal-alternative">
                <div *ngFor='let status of statusList; let i = index' class="mdl-stepper-step" [ngClass]="{'active-step step-done': (status.id < statusOrder  && statusOrder!=37) ,  'active-step editable-step':status.id == statusOrder }">
                    <div class="mdl-stepper-circle"><span>{{i + 1}}</span></div>
                    <div class="mdl-stepper-title">{{status.name}}</div>
                    <div *ngIf="status.id != 11" class="mdl-stepper-bar-left"></div>
                    <div *ngIf="status.id == 11" class="mdl-stepper-bar-left  mdl-stepper-bar-right-dashed"></div>
                    <div *ngIf="status.id != 10" class="mdl-stepper-bar-right"></div>
                    <div *ngIf="status.id == 10" class="mdl-stepper-bar-right mdl-stepper-bar-right-dashed"></div>
                </div>


            </div>

        </div>

    </div>
    <ng-container *ngIf=' (!this.showButtonForUserRole([ "NAN"]) || this.showButtonForUser([this.ownerId, this.delegate,  creator,this.ORM].concat(this.GovernanceCoordinator))) && isDesktopDevice'>
        <ng-container *ngIf="showBlueArrow(statusOrder)">
            <ng-container *ngIf='actionButtonText() !=""'>
                <img src="./assets/images/summary/actionbutton.png" class=" t-demo__btn  actionIcon" *ngIf="!existCheckFn(this.statusOrder,[37,10,11]) && !showMissing" ngxTippy [tippyProps]="tooltip(actionButtonText() , 'top')" (click)='checkValidation(riskCaseAllField,riskCase,statusOrder)'
                    style="     z-index: 999;   height: 100%;   width: 150px;" alt="">
                <img src="./assets/images/summary/actionbuttondis.png" class=" t-demo__btn  actionIcon" *ngIf="!existCheckFn(this.statusOrder,[37,10,11]) && showMissing" ngxTippy [tippyProps]="tooltip(actionButtonText() , 'top')" (click)='showMissing=false;showActionForUsers=false'
                    style="     z-index: 999;   height: 100%;   width: 150px;" alt="">

            </ng-container>


        </ng-container>
    </ng-container>


</div>
<div class="row">
    <div *ngIf='riskCase.submit_comment' class="col-md-3">

        <div class="contantStyle">

            Submission Comment
        </div>
        <div>
            {{riskCase.submit_comment}}
        </div>
        <div *ngIf="riskCase.requestor">
            {{riskCase.requestor.user_name }}
        </div>
        <div *ngIf="riskCase.received_date">
            {{showDate(riskCase.received_date) | oneOrmDate}}
        </div>


    </div>
    <div *ngIf='riskCase.risk_manager_comment' class="col-md-3">

        <div class="contantStyle">

            Risk Manager Comment
        </div>
        <div>
            {{riskCase.risk_manager_comment}}
        </div>
        <div *ngIf="riskCase.approver">
            {{riskCase.approver.user_name }}
        </div>
        <div *ngIf="riskCase.risk_manager_date">
            {{showDate(riskCase.risk_manager_date) | oneOrmDate}}
        </div>


    </div>
    <div *ngIf='riskCase.close_comment' class="col-md-3">

        <div class="contantStyle">

            Closure Comment
        </div>
        <div>
            {{riskCase.close_comment}}
        </div>
        <div *ngIf="riskCase.approver">
            {{riskCase.approver.user_name }}
        </div>
        <div *ngIf="riskCase.close_date">
            {{ showDate(riskCase.close_date) | oneOrmDate}}
        </div>

    </div>
    <div *ngIf='riskCase.cancel_comment' class="col-md-3">

        <div class="contantStyle">

            Cancellation Comment
        </div>
        <div>
            {{riskCase.cancel_comment}}
        </div>
        <div *ngIf="riskCase.cancelled_by">
            {{riskCase.cancelled_by.user_name }}
        </div>
        <div *ngIf="riskCase.cancel_date">
            {{showDate(riskCase.cancel_date) | oneOrmDate}}
        </div>
    </div>

</div>

<div class='row' *ngIf='showMissing && statusOrder!=37 && statusOrder != 11 && statusOrder != 10'>
    <div class='col-md-6'>
        <mat-accordion class="fix-radio-position " hideToggle="true">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-description class='d-flex' style="align-items: center;">

                        <div class="d-flex justify-content-between w-100">
                            <div [ngClass]="(requeredList.length>0)?'required':''">
                                Missing/Invalid <span class='noOfListItem'> ({{requeredList.length}})</span> Required Fields

                            </div>
                        </div>
                    </mat-panel-description>
                </mat-expansion-panel-header>


                <div class="modal-body row">

                    <ul class="mb-auto dUL bd-highlight requeredItemList">
                        <li *ngFor='let requeredItem of requeredList' (click)='openPopUp(requeredItem.popupName)'>
                            <ng-container *ngIf="requeredItem.title == 'RCSA'">
                                {{requeredItem.title}} : Contact your Op Risk Manager ({{riskCase.risk_manager.user_name}}) to correct
                            </ng-container>
                            <ng-container *ngIf="requeredItem.title != 'RCSA'">
                                {{requeredItem.title}}
                            </ng-container>
                        </li>

                    </ul>


                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class='col-md-6'>
        <mat-accordion class="fix-radio-position " hideToggle="true">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-description class='d-flex' style="align-items: center;">

                        <div class="d-flex justify-content-between w-100">
                            <div>
                                Missing <span class='noOfListItem'> ({{optionalList.length}})</span> Optional Fields

                            </div>
                        </div>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="modal-body row">


                    <ul class="mb-auto dUL bd-highlight optionalItemList">
                        <li *ngFor='let optionalItem of optionalList' (click)='openPopUp(optionalItem.popupName)'>
                            {{optionalItem.title}}
                        </li>

                    </ul>


                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>


    <div *ngIf='statusOrder==6' class="col-md-12 d-flex justify-content-end mt-2 ">


        <ng-container *ngIf='!showButtonForUserRole(["CORM","ORM","ORMII"])'>

            <button *ngIf='showButtonForUser([creator, ownerId,delegate,ORM].concat(GovernanceCoordinator)) || showButtonForUserRole(["GC"])' class='primaryBtn  ml-4 buttonWidth align-self-end t-demo__btn' ngxTippy [tippyProps]="tooltip('Send a request to Op Risk Manager for review and approval' , 'top')"
                pButton type="button" [label]="'Submit Event for Review'" [disabled]='requeredList.length>0' (click)="openCommentPopup(ActionMsg.event.submitDraft,'submitDraft',false,commentAndAction)"></button>
            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Cancels this event and informs all stakeholders' , 'top')">
                <button *ngIf='showButtonForUser([creator, ownerId,manager,delegate,ORM].concat(GovernanceCoordinator)) || showButtonForUserRole(["GC"])' class='primaryBtn  ml-4 buttonWidth align-self-end ' pButton type="button" label="Cancel Event" (click)="openCommentPopup(ActionMsg.event.CancelDraft,'CancelDraft',true,commentAndAction)"></button>
            </div>

        </ng-container>

        <ng-container *ngIf='showButtonForUserRole(["CORM","ORM","ORMII"])'>


            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Opens this event and informs all stakeholders' , 'top')">

                <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" [label]="'Approve Event'" [disabled]='requeredList.length>0' (click)="openCommentPopup(ActionMsg.event.ApproveEvent,'ApproveEvent',false,commentAndAction)"></button>
            </div>

            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Cancels this event and informs all stakeholders' , 'top')">
                <button class='primaryBtn  ml-4 buttonWidth align-self-end ' pButton type="button" label="Cancel Event" (click)="openCommentPopup(ActionMsg.event.CancelDraft,'CancelDraft',true,commentAndAction)"></button>
            </div>

        </ng-container>
    </div>


    <div *ngIf='statusOrder==7' class="col-md-12 d-flex justify-content-end mt-2 ">


        <ng-container *ngIf='!showButtonForUserRole(["CORM","ORM","ORMII"])'>

            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Cancels this event and informs all stakeholders' , 'top')">
                <button *ngIf='showButtonForUser([ ownerId,manager,delegate].concat(GovernanceCoordinator)) || showButtonForUserRole(["GC"])' class='primaryBtn  ml-4 buttonWidth align-self-end ' pButton type="button" label="Cancel Event" (click)="openCommentPopup(ActionMsg.event.CancelDraft,'CancelDraft',true,commentAndAction)"></button>
            </div>

        </ng-container>

        <ng-container *ngIf='showButtonForUserRole(["CORM","ORM","ORMII"])'>

            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Opens this event and informs all stakeholders' , 'top')">

                <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" [label]="'Approve Event'" [disabled]='requeredList.length>0 ' (click)="openCommentPopup(ActionMsg.event.ApproveEventR,'ApproveEventR',false,commentAndAction)"></button>
            </div>
            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Rejects the request, changes the status back to event and informs the requestor that further updates need to be made' , 'top')">

                <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" label="Reject request " (click)="openCommentPopup(ActionMsg.event.RejectEventR,'RejectEventR',true,commentAndAction)"></button>
            </div>

            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Cancels this event and informs all stakeholders' , 'top')">
                <button class='primaryBtn  ml-4 buttonWidth align-self-end  ' pButton type="button" label="Cancel Event" (click)="openCommentPopup(ActionMsg.event.CancelDraft,'CancelDraft',true,commentAndAction)"></button>

            </div>

        </ng-container>

    </div>


    <div *ngIf='statusOrder==8' class="col-md-12 d-flex justify-content-end mt-2 ">
        <ng-container>
            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Create Issue' , 'top')">
                <app-issue-from-event [eventData]='riskCase.risk_assessment' [mandate]='stepperService.newRisk.mandate' [eventId]='stepperService.newRisk.riskCaseId'></app-issue-from-event>
            </div>
        </ng-container>

        <ng-container *ngIf='!showButtonForUserRole(["CORM","ORM","ORMII"])'>

            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Send a request to ORM for review and approval' , 'top')">

                <button *ngIf='showButtonForUser([ ownerId,manager,delegate])' class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" [label]="'Submit Event for Closure'" [disabled]='requeredList.length>0 ' (click)="openCommentPopup(ActionMsg.event.SubmitEventforClosureApproval,'SubmitEventforClosureApproval',false,commentAndAction)"></button>
            </div>

        </ng-container>
        <ng-container *ngIf='showButtonForUserRole(["CORM","ORM","ORMII"])'>


            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Closes this event and informs all stakeholders' , 'top')">

                <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" label="Close Event" [disabled]=' requeredList.length > 0' (click)="openCommentPopup(ActionMsg.event.closeEvent,'closeEvent',true,commentAndAction)"></button>

            </div>

        </ng-container>


    </div>


    <div *ngIf='statusOrder==9' class="col-md-12 d-flex justify-content-end mt-2 ">
        <ng-container *ngIf='showButtonForUserRole(["CORM","ORM","ORMII"])'>

            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Closes this event and informs all stakeholders' , 'top')">

                <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" label="Approve Closure" [disabled]='requeredList.length>0' (click)="openCommentPopup(ActionMsg.event.approveClose,'approveClose',true,commentAndAction)"></button>
            </div>

            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Rejects the request, changes the status back to open and informs the requestor that further updates need to be made' , 'top')">


                <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" label="Reject Closure " (click)="openCommentPopup(ActionMsg.event.rejectClose,'rejectClose',true,commentAndAction)"></button>
            </div>


        </ng-container>

    </div>


</div>


<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title m-0">WARNING!</h4>
        <span (click)="modalRef.hide()" class="material-icons informationIcon">
            close
        </span>

    </div>
    <div class="modal-body">
        <div>
            <ul class=" dUL bd-highlight mb-4">
                <li> Cancelled events cannot be reactivated!</li>

            </ul>


            <div class="d-flex justify-content-end w-100">
                <button class='primaryBtn  mr-4 buttonWidth' pButton type="button" label="Proceed" (click)='changeStatus(11,comment,"cancel_comment","cancelled_by","cancel_date",219);modalRef.hide()'></button>
                <button class='primaryBtn   buttonWidth' pButton type="button" label="Cancel" (click)="modalRef.hide()"></button>
                <app-information-dialog [accordionIndex]='0'></app-information-dialog>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #commentAndAction>
    <div class="modal-header">
        <h4 class="modal-title m-0"></h4>
        <span (click)="modalRef.hide()" class="material-icons informationIcon">close</span>
    </div>
    <div class="modal-body">
        <div *ngIf='loadAssociatedOpenIssuesInProgress || this.associatedOpenIssues.length === 0'>
            <div [innerHTML]="popupMsg"></div>

            <div>
                <div class="col-md-12 py-4 contantStyle">
                    Comment <span *ngIf="commentStatus">(Mandatory)</span>
                    <span *ngIf="!commentStatus">(Optional)</span>
                </div>
                <div class="col-md-12 mb-4 d-flex flex-column ">
                    <small style=" text-align: right;" [ngClass]="(!byteCount(commentAction.value,4000).valid)?'warningTxt':''">
            {{byteCount(commentAction.value, 4000).length}} / 4000
          </small>
                    <textarea id="eventTitleInput" #commentAction cols="50" [(ngModel)]='comment' rows="3"></textarea>

                    <small>
            <span *ngIf='!byteCount(commentAction.value,4000).valid' class="warningTxt">
                The text entered exceeds the maximum number of characters allowed. Please reduce the text length up to the defined limit.
            </span>
          </small>
                </div>
            </div>

            <div class="d-flex justify-content-end w-100">

                <button class='primaryBtn buttonWidth' pButton type="button" label="Cancel" (click)='modalRef.hide()'>
        </button>

                <ng-container *ngIf='actionTaken == "submitDraft"'>
                    <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" [disabled]='!byteCount(commentAction.value,4000).valid || changeStatusRequestLoading' [label]="'Update'" (click)='changeStatus(statusOrder+1,comment,"submit_comment","requestor","received_date",217)'>
          </button>
                </ng-container>

                <ng-container *ngIf='actionTaken == "CancelDraft"'>
                    <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" [label]="'Update'" [disabled]='comment == ""||!byteCount(commentAction.value,4000).valid || changeStatusRequestLoading' (click)='changeStatus(11,comment,"cancel_comment","cancelled_by","cancel_date",219)'>
          </button>
                </ng-container>

                <ng-container *ngIf='actionTaken == "ApproveEvent"'>
                    <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" [disabled]='!byteCount(commentAction.value,4000).valid || changeStatusRequestLoading' [label]="'Update'" (click)='changeStatus(8,comment,"risk_manager_comment" ,"approver","risk_manager_date",218)'>
          </button>
                </ng-container>

                <ng-container *ngIf='actionTaken == "ApproveEventR"'>
                    <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" [label]="'Update'" [disabled]='!byteCount(commentAction.value,4000).valid || changeStatusRequestLoading' (click)='changeStatus(statusOrder+1,comment, "risk_manager_comment","approver","risk_manager_date",220 )'>
          </button>
                </ng-container>

                <ng-container *ngIf='actionTaken == "RejectEventR"'>
                    <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" [label]="'Update'" [disabled]='comment == ""|| !byteCount(commentAction.value,4000).valid || changeStatusRequestLoading' (click)='changeStatus(statusOrder-1,comment, "risk_manager_comment","approver","risk_manager_date",221 )'>
          </button>
                </ng-container>

                <ng-container *ngIf='actionTaken == "SubmitEventforClosureApproval"'>
                    <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" [label]="'Update'" [disabled]='!byteCount(commentAction.value,4000).valid || changeStatusRequestLoading' (click)='changeStatus(statusOrder+1,comment, "submit_comment" ,"requestor","received_date", 222)'>
          </button>
                </ng-container>

                <ng-container *ngIf='actionTaken == "closeEvent"'>
                    <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" [label]="'Update'" [disabled]='comment == ""|| !byteCount(commentAction.value,4000).valid || changeStatusRequestLoading' (click)='changeStatus(10,comment, "close_comment","approver","close_date",223)'>
          </button>
                </ng-container>

                <ng-container *ngIf='actionTaken == "rejectClose"'>
                    <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" [label]="'Update'" [disabled]='comment == ""|| !byteCount(commentAction.value,4000).valid || changeStatusRequestLoading' (click)='changeStatus(statusOrder-1,comment, "risk_manager_comment","approver","risk_manager_date",224)'>
          </button>
                </ng-container>

                <ng-container *ngIf='actionTaken == "approveClose"'>
                    <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" [label]="'Update'" [disabled]='comment == ""|| !byteCount(commentAction.value,4000).valid || changeStatusRequestLoading' (click)='changeStatus(10,comment, "close_comment","approver","close_date", 225)'>
          </button>
                </ng-container>
            </div>
        </div>
        <div>
            <ng-container *ngIf='!loadAssociatedOpenIssuesInProgress && this.associatedOpenIssues.length > 0'>
                <div class="required">
                    <p>Note: Events can only be closed when all related issues are completed.</p>
                    <p>Complete below issue(s) before proceeding:</p>
                    <ul class=" dUL bd-highlight mb-4">
                        <li *ngFor="let issue of associatedOpenIssues;"><a href="{{issue.permalink}}" target="_blank">{{issue.source_name}}</a> - {{issue.title}}</li>
                    </ul>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>
