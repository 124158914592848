import { Component, OnInit, TemplateRef,ViewChild, Input, OnChanges } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { informationLists, informationListsIssue, informationListsRCSA } from '../../../../shared/common/textualDescriptions';
import {   StepperService } from '@shared/services';
import { NewRisk } from 'src/app/shared/models/new-risk.model';

@Component({
  selector: 'app-information-dialog',
  templateUrl: './information-dialog.component.html',
  styleUrls: ['./information-dialog.component.scss']
})
export class InformationDialogComponent implements OnInit, OnChanges {
  @Input() accordionIndex;
  @Input() issue: boolean = false;
  @Input() openmulti: boolean = false;
  @Input() secaccordionIndex;
  infoTitle = 'Information';

  riskCase: NewRisk;

  informationLists = [];


  @ViewChild('template') template: TemplateRef<any>; 





  modalRef: BsModalRef;
  constructor(private modalService: BsModalService,private stepperService: StepperService) {
    this.riskCase = stepperService.newRisk;
    this.setData();

  
  
  }
  ngOnInit(): void {
  }
  ngOnChanges(){
   this.setData();
  }

  setInfoData(infoType){


    if (infoType == 'RCSA') {
      this.informationLists = informationListsRCSA;
      this.infoTitle = 'Risk and Control Self-Assessment (RCSA)';
    }
    if (infoType == 'issue') {
      this.informationLists = informationListsIssue;
      this.infoTitle = 'Issue Management';
    }
    if (infoType == 'event') {
      this.informationLists = informationLists;
      this.infoTitle = 'Event Reporting';
    }


    this.openModal();
  }
  openModal() {
    const configs = {
      keyboard: true,
      animated: true,
      class: ' modal-lg modal-primary informationModal',
      ignoreBackdropClick: false
    };
    this.modalRef = this.modalService.show(this.template, configs);
  }

setData(){
  this.informationLists = [];
  if ( this.issue){
    this.informationLists = informationListsIssue;
    return;

  }

  if (this.stepperService.newRisk.event){
    this.informationLists = informationLists;

  }
  if ( this.stepperService.newRisk.issue){
    this.informationLists = informationListsIssue;

  }
  
  
  if ( !this.stepperService.newRisk.issue && !this.stepperService.newRisk.event) {
    this.informationLists = [
      {
        title: 'Mandate',
        information: `We assign all reported cases (Issues and Events) in Risk and Control Assessments (RCSA) to clearly define ownership to an organizational entity.
        <br>
  
        <span class="material-icons">
  arrow_right
  </span>
  Please confirm the Organisational Unit & RCSA where your case best belongs to or follow the wizard to choose a different one.
  
        <br>
  
  
        <div class='mt-3'>
        <b>
  
        Additional Information:
        </b>
  
        </div>
  
  
        <div class='mt-3'>
        <b>
  
        What is an RCSA?
        </b>
        </div>
  
        <ul class='m-0'>
    <li>The Risk and Control Self-Assessment (also known as RCSA) provides an overview of all identified material operational risk exposures for a specific organisational unit, including all open issues and events.
    </li>
    <li>
    RCSA Owners periodically (re-) assess control effectiveness against identified material operational risk exposures.
  </li>
  
  </ul>
        ` },
      {
        title: 'Event or Issue',
        information: `We differentiate between things that have happened in order to learn from them and foster transparency; and things which need to be addressed to prevent them from happening in the future.
  
        <br>
  
        <span class="material-icons">
  arrow_right
  </span>
        Please select whether something <b>“has happened” (Event)</b> or describes a problem which
        <b>“needs to be addressed” (Issue)</b>.
        <br>
        <div class='mt-3'>
        <b>
  
        Additional Information:
        </b>
  
        </div>
  
  
  
        <div class='mt-3'>
        <b>
  
        What is an Event?
  
        </b>
        </div>
  
        <ul class='m-0'>
        <li>
        An operational event is a risk which has materialised, I.e., something went wrong. 
        </li>
  
    <li>
    Ongoing efforts after the initial reporting are primarily related to root cause analysis and the documentation of lessons learned. 
    </li>
    <li>
    Should any corrective actions be identified to prevent a re-occurring they can also be raised as issues at a later stage and should not delay the initial reporting of the event. 
    </li>
  
  </ul>
  
  
  
  <div class='mt-3'>
  <b>
  
  What is an Issue?
  
  
  </b>
  </div>
  
  <ul class='m-0'>
  <li>
  An issue describes a problem where a process or a control is non-existent, is not working as designed or where there is an opportunity to strengthen an existing control. 
  
  </li>
  <li>
  Corrective action plans are required to fix the issue within a timely manner in order to avoid a risk materializing/move outside the tolerance limits for Swiss Re.
  </li>
  
  </ul>
  
  
  
        ` },
    ];
  }
}

}
