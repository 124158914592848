export enum IssueStatus {
    Draft= 23,
    DraftPendingReview=24,
    Open=25,
    OpenPendingCompletionApproval= 27,
    OpenDueDateChangePending= 28,
    OpenDueDateExtended= 29,
    Completed= 30,
    Cancelled= 36
}

export enum EventStatus {
    Draft= 6,
    DraftPendingReview=7,
    Open=8,
    OpenPendingClosureApproval= 9,
    Closed= 10,
    Cancelled= 11
}

export enum IssuePropertes {
    ThirdLineIssue= 215,

}


export const PrimaryParentType = {
    Risk: {id:443, name:'Risk'},
    RiskAssessment:{id:442, name:'Risk Assessment'},
    Event: {id: 445,  name: 'Event'}
}


export const dateFormat = ' dd MMM yyyy';

export const RISK_CASE_ID_PARAM_MARK = '?id=';
export const RISK_CASE_ID_MAX_LENGTH = 8;
export const RETURN_BACK_TO_RCSA_PARAM = '?returnBackToRcsa=true';
export const RCSA_TAB_PARAM = 'rcsaTab';

