import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StepperService } from 'src/app/shared/services/stepper.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  riskCases;
  constructor(
    private stepperService: StepperService,
    private router: Router) { }

  ngOnInit(): void {
  }
  
  newCase() {
    this.stepperService.resetRiskCase();
    this.router.navigate(['/new-risk']);
  }
}
