import { Component, OnInit, Input, Output , EventEmitter, OnChanges} from '@angular/core';
import { StepperService } from '../../../../../shared/services/stepper.service';
import { BaseService } from 'src/app/shared/services';
import { NewRisk } from 'src/app/shared/models/new-risk.model';
@Component({
  selector: 'app-additional-information-list',
  templateUrl: './additional-information-list.component.html',
  styleUrls: ['./additional-information-list.component.scss']
})
export class AdditionalInformationListComponent implements OnInit, OnChanges {

  @Input()  mainList: [];
  @Input()  selectedItem: any;
  @Input()  objectValue: string;
  @Input()  objectId: string;

  @Input()  title: string;
  @Input()  placeHolder: string;


  @Output() newselectedItem: EventEmitter<any> = new EventEmitter<any>();




  riskCase: NewRisk;

  searchValue: string;

  selectedRisk;

  constructor(private stepperService: StepperService,
              private baseService: BaseService) {
                this.riskCase = stepperService.getNewRisk();


  }

  ngOnInit(): void {
  }
  ngOnChanges(){

  }


  onChangeRB(event) {
    this.selectedItem = event;
    this.selectedRisk = event;
    this.newselectedItem.emit(event);
  }

  // search
  Search(payLoad: any): void {
    this.searchValue = payLoad;
  }
}
