<div class="btn-group mr-3" dropdown (onShown)="handler(false)" (onHidden)="handler(true)" placement="bottom right">
    <span dropdownToggle class="material-icons-outlined btnIcon menuIconClick ">
      settings
      </span>

    <ul id="dropdown-alignment" *dropdownMenu class=" hambrgurMenuBody dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-alignment">
        <div class=" menuTitleContainer">
            <span dropdownToggle class="material-icons menuIcon">
          close
          </span>
        </div>
        <div class="menuTitle mt-2">Action</div>


        <li role="menuitem"> <a class="dropdown-item" dropdownToggle (click)='newCase()'>Report a new case (event or issue)</a></li>
        <li role="menuitem"><a class="dropdown-item" dropdownToggle (click)='goOutsideApp(requestControlChange)'>Request a control change</a></li>
        <li role="menuitem"><a class="dropdown-item" dropdownToggle (click)='goOutsideApp("https://swissreesm.service-now.com/contactone?id=request_access_rights")'>Request access</a></li>
        <li role="menuitem"><a class="dropdown-item" dropdownToggle (click)='goOutsideApp("https://swissreesm.service-now.com/contactone?id=request_support_view")'>Report bug</a></li>

        <li class="divider dropdown-divider"></li>

        <div class="menuTitle mt-2">Dashboards and reports</div>
        <li role="menuitem"><a class="dropdown-item" dropdownToggle (click)='goOutsideApp(environmentVariables.OperationalRiskDashboard)'>Operational Risk Dashboards</a></li>

    </ul>
</div>

<div class="btn-group" dropdown (onShown)="handler(false)" (onHidden)="handler(true)" placement="bottom right">
    <span dropdownToggle class="material-icons-outlined btnIcon menuIconClick ">
    help_outline
    </span>

    <ul id="dropdown-alignment" *dropdownMenu class=" hambrgurMenuBody dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-alignment">
        <div class=" menuTitleContainer">

            <span dropdownToggle class="material-icons menuIcon">
        close
        </span>
        </div>
        <div class="menuTitle mt-2">What's new</div>
        <li role="menuitem"><a class="dropdown-item" dropdownToggle (click)='goOutsideApp("https://wiki.swissre.com/pages/viewpage.action?pageId=396202449")'>OneORM - Release log</a></li>



        <li class="divider dropdown-divider"></li>


        <div class="menuTitle mt-2">OneORM - Help</div>
        <li role="menuitem"><a class="dropdown-item" dropdownToggle (click)="onFaqLinkClicked()">Frequently asked questions (FAQ)</a></li>
        <li role="menuitem"><a class="dropdown-item" dropdownToggle (click)='infoDialog.setInfoData("RCSA")'>RCSA</a></li>
        <li role="menuitem"><a class="dropdown-item" dropdownToggle (click)='infoDialog.setInfoData("event")'>Event reporting</a></li>
        <li role="menuitem"><a class="dropdown-item" dropdownToggle (click)='infoDialog.setInfoData("issue")'>Issue management</a></li>

        <li class="divider dropdown-divider"></li>





        <div class="menuTitle mt-2">ORM - Standards</div>

        <li role="menuitem"><a class="dropdown-item" dropdownToggle (click)='goOutsideApp("https://swissre.sharepoint.com/sites/policymanagement/PMTPublishedDocuments/5/T000650.pdf")'>RCSA</a></li>
        <li role="menuitem"><a class="dropdown-item" dropdownToggle (click)='goOutsideApp("https://swissre.sharepoint.com/sites/policymanagement/PMTPublishedDocuments/5/T000647.pdf")'>Event reporting</a></li>
        <li role="menuitem"><a class="dropdown-item" dropdownToggle (click)='goOutsideApp("https://swissre.sharepoint.com/sites/policymanagement/PMTPublishedDocuments/5/T000646.pdf")'>Issue management</a></li>



    </ul>
</div>

<div style="display: none;">
    <app-information-dialog #infoDialog></app-information-dialog>
</div>
