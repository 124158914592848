import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  @Input() displayConfirmDialog: boolean;
  @Output() reloadRiskCase = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  reload(){
    this.reloadRiskCase.emit();
  }
}
