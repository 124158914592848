

<div *ngIf="riskCase.event;else other_content">
    <app-step3-event></app-step3-event>
</div>

<ng-template #other_content>
    <app-step3-issue></app-step3-issue>

</ng-template>
