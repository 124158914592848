import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {GraphUser} from 'src/app/shared/models/graphUser.model';
import {BaseService, MsalService, StepperService} from 'src/app/shared/services';
import {RISK_CASE_ID_PARAM_MARK} from '@shared/common/constants';

export enum Status {
  Available,
  AvailableIdle,
  Away,
  BeRightBack,
  Busy,
  BusyIdle,
  DoNotDisturb,
  Offline,
  PresenceUnknown
}

@Component({
  selector: 'app-right-sidenav',
  templateUrl: './right-sidenav.component.html',
  styleUrls: ['./right-sidenav.component.scss']
})

export class RightSidenavComponent implements OnInit {
  @Output() closeRightBar = new EventEmitter();


  numberOfItems = 3;
  numberOfItemsL = 3;
  showSupport = true;
  mailText = '';
  governanceCoordinators: GraphUser[] = [];
  riskManagers: GraphUser[] = [];
  compliances: GraphUser[] = [];

  constructor(private baseService: BaseService,
              private stepperService: StepperService,
              private authService: MsalService) {
  }

  ngOnInit(): void {
    this.stepperService.currentRcsas.subscribe(rcsas => {
      this.governanceCoordinators = [];
      this.riskManagers = [];
      this.compliances = [];
      this.stepperService.checkRCSA(rcsas);
      this.governanceCoordinators = this.stepperService.governanceCoordinators;
      this.riskManagers = this.stepperService.riskManagers;
      this.compliances = this.stepperService.graphComplianceContacts;
    });
    // don't load the current user rcsa if coming from permalink
    if (window.location.href.indexOf(RISK_CASE_ID_PARAM_MARK) === -1 && (this.authService.riskCaseId == null || this.authService.riskCaseId === 0) &&
      !(/\/new-risk\/mandate\/[0-9]+/.test(window.location.href)) // is not Raise Issue/Event url from RCSA module
    ) {
      console.log('LOAD DEFAULT SIDEBAR');
      this.stepperService.loadRcsaByCurrentUser();
    }
  }

  areSupportContactAvailable() {
    if (this.governanceCoordinators.length == 0 && this.riskManagers.length == 0 && this.compliances.length == 0) {
      return false;
    } else {
      return true;
    }
  }

  sendChatMessage(messageTo: string) {
    let link: string = 'msteams:/l/chat/0/0?users=' + this.authService.loggedUser.user_email_address + ',' + messageTo;
    if (this.stepperService.newRisk.riskCaseId) {
      link = link + '&topicName=Op Event ' + this.stepperService.newRisk.riskCaseId + '&message=Please help me with this case: ' + this.stepperService.permalinkText;
    } else {
      link = link + '&topicName=Op Event Support&message=Please help me with the process of a new risk case';
    }
    location.href = link;
  }

  startTeamChat() {
    const riskCaseId = this.stepperService.newRisk.riskCaseId;
    let link: string = 'msteams:/l/chat/0/0?users=';
    this.stepperService.riskManagers.forEach(rm => {
      link = link + rm.userPrincipalName + ',';
    })
    this.stepperService.governanceCoordinators.forEach(element => {
      link = link + element.userPrincipalName + ',';
    })
    if (riskCaseId) {
      link = link + '&topicName=Op Event ' + this.stepperService.newRisk.riskCaseId + '&message=Please help me with this case: ' + this.stepperService.permalinkText;
    } else {
      link = link + '&topicName=Op Event Support&message=Please help me with the process of a new risk case';
    }
    location.href = link;
  }

  scheduleMeeting(meetTo: string) {
    let link: string = 'msteams:/l/meeting/new?attendees=' + meetTo;
    const riskCaseId = this.stepperService.newRisk.riskCaseId;
    if (riskCaseId) {
      link = link + '&subject=Op Event ' + riskCaseId + '&content=Please help me with this case: ' + this.stepperService.permalinkText;
    } else {
      link = link + '&subject=Op Event Support&content=Please help me with the process of a new risk case';
    }
    location.href = link;
  }

  scheduleTeamMeeting() {
    const riskCaseId = this.stepperService.newRisk.riskCaseId;
    let link: string = 'msteams:/l/meeting/new?attendees=';
    this.stepperService.riskManagers.forEach(rm => {
      link = link + rm.userPrincipalName + ',';
    })
    this.stepperService.governanceCoordinators.forEach(element => {
      link = link + element.userPrincipalName + ',';
    })
    if (this.stepperService.newRisk.riskCaseId) {
      link = link + '&subject=Op Event ' + riskCaseId + '&content=Please help me with this case: ' + this.stepperService.permalinkText;
    } else {
      link = link + '&subject=Op Event Support&content=Please help me with the process of a new risk case';
    }
    location.href = link;
  }

  sendEmail(to: string, name: string) {
    let subject = '';
    let body = 'Hello ' + name + ',%0D%0A%0D%0A I need your support on risk case reporting process... ';
    if (this.stepperService.newRisk.riskCaseId) {
      subject = 'Support request for risk case ' + this.stepperService.newRisk.event.title
      body = body + '%0D%0AYou can find it here: ' + this.stepperService.permalinkText;
    } else {
      subject = 'Support request on a generic risk case';
    }
    body = body + '%0D%0A%0D%0A%0D%0A Kind Regards,%0D%0A%0D%0A' + this.authService.loggedUser.user_name;
    this.mailText = 'mailto:' + to + '?subject=' + subject + '&body=' + body;
    window.location.href = this.mailText;
  }

  getColor(status: string) {
    let color;
    switch (status) {
      case Status[Status.Available]:
        color = '#32a852';
        break;
      // case Status[Status.AvailableIdle]:
      //   color = 'check_circle';
      //  break;
      case Status[Status.Away]:
        color = '#fcba03';
        break;
      case Status[Status.BeRightBack]:
        color = '#fcba03';
        break;
      case Status[Status.Busy]:
        color = '#eb4034';
        break;
      // case Status[Status.BusyIdle]:
      //   color = 'check_circle';
      //   break;
      case Status[Status.DoNotDisturb]:
        color = '#eb4034';
        break;
      case Status[Status.Offline]:
        color = 'grey';
        break;
      default: // Status.PresenceUnknown
        color = 'grey';
        break;
    }
    return {
      'position': 'absolute',
      'bottom': '4px',
      'right': '0px',
      'font-size': '17px',
      'color': color,
      'border': '2px solid #ffffff',
      'border-radius': '50%',
      'background-color': '#ffffff'
    };
  }

  getPresenceIcon(status: string) {
    switch (status) {
      case Status[Status.Available]:
        return 'check_circle';
      case Status[Status.Away]:
        return 'watch_later';
      case Status[Status.BeRightBack]:
        return 'watch_later';
      case Status[Status.Busy]:
        return 'lens';
      case Status[Status.DoNotDisturb]:
        return 'remove_circle';
      case Status[Status.Offline]:
        return 'cancel';
      default: //Status.PresenceUnknown
        return 'cancel';
    }
  }

  closeBar() {
    this.closeRightBar.emit();

  }
}
