<ng-container *ngIf='refreshData'>

    <mat-card id="detail-container">
        <div class="h-100 contactContainer">




            <div class="d-flex justify-content-between headerContainer">
                <div>
                    <p class="contantStyle">
                        <ng-container *ngIf='riskCase.source_name'>
                            {{riskCase.source_name}}</ng-container> {{riskCase.event ? riskCase.event.title : ''}}




                    </p>
                    <p>
                        RCSA:
                        <ng-container *ngIf='resultGit.risk_assessment'>{{resultGit.risk_assessment.name}}, {{resultGit.risk_assessment.description}},
                        </ng-container>
                        {{riskCase.mandate.owner}}


                    </p>
                    <p>Parent Risk:
                        <ng-container *ngIf='riskCase.risk'>
                            {{riskCase.risk.name}} {{riskCase.risk.description}}

                        </ng-container>

                    </p>
                    <p *ngIf='riskCaseOverview'>
                        Folder: {{riskCaseOverview.folder}}

                    </p>
                </div>






                <div>



                </div>


            </div>

            <div class="hlup"></div>


            <div class=" contantContainer mt-4">


                <div class="row">
                    <div class="col-md-4">
                        <div class="d-flex">
                            <div class=""> <span class="summaryIcon  material-icons">
                                    account_circle
                                </span></div>
                            <div>
                                <p class="mb-0">
                                    Event Owner *
                                </p>
                                <p class="contantStyle">{{riskCase.roles.eventOwner.user_name}}




                                </p>
                                <p class="mb-0">Delegate</p>
                                <p *ngIf='riskCase.roles.eventDelegate' class="contantStyle">
                                    {{riskCase.roles.eventDelegate.user_name}}

                                </p>



                            </div>
                        </div>


                    </div>
                    <div class="col-md-4">
                        <div class="d-flex">
                            <div class=""> <span class="summaryIcon  material-icons">
                                    date_range
                                </span></div>
                            <div>
                                <p class="mb-0">

                                    Discovery Date (CET) *
                                </p>
                                <p *ngIf="riskCase.event" class="contantStyle">{{dateValidate(riskCase.event.discoveryDate) | oneOrmDate}}

                                </p>
                                <p class="mb-0">Occurrence Date (CET) *

                                </p>
                                <p *ngIf="riskCase.event" class="contantStyle">{{dateValidate(riskCase.event.occurredDate) | oneOrmDate}}

                                </p>


                            </div>
                        </div>





                    </div>
                    <div class="col-md-4">
                        <div class="d-flex">
                            <div class=""> <span class="summaryIcon  material-icons">
                                    daccess_time
                                </span></div>
                            <div>

                                <p class="mb-0">
                                    Status
                                </p>
                                <p class="contantStyle"> {{riskStatus}}

                                </p>
                                <p class="mb-0">Op Risk Manager </p>
                                <p class="contantStyle">{{riskCase.mandate.risk_manager}}</p>


                            </div>
                        </div>



                    </div>

                </div>
                <div class="hlup"></div>

                <div class="hlup"></div>

                <div class="d-flex justify-content-between">
                    <div class="w-100">
                        <div class="row w-100">
                            <div class="col-md-12 py-4 contantStyle">

                                Description

                            </div>
                            <div class="col-md-8">
                                <div class="mb-3 w-100 inputField">


                                    <div *ngIf="riskCase.event">
                                        {{riskCase.event.description}}
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-4">
                                <p *ngIf="riskCase.event" class="">{{dateValidate(riskCase.event.discoveryDate) | oneOrmDate}}</p>
                                <p class="">{{riskCase.roles.eventOwner.user_name}}</p>





                            </div>
                        </div>
                        <div class="row w-100">

                            <div class="col-md-8">
                                <div class="w-100 py-4 contantStyle">

                                    Root Cause Description


                                </div>
                                <div class="mb-3 w-100 inputField">
                                    <div>
                                        {{riskCase.root_cause_description}}
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div class="row w-100">

                            <div class="col-md-8">
                                <div class="w-100 py-4 contantStyle">

                                    Lessons Learned and Actions Taken


                                </div>
                                <div class="mb-3 w-100 inputField">
                                    <div>
                                        {{riskCase.lesson_learn_description}}
                                    </div>



                                </div>
                            </div>

                        </div>
                    </div>



                    <div class="sidTitleImg">
                        <img src="../../../../../assets/images/summary/eventNatratev.PNG" alt="">
                    </div>
                </div>




                <div class="hlup"></div>
                <div class="d-flex justify-content-between">
                    <div class="w-100">
                        <div class="row minH mt-2 ">
                            <div class=" col-md-4">


                                <span class="contantStyle">
                                    Event Location
                                </span>


                                <p *ngIf='riskCase.location' class=""> {{riskCase.location.name}}</p>

                            </div>
                            <div class="bLeft col-md-4">


                                <span class="contantStyle">
                                    Outsourcing
                                </span>


                                <div *ngIf='riskCase.outsourcing'>
                                    {{ outsourse(riskCase.outsourcing) }}
                                </div>


                            </div>



                        </div>


                        <div class="row minH mt-2 ">
                            <div class="col-md-4">
                                <span class="contantStyle">
                                    Impacted Business Units
                                </span>
                                <ng-container *ngIf='showBUI'>

                                    <div class="row" *ngIf='BiLists(riskCase.businessImpact).length > 0'>
                                        <div class="col-md-12" *ngFor="let Capital of  BiLists(riskCase.businessImpact); let i = index">


                                            <div>
                                                {{Capital.bus_impact_name}}

                                            </div>

                                        </div>

                                    </div>

                                </ng-container>


                            </div>
                            <div class="bLeft col-md-7">


                                <span class="contantStyle">
                                    Impacted Legal Entities
                                </span>



                                <div *ngIf="riskCase.legalEntities.length > 0">
                                    <app-step8 [pdfView]='true' [viewButton]='false' [edit]='false'></app-step8>
                                </div>

                            </div>
                        </div>

                        <div class="row minH my-2 ">
                            <div class=" col-md-4">


                                <span class="contantStyle">
                                    Function Code
                                </span>


                                <p *ngIf='riskCase.company_function' class=""> {{riskCase.company_function.name}}</p>

                            </div>
                            <div class="bLeft col-md-4">
                                <span class=" contantStyle">
    
                                    Root Cause Code
    
    
                                </span>

                                <p class="">Level 1: -
                                    <ng-container *ngIf='riskCase.root_cause_level_1'>
                                        {{riskCase.root_cause_level_1.name}}</ng-container>
                                </p>
                                <p class="">Level 2: -
                                    <ng-container *ngIf='riskCase.root_cause_level_2'>
                                        {{riskCase.root_cause_level_2.name}}</ng-container>
                                </p>




                            </div>



                        </div>

                    </div>
                    <div class="sidTitleImg">
                        <img src="../../../../../assets/images/summary/ORGANISATIONALATTRIBUTES.png" alt="">
                    </div>
                </div>





                <div class="hlup"></div>

                <div class="d-flex justify-content-between">
                    <div class="row minH my-2 w-100">
                        <div class="col-md-4">


                            <span class="contantStyle">
                                ICOFR
                            </span>
                            <div *ngIf='riskCaseOverview'>
                                {{riskCaseOverview.icofr1_name}}

                            </div>
                            <div *ngIf='riskCaseOverview'>
                                {{riskCaseOverview.icofr2_name}}

                            </div>


                        </div>
                        <div class="col-md-4 bLeft">


                            <span class="contantStyle">
                                Local Reporting
                            </span>


                            <ng-container *ngIf='riskCase.local_reporting'>
                                <div *ngFor='let item of riskCase.local_reporting'>
                                    {{item.name}}

                                </div>
                            </ng-container>


                        </div>
                    </div>

                    <div class="sidTitleImg">
                        <img src="../../../../../assets/images/summary/frr.png" alt="">
                    </div>
                </div>





                <div class="hlup"></div>
                <div class="d-flex justify-content-between">
                    <div class="row  w-100">
                        <div class="col-md-12">
                            <span class="contantStyle">
                                Monetary Impact
                            </span>

                            <div class="w-100">



                                <app-step10-event #step10_View [viewOnly]='true' [viewOnlyPdf]='true' (newData)='updateData($event)'></app-step10-event>

                            </div>
                        </div>

                    </div>

                    <div class="sidTitleImg">
                        <img src="../../../../../assets/images/summary/MONETARYIMPACT.png" alt="">
                    </div>
                </div>






                <div class="hlup"></div>

                <div class="d-flex justify-content-between">
                    <div class="row  w-100">
                        <div class="row minH m-2 w-100">

                            <div class="col-md-4">


                                <span class="contantStyle">
                                    Event Type
                                </span>


                                <p class="">Level 1: -
                                    <ng-container *ngIf='riskCase.event_type_level_1'>
                                        {{riskCase.event_type_level_1.name}}</ng-container>
                                </p>
                                <p class="">Level 2: -
                                    <ng-container *ngIf='riskCase.event_type_level_2'>
                                        {{riskCase.event_type_level_2.name}}</ng-container>
                                </p>


                            </div>
                            <div class=" bLeft col-md-6">


                                <span class="contantStyle">
                                    Scenario Linkage
                                </span>




                                <ng-container *ngIf='riskCase.scenario_link'>
                                    <p>
                                        {{riskCase.scenario_link.name}}

                                    </p>

                                </ng-container>

                            </div>
                        </div>
                        <div class="hlup w-100"></div>
                        <div class="row w-100  minH mt-2 mx-2">

                            <div class="col-md-8">


                                <span class="contantStyle">
                                    Compliance Category
                                </span>


                                <ng-container *ngIf='riskCase.compliance_risk_category'>
                                    <div *ngFor='let item of riskCase.compliance_risk_category'>
                                        {{item.name}}

                                    </div>
                                </ng-container>



                            </div>
                        </div>

                    </div>

                    <div class="sidTitleImg">
                        <img src="../../../../../assets/images/summary/ADDITIONAATTRIBUTES.png" alt="">
                    </div>
                </div>

















            </div>

        </div>




    </mat-card>



</ng-container>