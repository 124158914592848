<span *ngIf='!link' (click)="openModal(video)" class="material-icons  informationIcon">
    info
</span>

<span class="link" *ngIf='link' (click)="openModal(video)">
    {{link}}</span>




<ng-template #video>
    <div class="modal-header">
        <h4 class="modal-title m-0">Information</h4>
        <span (click)="modalRef.hide()" class="material-icons informationIcon">
            close
        </span>


    </div>
    <div class="modal-body">

        <iframe width="640" height="360" src="https://web.microsoftstream.com/embed/video/8fe8fd3f-b661-47a9-9997-b25f8fbfecc1?autoplay=false&showinfo=true" allowfullscreen style="border:none;" title="Video"></iframe>


    </div>
</ng-template>