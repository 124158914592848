<mat-tab-group animationDuration="0ms" (selectedTabChange)="onTabChange($event)" [(selectedIndex)]="selectedTab">

    <mat-tab>
        <ng-template mat-tab-label>
            <label class="mat-tab-label-content t-demo__btn" ngxTippy [tippyProps]="tooltip('All my currently open tasks', 'bottom')">MY TO DO'S  </label>
        </ng-template>

        <app-my-todos-list></app-my-todos-list>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <label class="mat-tab-label-content t-demo__btn" ngxTippy [tippyProps]="tooltip('All issues and events where I have an assigned role or responsibility', 'bottom')">MY CASES  </label>
        </ng-template>


        <app-my-risk-cases (refreshTabs)="refreshTabs($event)" [statusListDefault]="statusListDefault" [statusListDefaultIssue]="statusListDefaultIssue" [statusList]="statusList" [issueStatusList]="issueStatusList" [loggedUserRole]='loggedUserRole' url='risk-cases-view/my-cases'
            [filterAttr]='"myCases"' #myriskcases></app-my-risk-cases>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <label class="mat-tab-label-content t-demo__btn" ngxTippy [tippyProps]="tooltip('All issues and events to which I have access', 'bottom')">ALL CASES  </label>
        </ng-template>
        <app-my-risk-cases (refreshTabs)="refreshTabs($event)" [statusListDefault]="statusListDefault" [statusListDefaultIssue]="statusListDefaultIssue" [statusList]="statusList" url='risk-cases-view' [issueStatusList]="issueStatusList" [loggedUserRole]='loggedUserRole'
            [filterAttr]='"allCases"' #allriskcases></app-my-risk-cases>
    </mat-tab>
    <mat-tab *ngIf="showRCSATab">
        <ng-template mat-tab-label>
            <label class="mat-tab-label-content t-demo__btn" ngxTippy [tippyProps]="tooltip('All RCSAs to which I have access', 'bottom')">ALL RCSAs  </label>
        </ng-template>

        <app-rcsa-list></app-rcsa-list>
    </mat-tab>