import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-info-video-modal',
  templateUrl: './info-video-modal.component.html',
  styleUrls: ['./info-video-modal.component.scss']
})
export class InfoVideoModalComponent implements OnInit {
  @Input() link: string = null;


  modalRef: BsModalRef;
  constructor(private modalService: BsModalService) { }
  ngOnInit(): void {
  }

  openModal(template: TemplateRef<any>) {
    const configs = {
      keyboard: true,
      animated: true,
      class: ' modal-lg modal-primary informationModalVideo',
      ignoreBackdropClick: false
    };
    this.modalRef = this.modalService.show(template, configs);
  }


}
