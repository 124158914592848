
import { Component } from '@angular/core';
import { NewRisk } from 'src/app/shared/models/new-risk.model';
import { MsalService ,  StepperService } from '@shared/services';
@Component({
  selector: 'app-step6-event',
  templateUrl: './step6-event.component.html',
  styleUrls: ['./step6-event.component.scss']
})
export class Step6EventComponent {
  viewStatus: string;
  today30 = new Date(); // Now
  riskCase: NewRisk;
  loggedUser;
  constructor(private msalService: MsalService,private stepperService: StepperService) {
    this.viewStatus = 'mainView';
    this.riskCase = stepperService.newRisk;
    this.loggedUser = msalService.getLoggedUser().user_id;

    this.today30 = this.riskCase.target_due_date; // Set now + 30 days as the new date
  }

  nextStep() {
    this.stepperService.changeIndex(5, null, '');
  }

  changeViewStatus(status: string) {
    this.viewStatus = status;
  }

}
