import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule  } from 'primeng/inputtext';
import { InputNumberModule, TreeModule  } from 'primeng';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { StepsModule } from 'primeng/steps';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';


const primeng = [
  ButtonModule,
  CheckboxModule,
  DropdownModule,
  InputTextModule,
  MenuModule,
  MultiSelectModule,
  SliderModule,
  TableModule,
  TooltipModule, InputNumberModule,
  DialogModule,
  ConfirmDialogModule,
  StepsModule,
  AutoCompleteModule,
  TreeModule
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    primeng
  ],
  exports: [
    primeng
  ],
  providers: [
    ConfirmationService,
  ]
})
export class PrimengModule { }
