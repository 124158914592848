<div class="containerDiv">
    <div class="w-100 mb-5" *ngIf="viewStatus === 'mainView'">
        <p class="mb-3">
            Which of the following statements fits your case best?
        </p>


        <div class="row justify-content-between mb-4 ">
            <div class="d-flex p-4 col-xl-6 flex-column bd-highlight  border-right ">
                <p class="mb-3">
                    I would like to report an event (potentially) including:

                </p>
                <ul class="mb-auto dUL bd-highlight">
                    <li>a cash loss; </li>


                    <li>financial misstatement;
                    </li>
                    <li>legal and regulatory consequences;
                    </li>
                    <li>reputational damage;
                    </li>
                    <li>fraudulent activities (only when case is closed); or
                    </li>
                    <li>as lessons learned beneficial to other areas.
                    </li>





                </ul>

                <div class="justify-content-end mb-2 mt-5 d-flex w-100">
                    <button class='primaryBtn  buttonWidth ' pButton type="button" label="Event" [disabled]="riskCaseId || stepperService.issueFromEvent" (click)="nextStep('event')"></button>
                    <app-information-dialog [accordionIndex]='1'></app-information-dialog>
                </div>


            </div>

            <div class="d-flex p-4 col-xl-6 flex-column bd-highlight ">
                <p class="mb-3">
                    I need to raise an issue related to:
                </p>
                <ul class="mb-auto dUL bd-highlight">
                    <li>improving risk mitigation;</li>
                    <li>controls which are missing;</li>
                    <li> controls not designed effectively; or </li>

                    <li>controls not operating effectively.</li>

                </ul>
                <div class="justify-content-end d-flex mb-2 mt-5 w-100">
                    <button class='primaryBtn buttonWidth ' pButton type="button" label="Issue" [disabled]="riskCaseId || stepperService.issueFromEvent" (click)="nextStep('issue')"></button>
                    <app-information-dialog [accordionIndex]='1'></app-information-dialog>
                </div>

            </div>


        </div>

    </div>
</div>