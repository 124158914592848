import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {EventMessage, EventType, InteractionType} from '@azure/msal-browser';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {MsalBroadcastService, MsalService} from './shared/services';
import {MSAL_GUARD_CONFIG} from './shared/services/constants';
import {MsalGuardConfiguration} from './shared/services/msal.guard.config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'orm-client';
  accessCode;
  loggedIn = false;
  isIframe = false;
  showRightBar = true;
  private readonly _destroying$ = new Subject<void>();

  constructor(private router: Router,
              @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
              public authService: MsalService,
              private msalBroadcastService: MsalBroadcastService) {
    // this.router.events.subscribe((event: any) => {
    //   // if (!(event && event.error && event.error.status === 400)) {
    //   const state: RouterState = this.router.routerState;
    //   const snapshot: RouterStateSnapshot = state.snapshot;
    //   const root: ActivatedRouteSnapshot = snapshot.root;

    //   // Don't delete - useful for debug purposes
    //   if (event instanceof ResolveStart) {
    //   }
    //   if (event instanceof ResolveEnd) {
    //   }
    // });
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;

    this.checkAccount();

    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
      takeUntil(this._destroying$)
    )
    .subscribe((result) => {
      this.checkAccount();
    });
  }

  checkAccount() {
    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.loginPopup({...this.msalGuardConfig.authRequest})
      .subscribe(() => this.checkAccount());
    } else {
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest});
    }
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

}
