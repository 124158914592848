import { Pipe, PipeTransform } from '@angular/core';

import { DatePipe } from '@angular/common';

@Pipe({
    name: 'oneOrmDate'
})
export class OneOrmDatePipe implements PipeTransform {

    transform(value: any) {
         const datePipe = new DatePipe('en-US');
         value = datePipe.transform(value, 'dd MMM yyyy');
         return value;
     }
}
