import { Event } from './event.model';
import { Issue } from './issue.model';
import { LegalEntity } from './legal-entity.model';
import { ComplianceRiskCategory, EnumType, LocalReporting, ScenarioLlink } from './local-reporting.model';
import { Mandate } from './mandate.model';

import { RiskCaseConstants } from 'src/app/modules/new-risk/common-constants';
import { BusinessImpact } from './business-impact.model';
import { Roles } from './roles.model';
import { User } from './user.model';

export class NewRisk {
    private _riskCaseId: number;
    private _issue_source_id: EnumType;
    private _type_id: EnumType;
    private _due_date_status: EnumType;
    private _completion_status: EnumType;
    private _type: EnumType;
    private _rcOverdue: EnumType;
    private _overall_impact_rating: EnumType;

    private _lastStep: number = 0;
    private _mandate: Mandate;
    private _event: Event;
    private _issue: Issue;
    private _risk: any;
    private _roles: Roles;
    private _legalEntities : LegalEntity[];
    private _monetary_impacts: any;
    private _identify_LOD: any;
    private _controls: any[];
    private _impact_ratings: any[];
    private _risk_assessments: any[];
    private _risks: any[];
    private _primary_parent_id: any;
    private _primary_parent_type_id: any;


    private _compliance_risk_category : ComplianceRiskCategory[];
    private _scenario_link : ScenarioLlink;
    private _local_reporting : LocalReporting[];
    private _action_items : any[];
    private _close_date: Date;
    private _parent_event_id: any;

    private _status: number = RiskCaseConstants.DRAFT_TECHNICAL;
    private _creationDate: Date = new Date();
    private _cancel_date: Date;
    private _submission_date: Date;
    private _risk_manager_date: Date;
    private _rcInitialDueDate: Date;
    private _approver: User;
    private _risk_manager: User;
    private _cancelled_by : User;
    private _dde_approver : User;
    private _completion_approver : User;
    private _assurOwner : User;
    private _rcPastDueDate: Date;
    private _proposed_due_date: Date;

    private _requestor : User;
    private _lesson_learn_description: string;
    private _assurComment : string;
    private _reOpened: string;

    private _creator: User;
    private _root_cause_description: string;
    private _resolutionApproach: string;
    private _rcLatestAssessmDesc : string;
    private _received_date: Date;

    private _modifyDate: Date;
    private _modifier: string;
    private _target_due_date: Date;
    private _businessImpact: BusinessImpact = new BusinessImpact();
    private _outsourcing: any ;
    private _source_name: string;
    private _rcsa_own_date: Date;
    private _rcsa_own_comment_dde_date: Date;

    private _cancel_comment: string;
    private _dueDateComment: string;
    private _close_comment: string;
    private _risk_manager_comment: string;
    private _rcsa_own_comment_dde: string;
    private _submit_comment: string;

    private _rcsa_own_comment: string;
  
    private _company_function: any;
    private _root_cause_level_1: any;
    private _root_cause_level_2: any;
    private _location: any;


    private _event_type_level_1: any;
    private _event_type_level_2: any;

    private _permalinkText: string;
    private _folder: string;

    public get riskCaseId(): number {
        return this._riskCaseId;
    }

    public set riskCaseId(_riskCaseId: number) {
        this._riskCaseId = _riskCaseId;
    }

    public get lastStep(): number {
        return this._lastStep;
    }

    public set lastStep(_lastStep: number) {
        this._lastStep = _lastStep;
    }

    public get mandate(): Mandate {
        return this._mandate;
    }
    public set mandate(value: Mandate) {
        this._mandate = value;
    }

    public get event(): Event {
        return this._event;
    }

    public set event(_event: Event) {
        this._event = _event;
    }

    public get issue(): Issue {
        return this._issue;
    }

    public set issue(_issue: Issue) {
        this._issue = _issue;
    }

    public get risk(): any{
        return this._risk;
    }

    public set risk(_risk: any) {
        this._risk = _risk;
    }
    public get primary_parent_id(): any {
        return this._primary_parent_id;
    }

    public set primary_parent_id(_primary_parent_id: any) {
        this._primary_parent_id = _primary_parent_id;
    }


    public get identify_LOD(): any {
        return this._identify_LOD;
    }

    public set identify_LOD(_identify_LOD: any) {
        this._identify_LOD = _identify_LOD;
    }



    public get primary_parent_type_id(): any {
        return this._primary_parent_type_id;
    }

    public set primary_parent_type_id(_primary_parent_type_id: any) {
        this._primary_parent_type_id = _primary_parent_type_id;
    }
    public get controls(): any {
        return this._controls;
    }

    public set controls(_controls: any) {
        this._controls = _controls;
    }

    
    public get risk_assessments(): any {
        return this._risk_assessments;
    }

    public set risk_assessments(_risk_assessments: any) {
        this._risk_assessments = _risk_assessments;
    }



    
    
    public get risks(): any {
        return this._risks;
    }

    public set risks(_risks: any) {
        this._risks = _risks;
    }










    public get impact_ratings(): any {
        return this._impact_ratings;
    }

    public set impact_ratings(_impact_ratings: any) {
        this._impact_ratings = _impact_ratings;
    }

    public get roles(): Roles {
        return this._roles;
    }

    public set roles(_roles: Roles) {
        this._roles = _roles;
    }



    public get monetary_impacts(): any {
        return this._monetary_impacts;
    }

    public set monetary_impacts(_monetary_impacts: any) {
        this._monetary_impacts = _monetary_impacts;
    }


    public get compliance_risk_category(): ComplianceRiskCategory[] {
        return this._compliance_risk_category;
    }

    public set compliance_risk_category(_compliance_risk_category: ComplianceRiskCategory[]) {
        this._compliance_risk_category = _compliance_risk_category;
    }


    public get scenario_link(): ScenarioLlink {

        return this._scenario_link;
    }

    public set scenario_link(_scenario_link: ScenarioLlink) {
        this._scenario_link = _scenario_link;
    }





    public get local_reporting(): LocalReporting[] {
        return this._local_reporting;
    }

    public set local_reporting(_local_reporting: LocalReporting[]) {
        this._local_reporting = _local_reporting;
    }







    public get action_items(): any[] {
        return this._action_items;
    }

    public set action_items(_action_items: any[]) {
        this._action_items = _action_items;
    }





    public get legalEntities(): LegalEntity[] {
        return this._legalEntities;
    }

    public set legalEntities(_legalEntities: LegalEntity[]) {
        this._legalEntities = _legalEntities;
    }

    public get status(): number {
        return this._status;
    }

    public set status(_status: number) {
        this._status = _status;
    }

    public get creationDate(): Date {
        return this._creationDate;
    }

    public set creationDate(_creationDate: Date) {
        this._creationDate = _creationDate;
    }

    public get creator(): User {
        return this._creator;
    }

    public set creator(_creator: User) {
        this._creator = _creator;
    }



    public set root_cause_description(_root_cause_description: string) {
        this._root_cause_description = _root_cause_description;
    }
    public get root_cause_description(): string {
        return this._root_cause_description;
    }

    public set source_name(_source_name: string) {
        this._source_name = _source_name;
    }
    public get source_name(): string {
        return this._source_name;
    }


    public set lesson_learn_description(_lesson_learn_description: string) {
        this._lesson_learn_description = _lesson_learn_description;
    }
    public get lesson_learn_description(): string {
        return this._lesson_learn_description;
    }
    public set reOpened(_reOpened: string) {
        this._reOpened = _reOpened;
    }
    public get reOpened(): string {
        return this._reOpened;
    }
    public set assurComment (_assurComment : string) {
        this._assurComment  = _assurComment ;
    }
    public get assurComment (): string {
        return this._assurComment ;
    }


    public set rcLatestAssessmDesc (_rcLatestAssessmDesc : string) {
        this._rcLatestAssessmDesc  = _rcLatestAssessmDesc ;
    }
    public get rcLatestAssessmDesc (): string {
        return this._rcLatestAssessmDesc ;
    }

    public get received_date(): Date {
        return this._received_date;
    }

    public set received_date(_received_date: Date) {
        this._received_date = _received_date;
    }



    public set resolutionApproach(_resolutionApproach: string) {
        this._resolutionApproach = _resolutionApproach;
    }
    public get resolutionApproach(): string {
        return this._resolutionApproach;
    }

    public get rcsa_own_date(): Date {
        return this._rcsa_own_date;
    }

    public set rcsa_own_date(_rcsa_own_date: Date) {
        this._rcsa_own_date = _rcsa_own_date;
    }



    public get rcsa_own_comment_dde_date(): Date {
        return this._rcsa_own_comment_dde_date;
    }

    public set rcsa_own_comment_dde_date(_rcsa_own_comment_dde_date: Date) {
        this._rcsa_own_comment_dde_date = _rcsa_own_comment_dde_date;
    }







    public get modifyDate(): Date {
        return this._modifyDate;
    }

    public set modifyDate(_modifyDate: Date) {
        this._modifyDate = _modifyDate;
    }





    public get modifier(): string {
        return this._modifier;
    }

    public set modifier(_modifier: string) {
        this._modifier = _modifier;
    }

    public get target_due_date(): Date {
        return this._target_due_date;
    }

    public set target_due_date(target_due_date: Date) {
        this._target_due_date = target_due_date;
    }

    public get businessImpact(): any {
        return this._businessImpact;
    }

    public set businessImpact(_businessImpact: any) {
        this._businessImpact = _businessImpact;
    }
    public get outsourcing(): any {
        return this._outsourcing;
    }

    public set outsourcing(_outsourcing: any) {
        this._outsourcing = _outsourcing;
    }
    public get location(): any {
        return this._location;
    }

    public set location(_location: any) {
        this._location = _location;
    }

    public get company_function(): any {
        return this._company_function;
    }

    public set company_function(_company_function: any) {
        this._company_function = _company_function;
    }
    public get root_cause_level_1(): any {
        return this._root_cause_level_1;
    }

    public set root_cause_level_1(_root_cause_level_1: any) {
        this._root_cause_level_1 = _root_cause_level_1;
    }

    public get root_cause_level_2(): any {
        return this._root_cause_level_2;
    }

    public set root_cause_level_2(_root_cause_level_2: any) {
        this._root_cause_level_2 = _root_cause_level_2;
    }


    // private event_type_level_1: any;
    // private event_type_level_2: any;

    public get event_type_level_1(): any {
        return this._event_type_level_1;
    }

    public set event_type_level_1(_event_type_level_1: any) {
        this._event_type_level_1 = _event_type_level_1;
    }

    public get event_type_level_2(): any {
        return this._event_type_level_2;
    }

    public set event_type_level_2(_event_type_level_2: any) {
        this._event_type_level_2 = _event_type_level_2;
    }


    public get parent_event_id(): any {
        return this._parent_event_id;
    }

    public set parent_event_id(_parent_event_id: any) {
        this._parent_event_id = _parent_event_id;
    }
    public get close_date(): any {
        return this._close_date;
    }

    public set close_date(_close_date: any) {
        this._close_date = _close_date;
    }

    public get cancel_date(): any {
        return this._cancel_date;
    }

    public set cancel_date(_cancel_date: any) {
        this._cancel_date = _cancel_date;
    }


    public get submission_date(): any {
        return this._submission_date;
    }

    public set submission_date(_submission_date: any) {
        this._submission_date = _submission_date;
    }

    public get risk_manager_date(): any {
        return this._risk_manager_date;
    }

    public set risk_manager_date(_risk_manager_date: any) {
        this._risk_manager_date = _risk_manager_date;
    }
    public get rcInitialDueDate(): any {
        return this._rcInitialDueDate;
    }

    public set rcInitialDueDate(_rcInitialDueDate: any) {
        this._rcInitialDueDate = _rcInitialDueDate;
    }


    public get rcPastDueDate(): any {
        return this._rcPastDueDate;
    }

    public set rcPastDueDate(_rcPastDueDate: any) {
        this._rcPastDueDate = _rcPastDueDate;
    }
    public get proposed_due_date(): any {
        return this._proposed_due_date;
    }

    public set proposed_due_date(_proposed_due_date: any) {
        this._proposed_due_date = _proposed_due_date;
    }

    public get cancelled_by(): any {
        return this._cancelled_by;
    }

    public set cancelled_by(_cancelled_by: any) {
        this._cancelled_by = _cancelled_by;
    }
    public get dde_approver(): any {
        return this._dde_approver;
    }

    public set dde_approver(_dde_approver: any) {
        this._dde_approver = _dde_approver;
    }
 
    public get completion_approver(): any {
        return this._completion_approver;
    }

    public set completion_approver(_completion_approver: any) {
        this._completion_approver = _completion_approver;
    }
 


    public get assurOwner(): any {
        return this._assurOwner;
    }

    public set assurOwner(_assurOwner: any) {
        this._assurOwner = _assurOwner;
    }
 
    public get approver(): any {
        return this._approver;
    }

    public set approver(_approver: any) {
        this._approver = _approver;
    }

    public get risk_manager(): any {
        return this._risk_manager;
    }

    public set risk_manager(_risk_manager: any) {
        this._risk_manager = _risk_manager;
    }
    public get requestor(): any {
        return this._requestor;
    }

    public set requestor(_requestor: any) {
        this._requestor = _requestor;
    }


    public get cancel_comment(): any {
        return this._cancel_comment;
    }

    public set cancel_comment(_cancel_comment: any) {
        this._cancel_comment = _cancel_comment;
    }
    public get issue_source_id(): any {
        return this._issue_source_id;
    }

    public set issue_source_id(_issue_source_id: any) {
        this._issue_source_id = _issue_source_id;
    }

    public get dueDateComment(): any {
        return this._dueDateComment;
    }

    public set dueDateComment(_dueDateComment: any) {
        this._dueDateComment = _dueDateComment;
    }
    public get type_id(): any {
        return this._type_id;
    }

    public set type_id(_type_id: any) {
        this._type_id = _type_id;
    }
    public get type(): any {
        return this._type;
    }

    public set type(_type: any) {
        this._type = _type;
    }
      public get risk_manager_comment(): any {
        return this._risk_manager_comment;
    }

    public set risk_manager_comment(_risk_manager_comment: any) {
        this._risk_manager_comment = _risk_manager_comment;
    }

    public get rcsa_own_comment_dde(): any {
        return this._rcsa_own_comment_dde;
    }

    public set rcsa_own_comment_dde(_rcsa_own_comment_dde: any) {
        this._rcsa_own_comment_dde = _rcsa_own_comment_dde;
    }


    public get due_date_status(): any {
        return this._due_date_status;
    }

    public set due_date_status(_due_date_status: any) {
        this._due_date_status = _due_date_status;
    }





    public get completion_status(): any {
        return this._completion_status;
    }

    public set completion_status(_completion_status: any) {
        this._completion_status = _completion_status;
    }













    public get rcOverdue(): any {
        return this._rcOverdue;
    }

    public set rcOverdue(_rcOverdue: any) {
        this._rcOverdue = _rcOverdue;
    }

    public get overall_impact_rating(): any {
        return this._overall_impact_rating;
    }

    public set overall_impact_rating(_overall_impact_rating: any) {
        this._overall_impact_rating = _overall_impact_rating;
    }



    public get submit_comment(): any {
        return this._submit_comment;
    }

    public set submit_comment(_submit_comment: any) {
        this._submit_comment = _submit_comment;
    }

    public get close_comment(): any {
        return this._close_comment;
    }

    public set close_comment(_close_comment: any) {
        this._close_comment = _close_comment;
    }

    public get rcsa_own_comment(): any {
        return this._rcsa_own_comment;
    }

    public set rcsa_own_comment(_rcsa_own_comment: any) {
        this._rcsa_own_comment = _rcsa_own_comment;
    }

    public get permalinkText(): any {
        return this._permalinkText;
    }

    public set permalinkText(_permalinkText: any) {
        this._permalinkText = _permalinkText;
    }

    public get folder(): string {
        return this._folder;
    }

    public set folder(folder: string) {
        this._folder = folder;
    }



}
