import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event } from 'src/app/shared/models/event.model';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { StepperService, BaseService } from '../../../../shared/services';
import { dateFormat } from '@shared/common/constants';

@Component({
  selector: 'app-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.scss']
})
export class Step3Component implements OnInit, OnDestroy  {

  viewStatus: string;
  event = new Event();
  today = new Date();
  showError = false;
  dateFormat = dateFormat;
  form: FormGroup;
  riskCase;
  subscription: Subscription = new Subscription();
  MIList = [];
  constructor(builder: FormBuilder, private stepperService: StepperService, private baseService: BaseService) {
    this.riskCase = stepperService.getNewRisk();
    this.viewStatus = 'mainView';
    this.form = builder.group({
      title: new FormControl(null, [Validators.required ]),
      description: new FormControl(null, [Validators.required ]),
      discoveryDate: new FormControl(new Date(), [Validators.required ]),
      occurredDate: new FormControl(null, [Validators.required ]),
    });

    this.form.valueChanges.subscribe(value => {
    });

    const newRisk = stepperService.getNewRisk();
    // to be fixed
    if (newRisk.event && newRisk.event.title !== undefined){
      this.event = newRisk.event;

      this.baseService.get('risk-case/' + newRisk.riskCaseId + '/monetary-impacts').subscribe(res => {
        this.MIList = [];
        res.body.data.forEach(element => {
          if (element.monetary_impact_type.id !== 212){
            this.MIList.push(element);
          }
          
        });
        });
      const controls = this.form.controls;
      Object.keys(controls).forEach(key => {​​​​
        if(this.event[key]!=null){​​​​
          this.form.get(key).setValue(this.event[key]);
        }​​​​
      });
    }
  }
  checkDate(){
    this.showError = false;

    for (const iterator of this.MIList) {
      if(new Date(iterator.bus_date).getTime() < new Date(this.form.value.discoveryDate).getTime() ){
        this.showError = true;
        return;

      }
    }
  }
  ngOnInit(){
    this.subscription.add(this.stepperService.closeCurrentStep.subscribe(index => this.stepperService.updateModel(index, this.form.value, 'event')));
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  nextStep() {
    this.subscription.unsubscribe();
    this.stepperService.changeIndex(3, this.form.value, 'event');
  }

  cantEdit(event): boolean {
    return false;
  }

  dateChanged(evt,row){
    const selectedDate = new Date(evt);
    selectedDate.setMinutes(selectedDate.getMinutes() - selectedDate.getTimezoneOffset());
    this.form.get(row).setValue(selectedDate);
    if(this.MIList.length > 0 && this.form.value.discoveryDate){
      this.checkDate();
    }
   }
   canGo(){
     if(this.form.status === "VALID" && !this.showError){
      return false;
     }
     return true;

   }
}
