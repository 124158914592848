export class MonetaryImpact{
    monetary_impact_type: MiType;
    amount = 0;
    bus_date?: Date;
    insert_date?: Date;
    fine_penalty?: string;
    comment?: string;
    captured_recoveries?: Array<captured_recoveries>;

  }

export class captured_recoveries{
  monetary_impact_type: MiType;
  amount = 0;
  bus_date: Date;
  insert_date: Date;

  comment: string;
  }

export class AdditionalInformation{
    function: string;
    location: string;
    rootCause1: string;
    rootCause2: string;
  }
export class MiType{
    name?: string;
    id: number;

}
