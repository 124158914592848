import { Component, OnInit, OnDestroy } from '@angular/core';
import { NewRisk } from 'src/app/shared/models/new-risk.model';
import { StepperService, BaseService } from '../../../../shared/services';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-step10',
  templateUrl: './step10.component.html',
  styleUrls: ['./step10.component.scss']
})
export class Step10Component implements OnInit, OnDestroy {
  riskCase: NewRisk;


  constructor(private baseService: BaseService, private stepperService: StepperService,
              private modalService: BsModalService, private router: Router) {
                this.riskCase = stepperService.newRisk;

  }


  ngOnInit(): void {
  }

  ngOnDestroy(){
  }
  
}
