import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FileUpload} from 'primeng/primeng';
import {BaseService, StepperService} from '@shared/services';
import {Table} from 'primeng/table/table';
import {EventStatus, IssuePropertes, IssueStatus} from '@shared/common/constants';
import swal from 'sweetalert2';
import {Subject} from 'rxjs';
import {takeUntil, timeout} from "rxjs/operators";

@Component({
  selector: 'app-attachment-dialog',
  templateUrl: './attachment-dialog-component.html',
  styleUrls: ['./attachment-dialog-component.scss']
})
export class AttachmentDialogComponent implements OnInit, OnDestroy {
  @Input() displayAttachment: boolean;
  @Input() attachedFiles: any[];
  @Output() hideAttachmentDialog = new EventEmitter();
  fileUpload: FileUpload;
  @ViewChild('dt') table: Table;
  @Input() status: number;
  @Input() userId: any;
  @Input() userRole = '';
  @Input() riskManagers: any;
  @Input() authUsers: any;
  @Input() rcsaOwner: any;
  @Input() creator: any;
  @Input() isDesktopDevice: boolean;

  @Input() riskCaseData: any;
  IssueStatus = IssueStatus;
  EventStatus = EventStatus;
  IssuePropertes = IssuePropertes;

  currentQurter = true;
  cols = [
    {field: 'file_name', header: 'File Name', filter: '', mode: '', isVisible: true, index: 0},
    {
      field: 'description',
      header: 'Description',
      filter: '',
      mode: '',
      isVisible: true,
      index: 1,
      subfield: ''
    },
    {
      field: 'type',
      header: 'Type',
      filter: '',
      mode: '',
      isVisible: true,
      index: 3,
      subfield: 'value'
    },
    {
      field: 'creator',
      header: 'Creator',
      filter: '',
      mode: '',
      isVisible: true,
      index: 4,
      subfield: 'user_name'
    },
    {
      field: 'creation_date',
      header: 'Creation Date',
      filter: '',
      mode: '',
      isVisible: true,
      index: 5,
      subfield: 'value'
    },
    {field: 'icons', header: '', filter: '', mode: '', isVisible: true, index: 6},
  ];
  uploadedList: {
    id: number,
    file_name: string,
    type: string | null,
    description: string | null,
    file_size: string
  }[] = [];
  toUploadList: {
    file_name: string,
    base64: string | ArrayBuffer,
    type_id: number | null,
    file_size: string,
    description: string | null
  }[] = [];
  type: any;
  description = '';
  documentTypeList = [];
  acceptedFiles = '.bmp,.gif,.jpeg,.jpg,.png,.tif,.tiff,.txt,.rtf,.rtx,.pdf,.doc,.docx,.ppt,.pptx,.mpp,.vsd,.xls,.xlsx,.xlw,.zip,.msg';
  Toast = swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 10000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', swal.stopTimer);
      toast.addEventListener('mouseleave', swal.resumeTimer);

    }
  });
  private readonly _destroying$ = new Subject<void>();

  constructor(private baseService: BaseService,
              public stepperService: StepperService) {
    this.currentQurter = this.stepperService.yearQuarterCheck;
    this.baseService.get('enum/items/42').subscribe(res => {
      this.documentTypeList = [];
      res.body.data.forEach(element => {
        this.documentTypeList.push({label: element.name, value: element.id});
      });
    });
  }

  ngOnInit(): void {
    this.loadAttachmentList();
  }

  hideDialog() {
    this.hideAttachmentDialog.emit();
  }

  onUploadFile(event) {
  }

  onSelect(event, fu) {

    for (const uploadedFile of event.files) {
      this.fileUpload = uploadedFile.fu;
      if (!this.validateFileSize(uploadedFile) ||
        !this.validateFileType(uploadedFile) ||
        !this.checkDuplicates(uploadedFile)) {
        fu.clear();
        event.stopPropagation();
      }

      if (uploadedFile) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(uploadedFile);
        fileReader.onloadend = (): void => {
          this.toUploadList.push({
            file_name: uploadedFile.name,
            file_size: this.sizeFormatter(uploadedFile.size),
            base64: fileReader.result,
            type_id: this.type ? this.type.value : '',
            description: this.description
          });
        };
      }
    }
  }

  upload(fu) {
    // file links
    const riskCaseId = this.stepperService.newRisk.riskCaseId;
    this.toUploadList.forEach(element => {
      element.type_id = this.type ? this.type.value : '';
      element.description = this.description ? this.description : '';
    });

    this.baseService.put('risk-case/' + riskCaseId + '/attachment', this.toUploadList, this.stepperService.etag)
    .pipe(timeout(180000), takeUntil(this._destroying$))
    .subscribe(data => {
        this.stepperService.etag = data.headers.get('ETag').replace(/"/g, '');
        console.log(data);
        this.loadAttachmentList();
      }, error => {
        alert('Attachments Upload was failed! ' + error.statusText);
        console.log(error);
      },
      () => {
        fu.clear();
        this.description = null;
        this.type = null;
        this.toUploadList = [];
      });
  }

  clear(fu) {
    this.toUploadList = [];
    fu.clear();
    this.type = null;
    this.description = null;
  }

  deleteFile(attachmentId) {
    this.baseService.delete('risk-case/' + this.stepperService.newRisk.riskCaseId + '/attachment/' + attachmentId, this.stepperService.etag).subscribe(data => {
        this.stepperService.etag = data.headers.get('ETag').replace(/"/g, '');
        this.loadAttachmentList();
      }, error => {
        console.log(error);
        this.Toast.fire({
          icon: 'error',
          title: `Sorry, ` + error.error.message,
        });
      },
      () => {
      });
  }

  sizeFormatter(bytes: any) {
    console.log(bytes);
    if (bytes === 0) {
      return '0 B';
    }
    const k = 1000;
    const dm = 3;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  downloadAttachment(id, fileName) {
    this.baseService.getBlob('risk-case/' + this.stepperService.newRisk.riskCaseId + '/attachment/' + id, fileName).subscribe(res => {
      window.open(res);
    });
  }

  isUploadDisabled() {
    if (this.toUploadList && this.toUploadList.length > 0 && this.type && this.byteCount(this.description, 200).valid) {
      return false;

    } else {
      return true;

    }
  }

  setDeleteStyle(fieldName) {
    let styles = {};
    if (fieldName === 'uploadBtn') {
      if (this.toUploadList.length > 0) {
        styles = {
          display: 'none',

        };
      }

      return styles;

    }

    if (fieldName === 'file_name') {
      styles = {
        width: '20%',

      };
      return styles;

    }
    if (fieldName === 'creation_date') {
      styles = {
        width: '120px',

      };
      return styles;

    }
    if (fieldName === 'description') {
      styles = {
        width: '35%',

      };
      return styles;

    }
    if (fieldName === 'icons') {
      styles = {
        width: '120px',
        padding: ' 5px',
        'text-align': 'left',
      };
      return styles;
    }
    return {};

  }

  getControl(typeId) {
    let obj;
    if (this.documentTypeList.find(o => o.value === typeId)) {
      obj = this.documentTypeList.find(o => o.value === typeId);
    }
    return obj;

  }

  byteCount(textValue, maxlength) {
    if (!textValue) {
      return {valid: true, length: 0};

    }
    if (encodeURI(textValue).split(/%..|./).length - 1 > maxlength) {
      return {valid: false, length: encodeURI(textValue).split(/%..|./).length - 1};

    }
    return {valid: true, length: encodeURI(textValue).split(/%..|./).length - 1};
  }

  removeFile(index, fu) {
    if (this.toUploadList.length === 1) {
      this.clear(fu);
    } else {
      this.toUploadList.splice(index, 1);

    }
  }

  canModify() {
    if (!this.isDesktopDevice) {
      return false;
    }
    if (this.status === this.EventStatus.Draft || this.status === this.IssueStatus.Draft) {
      if (this.userId === this.creator) {
        return true;

      }

    }
    // Gov. Coordinators or risk managers can upload closed or completed risk cases within the current quarter
    if (this.status === this.EventStatus.Cancelled || this.status === this.EventStatus.Closed
      || this.status === this.IssueStatus.Completed || this.status === this.IssueStatus.Cancelled) {
      if (this.currentQurter && (this.checkCurrentRole(['CORM', 'ORM', 'ORM II', 'Governance Coordinator']) ||
        this.isRiskManager())) {
        return true;
      }
      if (this.currentQurter && this.riskCaseData && this.riskCaseData.identify_LOD.id === this.IssuePropertes.ThirdLineIssue && this.checkCurrentRole(['GIA'])) {
        return true;
      }
    } else {
      // Owner, Delegate, RCSA Owner, Governance Coordinator, All risk managers can upload
      if (this.checkCurrentUser(this.authUsers) ||
        this.checkCurrentRole(['CORM', 'ORM', 'ORM II', 'Governance Coordinator']) ||
        this.isRcsaOwner()) {
        return true;
      }
      if (this.riskCaseData && this.riskCaseData.identify_LOD.id === this.IssuePropertes.ThirdLineIssue && this.checkCurrentRole(['GIA'])) {
        return true;
      }
    }
    return false;
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  private validateFileSize(uploadedFile: any): boolean {
    if (uploadedFile.size > 26214400) {
      this.Toast.fire({
        icon: 'error',
        title: `Sorry, maximum file size of 25 MB exceeded`,
      });
      return false;
    }
    return true;
  }

  private validateFileType(uploadedFile: any): boolean {
    const extension = uploadedFile.name.split('.').pop();
    if (this.acceptedFiles && this.acceptedFiles.length > 0) {
      if (this.acceptedFiles.indexOf(extension.toLowerCase()) < 0) {
        this.Toast.fire({
          icon: 'error',
          title: `Sorry, .${extension} file extension not allowed`,
        });
        return false;
      }
    }
    return true;
  }

  private checkDuplicates(uploadedFile: any): boolean {
    const isAlreadyUloaded = this.uploadedList.some(f => f.file_name === uploadedFile.name);
    if (isAlreadyUloaded) {
      this.Toast.fire({
        icon: 'error',
        title: `Sorry, file ${uploadedFile.name} already uploaded`,
      });
      return false;
    }
    return true;
  }

  private loadAttachmentList() {
    this.toUploadList = [];
    this.baseService.get('risk-case/' + this.stepperService.newRisk.riskCaseId + '/attachment').subscribe(res => {
      this.uploadedList = res.body.data;
    });
  }

  private checkCurrentRole(rolelist: string[]): boolean {
    if (rolelist && rolelist.length > 0) {
      for (const iterator of rolelist) {
        console.log('Current user role:' + this.userRole);
        if (this.userRole.indexOf(iterator) >= 0) {
          return true;
        }
      }
    }

    return false;
  }

  private checkCurrentUser(userlist: string[]): boolean {
    if (userlist && userlist.length > 0) {
      for (const iterator of userlist) {
        if (this.userId === iterator) {
          return true;
        }
      }
    }
    return false;
  }

  private isRiskManager() {
    if (this.riskManagers.find(x => x.onPremisesSamAccountName === this.userId)) {
      return true;
    }
    return false;
  }

  private isRcsaOwner() {
    if (this.rcsaOwner === this.userId) {
      return true;
    }
    return false;
  }
}
