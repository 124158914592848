import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';
import {TooltipPosition} from '@angular/material/tooltip';
import {BaseService, MsalService, StepperService} from '@shared/services';
import {Router} from '@angular/router';
import {RiscCaseRequest as RiskCaseMessage} from '@shared/models/dto/risk-case-request.dto';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {UserValidationRoles as existCheck} from 'src/app/shared/models/validation/userValidations';
import {ActionMsg} from '@shared/common/textualDescriptions';

@Component({
  selector: 'app-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss']
})
export class StatusBarComponent implements OnInit, OnChanges {
  @Input() statusOrder: number;
  @Input() owner: boolean;
  @Input() cashOut: boolean;
  @Input() userRole: string;
  @Output() newData: EventEmitter<any> = new EventEmitter<any>();
  @Input() isDesktopDevice: boolean;

  @Output() changeStatusEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() openPopUpEdit: EventEmitter<any> = new EventEmitter<any>();
  @Input() statusBarRiskCaseData: any;

  associatedOpenIssues = [];
  loadAssociatedOpenIssuesInProgress = false;
  popupMsg = '';
  commentStatus;
  actionTaken;
  showActionForUsers = true;
  ActionMsg;
  modalRef: BsModalRef;
  statusHistory;
  position: TooltipPosition = 'above';
  riskCase;
  loggedUser: string;
  comment = '';
  creator = '';
  ownerId = '';
  manager = '';
  delegate = '';
  ORM = '';
  rcscStatus = [];
  loggedUserData;
  changeStatusBody = {
    id: null,
    rcId: null,
    rcsc_status_id: null,
    rcsc_status_after_id: null,
    rcsc_action_id: null,
    rcsc_action_date: null,
    rcsc_actor_id: '',
    rcsc_action_comment: ''
  };
  changeStatusRequestLoading = false;

  GovernanceCoordinator = [];

  showMissing = false;
  statusList = [
    {
      id: 6,
      name: 'Draft',
      description: null,
      buttonName: 'Submit Draft for Review',
      comment: 'submit_comment'
    },
    {
      id: 7,
      name: 'Draft - Pending Review',
      description: null,
      buttonName: 'Submit Draft for Review',
      comment: ''
    },
    {
      id: 8,
      name: 'Open',
      description: null,
      buttonName: 'Submit Draft for Review',
      comment: ''
    },
    {
      id: 9,
      name: 'Open - Pending Closure Approval',
      description: null,
      buttonName: 'Submit Event for Closure',
      comment: ''
    },
    {
      id: 10,
      name: 'Closed',
      description: null,
      buttonName: 'Close Event',
      comment: 'close_comment'
    },
  ];
  allStatusList = [
    {
      id: 6,
      name: 'Draft',
      description: null,
      buttonName: 'Submit Draft for Review',
      comment: 'submit_comment'
    },
    {
      id: 7,
      name: 'Draft - Pending Review',
      description: null,
      buttonName: 'Submit Draft for Review',
      comment: ''
    },
    {id: 8, name: 'Open', description: null, buttonName: 'Submit Draft for Review', comment: ''},
    {
      id: 9,
      name: 'Open - Pending Closure Approval',
      description: null,
      buttonName: 'Submit Event for Closure',
      comment: ''
    },
    {
      id: 10,
      name: 'Closed',
      description: null,
      buttonName: 'Close Event',
      comment: 'close_comment'
    },
    {
      id: 11,
      name: 'Cancelled',
      description: null,
      buttonName: 'Submit Draft for Review',
      comment: 'cancel_comment'
    }
  ];
  requeredList = [];
  optionalList = [];
  riskCaseAllField = [
    {
      canCheck: true,
      fieldName: 'business_impact',
      fieldType: 'Array',
      required: [8, 9, 10],
      title: 'Business Impact',
      url: '',
      index: 5,
      editable: [],
      popupName: 'businessImpact',
      chiled: []
    },
    {
      canCheck: true,
      fieldName: 'classification',
      fieldType: 'Object',
      objAttr: 'id',
      required: [8, 9, 10],
      title: 'Classification',
      url: '',
      index: 0,
      editable: [],
      popupName: '',
      chiled: []
    },
    {
      canCheck: true,
      fieldName: 'company_function',
      fieldType: 'Object',
      objAttr: 'id',
      required: [8, 9, 10],
      title: 'Function Code',
      url: '',
      index: 9,
      editable: [],
      popupName: 'Function',
      chiled: []
    },
    //  { canCheck: true, fieldName: 'compliance_coord', fieldType: 'Array', required: [  8, 9, 10], title: 'Compliance Coord', url: '',
    //  index: 0 , editable: [], popupName:'' , chiled: [] },
    {
      canCheck: true,
      fieldName: 'delegate',
      fieldType: 'Object',
      objAttr: 'user_id',
      required: [],
      title: 'Delegate',
      url: '',
      index: 0,
      editable: [],
      popupName: 'event',
      chiled: []
    },
    {
      canCheck: true, fieldName: 'description', fieldType: 'String', required: [6, 7, 8, 9, 10],
      title: 'Description', url: '', index: 2, editable: [], popupName: 'description', chiled: []
    },
    {
      canCheck: true,
      fieldName: 'discovery_date',
      fieldType: 'Date',
      required: [6, 7, 8, 9, 10],
      title: 'Discovery Date',
      url: '',
      index: 0,
      editable: [],
      popupName: 'event',
      chiled: []
    },
    //  { canCheck: true, fieldName: 'governance_representant', fieldType: 'Array', required: [  8, 9, 10], title: 'Governance Representant', url: '',
    //  index: 0 , editable: [], popupName:'' , chiled: [] },
    {
      canCheck: true,
      fieldName: 'local_reporting',
      fieldType: 'Array',
      required: [],
      title: 'Local Reporting',
      url: '/new-risk/legalentities',
      index: 6,
      editable: [],
      popupName: 'localReporting',
      chiled: []
    },
    {
      canCheck: true,
      fieldName: 'legal_entities',
      fieldType: 'Array',
      required: [6, 7, 8, 9, 10],
      title: 'Legal Entities',
      url: '/new-risk/legalentities',
      index: 6,
      editable: [],
      popupName: 'legalEntities',
      chiled: []
    },
    {
      canCheck: true,
      fieldName: 'location',
      fieldType: 'Object',
      objAttr: 'id',
      required: [8, 9, 10],
      title: 'Location',
      url: '',
      index: 9,
      editable: [],
      popupName: 'AdditionalInformation',
      chiled: []
    },
    {
      canCheck: true,
      fieldName: 'occurrence_date',
      fieldType: 'Date',
      required: [6, 7, 8, 9, 10],
      title: 'Occurrence Date',
      url: '',
      index: 2,
      editable: [],
      popupName: 'event',
      chiled: []
    },
    {
      canCheck: true,
      fieldName: 'owner',
      fieldType: 'Object',
      objAttr: 'user_id',
      required: [6, 7, 8, 9, 10],
      title: 'Owner',
      url: '',
      index: 0,
      editable: [],
      popupName: 'event',
      chiled: []
    },
    // { canCheck: true, fieldName: 'rcsa_owner', fieldType: 'Object', objAttr: 'user_id', required: [6, 7, 8, 9, 10], title: 'RCSA Owner', url: '',
    // index: 0 , editable: [], popupName: 'RCSA' , chiled: [] },
    {
      canCheck: true,
      fieldName: 'risk_assessment',
      fieldType: 'Array',
      objAttr: 'id',
      required: [6, 7, 8, 9, 10],
      title: 'RCSA',
      url: '',
      index: 0,
      editable: [],
      popupName: 'RCSA',
      chiled: []
    },
    {
      canCheck: true,
      fieldName: 'outsourcing',
      fieldType: 'N',
      required: [8, 9, 10],
      title: 'Outsourcing',
      url: '',
      index: 0,
      editable: [],
      popupName: 'outsourcing',
      chiled: []
    },
    {
      canCheck: true,
      fieldName: 'root_cause_level_1',
      fieldType: 'Object',
      objAttr: 'id',
      required: [9],
      title: 'Root Cause Level 1',
      url: '',
      index: 9,
      editable: [],
      popupName: 'RootCauses',
      chiled: []
    },
    {
      canCheck: true,
      fieldName: 'root_cause_level_2',
      fieldType: 'Object',
      objAttr: 'id',
      required: [9],
      title: 'Root Cause Level 2',
      url: '',
      index: 9,
      editable: [],
      popupName: 'RootCauses',
      chiled: []
    },
    {
      canCheck: true,
      fieldName: 'monetary_impacts',
      fieldType: 'Array',
      required: [8, 9, 10],
      title: 'Monetary Impact',
      url: '',
      index: 0,
      editable: [],
      popupName: 'monetaryImpact',
      chiled: []
    },
    {
      canCheck: true,
      fieldName: 'event_type_level_1',
      fieldType: 'Object',
      objAttr: 'id',
      required: [7, 8, 9, 10],
      title: 'Event Type Level 1',
      url: '',
      index: 9,
      editable: [],
      popupName: 'EventType',
      chiled: []
    },
    {
      canCheck: true,
      fieldName: 'event_type_level_2',
      fieldType: 'Object',
      objAttr: 'id',
      required: [7, 8, 9, 10],
      title: 'Event Type Level 2',
      url: '',
      index: 9,
      editable: [],
      popupName: 'EventType',
      chiled: []
    },
    {
      canCheck: true, fieldName: 'compliance_risk_category', fieldType: 'Array', required: [9, 10],
      title: 'Compliance Category', index: 6, editable: [], popupName: 'complianceRiskCategory'
    },
    {
      canCheck: true,
      fieldName: 'scenario_link',
      fieldType: 'Object',
      objAttr: 'id',
      required: [7, 8, 9, 10],
      title: 'Scenario Linkage',
      url: '',
      index: 9,
      editable: [],
      popupName: 'scenarioLink',
      chiled: []
    },
    {
      canCheck: true,
      fieldName: 'risk_manager',
      fieldType: 'Object',
      objAttr: 'user_id',
      required: [6, 7, 8, 9, 10],
      title: 'Risk Manager',
      url: '',
      index: 0,
      editable: [],
      popupName: 'Risk',
      chiled: []
    },
    //  { canCheck: true, fieldName: 'target_due_date', fieldType: 'String', required: [  8, 9, 10], title: 'Target Due Date', url: '',
    //  index: 0 , editable: [], popupName:'' , chiled: [] },
    {
      canCheck: true,
      fieldName: 'title',
      fieldType: 'String',
      required: [6, 7, 8, 9, 10],
      title: 'Title',
      url: '',
      index: 2,
      editable: [],
      popupName: '',
      chiled: []
    },
    {
      canCheck: true,
      fieldName: 'root_cause_description',
      fieldType: 'String',
      required: [8, 9, 10],
      title: 'Root Cause Description',
      url: '',
      index: 2,
      editable: [],
      popupName: 'rootCauseDescription',
      chiled: []
    },
    {
      canCheck: true,
      fieldName: 'lesson_learn_description',
      fieldType: 'String',
      required: [8, 9, 10],
      title: 'Lessons Learned and Actions Taken',
      url: '',
      index: 2,
      editable: [],
      popupName: 'lessonLearnDescription',
      chiled: []
    },
  ];

  constructor(private baseService: BaseService, public stepperService: StepperService,
              private modalService: BsModalService, private msalService: MsalService, private router: Router) {
    const NewDtoriskCaseData = RiskCaseMessage.toDto(this.stepperService.newRisk, true);
    this.riskCase = NewDtoriskCaseData.risk_case;

    if (NewDtoriskCaseData.risk_case.primary_parent_type_id) {
      if (NewDtoriskCaseData.risk_case.primary_parent_type_id.id === 442) {
        this.riskCase.risk_assessment = [{id: NewDtoriskCaseData.risk_case.primary_parent_id}];
      }
    }

    if (this.checkList(NewDtoriskCaseData.risk_assessments_primary_path)) {
      if (this.checkList(this.riskCase.risk_assessment)) {
        this.riskCase.risk_assessment = this.riskCase.risk_assessment.concat(NewDtoriskCaseData.risk_assessments_primary_path);
      } else {
        this.riskCase.risk_assessment = NewDtoriskCaseData.risk_assessments_primary_path;
      }
    }
    this.setUsers();
    const riskCaseId = this.stepperService.newRisk.riskCaseId;

    if (msalService.getLoggedUser()) {
      this.loggedUser = msalService.getLoggedUser().user_id;
      this.loggedUserData = msalService.getLoggedUser();
    }

    this.baseService.get('governance-coordinators/risk-case/' + riskCaseId).subscribe(res => {
      this.GovernanceCoordinator = res.body.data;
    });
    this.ActionMsg = ActionMsg;
  }

  setUsers() {
    if (this.riskCase.creator) {
      this.creator = this.riskCase.creator.user_id;
    }
    if (this.riskCase.owner) {
      this.ownerId = this.riskCase.owner.user_id;
    }
    if (this.riskCase.risk_manager) {
      this.manager = this.riskCase.risk_manager.user_id;
    }
    if (this.riskCase.delegate) {
      this.delegate = this.riskCase.delegate.user_id;
    }
  }

  checkList(list) {
    if (!list) {
      return false;
    } else {
      return list.length !== 0;
    }
  }

  ngOnChanges(): void {
    const NewDtoriskCaseData = RiskCaseMessage.toDto(this.stepperService.newRisk, true);
    this.riskCase = NewDtoriskCaseData.risk_case;

    if (NewDtoriskCaseData.risk_case.primary_parent_type_id) {
      if (NewDtoriskCaseData.risk_case.primary_parent_type_id.id === 442) {
        this.riskCase.risk_assessment = [{id: NewDtoriskCaseData.risk_case.primary_parent_id}];
      }
    }

    if (this.checkList(NewDtoriskCaseData.risk_assessments_primary_path)) {
      if (this.checkList(this.riskCase.risk_assessment)) {
        this.riskCase.risk_assessment = this.riskCase.risk_assessment.concat(NewDtoriskCaseData.risk_assessments_primary_path);

      } else {
        this.riskCase.risk_assessment = NewDtoriskCaseData.risk_assessments_primary_path;
      }
    }

    if (this.showMissing) {
      this.checkValidation(this.riskCaseAllField, this.riskCase, this.statusOrder);
    }

    if (this.statusOrder === 11) {
      if (!this.statusList.find(x => x.id === 11)) {
        this.statusList.push({
          id: 11,
          name: 'Cancelled',
          description: null,
          buttonName: 'Submit Draft for Review',
          comment: 'cancel_comment'
        });
      }
    }
    this.setUsers();
  }

  ngOnInit(): void {
    this.baseService.get('enum/items/23').subscribe(res => {
      this.rcscStatus = res.body.data;
    });
    this.baseService.get('status-change/' + this.stepperService.newRisk.riskCaseId).subscribe(res => {
      this.statusHistory = res.body.data;
    });
  }

  submitTo() {
    let submit = '';

    if (existCheck.userRole(this.statusOrder, [7, 9])) {
      submit = (this.statusOrder === 7 ? 'Approve/Reject' : 'Close/Reject');
      return submit;
    }

    if (existCheck.userRole(this.statusOrder, [6, 8])) {
      if (this.showButtonForUserRole(['CORM', 'ORM', 'ORMII'])) {
        submit = (this.statusOrder === 6 ? 'Approve/Cancel' : 'Close');
      } else {
        submit = (this.statusOrder === 6 ? 'Submit/Cancel' : 'Submit');
      }
      return submit;
    }

    return submit;
  }

  existCheckFn(item, list) {
    return existCheck.userRole(item, list);
  }

  checkValidation(validationList, object, status) {
    this.showMissing = true;
    validationList.forEach(element => {
      if (element.fieldName === 'compliance_risk_category') {
        if (!this.showButtonForUserRole(['CORM', 'Comp', 'ORM', 'ORMII'])) {
          element.popupName = 'cantEdit';
          element.canCheck = false;
        }
        if (this.showButtonForUserRole(['ORM', 'ORMII'])) {
          element.popupName = 'notComp';
        }
      }

      if (element.fieldName === 'scenario_link' || element.fieldName === 'event_type_level_1' || element.fieldName === 'event_type_level_2') {
        if (!this.showButtonForUserRole(['CORM', 'ORM', 'ORMII'])) {
          element.popupName = 'cantEdit';
          element.canCheck = false;
        } else {
          if (this.cashOut) {
            element.required = [7, 8, 9, 10];
          } else {
            element.required = [9, 10];
          }
        }
      }

      // element.fieldName === 'company_function' || element.fieldName === 'location'||
      if (element.fieldName === 'root_cause_level_1' || element.fieldName === 'root_cause_level_2') {
        if (this.cashOut) {
          element.required = [6, 7, 8, 9, 10];
        } else {
          element.required = [8, 9, 10];
        }
      }
    });

    if (this.showButtonForUserRole(['CORM', 'ORM', 'ORMII']) && (status === 8 || status === 6)) {
      status = status + 1;
    }
    this.riskCase = object;
    this.requeredList = [];
    this.optionalList = [];
    const list = [];
    validationList.forEach(element => {
      if (element.canCheck) {
        if (!object[element.fieldName]) {
          if (element.required.find(x => x === status)) {
            this.requeredList.push(element);
          } else {
            this.optionalList.push(element);
          }
        } else {
          const listItem = {...{value: object[element.fieldName]}, ...element};

          if ((listItem.fieldType === 'Array' || listItem.fieldType === 'String') && listItem.value.length === 0) {
            if (listItem.required.find(x => x === status)) {
              this.requeredList.push(listItem);
            } else {
              this.optionalList.push(listItem);
            }
          }

          if (listItem.fieldType === 'Object' && !listItem.value[listItem.objAttr]) {
            if (listItem.required.find(x => x === status)) {
              this.requeredList.push(listItem);
            } else {
              this.optionalList.push(listItem);
            }
          }

          if (listItem.fieldType === 'Date' && listItem.value.toString().length === 0) {

            if (listItem.required.find(x => x === status)) {
              this.requeredList.push(listItem);
            } else {
              this.optionalList.push(listItem);
            }
          }

          if (listItem.fieldType === 'N' && !listItem.value) {
            if (listItem.required.find(x => x === status)) {
              this.requeredList.push(listItem);
            } else {
              this.optionalList.push(listItem);
            }
          }
          // rcsa missing primary parent check
          if (listItem.fieldName === 'risk_assessment' && !object.primary_parent_id){
            this.requeredList.push(listItem);
          }
        }
      }
    });
  }

  arrayDiffLists(a, b, id) {
    const bIds = {};
    b.forEach(obj => {
      bIds[obj[id]] = obj;
    });

    // Return all elements in A, unless in B
    return a.filter(obj => {
      return !(obj[id] in bIds);
    });
  }

  goToStep(url) {
    this.stepperService.indexSource.next(url);
  }

  getActionId(id) {
    return this.rcscStatus.find(element => element.id === id);
  }

  correctDate(date) {
    const selectedDate = new Date(date);
    if (selectedDate.getTimezoneOffset() < 0) {
      selectedDate.setMinutes(selectedDate.getMinutes() - selectedDate.getTimezoneOffset());
    }
    return selectedDate;
  }

  changeStatus(statusId, comments, commentsAttr, user?, date?, actionId?) {
    this.changeStatusRequestLoading = true;
    if (statusId === 8) {
      if (this.statusOrder === 6 || this.statusOrder === 7) {
        this.newData.emit({prop: 'submission_date', propValue: new Date()});
      }
    }
    if (statusId === 10) {
      this.newData.emit({prop: 'completion_approver', propValue: this.loggedUserData});
    }
    const todayDate = new Date();
    this.showMissing = false;
    let statusObject;
    this.allStatusList.forEach(element => {
      if (element.id === statusId) {
        statusObject = element;
      }
    });
    const statusObjEmit = {
      status: statusObject,
      comment: comments,
      commentAttr: commentsAttr,
      dateName: null,
      dateValue: null,
      userName: null,
      userValue: null
    };
    if (user) {
      statusObjEmit.userValue = this.loggedUserData;
      statusObjEmit.userName = user;
    }
    if (date) {
      statusObjEmit.dateValue = todayDate;
      statusObjEmit.dateName = date;
    }
    this.changeStatusEvent.emit(statusObjEmit);
    this.comment = '';
    this.changeStatusBody.rcId = this.stepperService.newRisk.riskCaseId;
    this.changeStatusBody.rcsc_action_comment = comments;
    this.changeStatusBody.rcsc_action_date = RiskCaseMessage.correctDate(todayDate);
    this.changeStatusBody.rcsc_actor_id = this.loggedUserData.user_id;

    this.changeStatusBody.rcsc_status_id = this.statusOrder;
    this.changeStatusBody.rcsc_status_after_id = statusId;
    this.changeStatusBody.rcsc_action_id = actionId;
    this.modalRef.hide();
    this.baseService.post('status-change', this.changeStatusBody).subscribe(
      data => {
      },
      error => {
        console.log(error);
        this.changeStatusRequestLoading = false;
      },
      () => {
      });
  }

  loadAssociatedOpenIssues() {
    this.loadAssociatedOpenIssuesInProgress = true;
    const notClosedStatusesQueryParam = '?statusIds=6,7,8,9,23,24,25,27,28,29,37,38';
    const rcId = this.stepperService.newRisk.riskCaseId;
    this.baseService.get('risk-case/' + rcId + '/associated-risk-cases' + notClosedStatusesQueryParam).subscribe(
      data => {
        this.associatedOpenIssues = data.body.data;
      },
      error => {
        console.log(error);
      },
      () => {
        this.loadAssociatedOpenIssuesInProgress = false;
      });
  }

  getCommentAttr(status) {
    let attr;
    this.allStatusList.forEach(element => {
      if (element.id === status) {
        attr = element.comment;
      }
    });
    return attr;
  }

  showButtonForUser(validUsersList) {
    return !!validUsersList.find(x => x === this.loggedUser);
  }

  showButtonForUserRole(validUsersList) {
    return !!validUsersList.find(x => x === this.userRole);
  }


  openPopUp(popupName) {
    if (popupName !== '') {
      this.openPopUpEdit.emit(popupName);
    }
  }

  refreshMissingField(newData) {
    if (this.showMissing) {
      this.checkValidation(this.riskCaseAllField, newData, this.statusOrder);
    }
  }

  showDate(date) {
    let newDate;

    if (typeof date === 'object' && date.length > 0) {
      newDate = date.join('/');
      return new Date(newDate);
    } else if (typeof date === 'string' && date.length > 0) {
      newDate = date.substr(0, 10);
      newDate = newDate.replace(/-/g, '/');
      return new Date(newDate);
    } else {
      newDate = date;
      return new Date(newDate);
    }
  }

  openModal(template: TemplateRef<any>) {
    const configs = {
      keyboard: true,
      animated: true,
      class: ' modal-lg modal-primary RCSAModal',
      ignoreBackdropClick: false
    };
    this.modalRef = this.modalService.show(template, configs);
  }

  tooltip(toolTipontant, position) {
    return {
      allowHTML: true,
      delay: [1200, null],
      animation: 'shift-away',
      placement: position,
      theme: 'light',
      content: toolTipontant
    };
  }

  actionButtonText() {
    if (this.statusOrder === 6) {
      if (!this.showButtonForUserRole(['CORM', 'ORM', 'ORMII']) && (this.showButtonForUser([this.creator, this.ownerId, this.manager, this.delegate, this.ORM].concat(this.GovernanceCoordinator)) || this.showButtonForUserRole(['GC']))) {
        return 'Check and complete your entries, send this event to your op risk manager for approval, or cancel the event.';
      }
      if (this.showButtonForUserRole(['CORM', 'ORM', 'ORMII'])) {
        return 'Review, update, approve, or cancel this event.';
      }
    }

    if (this.statusOrder === 7) {
      if (!this.showButtonForUserRole(['CORM', 'ORM', 'ORMII']) && (this.showButtonForUser([this.creator, this.ownerId, this.manager, this.delegate, this.ORM].concat(this.GovernanceCoordinator)) || this.showButtonForUserRole(['GC']))) {
        return 'Update or cancel this Event';
      }
      if (this.showButtonForUserRole(['CORM', 'ORM', 'ORMII'])) {
        if (this.showActionForUsers && this.isDesktopDevice ) {
          this.checkValidation(this.riskCaseAllField, this.riskCase, this.statusOrder);
        }
        return 'Review, update, approve, or cancel this event.';
      }
    }

    if (this.statusOrder === 8) {
      if (!this.showButtonForUserRole(['CORM', 'ORM', 'ORMII']) && (this.showButtonForUser([this.creator, this.ownerId, this.manager, this.delegate, this.ORM].concat(this.GovernanceCoordinator)))) {
        return 'Check your entries, send this event to your op risk manager for closure approval';
      }
      if (this.showButtonForUserRole(['CORM', 'ORM', 'ORMII'])) {
        return 'Review, update and close this event';
      }
    }
    if (this.statusOrder === 9) {
      if (this.showActionForUsers && this.isDesktopDevice ) {
        this.checkValidation(this.riskCaseAllField, this.riskCase, this.statusOrder);
      }
      if (!this.showButtonForUserRole(['CORM', 'ORM', 'ORMII']) && (this.showButtonForUser([this.creator, this.ownerId, this.manager, this.delegate, this.ORM].concat(this.GovernanceCoordinator)) || this.showButtonForUserRole(['GC']))) {
        return '';
      }
      if (this.showButtonForUserRole(['CORM', 'ORM', 'ORMII'])) {
        return 'Review, update and approve closure of this event';
      }
    }
    return '';
  }

  showActionButton() {
    if (this.statusOrder === 9) {
      if (!this.showButtonForUserRole(['CORM', 'ORM', 'ORMII']) && (this.showButtonForUser([this.creator, this.ownerId, this.manager, this.delegate, this.ORM].concat(this.GovernanceCoordinator)) || this.showButtonForUserRole(['GC']))) {
        return false;
      }
      if (this.showButtonForUserRole(['CORM', 'ORM', 'ORMII'])) {
        return true;
      }
    }
    return true;
  }

  openCommentPopup(msg, action, comm, modal) {
    if ((this.statusOrder === 8 && (action === 'SubmitEventforClosureApproval' || 'closeEvent'))
      || (this.statusOrder === 9 && action === 'approveClose')
    ) {
      this.loadAssociatedOpenIssues();
    } else {
      this.resetAssociatedOpenIssues();
    }

    this.commentStatus = comm;
    this.popupMsg = msg;
    this.actionTaken = action;
    const configs = {
      keyboard: true,
      animated: true,
      class: ' modal-lg modal-primary confermationModal',
      ignoreBackdropClick: false
    };
    this.modalRef = this.modalService.show(modal, configs);
  }

  resetAssociatedOpenIssues() {
    this.loadAssociatedOpenIssuesInProgress = false;
    this.associatedOpenIssues = [];
  }

  showBlueArrow(status) {
    if (status === 6 || status === 7 || status === 8) {
      return true;
    }
    if (status === 9) {
      if (this.showButtonForUserRole(['CORM', 'ORM', 'ORMII'])) {
        return true;
      }
      if (this.showButtonForUserRole(['Comp']) || this.showButtonForUser([this.creator, this.ownerId, this.manager, this.delegate, this.ORM].concat(this.GovernanceCoordinator))) {
        if (this.showActionForUsers && this.isDesktopDevice ) {
          this.checkValidation(this.riskCaseAllField, this.riskCase, this.statusOrder);
        }
        return false;
      }
    }
    return false;
  }

  byteCount(textValue, maxlength) {
    if (!textValue) {
      return {valid: true, length: 0};
    }

    if (encodeURI(textValue).split(/%..|./).length - 1 > maxlength) {
      return {valid: false, length: encodeURI(textValue).split(/%..|./).length - 1};
    }

    return {valid: true, length: encodeURI(textValue).split(/%..|./).length - 1};
  }

}
