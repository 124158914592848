import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from 'src/environments/environment';

@Injectable()
export class BaseService {
  private baseUrl = environment.apiUrl;
  private graphUrl = environment.graphUrl;

  private httpOptions = {
    // withCredentials: true
  };

  constructor(
    public http: HttpClient,
    public router: Router,
    public sanitizer: DomSanitizer) {
  }

  public get(url: string) {
    return this.http.get<any>(this.baseUrl + url, {observe: 'response'});
  }

  public post(url: string, body: any): Observable<any> {
    return this.http.post(this.baseUrl + url, body, {observe: 'response'});
  }

  public postWithETag(url: string, body: any, eTag: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'If-Match': eTag
    });
    return this.http.post(this.baseUrl + url, body, {headers, observe: 'response'});
  }

  public postWithHeaders(url: string, body: any, headers: any): Observable<any> {
    return this.http.post(url, body, headers);
  }

  public getDirect(url) {
    return this.http.get(url, this.httpOptions);
  }

  public put(url: string, body: any, etag: string, index?: number): Observable<any> {
    let path = this.baseUrl + url;
    if (index) {
      path += '/' + index;
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'If-Match': etag
    })

    return this.http.put(path, body, {headers, observe: 'response'});
  }

  public patch(url: string, body: any) {
    return this.http.patch(this.baseUrl + url, body, {withCredentials: true});
  }

  public delete(url: string, etag: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'If-Match': etag
    })
    return this.http.delete(this.baseUrl + url, {headers, observe: 'response'});
  }

  getUsers(url) {
    return this.http.get<any>(this.baseUrl + url)
    .toPromise()
    .then(res => res.data)
    .then(data => {
      return data;
    });
  }

  public getGraph(url: string) {
    return this.http.get<any>(this.graphUrl + url, this.httpOptions);
  }

  public getGraphBlob(url: string): Observable<any> {
    let httpHeaders = new HttpHeaders()
    .set('content-type', "image/Jpeg");
    //observe: 'response'
    return this.http.get(this.graphUrl + url, {headers: httpHeaders, responseType: 'blob'}).pipe(
      map(e => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e))));
  }


  public getBlob(url: string, fileName: string): Observable<any> {
    let httpHeaders = new HttpHeaders()
    .set('content-type', "application/octet-stream");

    const fileExt = fileName.substring(fileName.lastIndexOf(".") + 1);
    let fileType = 'text/html';
    switch (fileExt) {
      case 'bmp':
      case 'gif':
      case 'jpeg':
      case 'jpg':
      case 'png':
        fileType = 'image/jpeg';
        break;
      case 'tiff':
        fileType = 'image/tiff';
        break;
      case 'tif':
        fileType = 'image/tif';
        break;
      case 'doc':
        fileType = 'doc	application/msword';
        break;
      case 'docx':
        fileType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        break;
      case 'rtf':
      case 'rtx':
        fileType = 'application/rtf';
        break;
      case 'ppt':
        fileType = 'application/vnd.ms-powerpoint';
        break;
      case 'pptx':
        fileType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
        break;
      case 'vsd':
        fileType = 'application/vsd';
        break;
      case 'pdf':
        fileType = 'application/pdf';
        break;
      case 'mpp':
        fileType = 'application/mpp';
        break;
      case 'xls':
      case 'xlw':
        fileType = 'application/vnd.ms-excel';
        break;
      case 'xlsx':
        fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        break;
      case 'zip':
        fileType = 'application/zip';
        break;
      case 'msg':
        fileType = 'application/vnd.ms-outlook';
        httpHeaders = new HttpHeaders()
        .set('content-type', "application/vnd.ms-outlook")
        break;
      default:
        fileType = 'text/plain'
    }

    return this.http.get(this.baseUrl + url, {headers: httpHeaders, responseType: 'blob'}).pipe(
      map(data => {
        //managing .msg exception
        if (fileType == "application/vnd.ms-outlook") {
          let link = document.createElement('a');
          link.href = URL.createObjectURL(data);
          link.download = fileName;
          link.click();
        } else {
          let file = new Blob([data], {type: fileType});
          return URL.createObjectURL(file);
        }
      }));
  }

  public postGraph(url: string, body: any): Observable<any> {
    return this.http.post(this.graphUrl + url, body, this.httpOptions);
  }

  public loadUserSettings(currentTab: string) {
    return this.get("user-setting/" + currentTab);
  }

  public saveUserSettings(column: string, cols: any[]) {
    this.post("user-setting/" + column, cols).subscribe(res => {
      console.log("preferences saved!")
    }, error => {
      console.log(error);
    });
  }

}
