import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {MsalService} from './msal.service';
import {Inject, Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {AccountInfo, InteractionType} from '@azure/msal-browser';
import {MsalGuardConfiguration} from './msal.guard.config';
import {catchError, concatMap, map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {MSAL_GUARD_CONFIG} from './constants';
import {
  RETURN_BACK_TO_RCSA_PARAM,
  RISK_CASE_ID_MAX_LENGTH,
  RISK_CASE_ID_PARAM_MARK
} from '@shared/common/constants';
import {environment} from "../../../environments/environment";

@Injectable()
export class MsalGuard implements CanActivate {
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private location: Location
  ) {
  }

  /**
   * Builds the absolute url for the destination page
   * @param path Relative path of requested page
   * @returns Full destination url
   */
  getDestinationUrl(path: string): string {
    // Absolute base url for the application (default to origin if base element not present)
    const baseElements = document.getElementsByTagName('base');
    const baseUrl = this.location.normalize(baseElements.length ? baseElements[0].href : window.location.origin);

    // Path of page (including hash, if using hash routing)
    const pathUrl = this.location.prepareExternalUrl(path);

    // Hash location strategy
    if (pathUrl.startsWith('#')) {
      return `${baseUrl}/${pathUrl}`;
    }

    // If using path location strategy, pathUrl will include the relative portion of the base path (e.g. /base/page).
    // Since baseUrl also includes /base, can just concatentate baseUrl + path
    return `${baseUrl}${path}`;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {

    return this.authService.handleRedirectObservable()
    .pipe(
      concatMap(() => {
        const accountInfos: AccountInfo[] = this.authService.getAllAccounts();
        if (!accountInfos.length) {
          return this.manageRedirect(state);
        } else if (this.authService.riskCaseId > 0 && this.authService.riskCaseType === undefined) {
          console.log('MSAL SETUP CACHE URL', state.url + RISK_CASE_ID_PARAM_MARK + this.authService.riskCaseId);
          localStorage.setItem('routerUrl', state.url + RISK_CASE_ID_PARAM_MARK + this.authService.riskCaseId);
        }
        return of(true);
      }),
      catchError(error => {
        console.log(error);
        return of(false);
      })
    );
  }

  private manageRedirect(state: RouterStateSnapshot) {
    if (this.authService.riskCaseId) {
      if (this.authService.riskCaseType !== undefined) {
        return this.loginInteractively('/#/risk-case/' + this.authService.riskCaseType + '/' + this.authService.riskCaseId);
      } else {
        return this.loginInteractively(state.url + RISK_CASE_ID_PARAM_MARK + this.authService.riskCaseId);
      }
    } else if (window.location.href.indexOf('attachments') > -1) {
      return this.loginInteractively(window.location.hash.substring(1));
    } else {
      console.log("STATE", state);
      return this.loginInteractively(state.url);
    }
  }

  private loginInteractively(url: string): Observable<boolean> {
    console.log("loginInteractively INPUT", url);
    let urlCleaned = url;
    if (url && url.length > 1) {
      urlCleaned = url.indexOf("attachments") > -1 || url.indexOf("?id=") > -1
        ? url : url.indexOf("?") > -1
          ? url.substring(0, url.indexOf("?"))
          : url.indexOf("%3F") > -1
            ? url.substring(0, url.indexOf("%3F"))
            : url;
      console.log("SAVING URL TO LOCAL STORAGE", urlCleaned);
      localStorage.setItem('routerUrl', urlCleaned);
    }
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      return this.authService.loginPopup({...this.msalGuardConfig.authRequest})
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
    }
    const state = url.indexOf(RISK_CASE_ID_PARAM_MARK) !== -1
      ? url.substr(url.indexOf(RISK_CASE_ID_PARAM_MARK) + RISK_CASE_ID_MAX_LENGTH)
      : this.location.prepareExternalUrl(urlCleaned);

    const redirectStartPage = this.getDestinationUrl(urlCleaned);
    this.authService.loginRedirect({
      redirectStartPage,
      scopes: [],
      state,
      ...this.msalGuardConfig.authRequest,
    });
    return of(false);
  }

}
