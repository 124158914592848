<div *ngIf="showSupport" class="container  rightSideBar">
    <mat-card>
        <mat-card-header class="w-100 d-flex justify-content-between align-items-center">
            <strong>Do you need support?</strong>
            <span (click)="closeBar()" class="material-icons closeIcon">
        close
        </span>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content class="cardContant">
            <div *ngIf="!areSupportContactAvailable()">
                <div class="my-5 d-flex justify-content-center">
                    <div class=""><strong>No support contacts available</strong></div>
                </div>
            </div>
            <div *ngIf="governanceCoordinators && governanceCoordinators.length > 0">
                <div class="my-2">
                    <div class="titleSupport"><strong>Your Governance Contact</strong></div>
                </div>

                <div *ngFor='let item of governanceCoordinators; let i = index'>
                    <div class="showMoreLess pl-3" *ngIf="numberOfItems < governanceCoordinators.length && i==3 " (click)='numberOfItems = governanceCoordinators.length'>
                        show more...
                    </div>
                    <div *ngIf="i < numberOfItems" class="row my-1">
                        <div style="height: 74px;" class="col-md-3">
                            <img class="picture" *ngIf="item && item.picture" [src]="item.picture" alt="">
                            <mat-icon [ngStyle]="getColor(item.presence)" matTooltip="{{item.presence}}" class="">{{ getPresenceIcon(item.presence) }}</mat-icon>
                        </div>
                        <div class="col-md-9">
                            <div><label><strong>{{item.displayName}}</strong></label></div>
                            <div><label>{{item.jobTitle}}</label></div>
                            <div class="d-flex justify-content-between w-50">
                                <mat-icon matTooltip="Send an email" (click)="sendEmail(item.mail, item.givenName)">email</mat-icon>
                                <mat-icon matTooltip="Send a message via Microsoft Teams" (click)="sendChatMessage(item.mail)" [matTooltipPosition]="'above'">question_answer</mat-icon>
                                <mat-icon matTooltip="Schedule meeting via Microsoft Teams" (click)="scheduleMeeting(item.mail)" [matTooltipPosition]="'above'">book_online</mat-icon>
                            </div>
                        </div>
                    </div>
                    <div class="showMoreLess pl-3" *ngIf=" governanceCoordinators.length>3 && i==governanceCoordinators.length-1  && numberOfItems > 3" (click)='numberOfItems = 3'>
                        show less...
                    </div>
                </div>
            </div>

            <div *ngIf="riskManagers && riskManagers.length">
                <div class="my-2">
                    <div class="titleSupport"><strong>Your ORM Contact</strong></div>
                </div>
                <div *ngFor='let item of riskManagers; let i = index'>
                    <div class="showMoreLess pl-3" *ngIf="numberOfItemsL < riskManagers.length && i==3 " (click)='numberOfItemsL = riskManagers.length'>
                        show more...
                    </div>

                    <div *ngIf="i < numberOfItemsL" class="row my-1">
                        <div style="height: 74px;" class="col-md-3">
                            <img class="picture" [src]="item.picture" alt="">
                            <mat-icon [ngStyle]="getColor(item.presence)" matTooltip="{{item.presence}}" class="">{{ getPresenceIcon(item.presence) }}</mat-icon>
                        </div>
                        <div class="col-md-9">
                            <div><label><strong>{{item.displayName}}</strong></label></div>
                            <div><label>{{item.jobTitle}}</label></div>
                            <div class="d-flex justify-content-between w-50">
                                <mat-icon matTooltip="Send an email" (click)="sendEmail(item.mail, item.displayName)">email</mat-icon>
                                <mat-icon matTooltip="Send a message via Microsoft Teams" (click)="sendChatMessage(item.mail)" [matTooltipPosition]="'above'">question_answer</mat-icon>
                                <mat-icon matTooltip="Schedule meeting via Microsoft Teams" (click)="scheduleMeeting(item.mail)" [matTooltipPosition]="'above'">book_online</mat-icon>
                            </div>
                        </div>
                    </div>
                    <div class="showMoreLess pl-3" *ngIf=" riskManagers.length>3 && i==riskManagers.length-1  && numberOfItemsL > 3" (click)='numberOfItemsL = 3'>
                        show less...
                    </div>
                </div>
            </div>

            <!-- your compliance contact-->
            <div *ngIf="compliances && compliances.length">
                <div class="my-2">
                    <div class="titleSupport"><strong>Your Compliance Contact</strong></div>
                </div>
                <div *ngFor='let item of compliances; let i = index'>
                    <div *ngIf="i < numberOfItemsL" class="row my-1">
                        <div style="height: 74px;" class="col-md-3">
                            <img class="picture" [src]="item.picture" alt="">
                            <mat-icon [ngStyle]="getColor(item.presence)" matTooltip="{{item.presence}}" class="">{{ getPresenceIcon(item.presence) }}</mat-icon>
                        </div>
                        <div class="col-md-9">
                            <div><label><strong>{{item.displayName}}</strong></label></div>
                            <div><label>{{item.jobTitle}}</label></div>
                            <div class="d-flex justify-content-between w-50">
                                <mat-icon matTooltip="Send an email" (click)="sendEmail(item.mail, item.displayName)">email</mat-icon>
                                <mat-icon matTooltip="Send a message via Microsoft Teams" (click)="sendChatMessage(item.mail)" [matTooltipPosition]="'above'">question_answer</mat-icon>
                                <mat-icon matTooltip="Schedule meeting via Microsoft Teams" (click)="scheduleMeeting(item.mail)" [matTooltipPosition]="'above'">book_online</mat-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </mat-card-content>
        <div class="row justify-content-evenly px-3">
            <button *ngIf="areSupportContactAvailable()" class='primaryBtn buttonTeams d-flex align-items-center' pButton type="button" label="Start Team Chat " (click)="startTeamChat()">
        <mat-icon class="ml-1">question_answer</mat-icon>
      </button>
            <button *ngIf="areSupportContactAvailable()" class='primaryBtn buttonTeams d-flex align-items-center' pButton type="button" label="Schedule Team Meeting " (click)="scheduleTeamMeeting()">
        <mat-icon class="ml-1">book_online</mat-icon>
      </button>
        </div>
    </mat-card>
</div>