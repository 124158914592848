import { Component, TemplateRef, OnInit, ViewChild, Input, OnChanges } from '@angular/core';
import { FilterUtils } from 'primeng/utils';
import { UserValidationRoles as UserValidation } from 'src/app/shared/models/validation/userValidations';
import { Table } from 'primeng/table/table';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { BaseService } from 'src/app/shared/services/base.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { MsalService, StepperService } from 'src/app/shared/services';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { RiskCaseConstants, UserRoleConstants } from 'src/app/modules/new-risk/common-constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import {RETURN_BACK_TO_RCSA_PARAM} from '@shared/common/constants';
@Component({
  selector: 'app-rcsa-list',
  templateUrl: './rcsa-list.component.html',
  styleUrls: ['./rcsa-list.component.scss']
})
export class RcsaListComponent implements OnInit, OnChanges {
  @Input() all: boolean;
  allCasesList = [];
  toDoList = [];
  modalRef: BsModalRef;
  allUserRole = UserRoleConstants;
  caseType = 'Event';
  cols: any[];
  rcsaUrl = environment.rcsaUrl + RETURN_BACK_TO_RCSA_PARAM;
  defaultColumns = [
    { field: 'name', header: 'ID', filter: '', mode: '', isVisible: true, index: 0 },
    { field: 'description', header: 'Title', filter: '', mode: '', isVisible: true, index: 1 },
    { field: 'sign_off_date', header: 'Last Sign-off Date', filter: '', mode: '', isVisible: true, index: 2 },
    { field: 'rcsa_owner', header: 'Owner', filter: '', mode: '', isVisible: true, index: 3 },
    { field: 'risk_manager', header: 'Risk Manager', filter: '', mode: '', isVisible: true, index: 4},
    { field: 'type', header: 'Type', filter: '', mode: '', isVisible: true, index: 5},
    { field: 'status', header: 'Status', filter: '', mode: '', isVisible: true, index: 6 },
    { field: 'folder', header: 'Folder', filter: '', mode: '', isVisible: false, index: 7 },
    { field: 'global_function', header: 'BU/GF', filter: '', mode: '', isVisible: false, index: 8 },

  ];
  filterHistory = {};

  classNames: any[];
  statusList: any[];
  issueStatusList: any[];
  typeFilter = ['RCSA'];
  url = 'rcsa'
  RiskCaseConstants = RiskCaseConstants;
  backupCols: any[];
  riskCaseId;
  risks = [];
  nextDeadlineFilter: number;
  nextDeadlineTimeout;
  hasFilters = false;
  isConfigView = false;
  selectedColumns = [];
  selectedColumnsBackup = [];
  updating = false;
  timeout: any;
  comment = '';
  loggedUser: string;
  loggedUserRole;
  @ViewChild('rcsaTable') table: Table;
  constructor(private baseService: BaseService,
              public stepperService: StepperService,
              private modalService: BsModalService,
              private router: Router, private spinner: NgxSpinnerService,
              private confirmationService: ConfirmationService,
              private msalService: MsalService) {
    if (localStorage.getItem('RCSAFilter')){
      this.filterHistory = JSON.parse(localStorage.getItem('RCSAFilter'));
      }else {
        this.filterHistory = {type:  {valueT: ['RCSA'], fieldT: 'type.name', typeT: 'in', colN: 'type', subC: 'name'}      }
      }
    // this.filterHistory['type'] =  {fieldT:  'type', typeT: 'in',valueT: ['RCSA'],},
    this.statusList = [{ label: 'Pre-Draft', value: 'Pre-Draft' }, { label: 'Draft', value: 'Draft' }, { label: 'Draft - Pending Review', value: 'Draft - Pending Review' },
    { label: 'Open', value: 'Open' }, { label: 'Open - Due Date Change Pending', value: 'Open - Due Date Change Pending' }, { label: 'Open - Due Date Extended', value: 'Open - Due Date Extended' }, { label: 'Open - Pending Completion Approval', value: 'Open - Pending Completion Approval' },
    { label: 'Open - Pending Closure Approval', value: 'Open - Pending Closure Approval' }, { label: 'Completed', value: 'Completed' }, { label: 'Closed', value: 'Closed' }, { label: 'Cancelled', value: 'Cancelled' },
    ];
    this.spinner.show('rcsaList');
    if (this.stepperService.enumItemsStoredData && this.stepperService.enumItemsStoredData.rcsaListList) {
      this.allCasesList = this.stepperService.enumItemsStoredData.rcsaListList;
      this.filterCase(this.caseType);
      this.spinner.hide('rcsaList');
    }
    this.baseService.get(this.url).subscribe(res => {
      this.allCasesList = [];
      console.log(res.body.data);
      this.allCasesList = res.body.data;
      this.stepperService.enumItemsStoredData.rcsaListList = this.allCasesList;
      this.risks = this.allCasesList;
      this.filterCase(this.caseType);
      this.spinner.hide('rcsaList');
    });
  }
  ngOnChanges() {
    this.loadUserSetting();
  }
  loadUserSetting() {
    if (this.stepperService.enumItemsStoredData && this.stepperService.enumItemsStoredData.rcsa_list) {
      this.cols = this.stepperService.enumItemsStoredData.rcsa_list ? this.stepperService.enumItemsStoredData.rcsa_list : this.defaultColumns;
      this.selectedColumns = this.cols.filter(col => col.isVisible);
    } else {
      this.baseService.loadUserSettings("all_rcsas").subscribe(res => {
        this.cols = res.body && res.body.length > 0 ? res.body : this.defaultColumns;
        this.stepperService.enumItemsStoredData.rcsa_list = this.cols;
        this.selectedColumns = this.cols.filter(col => col.isVisible);
      });
    }
  }
  ngOnInit(): void {
    FilterUtils['dateInBetween'] = (value, dateFilters): boolean => {
      if (moment(value, environment.dateFormat).isBetween(dateFilters[0], dateFilters[1])) {
        return true;
      }
      return false;
    };
    FilterUtils['numbersInBetween'] = (value, dateFilters): boolean => {
      if (value >= dateFilters[0] && value <= dateFilters[1]) {
        return true;
      }
      return false;
    };
    if(this.stepperService.enumItemsStoredData && this.stepperService.enumItemsStoredData.enum_7){
      this.classNames = this.stepperService.enumItemsStoredData.enum_7;
    }else{
    this.baseService.get('enum/items/7').subscribe(res => {
      this.classNames = [];
      res.body.data.forEach(element => {
        this.classNames.push({ label: element.name, value: element.name });


      });
      this.stepperService.enumItemsStoredData.enum_7 = this.classNames;
    });
  }
    if (this.msalService.getLoggedUser()) {
      this.loggedUser = this.msalService.getLoggedUser().user_id;
      this.loadUserSetting();
    }
    FilterUtils['custom'] = (value, filter): boolean => {
      if (filter === undefined || filter === null || filter.trim() === '') {
        return true;
      }
      if (value === undefined || value === null) {
        return false;
      }
      // tslint:disable-next-line:radix
      return parseInt(filter) > value;
    };
  }
  // reloads the record every time the tab is selected
  refresh() {
    this.baseService.get(this.url).subscribe(res => {
      this.allCasesList = [];
      this.allCasesList = res.body.data;
      this.filterCase(this.caseType);
    });
  }
  filterCase(caseType) {
    this.risks = this.allCasesList;
    this.caseType = caseType;
    if(this.filterHistory){
      if(this.table){
        Object.keys(this.filterHistory).forEach((key: any) => {
          if(key.subC){
            this.filterTable(this.filterHistory[key].valueT, this.filterHistory[key].fieldT, this.filterHistory[key].typeT,this.filterHistory[key].colN,this.filterHistory[key].subC);

          }else{
            this.filterTable(this.filterHistory[key].valueT, this.filterHistory[key].fieldT, this.filterHistory[key].typeT);

          }
        });
      }else{
        setTimeout(() => {
          this.filterCase(caseType);
        }, 1000);
      }
    }
  }
  getDate(date) {
    date = new Date(date * 1000);
    return date;
  }
  onDeadlineChange(event, field, filterMode) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.table.filter(event.value, field, filterMode);
    }, 250);
    if (event.value !== 31) {
      this.hasFilters = true;
    }
  }
  clearFilters() {
    this.cols = this.cols.map(col => {
      col.filter = '';
      if (col.field === 'nextDeadline') {
        col.filter = 31;
      }
      return col;
    });
    this.hasFilters = false;
    this.filterHistory = {};
    localStorage.removeItem('RCSAFilter');

    this.table.reset();
  }
  filterTable(value: any, field: string, type: string,colName?, subCol?) {
    this.hasFilters = true;
    console.log( {valueT: value, fieldT: field, typeT: type});
    if(subCol){
      this.filterHistory[colName] =  {valueT: value, fieldT: field, typeT: type,colN:colName, subC:subCol} ;

    }else{
      this.filterHistory[field] =  {valueT: value, fieldT: field, typeT: type,colN:null, subC:null} ;

    }
    localStorage.setItem('RCSAFilter', JSON.stringify(this.filterHistory));
  }
  configureTable() {
    this.isConfigView = !this.isConfigView;
    this.backupCols = JSON.parse(JSON.stringify(this.cols));
    this.selectedColumnsBackup = this.selectedColumns;
  }
  getVisibleCols = (cols: any[]) => cols.filter(col => this.selectedColumns.indexOf(col.field) !== -1);
  updateSelectedColumns() {
    this.cols.map(col => {
      col.isVisible = this.selectedColumns.indexOf(col.field) !== -1;
      return col;
    });
  }
  drop(event: any) {
    this.updating = true;
    const colNames = this.cols.map(col => col.field);
    moveItemInArray(colNames, event.previousIndex, event.currentIndex);
    this.cols.map(col => {
      col.index = colNames.indexOf(col.field);
      return col;
    });
    this.cols.sort(this.sortCols);
    this.updating = false;
  }
  private sortCols(a, b) {
    return a.index - b.index;
  }
  cancelConfiguration() {
    this.cols = this.backupCols;
    this.selectedColumns = this.selectedColumnsBackup;
    this.isConfigView = !this.isConfigView;
  }
  saveConfiguration() {
    this.baseService.saveUserSettings("all_rcsas", this.cols);
    this.configureTable();
  }
  onRowSelect(event: any): void {

    const url = this.rcsaUrl + 'rcsa/detail/' + event.data.id;
    window.open(url, '_self');

  }

  setDeleteStyle(fieldName) {
    let styles = {};
    if (fieldName === 'name'  ||fieldName === 'type' || fieldName === 'status') {
      styles = {
        "width": "140px",
      };
      return styles;
    }
    if (fieldName === 'sign_off_date' ||fieldName === 'folder'  ) {
      styles = {
        "width": "180px",
      };
      return styles;
    }
    if (fieldName === 'description') {
      styles = {
        "width": "30%",
      };
      return styles;
    }

  }
  openModal(template: TemplateRef<any>) {
    const configs = {
      keyboard: false,
      ignoreBackdropClick: true,
      animated: true,
      class: 'modal-lg modal-primary RCSAModal',
    };
    this.modalRef = this.modalService.show(template, configs);
  }

  closeModel() {
    this.comment = '';
    this.modalRef.hide();
  }
  userRoles(user, list) {
    const isUser = UserValidation.userRole(user, list);
    return isUser;
  }
  checkAllLists(list) {
    for (const iterator of list) {
      if (this.userRoles(iterator.user, iterator.list)) {
        return true;
      }
    }
    return false;
  }
  getUserRole() {
    this.baseService.get('authorization/roles').subscribe(res => {
      this.loggedUserRole = UserValidation.defineUserRole(res.body.data);
    });
  }
  byteCount(textValue, maxlength) {
    if (!textValue) {
      return { valid: true, length: 0 };
    }
    if (encodeURI(textValue).split(/%..|./).length - 1 > maxlength) {
      return { valid: false, length: encodeURI(textValue).split(/%..|./).length - 1 };
    }
    return { valid: true, length: encodeURI(textValue).split(/%..|./).length - 1 };
  }

  getColumnValueSearch(value,attr){
    if(value && value[attr]){
      return  value.valueT;

    }
    return '';
  }

  getSavedFilterValue(colName){
    if(!this.filterHistory[colName]){ return '';}
    return this.filterHistory[colName].valueT;
  }
}
