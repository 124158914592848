



<div *ngIf="riskCase.event;else other_content">
    <app-step5-event></app-step5-event>
</div>

<ng-template #other_content>
    <app-step5-issue></app-step5-issue>

</ng-template>