<div class="containerDiv" *ngIf="!viewOnly">
    <div class="w-100 position-relative">




        <p>
            Do you want to record any of the following monetary impacts?

        </p>
        <div class="mb-3 w-100 inputField">
            <mat-radio-group aria-label="Select an option" class="d-flex justify-content-start mt-3" [(ngModel)]='monatryImpactItemsValue'>
                <mat-radio-button [checked]="haveMI(selectedList) == 'Yes'" (change)='this.selectedList = []' class="mr-4" [value]="'Yes'">Yes</mat-radio-button>
                <mat-radio-button [checked]="haveMI(selectedList) == 'No'" (change)='remveItemsWarning(selectedList,template,false)' [value]="'No'">No</mat-radio-button>
            </mat-radio-group>

        </div>




        <div class="d-flex justify-content-start my-4">
            <ng-container *ngFor='let miItem of monatryImpactItems'>
                <div *ngIf="miItem.id != 92 && miItem.id !=94 && miItem.id !=212" class="mr-2 t-demo__btn" ngxTippy [tippyProps]="btnTooltip(miItem.id)">
                    <button class='grayButton    buttonWidth align-self-end' pButton type="button" [label]="miItem.name" [disabled]='chechEnableRadioBtn(miItem.id,selectedList) || haveMI(selectedList) =="No"' (click)="handelClick(selectedList,miItem)"></button>
                </div>
            </ng-container>

        </div>


        <div class="row justify-content-end mb-5 w-100 mx-0" *ngIf='viewButton'>
            <button class='primaryBtn mr-4 buttonWidth' pButton type="button" label="Skip" (click)="remveItemsWarning(selectedList,skipTemp,true)"></button>


            <div class='d-flex'>

                <button class='primaryBtn  buttonWidth' pButton type="button" label="Proceed" [disabled]="!checkValiationOffListItems(selectedList,['amount'])" (click)="saveMI()"></button>
                <app-information-dialog [accordionIndex]='9'></app-information-dialog>


            </div>



        </div>

        <ng-container *ngIf='selectedList.length!=0'>
            <div class="mb-5">
                <mat-accordion class="fix-radio-position " hideToggle="true">
                    <ng-container *ngFor='let item of selectedList let i = index'>
                        <mat-expansion-panel *ngIf='item.monetary_impact_type.id !=212'>
                            <mat-expansion-panel-header>
                                <mat-panel-description class='d-flex' style="align-items: center;">
                                    <div class="d-flex justify-content-between w-100">
                                        <div class=" w-25">
                                            {{i+1}}. {{item.monetary_impact_type.name}}

                                        </div>
                                        <div *ngIf='item.monetary_impact_type.id == 91' class="d-flex justify-content-between w-75">
                                            <div>Gross Loss: ${{ sumCashOut(item).totalAmount | number: '1.0-0'}} </div>
                                            <div>Recovery: ${{ sumCashOut(item).totalCaptureRecovery | number: '1.0-0'}} </div>
                                            <div>Net Loss: ${{ sumCashOut(item).total | number: '1.0-0'}} </div>

                                        </div>
                                        <div *ngIf='item.monetary_impact_type.id != 91' class="d-flex justify-content-end w-75">
                                            <div>Amount: ${{ item.amount | number: '1.0-0'}} </div>


                                        </div>
                                        <div class="ml-5">
                                            <span (click)="deletItem(i)" class="removeIcomn material-icons">
                                                delete
                                            </span>

                                        </div>
                                    </div>


                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div class="row">




                                <ng-container *ngIf='item.monetary_impact_type.id == 91'>
                                    <div class="col-md-4">
                                        <label>
                                            <span class=' t-demo__btn' *ngIf='item.monetary_impact_type.id == 91' ngxTippy  [tippyProps]="tooltip(FieldHelpData.MonetaryImpact.GrossLossAmount)" > Gross Loss Amount (USD)
                                            </span>
                                            <span *ngIf='item.monetary_impact_type.id != 91'>Amount (USD)</span>


                                        </label>
                                        <div class="mb-3 w-50 inputField">
                                            <p-inputNumber placeholder="Amount" [(ngModel)]="item.amount"></p-inputNumber>

                                        </div>

                                    </div>
                                    <div class="col-md-4">
                                        <label>
                                            <span class=' t-demo__btn' *ngIf='item.monetary_impact_type.id == 91' ngxTippy  [tippyProps]="tooltip(FieldHelpData.MonetaryImpact.DateOfAcknowledgment)" >
                                                Date of Acknowledgement (CET)
                                           </span>
                                           <span *ngIf='item.monetary_impact_type.id != 91'> Date of Acknowledgement (CET)</span>


                                        </label>
                                        <div class=" input-container position-relative">
                                            <div (click)='discoveryDate.open()' class=" inputIcon material-icons">
                                                date_range
                                            </div>

                                            <input class="input-field" (keypress)="cantEdit($event)" placeholder="DD-MMM-YYYY" [disabled]='true' class="notDisabledInput" [ngModel]="item.bus_date | date: dateFormat" name="date">

                                            <input [max]="today" [matDatepicker]='discoveryDate' class="input-field matrialInputH" placeholder="dd-mm-yyyy" [(ngModel)]="item.bus_date" name="date">
                                            <mat-datepicker #discoveryDate></mat-datepicker>
                                        </div>



                                    </div>

                                    <div class="col-md-4">
                                        <label ngxTippy [tippyProps]="tooltip(FieldHelpData.MonetaryImpact.Regulatory)" class="t-demo__btn">
                                             Regulatory Fine / Penalty


                                        </label>
                                        <div class="mb-3 w-100 inputField">
                                            <mat-radio-group aria-label="Select an option" class="d-flex   justify-content-start mt-3" [(ngModel)]="item.fine_penalty">
                                                <mat-radio-button class="mr-4" [value]="'Y'">Yes</mat-radio-button>
                                                <mat-radio-button [value]="'N'">No</mat-radio-button>
                                            </mat-radio-group>

                                        </div>



                                    </div>
                                </ng-container>
                                <ng-container *ngIf='item.monetary_impact_type.id != 91'>
                                    <div class="col-md-6">
                                        <label>
                                            <span class=' t-demo__btn' *ngIf='item.monetary_impact_type.id == 91' ngxTippy  [tippyProps]="tooltip(FieldHelpData.MonetaryImpact.GrossLossAmount)" >
                                                 Gross Loss Amount (USD)
                                            </span>
                                            <span *ngIf='item.monetary_impact_type.id != 91'>Amount (USD)</span>


                                        </label>
                                        <div class="mb-3 w-50 inputField">
                                            <p-inputNumber placeholder="Amount " [(ngModel)]="item.amount"></p-inputNumber>

                                        </div>

                                    </div>
                                    <div class="col-md-6">
                                        <label>

                                            <span class=' t-demo__btn' *ngIf='item.monetary_impact_type.id == 91' ngxTippy  [tippyProps]="tooltip(FieldHelpData.MonetaryImpact.DateOfAcknowledgment)" >
                                                Date of Acknowledgement (CET)
                                           </span>
                                           <span *ngIf='item.monetary_impact_type.id != 91'> Date of Acknowledgement (CET)</span>
                                        </label>
                                        <div class="  input-container position-relative">
                                            <div (click)='discoveryDate.open()' class=" inputIcon material-icons">
                                                date_range
                                            </div>

                                            <input class="input-field" (keypress)="cantEdit($event)" placeholder="DD-MMM-YYYY" [disabled]='true' class="notDisabledInput" [ngModel]="item.bus_date | date: dateFormat" name="date">

                                            <input [max]="today" [matDatepicker]='discoveryDate' class="input-field matrialInputH" placeholder="dd-mm-yyyy" [(ngModel)]="item.bus_date" name="date">
                                            <mat-datepicker #discoveryDate></mat-datepicker>
                                        </div>



                                    </div>
                                </ng-container>
                                <div class="col-md-12">
                                    <label>

                                        <span class=' t-demo__btn' *ngIf='item.monetary_impact_type.id == 91'  ngxTippy  [tippyProps]="tooltip(FieldHelpData.MonetaryImpact.Description)" >
                                            Commentary
                                       </span>
                                       <span *ngIf='item.monetary_impact_type.id != 91'> Commentary</span>

                                    </label>
                                    <div class="mb-3 w-100 inputField">
                                        <textarea id="eventTitleInput" cols="50" maxlength='265' rows="2" placeholder="Commentary" [(ngModel)]="item.comment">

                                   </textarea>
                                        <small class="form-text text-muted text-right">{{item.comment.length}} / 265 </small>

                                    </div>
                                </div>

                                <ng-container *ngIf='item.monetary_impact_type.id == 91'>
                                    <div class="col-md-12 d-flex flex-row-reverse">
                                        <button class='primaryBtn  buttonWidth align-self-end' pButton type="button" [disabled]='chechEnableRadioBtn(92,item.captured_recoveries)' label="Capture Recovery" (click)="addCaptureRecovery(i, template)"></button>
                                    </div>
                                    <mat-accordion class="fix-radio-position " hideToggle="true">
                                        <mat-expansion-panel *ngFor='let CaptureRecovery of item.captured_recoveries let iC = index' [attr.data-index]="iC">
                                            <mat-expansion-panel-header>
                                                <mat-panel-description class='d-flex' style="align-items: center;">

                                                    <div class="d-flex justify-content-between w-100">
                                                        <div>
                                                            {{iC+1}}.Recovery : ${{CaptureRecovery.amount | number: '1.0-0'}}

                                                        </div>
                                                        <div>
                                                            {{CaptureRecovery.bus_date | oneOrmDate}}
                                                        </div>
                                                        <div>
                                                            <span (click)="deletCaptureRecoveryItem(i, iC)" class="removeIcomn material-icons">
                                                                delete
                                                            </span>

                                                        </div>
                                                    </div>
                                                </mat-panel-description>
                                            </mat-expansion-panel-header>


                                            <div class="modal-body row">
                                                <div class="col-md-6">
                                                    <label class=' t-demo__btn' ngxTippy [tippyProps]="tooltip(FieldHelpData.MonetaryImpact.RecoveryAmount)">
                                                        Amount (USD)

                                                    </label>
                                                    <div class="mb-3 w-50 inputField">
                                                        <p-inputNumber placeholder="Amount " [(ngModel)]="CaptureRecovery.amount"></p-inputNumber>

                                                    </div>

                                                </div>
                                                <div class="col-md-6">
                                                    <label class=" t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.MonetaryImpact.DateOfRecovery)">
                                                        Date (CET)


                                                    </label>
                                                    <div class=" input-container position-relative">
                                                        <div (click)='discoveryDate.open()' class=" inputIcon material-icons">
                                                            date_range
                                                        </div>

                                                        <input class="input-field" (keypress)="cantEdit($event)" placeholder="DD-MMM-YYYY" [disabled]='true' class="notDisabledInput" [ngModel]="CaptureRecovery.bus_date | date: dateFormat" name="date">

                                                        <input [max]="today" [matDatepicker]='discoveryDate' class="input-field matrialInputH" placeholder="dd-mm-yyyy" [(ngModel)]="CaptureRecovery.bus_date" name="date">
                                                        <mat-datepicker #discoveryDate></mat-datepicker>
                                                    </div>



                                                </div>
                                                <div class="col-md-12">
                                                    <label ngxTippy [tippyProps]="tooltip(FieldHelpData.MonetaryImpact.RecoveryCommentary)" class=" t-demo__btn">
                                                        Commentary


                                                    </label>
                                                    <div class="mb-3 w-100 inputField">
                                                        <textarea id="eventTitleInput" cols="50" maxlength='265' rows="2" placeholder="Commentary" [(ngModel)]="CaptureRecovery.comment">

                                                   </textarea>
                                                        <small class="form-text text-muted text-right">{{CaptureRecovery.comment.length}} / 265 </small>

                                                    </div>
                                                </div>

                                            </div>
                                        </mat-expansion-panel>
                                    </mat-accordion>

                                </ng-container>
                            </div>

                        </mat-expansion-panel>
                    </ng-container>

                </mat-accordion>
            </div>


        </ng-container>


    </div>


</div>

<div class="w-100" *ngIf="viewOnly">



    <div class="d-flex align-items-end">

        <div class="col-md-6">

            <ng-container *ngIf='MIViewList(selectedList, true).length!=0'>
                <div *ngIf='chechEnableRadioBtn(91,selectedList)' class="d-flex justify-content-between w-100 mt-2">
                    <div>Gross Loss: ${{ sumAllCashOut(selectedList).totalAmount | number: '1.0-0' }} </div>
                    <div>Recovery : ${{ sumAllCashOut(selectedList).totalCaptureRecovery | number: '1.0-0' }} </div>
                    <div>Net Loss: ${{ sumAllCashOut(selectedList).total | number: '1.0-0'}} </div>

                </div>
                <div *ngIf='chechEnableRadioBtn(91,selectedList)' class="mb-5">
                    <mat-accordion class="fix-radio-position " hideToggle="true">
                        <mat-expansion-panel *ngFor='let item of MIViewList(selectedList, true) let i = index' [attr.data-index]="i">
                            <mat-expansion-panel-header>
                                <mat-panel-description class='d-flex' style="align-items: center;">
                                    <div class="d-flex justify-content-between w-100">
                                        <div class=" w-25">
                                            {{i+1}}. {{item.monetary_impact_type.name}}

                                        </div>
                                        <div *ngIf='item.monetary_impact_type.id == 91' class="d-flex justify-content-between w-75">
                                            <div>Gross Loss: ${{ sumCashOut(item).totalAmount | number: '1.0-0'}} </div>
                                            <div>Recovery: ${{ sumCashOut(item).totalCaptureRecovery | number: '1.0-0' }} </div>
                                            <div>Net Loss: ${{ sumCashOut(item).total | number: '1.0-0'}} </div>

                                        </div>
                                        <div *ngIf='item.monetary_impact_type.id != 91' class="d-flex justify-content-center w-75">
                                            <div>Amount: ${{ item.amount | number: '1.0-0'}} </div>


                                        </div>
                                        <div>


                                        </div>
                                    </div>


                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div class="row">
                                <div class="col-md-4">
                                    <label>
                                        <ng-container *ngIf='item.monetary_impact_type.id == 91'> Gross loss amount
                                            (USD) </ng-container>
                                        <ng-container *ngIf='item.monetary_impact_type.id != 91'>Amount (USD)
                                        </ng-container>
                                    </label>
                                    <div class="mb-3 w-50 inputField">
                                        {{item.amount}}
                                    </div>

                                </div>
                                <div class="col-md-4">
                                    <label>
                                        Date of Acknowledgement (CET)


                                    </label>
                                    <div class="input-container position-relative">
                                        {{item.bus_date | date: dateFormat}}
                                    </div>



                                </div>
                                <div class="col-md-4">
                                    <label>
                                        Regulatory Fine / Penalty


                                    </label>
                                    <div class="mb-3 w-50 inputField">
                                        <ng-container *ngIf='item.fine_penalty=="Y"'>Yes</ng-container>
                                        <ng-container *ngIf='item.fine_penalty=="N"'>No </ng-container>
                                    </div>

                                </div>
                                <div class="col-md-12">
                                    <label>
                                        Commentary


                                    </label>
                                    <div class="mb-3 w-100 inputField">
                                        {{item.comment}}
                                    </div>
                                </div>

                                <ng-container *ngIf='item.monetary_impact_type.id == 91'>

                                    <mat-accordion class="fix-radio-position w-100" hideToggle="true">
                                        <mat-expansion-panel *ngFor='let CaptureRecovery of item.captured_recoveries let iC = index' [attr.data-index]="iC">
                                            <mat-expansion-panel-header>
                                                <mat-panel-description class='d-flex' style="align-items: center;">

                                                    <div class="d-flex justify-content-between w-100">
                                                        <div>
                                                            {{iC+1}}.Recovery: ${{CaptureRecovery.amount | number: '1.0-0'}}

                                                        </div>
                                                        <div>
                                                            {{CaptureRecovery.bus_date | oneOrmDate}}
                                                        </div>
                                                        <div>


                                                        </div>
                                                    </div>
                                                </mat-panel-description>
                                            </mat-expansion-panel-header>


                                            <div class="modal-body row">
                                                <div class="col-md-6">
                                                    <label>
                                                        Amount (USD)

                                                    </label>
                                                    <div class="mb-3 w-50 inputField">
                                                        {{CaptureRecovery.amount}}

                                                    </div>

                                                </div>
                                                <div class="col-md-6">
                                                    <label>
                                                        Date (CET)


                                                    </label>
                                                    <div class="input-container position-relative">
                                                        {{CaptureRecovery.bus_date | date: dateFormat}}
                                                    </div>



                                                </div>
                                                <div class="col-md-12">
                                                    <label>
                                                        Commentary


                                                    </label>
                                                    <div class="mb-3 w-100 inputField">
                                                        {{CaptureRecovery.comment}}

                                                    </div>
                                                </div>

                                            </div>
                                        </mat-expansion-panel>
                                    </mat-accordion>

                                </ng-container>
                            </div>

                        </mat-expansion-panel>

                    </mat-accordion>
                </div>
                <div *ngIf='chechEnableRadioBtn(212,selectedList)' class="mb-5">
                    No Monetary Impact

                </div>


            </ng-container>
        </div>
        <div class="col-md-6">
            <ng-container *ngIf='selectedList.length!=0'>
                <div class="mb-5">
                    <mat-accordion class="fix-radio-position " hideToggle="true">
                        <mat-expansion-panel *ngFor='let item of MIViewList(selectedList, false) let i = index' [attr.data-index]="i">
                            <mat-expansion-panel-header>
                                <mat-panel-description class='d-flex' style="align-items: center;">
                                    <div class="d-flex justify-content-between w-100">
                                        <div class=" w-50">
                                            {{i+1}}. {{item.monetary_impact_type.name}}

                                        </div>
                                        <div *ngIf='item.monetary_impact_type.id == 91' class="d-flex justify-content-between w-50">
                                            <div>Gross Loss: ${{ sumCashOut(item).totalAmount | number: '1.0-0'}} </div>
                                            <div>Recovery: ${{ sumCashOut(item).totalCaptureRecovery | number: '1.0-0'}} </div>
                                            <div>Net Loss: ${{ sumCashOut(item).total | number: '1.0-0'}} </div>

                                        </div>
                                        <div *ngIf='item.monetary_impact_type.id != 91' class="d-flex justify-content-center w-50">
                                            <div>Amount: ${{ item.amount | number: '1.0-0'}} </div>


                                        </div>
                                        <div>


                                        </div>
                                    </div>


                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div class="row">
                                <div class="col-md-6">
                                    <label>
                                        <ng-container *ngIf='item.monetary_impact_type.id == 91'> Gross loss amount
                                            (USD) </ng-container>
                                        <ng-container *ngIf='item.monetary_impact_type.id != 91'>Amount (USD)
                                        </ng-container>
                                    </label>
                                    <div class="mb-3 w-50 inputField">
                                        {{item.amount}}
                                    </div>

                                </div>
                                <div class="col-md-6">
                                    <label>
                                        Date of Acknowledgement (CET)


                                    </label>
                                    <div class="input-container position-relative">
                                        {{item.bus_date | date: dateFormat}}
                                    </div>



                                </div>
                                <div class="col-md-12">
                                    <label>
                                        Commentary


                                    </label>
                                    <div class="mb-3 w-100 inputField">
                                        {{item.comment}}
                                    </div>
                                </div>

                                <ng-container *ngIf='item.monetary_impact_type.id == 91'>

                                    <mat-accordion class="fix-radio-position w-100" hideToggle="true">
                                        <mat-expansion-panel *ngFor='let CaptureRecovery of item.captured_recoveries let iC = index' [attr.data-index]="iC">
                                            <mat-expansion-panel-header>
                                                <mat-panel-description class='d-flex' style="align-items: center;">

                                                    <div class="d-flex justify-content-between w-100">
                                                        <div>
                                                            {{iC+1}}.Recovery: {{CaptureRecovery.amount | currency}}

                                                        </div>
                                                        <div>
                                                            {{CaptureRecovery.bus_date | oneOrmDate}}
                                                        </div>
                                                        <div>


                                                        </div>
                                                    </div>
                                                </mat-panel-description>
                                            </mat-expansion-panel-header>


                                            <div class="modal-body row">
                                                <div class="col-md-6">
                                                    <label>
                                                        Amount (USD)

                                                    </label>
                                                    <div class="mb-3 w-50 inputField">
                                                        {{CaptureRecovery.amount}}

                                                    </div>

                                                </div>
                                                <div class="col-md-6">
                                                    <label>
                                                        Date (CET)


                                                    </label>
                                                    <div class="input-container position-relative">
                                                        {{CaptureRecovery.bus_date | date: dateFormat}}
                                                    </div>



                                                </div>
                                                <div class="col-md-12">
                                                    <label>
                                                        Commentary


                                                    </label>
                                                    <div class="mb-3 w-100 inputField">
                                                        {{CaptureRecovery.comment}}

                                                    </div>
                                                </div>

                                            </div>
                                        </mat-expansion-panel>
                                    </mat-accordion>

                                </ng-container>
                            </div>

                        </mat-expansion-panel>

                    </mat-accordion>
                </div>


            </ng-container>
        </div>
    </div>

</div>







<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title m-0">WARNING!</h4>
        <span (click)="modalRef.hide()" class="material-icons informationIcon">
            close
        </span>

    </div>
    <div class="modal-body">
        <div>
            <ul class=" dUL bd-highlight mb-4">
                <li> You have already entered monetary impact information.

                </li>

                <li> If you now select ‘No’ all the information you have entered will be lost. </li>
            </ul>


            <div class="d-flex justify-content-end w-100">


                <button *ngIf='!skipStep' class='primaryBtn  mr-4 buttonWidth' pButton type="button" label="Proceed" (click)="deleteData()"></button>

                <button class='primaryBtn   buttonWidth' pButton type="button" label="Cancel" (click)="cancelSkip()"></button>
                <app-information-dialog [accordionIndex]='8'></app-information-dialog>
            </div>
        </div>
    </div>
</ng-template>



<ng-template #skipTemp>
    <div class="modal-header">
        <h4 class="modal-title m-0">WARNING!</h4>
        <span (click)="modalRef.hide()" class="material-icons informationIcon">
            close
        </span>

    </div>
    <div class="modal-body">
        <div>
            <ul class=" dUL bd-highlight mb-4">
                <li> You have already entered some data on the current step.

                </li>
                <li> If you now choose to skip, all the information you have entered will be lost. </li>
            </ul>


            <div class="d-flex justify-content-end w-100">

                <button class='primaryBtn  mr-4 buttonWidth' pButton type="button" label="Proceed" (click)="approveSkip()"></button>

                <button class='primaryBtn   buttonWidth' pButton type="button" label="Cancel" (click)="modalRef.hide()"></button>
                <app-information-dialog [accordionIndex]='8'></app-information-dialog>
            </div>
        </div>
    </div>
</ng-template>




<ng-template #templateMap>
    <div class="modal-header">
        <h4 class="modal-title m-0">WARNING!</h4>
        <span (click)="modalRef.hide()" class="material-icons informationIcon">
            close
        </span>

    </div>
    <div class="modal-body">
        <div>
            <ul class=" dUL bd-highlight mb-4">
                <li> You have added one or more entries that are not completed.

                </li>
                <li> If you switch now to the One ORM Preview Page Your incompleted entries will be lost.</li>
            </ul>


            <div class="d-flex justify-content-end w-100">

                <button class='primaryBtn  mr-4 buttonWidth' pButton type="button" label="Proceed" (click)="goToOVP()"></button>

                <button class='primaryBtn   buttonWidth' pButton type="button" label="Cancel" (click)="modalRef.hide()"></button>
                <app-information-dialog [accordionIndex]='8'></app-information-dialog>
            </div>
        </div>
    </div>
</ng-template>
