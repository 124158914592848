<mat-card fxFlex="100" class="d-flex justify-content-start fullHeight" >
    <div class="containerDiv">
        <div class="w-100" *ngIf="viewStatus === 'notsaved'">
            <ul class="mb-3 dUL draftList">
                <li> If you leave now, you leave no trace in our system.</li>
                <li>
                    If you would still like to report an  {{caseType }} or raise an issue later, please create a new case.
                </li>
            </ul>
            <div class="d-flex justify-content-end mb-5 mt-4 w-100">

                <button class='primaryBtn mr-4 buttonWidth' pButton type="button" label="Leave" (click)="loadRcsa()"></button>
                    <button class='primaryBtn mr-4 buttonWidth' pButton type="button"  [label]="getLabel('Return to', caseType)" (click)="returnToCase()"></button>

            </div>
        </div>
        <div class="w-100" *ngIf="viewStatus === 'tecnicalDraft'">
            <ul class="mb-3 dUL draftList">
                <li>If you close the window now, we keep your current progress stored for you.</li>
                <li>You can always return with the following permalink and add information later.
                    <div class="d-flex justify-content-end">
                        <input class="mr-2" type="text" size="50" style="line-height: normal;" pInputText disabled [value]="permalinkText"/>
                        <button class='primaryBtn  buttonWidth d-flex d-flex justify-content-between' pButton type="button" label="copy link" (click)="copyPermalink()">
                            <mat-icon matTooltip="Share this case" class="copy">content_copy</mat-icon>
                        </button>
                    </div>
                </li>
            </ul>
            <div class="d-flex justify-content-end mb-5 mt-4 w-100">

                <button class='primaryBtn mr-4 buttonWidth' pButton type="button" label="Leave" (click)="loadRcsa()" routerLink='/'></button>
                <button class='primaryBtn mr-4 buttonWidth' pButton type="button"  [label]="getLabel('Return to', caseType)" (click)="returnToCase()"></button>

            </div>
        </div>
    </div>
</mat-card>
