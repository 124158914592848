import { Component, TemplateRef, OnInit, ViewChild, Input, OnChanges } from '@angular/core';
import { FilterUtils } from 'primeng/utils';
import { RiskCase } from 'src/app/shared/models/dto/risk-case.dto';
import { UserValidationRoles as UserValidation } from 'src/app/shared/models/validation/userValidations';
import { Table } from 'primeng/table/table';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { BaseService } from 'src/app/shared/services/base.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { MsalService, StepperService } from 'src/app/shared/services';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { RiskCaseConstants, UserRoleConstants } from 'src/app/modules/new-risk/common-constants';
import { RiscCaseRequest as RiskCaseMessage } from 'src/app/shared/models/dto/risk-case-request.dto';
import { concatMap } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-my-todos-list',
  templateUrl: './my-todos-list.component.html',
  styleUrls: ['./my-todos-list.component.scss']
})
export class MyTodosListComponent implements OnInit, OnChanges {
  @Input() all: boolean;
  allCasesList = [];
  toDoList = [];
  modalRef: BsModalRef;
  allUserRole = UserRoleConstants;
  caseType = 'Event';
  cols: any[];
  defaultColumns = [
    { field: 'rc_source_name', header: 'ID', filter: '', mode: '', isVisible: false, index: 0 },
    { field: 'rc_title', header: 'Title', filter: '', mode: '', isVisible: false, index: 1 },
    { field: 'action', header: 'Action', filter: '', mode: '', isVisible: true, index: 2, subfield: 'user_name' },
    { field: 'deadline', header: 'Deadline', filter: '', mode: '', isVisible: true, index: 3 },
    { field: 'ownership', header: 'Ownership', filter: '', mode: '', isVisible: true, index: 4, subfield: 'user_name' },
    { field: 'class_name', header: 'Case Type', filter: '', mode: '', isVisible: false, index: 5 },
    { field: 'status_name', header: 'Case Status', filter: '', mode: '', isVisible: false, index: 6 },
  ];
  lodList = [
    { label: 'Yes, self-identified.', value: 'Yes, self-identified.' },
    { label: 'No, identified by a 2nd line function.', value: 'No, identified by a 2nd line function.' },
    { label: 'No, identified by a 3rd line function.', value: 'No, identified by a 3rd line function.' },
  ];
  classNames: any[];
  statusList: any[];
  issueStatusList: any[];
  url = 'my-todos'
  RiskCaseConstants = RiskCaseConstants;
  backupCols: any[];
  riskCaseId;
  risks: RiskCase[] = [];
  nextDeadlineFilter: number;
  nextDeadlineTimeout;
  hasFilters = false;
  isConfigView = false;
  selectedColumns = [];
  selectedColumnsBackup = [];
  updating = false;
  timeout: any;
  comment = '';
  loggedUser: string;
  loggedUserRole;
  @ViewChild('dt') table: Table;
  constructor(private baseService: BaseService,
    public stepperService: StepperService,
    private modalService: BsModalService,
    private router: Router, private spinner: NgxSpinnerService,
    private confirmationService: ConfirmationService,
    private msalService: MsalService) {
    this.statusList = [{ label: 'Pre-Draft', value: 'Pre-Draft' }, { label: 'Draft', value: 'Draft' }, { label: 'Draft - Pending Review', value: 'Draft - Pending Review' },
    { label: 'Open', value: 'Open' }, { label: 'Open - Due Date Change Pending', value: 'Open - Due Date Change Pending' }, { label: 'Open - Due Date Extended', value: 'Open - Due Date Extended' }, { label: 'Open - Pending Completion Approval', value: 'Open - Pending Completion Approval' },
    { label: 'Open - Pending Closure Approval', value: 'Open - Pending Closure Approval' }, { label: 'Completed', value: 'Completed' }, { label: 'Closed', value: 'Closed' }, { label: 'Cancelled', value: 'Cancelled' },
    ];
    this.spinner.show('myToDo');
    if (this.stepperService.enumItemsStoredData && this.stepperService.enumItemsStoredData.myTodoList) {
      this.allCasesList = this.stepperService.enumItemsStoredData.myTodoList;
      this.filterCase(this.caseType);
      this.spinner.hide('myToDo');
    }
    this.baseService.get(this.url).subscribe(res => {
      this.allCasesList = [];
      this.allCasesList = res.body.data;
      this.stepperService.enumItemsStoredData.myTodoList = this.allCasesList;
      this.filterCase(this.caseType);
      this.spinner.hide('myToDo');
    });
  }
  ngOnChanges() {
    this.loadUserSetting();
  }
  loadUserSetting() {
    if (this.stepperService.enumItemsStoredData && this.stepperService.enumItemsStoredData.my_todos) {
      this.cols = this.stepperService.enumItemsStoredData.my_todos ? this.stepperService.enumItemsStoredData.my_todos : this.defaultColumns;
      this.selectedColumns = this.cols.filter(col => col.isVisible);
    } else {
      this.baseService.loadUserSettings("my_todos").subscribe(res => {
        this.cols = res.body && res.body.length > 0 ? res.body : this.defaultColumns;
        this.cols.forEach(element => {
          if (!element.header.includes("(CET)")) {
            element.header = element.header.replace("Deadline", "Deadline (CET)");
          }
        });
        this.stepperService.enumItemsStoredData.my_todos = this.cols;
        this.selectedColumns = this.cols.filter(col => col.isVisible);
      });
    }
  }
  ngOnInit(): void {
    FilterUtils['dateInBetween'] = (value, dateFilters): boolean => {
      if (moment(value, environment.dateFormat).isBetween(dateFilters[0], dateFilters[1])) {
        return true;
      }
      return false;
    };
    FilterUtils['numbersInBetween'] = (value, dateFilters): boolean => {
      if (value >= dateFilters[0] && value <= dateFilters[1]) {
        return true;
      }
      return false;
    };
    this.classNames = [
      { label: 'Issue', value: 'Issue' },
      { label: 'Event', value: 'Event' }
    ];
    if (this.msalService.getLoggedUser()) {
      this.loggedUser = this.msalService.getLoggedUser().user_id;
      this.loadUserSetting();
    }
    FilterUtils['custom'] = (value, filter): boolean => {
      if (filter === undefined || filter === null || filter.trim() === '') {
        return true;
      }
      if (value === undefined || value === null) {
        return false;
      }
      // tslint:disable-next-line:radix
      return parseInt(filter) > value;
    };
  }
  // reloads the record every time the tab is selected
  refresh() {
    this.baseService.get(this.url).subscribe(res => {
      this.allCasesList = [];
      this.allCasesList = res.body.data;
      this.filterCase(this.caseType);
    });
  }
  filterCase(caseType) {
    this.caseType = caseType;
    this.risks = this.allCasesList;
  }
  getDate(date) {
    date = new Date(date * 1000);
    return date;
  }
  onDeadlineChange(event, field, filterMode) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.table.filter(event.value, field, filterMode);
    }, 250);
    if (event.value !== 31) {
      this.hasFilters = true;
    }
  }
  clearFilters() {
    this.cols = this.cols.map(col => {
      col.filter = '';
      if (col.field === 'nextDeadline') {
        col.filter = 31;
      }
      return col;
    });
    this.hasFilters = false;
    this.table.reset();
  }
  filterTable(value: any, field: string, type: string) {
    const filters = this.cols
      .map(col => col.filter)
      .filter((filter: string | []) => {
        if (typeof filter === 'string' && filter !== '') {
          return filter;
        }
        if (Array.isArray(filter) && filter.length) {
          return filter;
        }
      });
    if (filters.length) {
      this.hasFilters = true;
    } else {
      this.hasFilters = false;
    }
    this.table.filter(value, field, type);
  }
  configureTable() {
    this.isConfigView = !this.isConfigView;
    this.backupCols = JSON.parse(JSON.stringify(this.cols));
    this.selectedColumnsBackup = this.selectedColumns;
  }
  getVisibleCols = (cols: any[]) => cols.filter(col => this.selectedColumns.indexOf(col.field) !== -1);
  updateSelectedColumns() {
    this.cols.map(col => {
      col.isVisible = this.selectedColumns.indexOf(col.field) !== -1;
      return col;
    });
  }
  drop(event: any) {
    this.updating = true;
    const colNames = this.cols.map(col => col.field);
    moveItemInArray(colNames, event.previousIndex, event.currentIndex);
    this.cols.map(col => {
      col.index = colNames.indexOf(col.field);
      return col;
    });
    this.cols.sort(this.sortCols);
    this.updating = false;
  }
  private sortCols(a, b) {
    return a.index - b.index;
  }
  cancelConfiguration() {
    this.cols = this.backupCols;
    this.selectedColumns = this.selectedColumnsBackup;
    this.isConfigView = !this.isConfigView;
  }
  saveConfiguration() {
    this.baseService.saveUserSettings("my_todos", this.cols);
    this.configureTable();
  }
  onRowSelect(event: any): void {
    this.spinner.show('myToDo');
    this.stepperService.loadRiskCaseById(event.data.rc_id);
  }
  showDiscardDialog(event: any, id: number) {
    event.preventDefault();
    event.stopPropagation();
    this.confirmationService.confirm({
      message: "Are you sure you want to discard this case?",
      accept: () => {
        if (!id) {
          this.router.navigate(['/']);
          return;
        }
        this.baseService.get('risk-case/' + id).pipe(
          concatMap(res => {
            this.stepperService.etag = res.headers.get('ETag').replace(/"/g, '');
            return this.baseService.delete('risk-case/' + id, this.stepperService.etag)
          })).subscribe(
            success => { this.refresh(); },
            errorData => { console.log('error: ' + errorData) }
          );
      }
    });
  }
  showCancelDialog(event: any, id: number, templet) {
    event.preventDefault();
    event.stopPropagation();
    if (!id) {
      this.router.navigate(['/']);
      return;
    } else {
      this.riskCaseId = id;
    }
    this.openModal(templet);
  }
  setDeleteStyle(fieldName) {
    let styles = {};
    if (fieldName === 'action') {
      styles = {
        "width": "50%",
      };
      return styles;
    }
    if (fieldName === 'delete') {
      styles = {
        "width": "50px",
        "padding": "0",
        "text-align": "center",
        "padding-top": " 5px",
      };
      return styles;
    }
  }
  openModal(template: TemplateRef<any>) {
    const configs = {
      keyboard: false,
      ignoreBackdropClick: true,
      animated: true,
      class: 'modal-lg modal-primary RCSAModal',
    };
    this.modalRef = this.modalService.show(template, configs);
  }
  cancelEvent(id) {
    if (!id) {
      this.router.navigate(['/']);
      return;
    }
    this.baseService.get('risk-case/' + id).pipe(
      concatMap(res => {
        this.stepperService.etag = res.headers.get('ETag').replace(/"/g, '');
        let riskCase = RiskCaseMessage.toModel(res.body.risk_case);
        riskCase.status = RiskCaseConstants.CANCELLED;
        riskCase.lastStep = 99;
        riskCase.cancel_comment = this.comment;
        riskCase.cancel_comment = this.comment;
        riskCase.cancelled_by = this.msalService.getLoggedUser();
        return this.baseService.put('risk-case', RiskCaseMessage.toDto(riskCase), this.stepperService.etag, id);
      })).subscribe(
        success => { this.refresh(); this.closeModel(); },
        errorData => { this.closeModel(); console.log('error: ' + errorData) }
      );
  }
  closeModel() {
    this.comment = '';
    this.modalRef.hide();
  }
  userRoles(user, list) {
    const isUser = UserValidation.userRole(user, list);
    return isUser;
  }
  checkAllLists(list) {
    for (const iterator of list) {
      if (this.userRoles(iterator.user, iterator.list)) {
        return true;
      }
    }
    return false;
  }
  getUserRole() {
    this.baseService.get('authorization/roles').subscribe(res => {
      this.loggedUserRole = UserValidation.defineUserRole(res.body.data);
    });
  }
  byteCount(textValue, maxlength) {
    if (!textValue) {
      return { valid: true, length: 0 };
    }
    if (encodeURI(textValue).split(/%..|./).length - 1 > maxlength) {
      return { valid: false, length: encodeURI(textValue).split(/%..|./).length - 1 };
    }
    return { valid: true, length: encodeURI(textValue).split(/%..|./).length - 1 };
  }
}
