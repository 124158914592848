<div class="containerDiv">


    <div class="w-100 position-relative" *ngIf="viewStatus === 'additionalInformation'">

        <div class="d-flex " *ngIf='viewButton'>
            <p class="mr-1"> Could you provide the following additional information?


            </p>
        </div>
        <div class="row">
            <div [ngClass]="(location)?'col-md-6   my-3':'col-md-12'" *ngIf='Function'>
                <div class="borderLine">

                    <div class="containerDiv p-1 w-100">
                        <p style="width: max-content;" class=' t-demo__btn' ngxTippy [tippyProps]="tooltip(FieldHelpData.AdditionalInformation.Function)">
                            Function Code:
                        </p>


                        <app-custom-search [placeholder]='"Search Function"' class="d-flex justify-content-center" (value)="SearchCF($event)"></app-custom-search>

                        <div class="radioBtnContainer">
                            <div *ngIf="allFunctions.length == 0" class="d-flex justify-content-center align-items-center loading">Loading...</div>
                            <mat-accordion class="fix-radio-position" hideToggle="true">
                                <mat-radio-group aria-label="Select an option">

                                    <mat-expansion-panel *ngFor='let item of allFunctions | searchPipe: { name: searchValueCF}'>
                                        <mat-expansion-panel-header>
                                            <mat-panel-description class='d-flex' style="align-items: center;">
                                                <mat-radio-button (change)='setAdditionalInformationData(item,"company_function" )' [value]="item.id" [checked]="riskCase.company_function ? item.id === riskCase.company_function.id : false">
                                                </mat-radio-button>
                                                <div>{{item.name}} </div>

                                            </mat-panel-description>
                                        </mat-expansion-panel-header>

                                    </mat-expansion-panel>
                                </mat-radio-group>
                            </mat-accordion>
                        </div>
                        <div *ngIf='riskCase.company_function' class=" w-100 mt-3 d-flex justify-content-start align-items-end">

                            <div class="mr-4"> <span class="contantStyle">
                            Your Selection: 
                        </span> </div>
                            <div class="d-flex">
                                <span>
                                {{riskCase.company_function.name}}  
                            </span>

                                <span class="clearSelection ml-2 material-icons" matTooltip="Clear your selection" [matTooltipPosition]="'above'" (click)='setAdditionalInformationData(null,"company_function" )'>
                            close
                        </span></div>

                        </div>

                    </div>





                </div>

            </div>
            <div [ngClass]="(Function)?'col-md-6   my-3':'col-md-12'" *ngIf='location'>
                <div class="borderLine">


                    <div class="containerDiv p-1 w-100">
                        <p style="width: max-content;" class=' t-demo__btn' ngxTippy [tippyProps]="tooltip(FieldHelpData.AdditionalInformation.Location)">
                            Event Location (Country):
                        </p>


                        <app-custom-search [placeholder]='"Search Event Location (Country)"' class="d-flex justify-content-center" (value)="SearchL($event)"></app-custom-search>

                        <div class="radioBtnContainer">
                            <div *ngIf="countryListAllIsoData.length == 0" class="d-flex justify-content-center align-items-center loading">Loading...</div>
                            <mat-accordion class="fix-radio-position" hideToggle="true">
                                <mat-radio-group aria-label="Select an option">

                                    <mat-expansion-panel *ngFor='let item of countryListAllIsoData | searchPipe: { name: searchValueL}'>
                                        <mat-expansion-panel-header>
                                            <mat-panel-description class='d-flex' style="align-items: center;">
                                                <mat-radio-button (change)='setAdditionalInformationData(item,"location" )' [value]="item.id" [checked]="riskCase.location ? item.id === riskCase.location.id : false">
                                                </mat-radio-button>
                                                <div>{{item.name}} </div>

                                            </mat-panel-description>
                                        </mat-expansion-panel-header>

                                    </mat-expansion-panel>
                                </mat-radio-group>
                            </mat-accordion>
                        </div>

                        <div *ngIf='riskCase.location' class="mt-3  w-100 d-flex justify-content-start align-items-end">

                            <div class="mr-4"> <span class="contantStyle">
                            Your Selection: 
                        </span> </div>
                            <div class="d-flex">
                                <span>
                                {{riskCase.location.name}}  
                            </span>

                                <span class="clearSelection ml-2 material-icons" matTooltip="Clear your selection" [matTooltipPosition]="'above'" (click)='setAdditionalInformationData(null,"location" )'>
                            close
                        </span></div>

                        </div>
                    </div>




                </div>

            </div>

            <div class="  col-md-6   my-3" *ngIf='rootCause'>
                <div class="borderLine d-flex align-items-start flex-column h-100">
                    <p>
                        Root Cause Code Level 1:
                    </p>

                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group">
                        <mat-radio-button (change)='setRootCause2(rootCauseItem)' class="example-radio-button" *ngFor="let rootCauseItem of RootCause" [value]="rootCauseItem.level_1_id" [checked]="riskCase.root_cause_level_1 ? rootCauseItem.level_1_id === riskCase.root_cause_level_1.id : false">
                            {{rootCauseItem.level_1_name}}
                        </mat-radio-button>
                    </mat-radio-group>


                    <div *ngIf='riskCase.root_cause_level_1' class="mt-auto  w-100 d-flex justify-content-start align-items-end">

                        <div class="mr-4"> <span class="contantStyle">
                    Your Selection: 
                </span> </div>
                        <div class="d-flex">
                            <span>
                        {{riskCase.root_cause_level_1.name}}  
                    </span>

                            <span class="clearSelection ml-2 material-icons" matTooltip="Clear your selection" [matTooltipPosition]="'above'" (click)='setRootCause2(null)'>
                    close
                </span></div>

                    </div>
                </div>

            </div>
            <ng-container *ngIf='rootCause'>
                <div class="  col-md-6   my-3" *ngIf='RootCause2.length!=0'>
                    <div class="borderLine">
                        <div class="containerDiv p-1 w-100">
                            <p>
                                Root Cause Code Level 2:
                            </p>


                            <app-custom-search [placeholder]='"Search Root Cause Code Level 2 Search"' class="d-flex justify-content-center" (value)="SearchRCL2($event)"></app-custom-search>

                            <div class="radioBtnContainer">
                                <div *ngIf="RootCause2.length == 0" class="d-flex justify-content-center align-items-center loading">Loading...</div>
                                <mat-accordion class="fix-radio-position" hideToggle="true">
                                    <mat-radio-group aria-label="Select an option">

                                        <mat-expansion-panel *ngFor='let item of RootCause2 | searchPipe: {  level_2_name: searchValueRCL2}'>
                                            <mat-expansion-panel-header>
                                                <mat-panel-description class='d-flex' style="align-items: center;">
                                                    <mat-radio-button (change)='setAdditionalInformationData({id:item.level_2_id,name:item.level_2_name},"root_cause_level_2" )' [value]="item.level_2_id" [checked]="riskCase.root_cause_level_2 ? item.level_2_id === riskCase.root_cause_level_2.id : false">
                                                    </mat-radio-button>
                                                    <div>{{item.level_2_name}} </div>

                                                </mat-panel-description>
                                            </mat-expansion-panel-header>

                                        </mat-expansion-panel>
                                    </mat-radio-group>
                                </mat-accordion>
                            </div>
                            <div *ngIf='riskCase.root_cause_level_2' class="mt-3  w-100 d-flex justify-content-start align-items-end">

                                <div class="mr-4"> <span class="contantStyle">
                                Your Selection: 
                            </span> </div>
                                <div class="d-flex">
                                    <span>
                                    {{riskCase.root_cause_level_2.name}}  
                                </span>

                                    <span class="clearSelection ml-2 material-icons" matTooltip="Clear your selection" [matTooltipPosition]="'above'" (click)='setAdditionalInformationData(null,"root_cause_level_2" )'>
                                close
                            </span></div>

                            </div>

                        </div>



                    </div>

                </div>
            </ng-container>

        </div>







        <div *ngIf='viewButton' class="row justify-content-end w-100 mb-5  mx-0">
            <button class='primaryBtn mr-4  buttonWidth' pButton type="button" label="Skip" (click)="toggleSkipDialog()"></button>


            <div class='d-flex'>



                <button class='primaryBtn  buttonWidth' pButton type="button" label="Proceed" [disabled]='additionalInformationMandatoryFlag()' (click)="nextStep(true)"></button>


                <app-information-dialog [accordionIndex]='11' [openmulti]="true" [secaccordionIndex]="10"></app-information-dialog>


            </div>



        </div>


    </div>
    <app-skip-dialog [displaySkipDialog]="displaySkipDialog" (hideDialog)="toggleSkipDialog()" (clearData)="clearData();"></app-skip-dialog>
</div>