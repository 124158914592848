<mat-card fxFlex="100" class="d-flex justify-content-start fullHeight" >
    <div class="containerDiv">
        <div class="w-100">
            <ul class="mb-3 dUL draftList">
                <li> If you choose to discard this {{caseType }} now, it will be removed from our records.</li>
                <li>If you would like to keep your {{caseType}} , click "Return to {{caseType | titlecase}} ".</li>
                <li>If you really want to discard this {{caseType }} , click the "Discard" button. This action cannot be undone.</li>
            </ul>
            <div class="d-flex justify-content-end mb-5 mt-4 w-100">

                <button class='primaryBtn mr-4 buttonWidth' pButton type="button" [label]="getLabel('Discard', caseType)" (click)="discard()"></button>
                <button class='primaryBtn mr-4 buttonWidth' pButton type="button"   [label]="getLabel('Return to', caseType)" (click)="returnToCase()"></button>

            </div>
        </div>
    </div>
</mat-card>

<app-confirm-dialog [displayConfirmDialog]="displayConfirmDialog" (reloadRiskCase) ="reloadRiskCase()"></app-confirm-dialog>
