<div class="breadcrumbStyle my-0 ">
    <span class=" home material-icons-outlined">
        home
        </span>
  <div *ngFor="let breadcrumb of breadcrumbs; let i = index" class="ml-1">
        <span *ngIf="i>0 && breadcrumbs.length > 1" class="material-icons mr-1 "
              style="font-family: 'SwissReSans', Arial, Helvetica, sans-serif; font-size: 20px">
            chevron_right
            </span>

    <a class="route" (click)="navigateToRoute(breadcrumb.url)"
       style="font-family: 'SwissReSans', Arial, Helvetica, sans-serif; font-size: 20px">
      {{ breadcrumb.label }}

    </a>
  </div>
</div>
