import { Component, OnDestroy, OnInit, Input, Output,EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { StepperService, BaseService, MsalService} from '../../../../../shared/services';
import { Roles } from 'src/app/shared/models/roles.model';
import { Router } from '@angular/router';
import { User } from 'src/app/shared/models/user.model';
import { NewRisk } from 'src/app/shared/models/new-risk.model';
import { RiskCaseConstants } from '../../../common-constants';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-step7_Roles-issue',
  templateUrl: './step7-issue.component.html',
  styleUrls: ['./step7-issue.component.scss']
})
export class Step7IssueComponent  implements OnInit, OnDestroy, OnChanges{
  viewStatus: string;
  FieldHelpData;
  filterUsersSingleList: any[];
  roles = new Roles();
  loggedUser: User = new User();
  riskCase: NewRisk;
  RiskCaseConstants = RiskCaseConstants;
  subscription: Subscription = new Subscription();
  @Input() issueFromEvent: boolean = false;
  @Input() issueFromEventObject: any;
  @Output() newIssueData: EventEmitter<any> = new EventEmitter<any>();

  constructor(private stepperService: StepperService,
              private router: Router,
              private baseService: BaseService,
              private authService: MsalService) {
    this.viewStatus = 'mainView';
    this.FieldHelpData = this.stepperService.FieldHelpData;

    this.loggedUser = this.roles.eventOwner = authService.getLoggedUser();
    this.riskCase = stepperService.newRisk;
    if(this.riskCase.roles && !this.issueFromEvent){
      this.roles = this.riskCase.roles;
    }
    // at this step the rcsa has been already chosen
    const rcsa = stepperService.getRcsa()[0];
    this.roles.riskManager = !this.roles.riskManager ? {user_id: rcsa.risk_manager_id, user_name: rcsa.risk_manager} : this.roles.riskManager;
    this.roles.governanceCoordinators = this.roles.governanceCoordinators && this.roles.governanceCoordinators.length == 0 ? this.stepperService.getGovernanceCoords() : this.roles.governanceCoordinators;
  }

  ngOnInit(): void {
    this.subscription.add(this.stepperService.closeCurrentStep.subscribe(index => this.stepperService.updateModel(index, this.roles, 'roles')));
    this.subscription.add(this.stepperService.saveCurrentStep.subscribe(() =>  this.stepperService.changeIndex(5, this.roles, 'roles', true)));
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.issueFromEvent){
      this.roles = new Roles();
      this.roles.eventOwner = this.authService.getLoggedUser();
      const rcsa = this.stepperService.getRcsa()[0];
      this.roles.riskManager = !this.roles.riskManager ? {user_id: rcsa.risk_manager_id, user_name: rcsa.risk_manager} : this.roles.riskManager;
      this.roles.governanceCoordinators = this.roles.governanceCoordinators && this.roles.governanceCoordinators.length == 0 ? this.stepperService.getGovernanceCoords() : this.roles.governanceCoordinators;
    }
  }
  nextStep() {
    if(this.issueFromEvent){
      this.newIssueData.emit({index: 6, listOfData: [{attr: 'roles', issueData: this.roles},{attr: 'status', issueData: 23}]});
    }else{
      this.stepperService.newRisk.status = 23;

      this.stepperService.changeIndex(6, this.roles, 'roles');
    }
  }

  changeViewStatus(status: string) {
    this.viewStatus = status;
  }
  selectUser(data, field){
    this.roles[field] = data;
  }
  handleOutput() {
    this.viewStatus = 'mainView';
  }
  onChangeRB(event) {

  }

  filterUsersSingle(event) {

    const query = event.query;
    if (query.length > 2) {
      this.filterUsersSingleList = [];
      this.baseService.getUsers('user?q=' + query).then(users => {
        this.filterUsersSingleList.push(...users);
      });
    } else {
      this.filterUsersSingleList = [];
    }
  }

  composeName(user: any){
    return user.user_name + ' (' + user.user_id + ')';
  }
checkValidation(){
  const rcsa = this.stepperService.getRcsa()[0];
  if (!this.riskCase.roles.eventOwner) {
    return { disabled: true, msg: 'Issue Owner is Mandatory' };
  }

  if (rcsa.owner_id === this.riskCase.roles.eventOwner?.user_id) {
    return { disabled: true, msg: "Issue Owner and RCSA Owner can't be the same person" };
  }

  if (rcsa.owner_id === this.riskCase.roles.eventDelegate?.user_id) {
    return { disabled: true, msg: "Issue Delegate and RCSA Owner can't be the same person" };
  }

  if (this.riskCase.roles.eventOwner && this.riskCase.roles.eventDelegate) {
    if (this.riskCase.roles.eventOwner.user_id === this.riskCase.roles.eventDelegate.user_id) {
      return { disabled: true, msg: "Issue Owner and Issue Delegate can't be the same person" };
    }



    if (this.riskCase.mandate.owner_id === this.riskCase.roles.eventDelegate.user_id) {
      return { disabled: true, msg: "Issue Delegate and RCSA Owner can't be the same person" };

    }


    if (this.riskCase.mandate.owner_id === this.riskCase.roles.eventOwner.user_id) {
      return { disabled: true, msg: "Issue Owner and RCSA Owner can't be the same person" };
    }
  }

  return { disabled: false, msg: '' };
}
tooltip(toolTipontant){
  const tooltipData = {allowHTML: true,   delay: [1200, null], animation: 'shift-away', placement: 'right',  theme: 'light', content: '' };

  return tooltipData;

}
}
