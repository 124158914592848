<div class="containerDiv">
    <div class="w-100" *ngIf="viewStatus === 'mainView'">
        <p class="t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.ParentRiskControl)">
            Does this issue primarily arise due to a control which needs improvement, or does this issue have a wider scope?


        </p>
        <ul class="mb-4 dUL bd-highlight">
            <li>
                <div class="d-flex justify-content-start">
                    <div class='listText'>
                        An existing control needs improvement:

                    </div>
                    <button class='primaryBtn  buttonWidth ml-3' pButton type="button" label="Select Control" (click)="selectIssueType('Control',templateControl,selectedRisk)" [disabled]="currentStatus != 38"></button>

                </div>


            </li>
            <li>

                <div class="d-flex justify-content-start">
                    <div class='listText'>
                        This issue has a wider scope e.g. process or application change, need for additional control(s):

                    </div>
                    <button class='primaryBtn  buttonWidth ml-3' pButton type="button" label="Select Risk" (click)="selectIssueType('Risk',templateRisk,selectedControl)" [disabled]="currentStatus != 38"></button>
                </div>


            </li>


        </ul>
        <div *ngIf='issueType=="Control"'>
            <p>
                What control needs to be improved?


            </p>


            <app-custom-search class="d-flex justify-content-center mt-4" (value)="Search($event)" [placeholder]="'(Filter Controls by Keywords)'"></app-custom-search>

            <div class="radioBtnContainer my-4">
                <div *ngIf="risksLists.length == 0" class="d-flex justify-content-center align-items-center loading"> No Controls available!</div>
                <mat-accordion class="fix-radio-position" hideToggle="true">
                    <mat-expansion-panel *ngFor='let item of controlList | searchPipe: { control_name: searchValue, detail_description: searchValue, ctrl_type: searchValue, control_id: searchValue, assessors_ids: searchValue}' [disabled]="currentStatus != 38">
                        <mat-expansion-panel-header>
                            <mat-panel-description class='d-flex' style="align-items: center;">
                                <mat-radio-button (change)="onChangeRBControl($event.value)" [id]="item.control_name" [value]="item" [checked]="selectedControl ? item.control_id === selectedControl.control_id : false" [disabled]="currentStatus != 38">
                                </mat-radio-button>
                                <div>{{item.control_name}}: <span>{{item.description}}</span></div>

                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div>
                            <span class="titleRB">Assessors Id: </span>
                            <span>{{item.assessors_ids}}</span>
                        </div>
                        <div>
                            <span class="titleRB">Ctrl Type: </span>
                            <span>{{item.ctrl_type}}</span>
                        </div>
                        <div>
                            <span class="titleRB">Control Detail Description:</span>
                            <p>{{item.detail_description}}</p>
                        </div>


                    </mat-expansion-panel>
                </mat-accordion>


            </div>
            <div *ngIf='selectedControl' class=" w-100 mt-3 d-flex justify-content-start align-items-end">

                <div class="mr-4"> <span class="contantStyle">
                    Your Selection:
                </span> </div>
                <div class="d-flex">
                    <span *ngIf="getControl(selectedControl.control_id)">
                        {{getControl(selectedControl.control_id).control_name}}
                    </span>

                    <span class="clearSelection ml-2 material-icons" matTooltip="Clear your selection" [matTooltipPosition]="'above'" (click)='(currentStatus == 38) ? onChangeRBControl(null) : ""'>
                    close
                </span></div>

            </div>




            <ng-container *ngIf='viewButton && issueType'>
                <div class="row justify-content-end mb-5 w-100 mx-0">
                    <button *ngIf="!issueFromEvent" class='primaryBtn mr-4 buttonWidth' pButton type="button" label="Discard Issue" [disabled]="currentStatus!==RiskCaseConstants.Issue_DRAFT_TECHNICAL" [routerLink]="['/discard']"></button>

                    <button class='primaryBtn  buttonWidth' pButton type="button" label="Proceed" [disabled]="!selectedControl" (click)="nextStepControl(selectedControl)"></button>
                    <div class='d-flex'>
                        <app-information-dialog [issue]="true" [accordionIndex]='4'></app-information-dialog>
                    </div>

                </div>
            </ng-container>
        </div>

        <div *ngIf='issueType=="Risk"'>
            <p>
                Which risk is affected by the wider scope of your issue?

            </p>


            <app-custom-search class="d-flex justify-content-center mt-4" (value)="Search($event)" [placeholder]="'(Filter Risks by Keywords)'"></app-custom-search>

            <div class="radioBtnContainer my-4">
                <div *ngIf="risksLists.length == 0" class="d-flex justify-content-center align-items-center loading"> No Risks available!</div>
                <mat-accordion class="fix-radio-position" hideToggle="true">
                    <mat-expansion-panel *ngFor='let item of risksLists | searchPipe: { name: searchValue, detail_description: searchValue, description: searchValue}' [disabled]="currentStatus != 38">
                        <mat-expansion-panel-header>
                            <mat-panel-description class='d-flex' style="align-items: center;">
                                <mat-radio-button (change)="onChangeRBRisk($event.value)" [id]="item.name" [value]="item" [checked]="selectedRisk ? item.risk_id === selectedRisk.id : false" [disabled]="currentStatus != 38">
                                </mat-radio-button>
                                <div>{{item.name}}: <span>{{item.description}}</span></div>

                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div>
                            <span class="titleRB">Risk Level 1: </span>
                            <span>{{item.risk_level_1.name}}</span>
                        </div>
                        <div>
                            <span class="titleRB">Risk Level 2: </span>
                            <span>{{item.risk_level_2.name}}</span>
                        </div>
                        <div>
                            <span class="titleRB">Risk Detail Description:</span>
                            <p>{{item.detail_description}}</p>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>


            </div>
            <div *ngIf='selectedRisk' class=" w-100 mt-3 d-flex justify-content-start align-items-end">

                <div class="mr-4"> <span class="contantStyle">
                    Your Selection:
                </span> </div>
                <div class="d-flex">
                    <span>
                        {{selectedRisk.name}}
                    </span>

                    <span class="clearSelection ml-2 material-icons" matTooltip="Clear your selection" [matTooltipPosition]="'above'" (click)='(currentStatus == 38) ? onChangeRBRisk(null) : ""'>
                    close
                </span></div>

            </div>




            <ng-container *ngIf='viewButton && issueType'>
                <div class="row justify-content-end mb-5 w-100 mx-0">
                    <button *ngIf="!issueFromEvent" class='primaryBtn mr-4 buttonWidth' pButton type="button" label="Discard Issue" [routerLink]="['/discard']" [disabled]="currentStatus!==RiskCaseConstants.Issue_DRAFT_TECHNICAL"></button>

                    <button class='primaryBtn  buttonWidth' pButton type="button" label="Proceed" [disabled]="!selectedRisk" (click)="nextStepRisk(selectedRisk)"></button>
                    <div class='d-flex'>
                        <app-information-dialog [issue]="true" [accordionIndex]='4'></app-information-dialog>
                    </div>

                </div>
            </ng-container>
        </div>


        <ng-container *ngIf='viewButton && !issueType'>
            <div class="row justify-content-end mb-5 w-100 mx-0">
                <button *ngIf="!issueFromEvent" class='primaryBtn mr-4 buttonWidth' pButton type="button" label="Discard Issue" [routerLink]="['/discard']" [disabled]="currentStatus!==RiskCaseConstants.Issue_DRAFT_TECHNICAL"></button>

                <button class='primaryBtn  buttonWidth' pButton type="button" label="Proceed" [disabled]="!selectedRisk" (click)="nextStepRisk(selectedRisk)"></button>
                <div class='d-flex'>
                    <app-information-dialog [issue]="true" [accordionIndex]='4'></app-information-dialog>
                </div>

            </div>
        </ng-container>
    </div>
</div>





<ng-template #templateRisk>
    <div class="modal-header">
        <h4 class="modal-title m-0">WARNING!</h4>
        <span (click)="modalRef.hide()" class="material-icons informationIcon">
            close
        </span>

    </div>
    <div class="modal-body">
        <div>
            <ul class=" dUL bd-highlight mb-4">
                <li> You have already selected a Control.

                </li>

                <li> Selecting a Risk will remove your Control selection.
                </li>
            </ul>


            <div class="d-flex justify-content-end w-100">
                <button class='primaryBtn  mr-4 buttonWidth' pButton type="button" label="Proceed" (click)="selectIssueType('Risk',null,null);modalRef.hide()"></button>
                <button class='primaryBtn   buttonWidth' pButton type="button" label="Cancel" (click)="modalRef.hide()"></button>
                <app-information-dialog [issue]="true" [accordionIndex]='0'></app-information-dialog>
            </div>
        </div>
    </div>
</ng-template>



<ng-template #templateControl>
    <div class="modal-header">
        <h4 class="modal-title m-0">WARNING!</h4>
        <span (click)="modalRef.hide()" class="material-icons informationIcon">
            close
        </span>

    </div>
    <div class="modal-body">
        <div>
            <ul class=" dUL bd-highlight mb-4">
                <li> You have already selected a Risk.

                </li>

                <li> Selecting a Control will remove your Risk selection.
                </li>
            </ul>


            <div class="d-flex justify-content-end w-100">
                <button class='primaryBtn  mr-4 buttonWidth' pButton type="button" label="Proceed" (click)="selectIssueType('Control',null,null);modalRef.hide()"></button>
                <button class='primaryBtn   buttonWidth' pButton type="button" label="Cancel" (click)="modalRef.hide()"></button>
                <app-information-dialog [issue]="true" [accordionIndex]='0'></app-information-dialog>
            </div>
        </div>
    </div>
</ng-template>
