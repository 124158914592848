import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {BaseService, StepperService} from 'src/app/shared/services';
import {environment} from "../../../../../environments/environment";
import {RETURN_BACK_TO_RCSA_PARAM} from '@shared/common/constants';

@Component({
  selector: 'app-discard',
  templateUrl: './discard.component.html',
  styleUrls: ['./discard.component.scss']
})
export class DiscardComponent implements OnInit {
  subscription: Subscription = new Subscription();
  displayConfirmDialog = false;
  caseType;

  constructor(private stepperService: StepperService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private baseService: BaseService) {
    this.caseType = stepperService.caseType;
  }

  ngOnInit(): void {
    this.subscription.add(this.stepperService.riskCaseChanged.subscribe(() => this.displayConfirmDialog = true));
  }

  returnToCase() {
    this.stepperService.loadRiskCaseById(this.stepperService.newRisk.riskCaseId);
  }

  discard() {
    const id = this.stepperService.newRisk.riskCaseId;
    this.stepperService.loadRcsaByCurrentUser();
    this.baseService.delete('risk-case/' + id, this.stepperService.etag).subscribe(res => {
      localStorage.removeItem('routerUrl');
      this.stepperService.resetRiskCase();
      window.location.href = environment.rcsaUrl + RETURN_BACK_TO_RCSA_PARAM;
    }, error => {
      console.log(error);
      if (error.status == '409') {
        //display reload dialog
        this.stepperService.rCChanged.next();
      }
    });
  }


  reloadRiskCase() {
    this.displayConfirmDialog = false;
    this.stepperService.loadRiskCaseById(this.stepperService.newRisk.riskCaseId);
  }

  getLabel(stringValue, variable) {
    const newstring = variable.charAt(0).toUpperCase() + variable.slice(1);
    return stringValue + " " + newstring;

  }

}
