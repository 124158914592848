import { Component, OnInit , Input, OnChanges} from '@angular/core';
import { TreeNode } from 'primeng';
import structuredClone from '@ungap/structured-clone';

@Component({
  selector: 'app-view-le',
  templateUrl: './view-le.component.html',
  styleUrls: ['./view-le.component.scss']
})
export class ViewLEComponent implements OnInit, OnChanges {
  @Input() savedLE: any;
  @Input() legalEntitiesAllStatus: any;
  @Input() checkedNodes: any;
  selectedNodesView: TreeNode[] = [];
  @Input() pdfView: any;
  @Input() edit: any;


  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(){
    if(this.legalEntitiesAllStatus && this.checkedNodes && this.savedLE){
      this.loadAllSelectedLE();
      this.expandAll(this.selectedNodesView);


    }

  }
  loadAllSelectedLE(){
    this.addLE(this.legalEntitiesAllStatus, this.checkedNodes, <TreeNode[]>this.savedLE);
    this.selectedNodesView = structuredClone(this.legalEntitiesAllStatus);
    this.selectedNodesView = this.filterSelectedTree(this.selectedNodesView, <TreeNode[]>this.savedLE);
  }
  private addLE(tree: TreeNode[], checkedNodes: TreeNode[], legalEntities: TreeNode[], parent?: TreeNode) {
    let count = tree.length;
    for (const node of tree) {
      if(parent){
        node.parent = parent;
      }
      if(legalEntities.some(e => e['id'] == node['id'] )){
        if(checkedNodes.findIndex(x => x === node) === -1){
        }
        count--;
      }

      if (node.children){
        this.addLE(node.children, checkedNodes, legalEntities, node);
      }
      // Once all nodes of a tree are looked at for selection, see if only some nodes are selected and make this node partially selected
      if (tree.length > 0 && tree[0].parent) {
        let isPartiallySelected = (count > 0 && count != tree.length);
        tree[0].parent.partialSelected = isPartiallySelected;
      }
    }

  }
  private filterSelectedTree(tree: TreeNode[], flatToCompareTo: TreeNode[], parent?: TreeNode) {
    for (const node of tree) {
      if (node.children){
        node.children = this.filterSelectedTree(node.children, flatToCompareTo, node);
      }
      if(this.hasNoChildren(node) && (!flatToCompareTo.some(e => e['id'] == node['id']) || node['level'] == 1)){
          tree = tree.filter(x => x !== node);      }
    }
    return tree;
  }
  private hasNoChildren(node: TreeNode) {
    return (!node.children || (node.children && node.children.length == 0));
  }


  private expandRecursive(node: TreeNode, isExpand: boolean){
    node.expanded = isExpand;
    if (node.children){
        node.children.forEach( childNode => {
            this.expandRecursive(childNode, isExpand);
        } );
    }
  }
  private expandAll(checkednodes : TreeNode[]){
    checkednodes.forEach( node => {
        this.expandRecursive(node, true);
    } );
  }



}
