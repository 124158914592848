import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { StepperService } from 'src/app/shared/services';

@Component({
  selector: 'app-permalink-dialog',
  templateUrl: './permalink-dialog.component.html',
  styleUrls: ['./permalink-dialog.component.scss']
})

export class PermalinkDialogComponent implements OnInit {
  @Input() displayPermalink: boolean;
  @Output() hideEvent = new EventEmitter();
  position = "right";
  permalinkText: string;

  constructor(private clipboard: Clipboard,
              private stepperService: StepperService) {
                this.permalinkText = this.stepperService.permalinkText;
  }

  ngOnInit(): void {
    this.permalinkText = this.stepperService.permalinkText;
  }

  copyPermalink() {
    this.clipboard.copy(this.permalinkText);
    this.hideDialog();
  }

  hideDialog(){
    this.hideEvent.emit();
  }

  updatePermalink(){
    this.permalinkText = this.stepperService.permalinkText;
  }

}
