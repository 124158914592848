
import { Component,Input,Output,EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { StepperService } from '../../../../../shared/services/stepper.service';
import { PrimaryParentType } from '../../../../../shared/common/constants';
import { BaseService } from 'src/app/shared/services';
import { NewRisk } from 'src/app/shared/models/new-risk.model';
import { RiskCaseConstants } from '../../../common-constants';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-step4-event',
  templateUrl: './step4-event.component.html',
  styleUrls: ['./step4-event.component.scss']
})
export class Step4EventComponent implements OnInit, OnDestroy {
  viewStatus: string;
  searchValue: string;
  selectedRisk;
  risksLists = [];
  riskCase: NewRisk;
  RiskCaseConstants = RiskCaseConstants;
  @Input() viewButton: boolean = true;
  @Output() newData: EventEmitter<any> = new EventEmitter<any>();
  PrimaryParentType = PrimaryParentType;
  subscription: Subscription = new Subscription();

  constructor(private stepperService: StepperService,
              private baseService: BaseService) {
    this.viewStatus = 'mainView';
    this.riskCase = stepperService.getNewRisk();
    if(this.riskCase.risk){
      this.selectedRisk = this.riskCase.risk[0];
    }
    if(this.riskCase.mandate && this.riskCase.mandate.risk_assessment_id) {
      this.baseService.get('risks?riskAssessmentId=' + this.riskCase.mandate.risk_assessment_id).subscribe(res => {
        this.risksLists = res.body.data;
      });
    }

  }

  ngOnInit(): void {
    this.subscription.add(this.stepperService.closeCurrentStep.subscribe(index => this.stepperService.updateModel(index, this.selectedRisk, 'risk')));
    this.subscription.add(this.stepperService.saveCurrentStep.subscribe(() =>  this.stepperService.changeIndex(null, this.selectedRisk , 'risk')));
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
  
  nextStep(data) {
    if(data){
      
      if(this.stepperService.newRisk.primary_parent_type_id.id==this.PrimaryParentType.RiskAssessment.id){
        this.stepperService.newRisk.risk_assessments = [{id:this.stepperService.newRisk.primary_parent_id}];

      }



      this.stepperService.newRisk.primary_parent_type_id = this.PrimaryParentType.Risk;
      this.stepperService.newRisk.primary_parent_id = data.risk_id ? data.risk_id : data.id;
      this.stepperService.changeIndex(4, [data] , 'risk');


    }else{
      if(this.stepperService.newRisk.primary_parent_type_id.id==this.PrimaryParentType.RiskAssessment.id){
        this.stepperService.newRisk.risk_assessments = [{id:this.stepperService.newRisk.primary_parent_id}];

      }

      if(this.stepperService.newRisk.primary_parent_type_id.id == this.PrimaryParentType.Risk.id && this.stepperService.newRisk.risk_assessments && this.stepperService.newRisk.risk_assessments.length > 0 ){


        this.stepperService.newRisk.primary_parent_type_id = this.PrimaryParentType.RiskAssessment;
        this.stepperService.newRisk.primary_parent_id = this.stepperService.newRisk.risk_assessments[0].id;
        this.stepperService.newRisk.risk_assessments = this.stepperService.newRisk.risk_assessments.splice(0, 1);
      }



      this.stepperService.changeIndex(4,null, 'risk');

    }
 

  }

  onChangeRB(event) {
    this.selectedRisk = event;
    this.newData.emit({prop: 'risk', propValue: this.selectedRisk});

  }

  // search
  Search(payLoad: any): void {
    this.searchValue = payLoad;
  }
}

