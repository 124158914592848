import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {GraphUser} from '@shared/models/graphUser.model';
import {BehaviorSubject, of, Subject} from 'rxjs';
import {concatMap} from 'rxjs/operators';
import {RiskCaseConstants} from 'src/app/modules/new-risk/common-constants';
import {RiscCaseRequest as RiskCaseMessage} from '../models/dto/risk-case-request.dto';
import {Mandate} from '../models/mandate.model';
import {NewRisk} from '../models/new-risk.model';
import {BaseService} from './base.service';
import {FieldHelp} from '../common/textualDescriptions';
import swal from 'sweetalert2';
import {RISK_CASE_ID_PARAM_MARK} from '@shared/common/constants';

@Injectable()
export class StepperService {
  public indexSource = new BehaviorSubject(0);
  currentIndex = this.indexSource.asObservable();
  userRole = 'NAN';
  caseType = 'case';
  issueFromEvent = false;
  issueFromEventEventId = '';
  rCChanged = new Subject<any>();
  riskCaseChanged = this.rCChanged.asObservable();
  FieldHelpData = FieldHelp;
  yearQuarterCheck = true;
  savedTab = 0;
  enumItemsStoredData: any = {};
  redirectFromMap = false;
  filtteredData = {
    RCSAFilter: {type: {"valueT": ["RCSA"], "fieldT": "type", "typeT": "in"}},
    myCasesEvent: {
      status: {
        "valueT": ["Pre-Draft", "Draft", "Draft - Pending Review", "Open", "Open - Pending Closure Approval"],
        "fieldT": "status",
        "typeT": "in"
      }
    },
    myCasesIssue: {
      status: {
        "valueT": ["Pre-Draft", "Draft", "Draft - Pending Review", "Open", "Open - Pending Completion Approval", "Open - Due Date Change Pending", "Open - Due Date Extended"],
        "fieldT": "status",
        "typeT": "in"
      }
    },
    allCasesEvent: {
      status: {
        "valueT": ["Pre-Draft", "Draft", "Draft - Pending Review", "Open", "Open - Pending Closure Approval"],
        "fieldT": "status",
        "typeT": "in"
      }
    },
    allCasesIssue: {
      status: {
        "valueT": ["Pre-Draft", "Draft", "Draft - Pending Review", "Open", "Open - Pending Completion Approval", "Open - Due Date Change Pending", "Open - Due Date Extended"],
        "fieldT": "status",
        "typeT": "in"
      }
    },
  };
  sortedColumn = {
    myCasesEvent: null,
    myCasesIssue: null,
    allCasesEvent: null,
    allCasesIssue: null,
  };
  pageNumber = {
    myCasesEvent: null,
    myCasesIssue: null,
    allCasesEvent: null,
    allCasesIssue: null,
  };
  selectedType = {
    myCases: 'Event',
    allCases: 'Event',
  };
  newRisk: NewRisk = new NewRisk();
  monetaryImpactList = [];
  steps: any[];
  lastSelectedStep: number;
  alreadySaved: boolean = false;
  currentStepData = {index: 0, currentstep: false, modelType: ''}
  etag: string;
  governanceCoordinators: GraphUser[] = [];
  riskManagers: GraphUser[] = [];
  lastRcsas: any[] = [];
  graphComplianceContacts: GraphUser[] = [];
  Toast = swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 10000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', swal.stopTimer);
      toast.addEventListener('mouseleave', swal.resumeTimer);
    }
  })
  private rcsas = new BehaviorSubject<Mandate[]>(null);
  currentRcsas = this.rcsas.asObservable();
  private closeRiskCase = new Subject<any>();
  closeCurrentStep = this.closeRiskCase.asObservable();
  private saveRiskCase = new Subject<any>();
  saveCurrentStep = this.saveRiskCase.asObservable();
  private selectedRcsa = new BehaviorSubject<Mandate>(undefined);
  selectedRcsa$ = this.selectedRcsa.asObservable();
  private gc: any[] = [];

  constructor(private baseService: BaseService,
              private router: Router) {
    this.getUserRole();
    this.steps = [
      {label: 'Mandate', data: null, url: '/new-risk/mandate'},
      {label: 'Event', data: null, url: '/new-risk/event'},
      {label: 'Description', data: null, url: '/new-risk/description'},
      {label: 'Risk', data: null, url: '/new-risk/risk'},
      {label: 'Define Roles', data: null, url: '/new-risk/defineroles'},
      {label: 'Business Impact', data: 'businessImpact', url: '/new-risk/businessimpact'},
      {label: 'Legal Entities', data: 'legalEntities', url: '/new-risk/legalentities'},
      {label: 'Outsourcing', data: 'outsourcing', url: '/new-risk/outsourcing'},
      {label: 'Monetary Impact', data: 'monetary_impacts', url: '/new-risk/monetaryimpact'},
      {label: 'Additional Information', data: 'location', url: '/new-risk/additionalInformation'},
      {label: 'Review Before Go-Live', data: null, url: '/new-risk/go-live'},
    ];
    this._permalinkText = 'not available';
  }

  private _permalinkText;

  public get permalinkText() {
    return this._permalinkText;
  }

  public set permalinkText(permalinkText: string) {
    this._permalinkText = permalinkText;
  }

  private _complianceContacts: any[] = [];

  public get complianceContacts(): any[] {
    return this._complianceContacts;
  }

  public set complianceContacts(value: any[]) {
    this._complianceContacts = value;
  }

  setSelectedRcsa(rcsa: Mandate): void {
    this.selectedRcsa.next(rcsa);
  }

  getSelectedRcsa(): Mandate {
    return this.selectedRcsa.getValue();
  }

  resetRiskCase() {
    this.newRisk = new NewRisk();
    this.permalinkText = 'not available';
    this.indexSource.next(0);
    this.lastSelectedStep = null;
    this.caseType = 'case';
    this.issueFromEvent = false;
    this.setSelectedRcsa(undefined);
  }

  changeIndex(index?: number, modelData?: any, modelType?: string, currentStep?: boolean) {
    this.updateModel(index, modelData, modelType);
    this.currentStepData.currentstep = currentStep;
    this.currentStepData.index = index;
    this.currentStepData.modelType = modelType;
    this.save();
  }

  updateModel(index: number, modelData: any, modelType: string) {
    this.newRisk[modelType] = modelData;
    if (!this.alreadySaved) {
      this.newRisk.lastStep = this.newRisk.lastStep < index ? index : this.newRisk.lastStep;
    }
    // risk case status changed to draft after step 5
    if (index === 5 && this.newRisk.event) {
      this.newRisk.status = RiskCaseConstants.DRAFT;
    }
    // due date saved in step 5
    if (index === 5 && !this.newRisk.target_due_date && this.newRisk.event) {
      this.newRisk.target_due_date = new Date();
      this.newRisk.target_due_date.setDate(new Date().getDate() + 30);
    }
    if (this.newRisk.event) {
      this.caseType = 'event';
    }
    if (this.newRisk.issue) {
      this.caseType = 'issue';
    }
  }

  updateRiskCase(riskCase: NewRisk) {
    this.newRisk = riskCase;
    this.save();
  }

  save() {
    // the risk case data is saved after clicking on proceed of step3 Description
    if (this.newRisk.lastStep < 3) {
      if (!this.currentStepData.currentstep) {
        this.indexSource.next(this.currentStepData.index);
      }
      this.setRcsa([this.newRisk.mandate]);
      return;
    }
    let req = RiskCaseMessage.toDto(this.newRisk);
    if (this.newRisk.riskCaseId !== undefined) {
      req.risk_case.permalink = this.permalinkText;
      this.baseService.put('risk-case', req, this.etag, this.newRisk.riskCaseId).subscribe(data => {
          this.etag = data.headers.get('ETag').replace(/"/g, '');
          const riskCaseUpdatedData = data.body.risk_case;
          if (data.body.risks_primary_path) {
            riskCaseUpdatedData.risk = data.body.risks_primary_path;
          }
          if (data.body.risk_assessments_primary_path) {
            riskCaseUpdatedData.risk_assessments = data.body.risk_assessments_primary_path;
          }
          this.newRisk = RiskCaseMessage.toModel(riskCaseUpdatedData);
          if (!this.currentStepData.currentstep && !this.alreadySaved) {
            this.indexSource.next(this.currentStepData.index);
          }
          if (this.newRisk.lastStep === 99 || this.alreadySaved || !this.checkStatus([6, 37, 38, 23], this.newRisk.status) || (this.newRisk.lastStep > 6 && this.newRisk.issue)) {
            if (this.alreadySaved) {
              this.redirectFromMap = true;
            } else {
              this.redirectFromMap = false;
            }
            this.alreadySaved = false;
            this.router.navigate(['/risk-case/' + (this.newRisk.issue ? 'issue' : 'event') + '/' + this.newRisk.riskCaseId]);
          }
        }, error => {
          console.log(error);
          if (error.status == '409') {
            //display reload dialog
            this.rCChanged.next();
          }
        },
        () => {
          if (this.currentStepData.modelType == 'mandate') {
            this.setRcsa([this.newRisk.mandate]);
          }
        });
    } else {
      this.baseService.post('risk-case', req.risk_case).subscribe(data => {
          this.etag = data.headers.get('ETag').replace(/"/g, '');
          this.newRisk = RiskCaseMessage.toModel(data.body);
          this.newRisk.roles = {eventOwner: data.body.owner};
          if (!this.currentStepData.currentstep && !this.alreadySaved) {
            this.indexSource.next(this.currentStepData.index);
          }
          this.newRisk.riskCaseId = data.body.id;
          this.permalinkText = this.permalinkText.replace('undefined', this.newRisk.riskCaseId + '');
          const linkedIssue = {
            "parentRiskCaseId": this.issueFromEventEventId,
            "riskCaseId": this.newRisk.riskCaseId
          };
          if (this.issueFromEvent) {

            this.baseService.post('risk-case-association', linkedIssue).subscribe(resultData => {

                this.loadRiskCaseById(this.newRisk.riskCaseId);
              }, error => {
                console.log(error);
              },
              () => {
              });

          }
        }, error => {
          console.log(error);
        },
        () => {
          if (this.currentStepData.modelType == 'mandate') {
            this.setRcsa([this.newRisk.mandate]);
          }
        });
    }
  }

  public getNewRisk() {
    return this.newRisk;
  }

  public setNewRisk(newRisk: NewRisk) {
    this.newRisk = newRisk;
  }

  updatePermalinkText(permalinkText: string, riskCaseId?) {
    if (riskCaseId) {
      this.newRisk.riskCaseId = riskCaseId;
    }
    this._permalinkText = permalinkText + RISK_CASE_ID_PARAM_MARK + this.newRisk.riskCaseId;
    this.newRisk.permalinkText = this._permalinkText;
  }

  setmonetaryImpactList(list) {
    this.monetaryImpactList = list;
  }

  loadRiskCaseById(id: number) {
    if (!id) {
      this.router.navigate(['/']);
      return;
    }
    this.baseService.get('risk-case/' + id).pipe(
    ).subscribe(
      res => {
        if (this.newRisk.event) {
          this.caseType = 'event';
        }
        if (this.newRisk.issue) {
          this.caseType = 'issue';
        }
        this.etag = res.headers.get('ETag').replace(/"/g, '');
        const riskCaseUpdatedData = res.body.risk_case;
        riskCaseUpdatedData.risk_assessments = [];
        if (res.body.risks_primary_path) {
          riskCaseUpdatedData.risk = res.body.risks_primary_path;
        }
        if (res.body.risk_assessment) {
          riskCaseUpdatedData.risk_assessments.push(res.body.risk_assessment);
        }
        if (res.body.risk_assessments_primary_path) {
          riskCaseUpdatedData.risk_assessments = this.getUnique(riskCaseUpdatedData.risk_assessments.concat(res.body.risk_assessments_primary_path), 'id');
        }
        this.newRisk = RiskCaseMessage.toModel(riskCaseUpdatedData);
        this.checkQurter(riskCaseUpdatedData);
        this.permalinkText = this.newRisk.permalinkText;
        const lastStep = this.newRisk.lastStep ? this.newRisk.lastStep : 0;
        this.updateModel(lastStep, null, null);
        this.indexSource.next(lastStep);
        this.setRcsa([this.newRisk.mandate]);
        if (res.body.op_events && res.body.op_events.length > 0) {
          this.issueFromEvent = true;
        }

        if (lastStep === 99 || !this.checkStatus([6, 37, 38, 23], this.newRisk.status) || (this.newRisk.lastStep > 6 && this.newRisk.issue)) {
          this.router.navigate(['/risk-case/' + (this.newRisk.issue ? 'issue' : 'event') + '/' + this.newRisk.riskCaseId]);
        } else {
          this.router.navigate([this.steps[lastStep].url]);
        }
      },
      errorData => {
        if (errorData.status === 403) {
          this.Toast.fire({
            icon: 'error',
            title: `Sorry, you don't have permission to access this case`,
          });
        }
      }
    );
  }

  loadRcsaByCurrentUser() {
    this.baseService.get('rcsa-selection/user').subscribe(res => {
      this.setRcsa(res.body.data as Mandate[]);
    })
  }

  getRcsa(): Mandate[] {
    return this.rcsas.getValue();
  }

  setRcsa(mandate: Mandate[]) {
    this.rcsas.next(mandate);
  }

  getGovernanceCoords() {
    return this.gc;
  }

  setGovernanceCoords(gc: any) {
    this.gc = gc;
  }

  //add those governance coordinators if not already present
  addGovernanceCoords(input: any[]) {
    input.filter(e => !this.gc.some(g => g.contact_email == e.contact_email))
    .forEach(one => {
      this.gc.push(one);
    })
  }

  addCompliance(input: any[]) {
    input.filter(e => !this.complianceContacts.some(c => c.user_id == e.user_id))
    .forEach(one => {
      this.complianceContacts.push(one.compliance_contact);
    })
  }

  closeStep(currentStep: number) {
    this.closeRiskCase.next(currentStep);
  }

  saveStep(currentStep: number) {
    this.saveRiskCase.next(currentStep);
  }

  checkStatus(validStatusList, current) {
    if (validStatusList.find(x => x === current)) {
      return true;
    } else {
      return false;
    }
  }

  checkRCSA(rcsas: Mandate[]) {
    if (!rcsas || !this.lastRcsas || (rcsas && !this.areEqual(this.lastRcsas, rcsas))) {
      this.lastRcsas = rcsas;
      this.updateRcsas(rcsas)
    }
  }

  getUserRole() {
    this.baseService.get('authorization/roles').subscribe(res => {
      if (res.body.data.indexOf('GIA') >= 0) {
        this.userRole = 'GIA';
        return;
      }
      if (res.body.data.indexOf('CORM') >= 0) {
        this.userRole = 'CORM';
        return this.userRole;
      }
      if (res.body.data.indexOf('ORM') >= 0) {
        this.userRole = 'ORM';
        return this.userRole;
      }
      if (res.body.data.indexOf('ORM II') >= 0) {
        this.userRole = 'ORMII';
        return this.userRole;
      }
      if (res.body.data.indexOf('Compliance') >= 0) {
        this.userRole = 'Comp';
        return this.userRole;
      }
      if (res.body.data.indexOf('Governance Coordinator') >= 0) {
        this.userRole = 'GC';
        return this.userRole;
      }
      this.userRole = '';
      return this.userRole;
    });
  }

  dateValidate(date) {
    if (!date) {
      return;
    }
    let newDate;
    if (typeof date == "object" && date.length > 0) {
      newDate = date.join('/');
    } else if (typeof date == "string" && date.length > 0) {
      newDate = date.substring(0, 10);
      newDate = newDate.replace(/-/g, "/");
      return new Date(newDate);
    } else {
      newDate = date;
    }
    return new Date(newDate);
  }

  checkQurter(riskCaseUpdatedData) {
    if (riskCaseUpdatedData.close_date || riskCaseUpdatedData.cancel_date) {
      const today = new Date();
      let caseCloseOrCancellDate;
      if (riskCaseUpdatedData.close_date) {
        caseCloseOrCancellDate = this.dateValidate(riskCaseUpdatedData.close_date);
      }
      if (riskCaseUpdatedData.cancel_date) {
        caseCloseOrCancellDate = this.dateValidate(riskCaseUpdatedData.cancel_date);
      }
      if (today.getFullYear() != caseCloseOrCancellDate.getFullYear()) {
        this.yearQuarterCheck = false;
        return;
      } else {
        if (today.getMonth() >= 0 && today.getMonth() <= 2 && caseCloseOrCancellDate.getMonth() >= 0 && caseCloseOrCancellDate.getMonth() <= 2) {
          this.yearQuarterCheck = true;
          return;
        }
        if (today.getMonth() >= 3 && today.getMonth() <= 5 && caseCloseOrCancellDate.getMonth() >= 3 && caseCloseOrCancellDate.getMonth() <= 5) {
          this.yearQuarterCheck = true;
          return;
        }
        if (today.getMonth() >= 6 && today.getMonth() <= 8 && caseCloseOrCancellDate.getMonth() >= 6 && caseCloseOrCancellDate.getMonth() <= 8) {
          this.yearQuarterCheck = true;
          return;
        }
        if (today.getMonth() >= 9 && today.getMonth() <= 11 && caseCloseOrCancellDate.getMonth() >= 9 && caseCloseOrCancellDate.getMonth() <= 11) {
          this.yearQuarterCheck = true;
          return;
        }
        this.yearQuarterCheck = false;
        return;
      }
    }
  }

  getUnique(arr, comp) {
    // store the comparison  values in array
    const unique = arr.map(e => e[comp])
    // store the indexes of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)
    // eliminate the false indexes & return unique objects
    .filter((e) => arr[e]).map(e => arr[e]);
    return unique;
  }

  /*    PRIVATE METHODS  */
  private getUserInfo(email: string, graphListToAddTo: any[]) {
    this.baseService.getGraph('users/' + email).pipe(
      concatMap(graphUser => {
        //picture by userId
        let user: GraphUser = graphUser;
        this.baseService.getGraphBlob('users/' + graphUser.id + '/photo/$value').subscribe(res => {
          user.picture = res;
        });
        this.baseService.getGraph('users/' + graphUser.id + '/presence').subscribe(res => {
          user.presence = res.availability;
        });
        //add graph user if not exists
        this.addGraphUser(graphListToAddTo, user);
        return of(user);
      })).subscribe(
      success => {
        console.log("success");
      },
      errorData => {
        console.log('error: ' + JSON.stringify(errorData))
      }
    );
  }

  private addGraphUser(where: GraphUser[], user: GraphUser) {
    where.findIndex(g => g.userPrincipalName == user.userPrincipalName) === -1 ? where.push(user) : console.log('user ' + user.userPrincipalName + ' already added');
  }

  private areEqual(a, b) {
    if (a.length != b.length) {
      return false;
    }
    const result = a.filter(i => {
      return !b.some(f => JSON.stringify(f) === JSON.stringify(i));
    })
    return result.length === 0;
  }

  private updateRcsas(rcsas: Mandate[]) {
    this.governanceCoordinators = [];
    this.graphComplianceContacts = [];
    this.riskManagers = [];
    this.gc = [];
    if (rcsas && rcsas.length > 0) {
      rcsas.forEach(rcsa => {
        if (rcsa.risk_assessment_id) {
          // retrieve governance coordinators graph informations
          this.baseService.get('governance-coordinators/risk-assessment/' + rcsa.risk_assessment_id).subscribe(res => {
            const respGc = res.body.data.filter(x => x.contact_type == 'Governance Contact');
            if (respGc.length > 0) {
              this.addGovernanceCoords(respGc);
            }
            respGc.forEach(item => {
              this.getUserInfo(item.contact_email, this.governanceCoordinators);
            });
          });
          //retrieve compliance contact for rcsa
          if (rcsa.global_function) {
            let secondLevel = 'empty';
            if (!!rcsa.ra_folder) {
              const path = rcsa.ra_folder.split('/');
              secondLevel = path[3] || 'empty';
            } else {
              secondLevel = rcsa.division ? rcsa.division : 'empty';
            }
            this.baseService.get('compliance/' + rcsa.global_function + '/' + secondLevel + '/').subscribe(res => {
              const resCompliance = res.body.data;
              if (resCompliance.length > 0) {
                this.addCompliance(resCompliance);
              }
              resCompliance.forEach(item => {
                this.getUserInfo(item.compliance_contact.user_email_address, this.graphComplianceContacts);
              });
            });
          }
        }
        if (this.newRisk.riskCaseId) {
          //adds governance coordinators added to risk case
          this.gc = [];
          this.baseService.get('governance-coordinators/risk-case/' + this.newRisk.riskCaseId).subscribe(res => {
            const resGc = res.body.data.filter(x => x.contact_type == 'Governance Contact');
            if (resGc.length > 0) {
              this.addGovernanceCoords(resGc);
            }
            resGc.forEach(item => {
              this.getUserInfo(item.contact_email, this.governanceCoordinators);
            });
          });
          //add your compliance contact
          this.complianceContacts = [];
          if (this.newRisk.folder) {
            const path = this.newRisk.folder.split('/');
            const secondLevel = path[3] || 'empty';
            this.baseService.get('compliance/' + path[2] + '/' + secondLevel + '/').subscribe(res => {
              const resCompliance = res.body.data;
              if (resCompliance.length > 0) {
                resCompliance.forEach(c => {
                  this.complianceContacts.push(c);
                })
              }
              resCompliance.forEach(item => {
                this.getUserInfo(item.compliance_contact.user_email_address, this.graphComplianceContacts);
              });
            });
          }
        }
        //retrieve riskmanagers graph informations
        this.baseService.get('user/' + rcsa.risk_manager_id).pipe(
          concatMap(user => {
            return this.baseService.getGraph('users/' + user.body.user_email_address);
          }),
          concatMap(graphUser => {
            //picture by userId
            this.baseService.getGraphBlob('users/' + graphUser.id + '/photo/$value').subscribe(
              (res: any) => {
                graphUser.picture = res;
              },
              errorData => {
                console.log('error: ' + JSON.stringify(errorData))
              }
            )
            this.baseService.getGraph('users/' + graphUser.id + '/presence').subscribe(res => {
              graphUser.presence = res.availability;
            });
            return of(graphUser);
          })).subscribe(
          graphUser => {
            this.addGraphUser(this.riskManagers, graphUser);
          },
          errorData => {
            console.log('error: ' + JSON.stringify(errorData))
          }
        );
      })
    }
  }
}
