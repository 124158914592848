import { Component, OnInit, OnDestroy } from '@angular/core';
import { StepperService } from '../../../../shared/services/stepper.service';
import { Event } from 'src/app/shared/models/event.model';
import { Issue } from 'src/app/shared/models/issue.model';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss']
})
export class Step2Component implements OnInit, OnDestroy {
  viewStatus: string;
  riskCaseId;
  viewIssuesBtn = environment.viewIssue;
  graceUrl = environment.graceUrl;
  resourceId = 0;
  subscription: Subscription = new Subscription();

  constructor(public stepperService: StepperService) {
    this.viewStatus = 'mainView';
    this.riskCaseId = this.stepperService.newRisk.riskCaseId;
    this.resourceId = this.stepperService.newRisk.mandate.ra_source_id;
  }

  nextStep(caseType: string) {
    const fullGraceUrl = this.graceUrl + this.resourceId;
    if(caseType === 'issue' && !this.viewIssuesBtn ){
      window.location.href = fullGraceUrl;
      return;
    }
    if(this.stepperService.newRisk.riskCaseId){
      this.stepperService.changeIndex(2, null, '');
      return;
    }
    if (caseType === 'event'){
      this.stepperService.newRisk.issue = null;
      this.stepperService.newRisk.status = 37;
      this.stepperService.changeIndex(2, new Event(), 'event');

    } else {
      this.stepperService.newRisk.event = null;
      this.stepperService.newRisk.status = 38;
      this.stepperService.changeIndex(2, new Issue(), 'issue');

    }
  }

  ngOnInit(): void {
    this.subscription.add(this.stepperService.saveCurrentStep.subscribe(() =>  this.stepperService.changeIndex(2, null, '')));
  }
  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
}
