import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {StepperService} from '@shared/services';
import {RETURN_BACK_TO_RCSA_PARAM, RISK_CASE_ID_PARAM_MARK} from '@shared/common/constants';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-routing',
  templateUrl: './routing.component.html',
  styleUrls: []
})
export class RoutingComponent implements OnInit {

  constructor(private router: Router, public stepperService: StepperService) {
  }

  ngOnInit(): void {
    console.log("UA RG: ", navigator.userAgent);
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.router.navigate(['/unsupported-device']);
      return;
    }
    const routeURL = localStorage.getItem('routerUrl');
    console.log('ROUTING SAVED URL', routeURL);
    localStorage.removeItem('routerUrl');
    if (routeURL) {
      const routeMatch = RegExp(/#\/risk-case\/(\w+)\/(\d+)/).exec(routeURL);
      if (routeMatch && routeMatch.length > 0) {
        console.log('ROUTING  - SAVED HASH URL, LOADING RISK CASE', routeMatch);
        this.stepperService.loadRiskCaseById(+routeMatch[2]);
      } else if (routeURL.indexOf(RISK_CASE_ID_PARAM_MARK) !== -1) {
        const riskId = routeURL.substring(routeURL.indexOf(RISK_CASE_ID_PARAM_MARK) + 4);
        console.log('ROUTING - SAVED ID PARAMETER, LOADING RISK CASE', riskId);
        this.stepperService.loadRiskCaseById(+riskId);
      } else if (routeURL.includes("attachments")) {
        console.log('ROUTING - SAVED ATTACHMENTS URL, NAVIGATE', routeURL);
        const urlParams = new URLSearchParams(routeURL.substring(routeURL.indexOf("?") + 1));
        const path = urlParams.get('path');
        const file_name = urlParams.get('file_name');
        this.router.navigate(['/attachments'], {queryParams: {path: path, file_name: file_name}});
      } else {
        console.log('ROUTING - SAVED OTHER URL, NAVIGATE', routeURL);
        this.router.navigate([routeURL]);
      }
    } else {
      this.stepperService.resetRiskCase();
      console.log('ROUTING', 'DEFAULT - MANDATE');
      window.location.href = environment.rcsaUrl + RETURN_BACK_TO_RCSA_PARAM;
    }
  }

}
