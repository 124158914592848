import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {Component, OnInit} from '@angular/core';
import {TooltipPosition} from '@angular/material/tooltip';
import {ActivatedRoute, Router} from '@angular/router';
import {MenuItem} from 'primeng/api';
import {Subscription} from 'rxjs';
import {StepperService} from '../../../../shared/services/stepper.service';
import {
  eventStepList,
  issueStepList,
  preDefineStepList
} from 'src/app/modules/new-risk/common-constants';
import {environment} from '../../../../../environments/environment';
import {BaseService} from '@shared/services';

@Component({
  selector: 'app-new-risk-container',
  templateUrl: './new-risk-container.component.html',
  styleUrls: ['./new-risk-container.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}
  }]
})
export class NewRiskContainerComponent implements OnInit {

  isLinear = true;
  subscription: Subscription = new Subscription();
  isEditableStep = false;
  position: TooltipPosition = 'above';
  stepperSelectedIndex = 0;
  steps: MenuItem[];
  selectedStep = 0;
  displayPermalink = false;
  displayConfirmDialog = false;
  riskCaseData;
  title = '';
  routeCaseId;
  caseType;
  private paramsID: any;

  constructor(private stepperService: StepperService,
              private routeParams: ActivatedRoute,
              private readonly baseService: BaseService,
              private router: Router) {
  }

  ngOnInit() {
    console.log("UA NRC: ", navigator.userAgent);
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.router.navigate(['/unsupported-device']);
      return;
    }
    this.caseType = this.stepperService.caseType;
    this.paramsID = this.routeParams.params.subscribe(params => {
      console.log('ENTERING NEW RISK CONTAINER', params);
      if (params['id']) {
        if (!isNaN(+params['id'])) {
          this.routeCaseId = +params['id'];
          this.stepperService.loadRiskCaseById(this.routeCaseId);
        }
      }
    });

    this.steps = preDefineStepList;
    this.subscription.add(this.stepperService.currentIndex.subscribe(index => this.changeIndex(index)));
    this.subscription.add(this.stepperService.riskCaseChanged.subscribe(() => this.displayConfirmDialog = true));
    this.setCurrentlySelectedRcsa();
  }

  setMyStyles(index) {
    if (index > 5 && index === this.steps.length - 1) {
      const styles = {
        'border-radius': '2px',
      };
      return styles;
    }

  }

  showPermalinkDialog() {
    this.displayPermalink = true;
  }

  hidePermalinkDialog() {
    this.displayPermalink = false;
  }

  handleStepChange(event: any) {
    if (this.stepperSelectedIndex >= event) {
      this.router.navigate([this.steps[event].url]);
      this.selectedStep = event;
    }
    this.stepperService.lastSelectedStep = this.selectedStep;

  }

  checkCurrentUrl(event) {
    this.checkStepData();
    if (this.steps[event].url === this.router.url) {
      return true;
    } else {
      return false;

    }

  }

  checkStepData() {
    const riskCase = this.stepperService.getNewRisk();
    this.caseType = this.stepperService.caseType;

    if (!riskCase) {
      this.steps = preDefineStepList;
      return;
    }
    if (riskCase.lastStep < 2) {
      this.steps = preDefineStepList;
      return;

    } else {
      if (riskCase.event) {
        this.steps = eventStepList;
        return;
      } else {
        this.steps = issueStepList;
        return;
      }
    }
  }

  changeIndex(index) {
    this.checkStepData();


    const params = location.hash.substr(1, location.hash.length - 1).split('&');
    // specific risk case id
    const state = params.findIndex(x => x.indexOf('state') !== -1);

    console.log('NEW RISK CONT - PARAMS', params);
    console.log('NEW RISK CONT - STATE', state);

    if (state !== -1 && params[state].indexOf('riskCaseId') === -1) {
      const stepFromPermalink = (e) => e.url === location.pathname;
      this.stepperSelectedIndex = this.selectedStep = this.steps.findIndex(stepFromPermalink);
      this.stepperService.updatePermalinkText(environment.baseUrl + this.steps[this.stepperSelectedIndex].url, params[state].replace('state=', ''));
      console.log('NEW RISK CONT REDIRECT TO STEP', location.pathname);
      this.router.navigate([location.pathname]);
    } else {

      this.selectedStep = index;
      if (index === 99 || !this.stepperService.checkStatus([6, 37, 38, 23], this.stepperService.newRisk.status) || (this.stepperService.newRisk.lastStep > 6 && this.stepperService.newRisk.issue)) {
        console.log('NEW RISK CONT REDIRECT TO OVERVIEW', this.stepperService.newRisk);
        this.router.navigate(['/risk-case/' + (this.stepperService.newRisk.issue ? 'issue' : 'event') + '/' + this.stepperService.newRisk.riskCaseId]);
        return;
      }
      if (index > 2) {
        // sends step before for updating permalink
        this.stepperService.updatePermalinkText(window.location.origin + this.steps[index].url);
      }
      if (this.stepperService.newRisk.lastStep && this.stepperService.newRisk.lastStep > index) {
        this.stepperSelectedIndex = this.stepperService.newRisk.lastStep;
        this.selectedStep = index;
      } else {
        if (index > this.stepperSelectedIndex) {
          this.stepperSelectedIndex = index;
          if (this.stepperService.lastSelectedStep) {
            this.selectedStep = this.stepperService.lastSelectedStep;
          }
        }
      }
      this.router.navigate([this.steps[index].url]);
    }
  }

  closeCase(stepperSelectedIndex) {
    this.stepperService.closeStep(stepperSelectedIndex);
    this.router.navigate(['/exit/', stepperSelectedIndex]);
  }

  saveCase(stepperSelectedIndex) {
    this.stepperService.alreadySaved = true;
    this.stepperService.saveStep(stepperSelectedIndex);
  }

  reloadRiskCase() {
    this.displayConfirmDialog = false;
    this.stepperService.loadRiskCaseById(this.stepperService.newRisk.riskCaseId);
  }

  stepCheckData(i, step) {
    this.riskCaseData = this.stepperService.newRisk;
    if (this.riskCaseData.event) {
      if (this.riskCaseData.source_name) {
        this.title = this.riskCaseData.source_name + ' ' + this.riskCaseData.event.title;

      } else {
        this.title = this.riskCaseData.event.title;

      }
    }
    if (this.riskCaseData.issue) {
      if (this.riskCaseData.source_name) {
        this.title = this.riskCaseData.source_name + ' ' + this.riskCaseData.issue.title;

      } else {
        this.title = this.riskCaseData.issue.title;

      }
    }
    if (step.data && !this.riskCaseData[step.data]) {
      return {icon: false, data: i};
    }


    if (step.data === 'businessImpact' && this.riskCaseData.businessImpact) {
      if (this.riskCaseData.businessImpact.corso.length === 0 && this.riskCaseData.businessImpact.lifeCapital.length === 0 && this.riskCaseData.businessImpact.reinsurance.length === 0 && !this.riskCaseData.businessImpact.noValue) {
        return {icon: false, data: i};
      }
    }
    if (step.data === 'legalEntities' && this.riskCaseData.legalEntities) {
      if (this.riskCaseData.legalEntities.length === 0) {
        return {icon: false, data: i};
      }
    }
    if (step.data === 'outsourcing') {
      if (!this.riskCaseData.outsourcing) {
        return {icon: false, data: i};
      }
    }
    if (step.data === 'monetary_impacts' && this.riskCaseData.monetary_impacts) {
      if (this.riskCaseData.monetary_impacts.length === 0) {
        return {icon: false, data: i};
      }
    }
    if (step.data === 'location') {
      if (!this.riskCaseData.location || !this.riskCaseData.company_function) {
        return {icon: false, data: i};
      }
    }


    return {icon: true, data: 'done'};

  }

  private setCurrentlySelectedRcsa(): void {
    const {mandate} = this.stepperService.getNewRisk();

    if (!mandate) {
      return;
    }

    this.baseService
    .get('rcsa-selection/all')
    .subscribe(res => {
      const selectedMandate = res.body.data.find(o => o.risk_assessment_id === mandate.risk_assessment_id);
      if (selectedMandate) {
        this.stepperService.setSelectedRcsa(selectedMandate);
      }
    });
  }
}
