<div class="containerDiv">
    <div class="w-100" *ngIf="viewStatus === 'mainView'">
        <ul class="mb-3 dUL draftList">
            <li> You are almost done!

            </li>
            <li>
                Please review your {{caseType}} information on the {{caseType }} overview page.

            </li>
            <li> When ready, submit the {{caseType }} to your assigned Risk Manager {{riskCase.mandate.risk_manager}}.

            </li>
            <li>
                {{riskCase.mandate.risk_manager}} will have a look at it and help you to finalise the reporting.
            </li>

        </ul>
        <div class="row justify-content-end mb-5 w-100 mx-0">

            <button class='primaryBtn mr-4 buttonWidth' pButton type="button" label="Proceed to Review"
            (click)='nextStep()'></button>

        </div>
    </div>
</div>
