import { User } from './user.model';

export class Roles {
  eventOwner: User;
  eventDelegate?: User;
  riskManager?: User;
  governanceCoordinators?: {
    contact_email:string,
    contact_id:string,
    contact_name:string,
    contact_type?:string,
    risk_assessment_id?:number
  }[] = [];
}
