import {Component, OnInit} from '@angular/core';
import {MsalService, StepperService} from 'src/app/shared/services';
import {ActivatedRoute, Router} from '@angular/router';
import {Clipboard} from '@angular/cdk/clipboard';
import {Location} from '@angular/common';
import {NewRisk} from '@shared/models/new-risk.model';
import {environment} from '../../../../../environments/environment';
import {RETURN_BACK_TO_RCSA_PARAM} from '@shared/common/constants';

@Component({
  selector: 'app-exit',
  templateUrl: './exit.component.html',
  styleUrls: ['./exit.component.scss']
})

export class ExitComponent implements OnInit {
  viewStatus: string;
  permalinkText: string;
  caseType;
  newHomeUrl = environment.rcsaUrl + RETURN_BACK_TO_RCSA_PARAM;

  constructor(private stepperService: StepperService,
              private router: Router,
              private activeRouter: ActivatedRoute,
              private clipboard: Clipboard,
              private authService: MsalService,
              private location: Location) {
    this.caseType = stepperService.caseType;
    const stepId = +this.activeRouter.snapshot.paramMap.get('step-id');
    if (stepId < 3) {
      this.viewStatus = 'notsaved';
    } else if (stepId > 0 && stepId < 5) {
      this.viewStatus = 'tecnicalDraft';
    } else {
      // needed for exiting from permalink
      this.stepperService.resetRiskCase();
      this.authService.riskCaseId = 0;
      this.stepperService.loadRcsaByCurrentUser();
      window.location.href = environment.rcsaUrl + RETURN_BACK_TO_RCSA_PARAM;
    }
    this.permalinkText = this.stepperService.permalinkText;
  }

  ngOnInit(): void {
  }

  returnToCase() {
    this.location.back();
  }

  copyPermalink() {
    this.clipboard.copy(this.permalinkText);
  }

  loadRcsa() {
    this.authService.riskCaseId = 0;
    this.stepperService.newRisk = new NewRisk();
    window.location.href = this.newHomeUrl;
  }

  getLabel(stringValue, variable) {
    const newstring = variable.charAt(0).toUpperCase() + variable.slice(1);
    return stringValue + ' ' + newstring;

  }
}
