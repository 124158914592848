import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { SearchPipe } from './Pipe/search.pipe';
import { NgxSpinnerModule } from "ngx-spinner";
import { OneOrmDatePipe } from './Pipe/dateFormat.pipe';

import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { InformationDialogComponent } from '../modules/new-risk/components/information-dialog/information-dialog.component';
import { MaterialModule } from '../material/material.module';

import { CustomSearchComponent } from './components/custom-search/custom-search.component';
import { HighlightSearch } from './Pipe/hilight.pipe';
import { ReadMoreComponent } from './components/read-more/read-more.component';

@NgModule({
  declarations: [CustomSearchComponent,InformationDialogComponent, SearchPipe, HighlightSearch, OneOrmDatePipe,ReadMoreComponent],
  imports: [
    CommonModule, NgxSpinnerModule,
    HttpClientModule, NgxTippyModule,
    FormsModule,MaterialModule
  ],
  providers: [
    OneOrmDatePipe
  ],
  exports: [CustomSearchComponent, ReadMoreComponent,
    SearchPipe, NgxSpinnerModule,OneOrmDatePipe,InformationDialogComponent,
    HighlightSearch, NgxTippyModule,MaterialModule
  ]
})
export class SharedModule { }
