<mat-toolbar class="toolbar p-0">
    <div class="d-flex justify-content-between align-items-center  w-100 px-3">
        <div class="logo-container align-items-center">
          <img class="small-screen" (click)="onClick()" alt="SwissRe Logo" src="./assets/images/SwissRe_Logo_Lake.svg"  >
          <img class="small-screen" (click)="onClick()" alt="OneORM Logo" src="./assets/images/OneORM_LOGO.svg"  >
          <div class="appName flex-centerY"> <span *ngIf='environmentType'>({{environmentType}})</span>
          </div>
          <app-breadcrumb></app-breadcrumb>
        </div>
        <div class="d-flex justify-content-start align-items-center">
            <app-hamburgher-menu></app-hamburgher-menu>

            <button [ngClass]="{'activeBtnP': showRightBar ,  'disactiveBtnP':!showRightBar }" (click)="openBar()" class="button align-items-center mx-3">
              <span  class="material-icons btnIcon">
                chat
                </span>

              Ask Someone
            </button>
            <img class="profileImg" [src]="picture" onError="this.src='assets/images/app/profile.PNG'" alt="">

        </div>
    </div>
</mat-toolbar>
