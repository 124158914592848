<div class="d-flex justify-content-between">



    <div class="mdl-card mdl-shadow--2dp">

        <div class="mdl-card__supporting-text">

            <div class="mdl-stepper-horizontal-alternative">
                <div *ngFor='let status of actionStatusList; let i = index' class="mdl-stepper-step" [ngClass]="{'active-step step-done': status.id < form.value.status.id  ,  'active-step editable-step':status.id == form.value.status.id }">
                    <div class="mdl-stepper-circle"><span>{{i + 1}}</span></div>
                    <div class="mdl-stepper-title">{{status.name}}</div>
                    <div *ngIf="status.id != 269" class="mdl-stepper-bar-left"></div>
                    <div *ngIf="status.id == 269" class="mdl-stepper-bar-left  mdl-stepper-bar-right-dashed"></div>
                    <div *ngIf="status.id != 268" class="mdl-stepper-bar-right"></div>
                    <div *ngIf="status.id == 268" class="mdl-stepper-bar-right mdl-stepper-bar-right-dashed"></div>
                </div>


            </div>

        </div>

    </div>




    <div class="actionIcon" *ngIf=' form.value.status.id != 266'>
        <div (click)='showAction=true'>
            <span class="material-icons actionIcon">
        forward
      </span>

        </div>

    </div>
</div>

<div *ngIf='showAction' class='d-flex justify-content-end'>
    <div *ngIf=' form.value.status.id == 268 || form.value.status.id == 269'>

        <button class='primaryBtn  ml-2 buttonWidth align-self-end' pButton type="button" label="Reopen" [disabled]='false' (click)='changeActionStatus(267)'></button>

    </div>
    <div *ngIf=' form.value.status.id != 269 && form.value.status.id != 268'>
        <button *ngIf=' form.value.status.id == 266' class='primaryBtn  mr-2 buttonWidth align-self-end' pButton type="button" label="Submit Draft" [disabled]='canGo() ||!byteCount(ActionTakenComment.value,4000).valid ||   !byteCount(ActionTakenInput.value,4000).valid   ||   !byteCount(actionDescriptionInput.value,4000).valid  ||  !byteCount(actionTitleInput.value,120).valid  '
            (click)='changeActionStatus(267)'></button>

        <button class='primaryBtn  ml-2  mr-2  buttonWidth align-self-end' pButton type="button" label="Cancel Action" [disabled]='false' (click)='changeActionStatus(269)'></button>
        <button *ngIf=' form.value.status.id == 267' class='primaryBtn  mr-2 buttonWidth align-self-end' pButton type="button" label="Close Action" [disabled]='canGo(268)||!byteCount(ActionTakenComment.value,4000).valid ||   !byteCount(ActionTakenInput.value,4000).valid   ||   !byteCount(actionDescriptionInput.value,4000).valid  ||  !byteCount(actionTitleInput.value,120).valid  '
            (click)='changeActionStatus(268)'></button>

    </div>

</div>


<form [formGroup]="form">




    <div class="row">
        <div class="col-md-6">



            <div class="d-flex justify-content-between">
                <p class="" [ngClass]="!form.get('title').valid?'required':''">
                    Action Title
                    <span class="required" *ngIf="!form.get('title').valid">*</span>

                </p>
                <small [ngClass]="(!byteCount(actionTitleInput.value,120).valid)?'warningTxt':''">{{byteCount(actionTitleInput.value,120).length}} / 120 </small>


            </div>
            <div class=" inputField ">
                <textarea id="actionTitleInput" #actionTitleInput cols="50" rows="2" placeholder="(Action Title)" formControlName="title">
               </textarea>
                <div class="d-flex justify-content-between">
                    <small>    
                    <span *ngIf='!byteCount(actionTitleInput.value,120).valid' class="warningTxt">
                        The text entered exceeds the maximum number of characters allowed. Please reduce the text length up to the defined limit.
                    </span>
                     </small>


                </div>

            </div>


        </div>


        <div class=" col-md-6 inputField ">

            <div class="d-flex justify-content-between">
                <p class="" [ngClass]="!form.get('desc').valid?'required':''">
                    Description
                    <span class="required" *ngIf="!form.get('desc').valid">*</span>

                </p>
                <small [ngClass]="(!byteCount(actionDescriptionInput.value,4000).valid)?'warningTxt':''">{{byteCount(actionDescriptionInput.value,4000).length}} / 4000 </small>


            </div>
            <textarea #actionDescriptionInput placeholder="Description" class="" rows="3" cols="50" formControlName="desc"></textarea>
            <div class="d-flex justify-content-between">
                <small>
                <span *ngIf='!byteCount(actionDescriptionInput.value,4000).valid' class="warningTxt">
                    The text entered exceeds the maximum number of characters allowed. Please reduce the text length up to the defined limit.

                </span>    
                </small>


            </div>
        </div>



        <div class="col-md-4 d-flex justify-content-between align-items-end">
            <div class='mr-2'>
                <p class="  t-demo__btn" [ngClass]="!form.get('dueDate').valid?'required':''">
                    Due Date (CET) <span class="required" *ngIf="!form.get('dueDate').valid">*</span>

                </p>
                <div class=" w-100 d-flex justify-content-start">
                    <div>
                        <div class="input-container position-relative">
                            <div (click)='discoveryDate.open()' class=" inputIcon material-icons">
                                date_range
                            </div>
                            <input class="input-field" (keypress)="cantEdit($event)" placeholder="DD-MMM-YYYY" [disabled]='true' class="notDisabledInput" [ngModelOptions]="{standalone: true}" [ngModel]="form.value.dueDate | date: dateFormat" name="dueDate">

                            <input [min]="form.value.creationDate" [max]='maxDay' [matDatepicker]='discoveryDate' class="input-field matrialInputH" placeholder="dd-mm-yyyy" formControlName="dueDate" name="dueDate" (dateChange)="dateChanged($event.value, 'dueDate')">
                            <mat-datepicker #discoveryDate></mat-datepicker>
                        </div>
                    </div>

                </div>

            </div>




            <div>
                <p *ngIf="!form.get('dueDate').valid && form.value.dueDate"> The Action Due Date is currently exceeding the Target Due Date of the Issue.
                </p>
            </div>
        </div>

        <div class="col-md-4">
            <p [ngClass]="!form.get('assignee').valid?'required':''">
                Owner <span class="required" *ngIf="!form.get('assignee').valid">*</span>

            </p>
            <p-autoComplete formControlName="assignee" [suggestions]="filterUsersSingleList" (completeMethod)="filterUsersSingle($event)" [field]="composeName" [size]="50" placeholder="Name of SR Employee (Mandatory)" [minLength]="1" [forceSelection]="true" emptyMessage="{{filterUsersSingleList && filterUsersSingleList.length >0 ? '' : 'User Not Found'}}">
                <ng-template let-user pTemplate="item">
                    <div>
                        {{user.user_name}} ({{user.user_id}})
                    </div>
                </ng-template>
            </p-autoComplete>
        </div>
        <div class="col-md-4">
            <p [ngClass]="!form.get('delegate').valid?'required':''">
                Delegate
            </p>
            <p-autoComplete formControlName="delegate" [suggestions]="filterUsersSingleList" (completeMethod)="filterUsersSingle($event)" [field]="composeName" [size]="50" placeholder="Name of SR Employee" [minLength]="1" [forceSelection]="true" emptyMessage="{{filterUsersSingleList && filterUsersSingleList.length >0 ? '' : 'User Not Found'}}">
                <ng-template let-user pTemplate="item">
                    <div>
                        {{user.user_name}} ({{user.user_id}})
                    </div>
                </ng-template>
            </p-autoComplete>
        </div>





        <div class="col-md-6">
            <div class="d-flex justify-content-between">
                <p [ngClass]="!form.get('actionTaken').valid?'required':''">
                    Action Taken <span class="required" *ngIf="!form.get('actionTaken').valid">*</span>
                </p>
                <small [ngClass]="(!byteCount(ActionTakenInput.value,4000).valid)?'warningTxt':''">{{byteCount(ActionTakenInput.value,4000).length}} / 4000 </small>


            </div>
            <textarea id="ActionTakenInput" class='w-100' #ActionTakenInput cols="50" rows="3" placeholder="(Action Taken)" formControlName="actionTaken">
            </textarea>
            <div class="d-flex justify-content-between">
                <small>
                <span *ngIf='!byteCount(ActionTakenInput.value,4000).valid' class="warningTxt">
                    The text entered exceeds the maximum number of characters allowed. Please reduce the text length up to the defined limit.

                </span>    
                </small>


            </div>







        </div>





        <div class=" col-md-6 inputField ">

            <div class="d-flex justify-content-between">
                <p [ngClass]="!form.get('comments').valid?'required':''">
                    Comment <span class="required" *ngIf="!form.get('comments').valid">*</span>
                </p>
                <small [ngClass]="(!byteCount(ActionTakenComment.value,4000).valid)?'warningTxt':''">{{byteCount(ActionTakenComment.value,4000).length}} / 4000 </small>


            </div>
            <textarea placeholder="comments" #ActionTakenComment class="" rows="3" cols="50" formControlName="comments"></textarea>

            <div class="d-flex justify-content-between">
                <small>
                <span *ngIf='!byteCount(ActionTakenComment.value,4000).valid' class="warningTxt">
                    The text entered exceeds the maximum number of characters allowed. Please reduce the text length up to the defined limit.

                </span>    
                </small>


            </div>








        </div>





    </div>




    <div class="d-flex justify-content-end w-100 mb-5">


        <button pButton type="button" label="Cancel" class='primaryBtn mr-2 buttonWidth ' (click)="nextStep(null)"></button>
        <ng-container *ngIf=' form.value.status.id == 266'>
            <button class='primaryBtn  mr-2 buttonWidth align-self-end' pButton type="button" label="Save" [disabled]='canGo() ||!byteCount(ActionTakenComment.value,4000).valid ||   !byteCount(ActionTakenInput.value,4000).valid   ||   !byteCount(actionDescriptionInput.value,4000).valid  ||  !byteCount(actionTitleInput.value,120).valid  '
                (click)='changeActionStatus(267)'></button>
        </ng-container>

        <ng-container *ngIf=' form.value.status.id != 266'>
            <button pButton type="button" label="Save" class='primaryBtn   buttonWidth ' [disabled]='canGo() || !byteCount(ActionTakenComment.value,4000).valid ||   !byteCount(ActionTakenInput.value,4000).valid   ||   !byteCount(actionDescriptionInput.value,4000).valid  ||  !byteCount(actionTitleInput.value,120).valid  '
                (click)="nextStep(form.value)"></button>
        </ng-container>

        <div class='d-flex'>
            <app-information-dialog [accordionIndex]='7'></app-information-dialog>
        </div>
    </div>





</form>