import {SelectionModel} from '@angular/cdk/collections';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {Router} from '@angular/router';
import {RiscCaseRequest as RiskCaseMessage} from '@shared/models/dto/risk-case-request.dto';
import {GraphUser} from '@shared/models/graphUser.model';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {NewRisk} from 'src/app/shared/models/new-risk.model';
import swal from 'sweetalert2';
import {BaseService, MsalService, StepperService} from '../../../../../shared/services';
import {Clipboard} from '@angular/cdk/clipboard';
import {dateFormat, IssueStatus, RETURN_BACK_TO_RCSA_PARAM} from '@shared/common/constants';
import {issueStepList} from '../../../common-constants';
import {environment} from '../../../../../../environments/environment';

enum SourceIdGroupLabel {
  first = '1st line',
  second = '2nd line',
  third = '3rd line'
}

@Component({
  selector: 'app-summary-issue',
  templateUrl: './summary-issue.component.html',
  styleUrls: ['./summary-issue.component.scss']
})
export class SummaryIssueComponent implements OnInit, AfterContentChecked {
  @Input() isDesktopDevice: boolean;

  riskCaseId: number;

  IssueStatus = IssueStatus;
  viewStatus: string;
  changeStatusBody;
  today30 = new Date(); // Now
  hideRisks = true;
  irTypeList = [];
  irImpactList = [];
  viewActionList;
  primaryControl;
  actionItemValidationList = [];
  showActionValidation = false;
  issueTypeList = [];
  actionItemAssigneAndDelegate = [];
  notYet = 'notYet';
  attachedFiles: any[] = [];

  validationActionItemText = '';
  @ViewChild('step10_View') MonetaryImpact;
  @ViewChild('legalEntitiesView') legalEntitiesView;
  riskCaseLoadData = false;
  riskCase: NewRisk;
  hideAdministration = true;
  modalRef: BsModalRef;
  GCOnly = true;
  loadDataFlag = true;
  changStatus = false;
  selection = new SelectionModel<any>(true, []);
  userRole = 'NAN';
  allUserRoles;
  allUserRolesShort: string[] = [];
  risksAndControls = [];
  impactRating = [];
  searchValueRCL2: string;
  displayPermalink = false;
  RootCause = false;
  statusBarRiskCaseData;
  Lessons = false;
  riskCaseOverview;
  filterUsersSingleList: any[];
  today = new Date();
  numberOfItems = 3;
  numberOfItemsL = 3;
  dateFormat = dateFormat;
  modelView = '';

  MIList = [];
  nonMIList = [];
  rcOverdueList = [];
  outsourcingList = [];
  displayConfirmDialog = false;
  localReporting = [];
  complianceRiskCategory = [];
  eventTypes1 = [];
  eventTypes2 = [];
  controlList = [];
  controlRisks = [];
  controlRisksassociations = [];
  uploadedList = [];

  AllMIList = [];
  showBUI = false;
  resultGit;
  resultGitNew;
  actionItemData;
  creator = '';
  ownerId = '';
  manger = '';
  delegate = '';
  additionalInfoRecipients = [];
  rcsaOwner = {user_id: '', user_name: '', user_status: ''};
  showError = false;
  loggedUser = '';
  Toast = swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 10000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', swal.stopTimer);
      toast.addEventListener('mouseleave', swal.resumeTimer);

    }
  });
  ORM = '';
  primaryParent;
  GovernanceCoordinator = [];
  governanceCoordinators: GraphUser[] = [];
  riskManagers: GraphUser[] = [];
  FieldHelpData;
  riskStatus = '';
  statusList = [{
    id: this.IssueStatus.Draft,
    name: 'Draft',
    description: null,
    buttonName: 'Submit Draft for Review',
    comment: 'submit_comment'
  },
    {
      id: this.IssueStatus.DraftPendingReview,
      name: 'Draft - Pending Review',
      description: null,
      buttonName: 'Submit Draft for Review',
      comment: ''
    },
    {
      id: this.IssueStatus.Open,
      name: 'Open',
      description: null,
      buttonName: 'Submit Draft for Review',
      comment: ''
    },
    {
      id: this.IssueStatus.OpenPendingCompletionApproval,
      name: 'Open - Pending Completion Approval',
      description: null,
      buttonName: 'Submit Event for Closure',
      comment: ''
    },
    {
      id: this.IssueStatus.OpenDueDateChangePending,
      name: 'Open - Due Date Change Pending',
      description: null,
      buttonName: 'Submit Event for Closure',
      comment: ''
    },
    {
      id: this.IssueStatus.OpenDueDateExtended,
      name: 'Open - Due Date Extended',
      description: null,
      buttonName: 'Submit Event for Closure',
      comment: ''
    },
    {
      id: this.IssueStatus.Completed,
      name: 'Completed',
      description: null,
      buttonName: 'Close Event',
      comment: 'close_comment'
    },
    {
      id: this.IssueStatus.Cancelled,
      name: 'Cancelled',
      description: null,
      buttonName: 'Submit Draft for Review',
      comment: 'cancel_comment'
    }
  ];

  readonly sourceIdLabel = SourceIdGroupLabel;
  readonly secondLineIssueLodID = 214;
  readonly thirdLineIssueLodID = 215;
  readonly selfIdentifiedIssueLodId = 213;
  readonly sourceIdGroups: Record<'first' | 'second' | 'third', number[]> = {
    first: [241],
    second: [242, 556, 249, 250, 251, 252, 243, 555, 260, 258, 259],
    third: [246, 256],
  };
  issueSourceList: Record<'first' | 'second' | 'third', any[]> = {
    first: [],
    second: [],
    third: []
  };

  constructor(private stepperService: StepperService,
              private clipboard: Clipboard,
              private modalService: BsModalService,
              private baseService: BaseService,
              private msalService: MsalService,
              private cdref: ChangeDetectorRef,
              private router: Router) {
    this.FieldHelpData = this.stepperService.FieldHelpData;

    this.viewStatus = 'mainView';
    this.today30.setDate(this.today30.getDate() + 30); // Set now + 30 days as the new date
    this.riskCaseId = this.stepperService.newRisk.riskCaseId;
    this.loadRiskCaseById(this.riskCaseId);
    this.loadData();

    if (this.stepperService.permalinkText == 'not available') {
      this.stepperService.updatePermalinkText(window.location.origin + '/new-risk/additionalInformation');

    }

  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();

  }

  ngOnInit(): void {
    this.stepperService.currentRcsas.subscribe(rcsas => {
      this.stepperService.checkRCSA(rcsas);
      this.governanceCoordinators = this.stepperService.governanceCoordinators;
      this.riskManagers = this.stepperService.riskManagers;
    });
  }

  reloadRiskCase() {
    this.loadRiskCaseById(this.riskCaseId);
    this.displayConfirmDialog = false;

  }

  showButtonForUser(validUsersList) {
    if (validUsersList.find(x => x === this.loggedUser)) {
      return true;
    } else {
      return false;
    }
  }

  loadData() {
    this.baseService.get('governance-coordinators/risk-case/' + this.riskCaseId).subscribe(res => {
      this.GovernanceCoordinator = res.body.data;

    });

    this.baseService.get('authorization/roles').subscribe(res => {
      this.allUserRoles = res.body.data;
      this.allUserRoles.forEach(r => {
        switch (r) {
          case 'ORM II':
            this.allUserRolesShort.push('ORMII');
            break;
          case 'Compliance':
            this.allUserRolesShort.push('Comp');
            break;
          case 'Governance Coordinator':
            this.allUserRolesShort.push('GC');
            break;
          default:
            this.allUserRolesShort.push(r);
        }
      });

      if (res.body.data.indexOf('GIA') >= 0) {
        this.userRole = 'GIA';
        return;
      }

      if (res.body.data.indexOf('CORM') >= 0) {
        this.userRole = 'CORM';
        return;
      }


      if (res.body.data.indexOf('ORM') >= 0) {
        this.userRole = 'ORM';
        return;
      }
      if (res.body.data.indexOf('ORM II') >= 0) {
        this.userRole = 'ORMII';
        return;
      }

      if (res.body.data.indexOf('Compliance') >= 0) {
        this.userRole = 'Comp';
        return;
      }

      if (res.body.data.indexOf('Governance Coordinator') >= 0) {
        this.userRole = 'GC';
        return;
      }
      if (this.userRole === 'NAN') {
        this.baseService.get('authorization/unit/' + this.riskCaseId).subscribe(resUM => {
          if (resUM.body === true) {
            this.userRole = 'UM'; // unit member
            return;
          }
        });
      }
    });


    this.baseService.get('enum/items/27').subscribe(res => {
      this.issueSourceList = res.body.data.reduce((acc, curr) => {
        if (this.sourceIdGroups.first.includes(curr.id)) {
          acc.first.push(curr);
          return acc;
        }

        if (this.sourceIdGroups.second.includes(curr.id)) {
          acc.second.push(curr);
          return acc;
        }

        if (this.sourceIdGroups.third.includes(curr.id)) {
          acc.third.push(curr);
          return acc;
        }

        return acc;
      }, {first: [], second: [], third: []});


    });
    this.baseService.get('enum/items/4').subscribe(res => {

      this.issueTypeList = res.body.data;


    });

    this.baseService.get('enum/items/30').subscribe(res => {
      this.rcOverdueList = res.body.data;

    });

    this.baseService.get('enum/items/36').subscribe(res => {

      this.localReporting = res.body.data;
    });
    this.baseService.get('enum/items/39').subscribe(res => {


      this.complianceRiskCategory = [];
      res.body.data.forEach(element => {
        if (element.id !== 427) {
          this.complianceRiskCategory.push(element);
        }
      });
    });


    this.baseService.get('enum/items/37').subscribe(res => {
      this.outsourcingList = res.body.data;
    });
    this.baseService.get('enum/items/28').subscribe(res => {
      this.irTypeList = res.body.data;
    });
    this.baseService.get('enum/items/5').subscribe(res => {
      this.irImpactList = res.body.data;
    });

  }

  getRiskStatus(status) {
    this.statusList.forEach(element => {
      if (element.id === status) {
        this.riskStatus = element.name;
      }
    });
  }

  loadRiskCaseById(id: number) {
    if (!id) {
      this.router.navigate(['/']);
      return;
    }

    this.rcsaOwner = {user_id: '', user_name: '', user_status: ''};

    this.primaryParent = null;
    this.loadDataFlag = false;
    this.primaryControl = null;
    this.baseService.get('risk-case/' + id).subscribe(res => {

      if (this.msalService.getLoggedUser()) {
        console.log('msalService.getLoggedUser()', this.msalService.getLoggedUser());
        this.loggedUser = this.msalService.getLoggedUser().user_id;
      }

      this.baseService.get('risk-case/' + id + '/attachment').subscribe(res => {
        this.uploadedList = res.body.data;
      });

      this.stepperService.etag = res.headers.get('ETag').replace(/"/g, '');
      this.controlRisks = [];
      const riskCaseUpdatedData = res.body.risk_case;
      if (!this.stepperService.redirectFromMap && riskCaseUpdatedData.last_step < 99) {
        window.location.href = issueStepList[riskCaseUpdatedData.last_step].url + '?id=' + id;
        return;
      }
      riskCaseUpdatedData.risk_assessments = [];
      if (res.body.risk_assessment) {
        riskCaseUpdatedData.risk_assessments.push(res.body.risk_assessment);
        this.rcsaOwner = res.body.risk_assessment.rcsa_owner;
      }
      if (this.checkList(res.body.risk_assessments_primary_path) && !res.body.risk_assessment) {
        this.rcsaOwner = res.body.risk_assessments_primary_path[0].rcsa_owner;
      }
      if (res.body.risks_primary_path) {
        riskCaseUpdatedData.risk = res.body.risks_primary_path;
      }
      if (this.checkList(res.body.risk_assessments_primary_path)) {
        riskCaseUpdatedData.risk_assessments = riskCaseUpdatedData.risk_assessments.concat(res.body.risk_assessments_primary_path);
      }
      this.riskCase = RiskCaseMessage.toModel(riskCaseUpdatedData);

      this.resultGit = riskCaseUpdatedData;
      this.resultGitNew = res.body;

      this.stepperService.newRisk = this.riskCase;
      this.stepperService.permalinkText = window.location.href;
      this.stepperService.setRcsa([this.stepperService.newRisk.mandate]);

      this.loadControlsAndAddInfoRecipients();

      if (this.riskCase.creator) {
        this.creator = this.riskCase.creator.user_id;
      }
      if (this.resultGit.owner) {
        this.ownerId = this.resultGit.owner.user_id;
      }
      if (this.resultGit.action_items.length > 0) {
        this.resultGit.action_items.forEach(actionItem => {
          if (actionItem.delegate) {
            this.actionItemAssigneAndDelegate.push(actionItem.delegate.user_id);
          }
          if (actionItem.assignee) {
            this.actionItemAssigneAndDelegate.push(actionItem.assignee.user_id);

          }
        });
      }
      if (this.resultGit.risk_manager) {
        this.manger = this.resultGit.risk_manager.user_id;
      }
      if (this.resultGit.delegate) {
        this.delegate = this.resultGit.delegate.user_id;
      }
      if (this.riskCase.event_type_level_1) {

        this.baseService.get('event-types/level2/' + this.riskCase.event_type_level_1.id).subscribe(resEvent => {
          this.eventTypes2 = resEvent.body.data;
        });

      }
      this.getRiskStatus(this.riskCase.status);
      this.statusBarRiskCaseData = RiskCaseMessage.toDto(this.riskCase, true).risk_case;


      if (this.riskCase.controls.find(x => x.association_type === 'PRIMARY')) {
        this.primaryControl = this.riskCase.controls.find(x => x.association_type === 'PRIMARY');
      }
      if (!this.resultGit.identify_LOD) {
        this.resultGit.identify_LOD = {id: 0};
      }
      this.ORM = this.riskCase.mandate.owner_id;
      this.baseService.get('controls/risks/' + id).subscribe(controlRes => {

        this.controlRisks = controlRes.body.data;
        if (controlRes.body.data.find(x => x.associationType === 'PRIMARY')) {
          this.primaryParent = controlRes.body.data.find(x => x.associationType === 'PRIMARY');
        }

      });
      this.baseService.get('risk-association-control/' + id).subscribe(controlRes => {
        this.controlRisksassociations = controlRes.body.data;


      });


      const element: HTMLElement = document.getElementById('refreshStatus') as HTMLElement;
      if (element) {
        element.click();

      }
      this.loadDataFlag = true;
      if (this.BiLists(this.riskCase.businessImpact).length > 0) {
        this.showBUI = true;

      }

    });
    this.changStatus = false;

  }

  openModal(template: TemplateRef<any>) {
    const configs = {
      keyboard: false,
      ignoreBackdropClick: true,
      animated: true,
      class: ' modal-lg modal-primary informationModalSummary',
    };
    this.modalRef = this.modalService.show(template, configs);
  }

  nextStep() {
  }

  newCase() {
    this.stepperService.resetRiskCase();
    this.router.navigate(['/new-risk']);
  }

  editEntityfromPopUp(templet, eventData) {
    if (eventData.validation) {
      this.editEntity(templet, eventData.popupName, eventData.validation.status ? eventData.validation.status : null, eventData.validation.notValid ? eventData.validation.notValid : null);

    } else {
      this.editEntity(templet, eventData.popupName);

    }
  }

  editEntity(templet, view, status?, notValid?) {
    if (view === 'attachment') {
      this.modelView = view;
      this.openModal(templet);
      return;

    }
    if (this.riskCase.status === this.IssueStatus.Cancelled || this.riskCase.status === this.IssueStatus.Completed) {
      this.modelView = 'cantEdit';
      this.openModal(templet);
      return;
    }
    if (!view) {
      return;
    }
    if (notValid) {
      this.modelView = 'cantEdit';
      this.openModal(templet);
      return;
    }
    if (status) {
      if (this.riskCase.status >= status) {
        this.modelView = 'cantEdit';
        this.openModal(templet);
        return;
      }
    }
    if (view === 'assurComment' || view === 'reOpened') {
      this.modelView = view;
      this.openModal(templet);
      return;
    }
    if (view === 'complianceRiskCategory') {
      if (this.showButtonForUserRole(['Comp', 'CORM', 'UM'])) {
        this.modelView = view;
        this.openModal(templet);
        return;
      } else {
        this.modelView = 'cantEdit';
        this.openModal(templet);
        return;
      }

    }
    if (this.showButtonForUserRole(['NAN']) && !this.showButtonForUser([this.ownerId, this.delegate, this.creator, this.ORM].concat(this.GovernanceCoordinator).concat(this.actionItemAssigneAndDelegate))) {
      this.modelView = 'cantEdit';
      this.openModal(templet);
      return;
    }
    if (view !== 'complianceRiskCategory' && this.showButtonForUserRole(['Comp'])
      && !this.showButtonForUser([this.ownerId, this.delegate, this.ORM].concat(this.GovernanceCoordinator).concat(this.actionItemAssigneAndDelegate))
      && !this.hasAnyOfRoles(['GC'])) {
      this.modelView = 'cantEdit';
      this.openModal(templet);
      return;
    }

    if (view === 'Risks&Controls') {
      if (this.showButtonForUserRole(['CORM', 'ORMII', 'ORM', 'GC', 'GIA'])) {
        this.modelView = 'Risks&ControlsHighProfile';
        this.openModal(templet);
        return;
      } else {
        this.modelView = 'Risks&ControlsLowProfile';
        this.openModal(templet);
        return;
      }
    }

    if (this.riskCase.status !== this.IssueStatus.Draft) {
      if (!this.showButtonForUser([this.ownerId, this.delegate, this.ORM].concat(this.GovernanceCoordinator).concat(this.actionItemAssigneAndDelegate)) && !this.showButtonForUserRole(['CORM', 'ORMII', 'ORM', 'GC', 'GIA'])) {
        this.modelView = 'cantEdit';
        this.openModal(templet);
        return;
      }
    }


    if (!this.showButtonForUser([this.ownerId, this.delegate, this.ORM].concat(this.GovernanceCoordinator).concat(this.actionItemAssigneAndDelegate))
      && !this.hasAnyOfRoles(['GC'])) {

      if (this.showButtonForUserRole(['Comp', 'UM']) && view !== 'complianceRiskCategory' && !this.showButtonForUser([this.creator])) {
        this.modelView = 'cantEdit';
        this.openModal(templet);
        return;
      }
      if (this.showButtonForUserRole(['Comp']) && !this.showButtonForUser([this.creator]) && (this.riskCase.status === this.IssueStatus.Draft || this.riskCase.status === 7 || this.riskCase.status === 37)) {
        this.modelView = 'cantEdit';
        this.openModal(templet);
        return;
      }

      if (this.showButtonForUserRole(['GC']) && view !== 'event') {
        this.modelView = view;
        this.openModal(templet);
        return;
      }

    }

    if (view === 'action-item' && this.showActionValidation) {
      this.showActionValidation = false;
      if (this.riskCase.status < 25) {
        this.validationActionItemText = `You need at least one action item in status 'Open' in order to proceed.`;
      }
      if (this.riskCase.status >= 25) {
        this.validationActionItemText = `All action items on this issue should be in status 'Closed' or 'Cancelled' before the issue can be submitted for completion approval.`;
      }
    }

    this.getSelectionLocalReporting();
    this.openModal(templet);
    this.modelView = view;
  }

  actionItemListNew(status) {
    this.actionItemValidationList = [];
    this.riskCase.action_items.forEach(element => {
      if (element.status.id === status) {
        this.actionItemValidationList.push(element);

      }
    });
  }

  cantEdit(event): boolean {
    return false;
  }

  BiLists(allLists) {
    let newList = [];
    if (!allLists) {
      return [];

    }
    if (allLists.lifeCapital) {
      newList = newList.concat(allLists.lifeCapital);

    }
    if (allLists.corso) {
      newList = newList.concat(allLists.corso);

    }
    if (allLists.reinsurance) {
      newList = newList.concat(allLists.reinsurance);

    }
    if (allLists.noValue) {
      newList = newList.concat(allLists.noValue);

    }

    return newList;
  }

  selectUser(data, field) {
    if (field === 'assurOwner') {
      this.riskCase[field] = data;
      return;

    }
    this.riskCase.roles[field] = data;
  }

  filterUsersSingle(event) {

    const query = event.query;
    if (query.length > 2) {
      this.filterUsersSingleList = [];
      this.baseService.getUsers('user?q=' + query).then(users => {
        this.filterUsersSingleList.push(...users);
      });
    } else {
      this.filterUsersSingleList = [];

    }
  }

  composeName(user: any) {
    return user.user_name + ' (' + user.user_id + ')';
  }

  cancel() {
    this.loadDataFlag = false;
    this.loadRiskCaseById(this.riskCaseId);
    this.modalRef.hide();

  }

  update() {
    this.riskCase.lastStep = 99;
    const req = RiskCaseMessage.toDto(this.riskCase);
    req.risk_case.permalink = this.stepperService.permalinkText;

    this.loadDataFlag = false;
    this.baseService.put('risk-case', req, this.stepperService.etag, this.riskCaseId).subscribe(data => {
        this.getRiskStatus(data.body.risk_case.status.id);
        this.stepperService.etag = data.headers.get('ETag').replace(/"/g, '');
        if (this.changStatus) {
          if (this.changeStatusBody) {
            this.baseService.post('status-change', this.changeStatusBody).subscribe(datachangeStatus => {
                console.log(datachangeStatus);
                this.showTHYDialog();

              }, errordatachangeStatus => {
                console.log(errordatachangeStatus);
              },
              () => {

              });
          } else {
            this.showTHYDialog();

          }


        } else {
          this.loadRiskCaseById(this.riskCaseId);
        }
      }, error => {
        console.log(error);
        if (error.status == '409') {
          // display reload dialog
          this.displayConfirmDialog = true;
        } else {
          this.loadRiskCaseById(this.riskCaseId);

          this.Toast.fire({
            icon: 'error',
            title: `Sorry, ${this.riskCase.source_name} ${this.riskCase.issue.title} not saved`,
          });
        }
      },
      () => {
      });

    this.modalRef.hide();

  }

  showTHYDialog() {
    this.Toast.fire({
      icon: 'info',
      title: `Thank you, ${this.riskCase.source_name} ${this.riskCase.issue.title} is now in status ${this.riskStatus}`,
    });
    this.stepperService.resetRiskCase();
    this.msalService.riskCaseId = 0;
    this.stepperService.loadRcsaByCurrentUser();
    // this.router.navigate(['/']);
    setTimeout(() => {
      window.location.href = environment.rcsaUrl + RETURN_BACK_TO_RCSA_PARAM;
    }, 5000);
  }

  updateMI_view() {
    this.MonetaryImpact.getMI_Items();
    this.loadRiskCaseById(this.riskCaseId);
    this.modalRef.hide();

  }

  updateData(data) {

    this.riskCase[data.prop] = data.propValue;
  }

  updateDataAndSave(data) {
    this.riskCase[data.prop] = data.propValue;
    this.update();
  }

  dateChanged(evt, row) {
    this.riskCase[row] = new Date(evt);
  }

  changeStatus(allStatusData) {
    const statusData = allStatusData.status;
    this.changeStatusBody = allStatusData.changeStatus;
    this.changStatus = true;
    this.riskCase.lastStep = 99;
    this.riskCase.status = statusData.status.id;
    this.riskCase[statusData.commentAttr] = statusData.comment;
    if (statusData.userValue) {
      this.riskCase[statusData.userName] = statusData.userValue;
    }
    if (statusData.dateValue) {
      this.riskCase[statusData.dateName] = statusData.dateValue;
    }
    if (statusData.status.id === IssueStatus.Open && !this.riskCase.submission_date) {
      this.riskCase.submission_date = new Date();
    }
    this.update();
  }

  goToStep() {
    this.stepperService.loadRiskCaseById(this.riskCaseId);
  }

  outsourse(selectedOutsourcing) {
    let name = '';
    if (this.outsourcingList) {
      this.outsourcingList.forEach(element => {
        if (element.id === selectedOutsourcing) {
          name = element.name;
        }
      });
    }
    return name;
  }

  checkValidation() {

    if (this.showError) {
      return {disabled: true, msg: ''};
    }
    if (!this.riskCase.roles.eventOwner) {
      return {disabled: true, msg: 'Event Owner is Mandatory'};
    }

    if (this.riskCase.mandate.owner_id === this.riskCase.roles.eventOwner.user_id) {
      return {disabled: true, msg: 'Event Owner and RCSA Owner can\'t be the same person'};
    }
    if (this.riskCase.roles.eventOwner && this.riskCase.roles.eventDelegate) {
      if (this.riskCase.roles.eventOwner.user_id === this.riskCase.roles.eventDelegate.user_id) {
        return {disabled: true, msg: 'Event Owner and Event Delegate can\'t be the same person'};
      }


      if (this.riskCase.mandate.owner_id === this.riskCase.roles.eventDelegate.user_id) {
        return {disabled: true, msg: 'Event Delegate and RCSA Owner can\'t be the same person'};

      }


      if (this.riskCase.mandate.owner_id === this.riskCase.roles.eventOwner.user_id) {
        return {disabled: true, msg: 'Event Owner and RCSA Owner can\'t be the same person'};
      }


    }

    return {disabled: false, msg: ''};
  }

  hidePermalinkDialog() {
    this.displayPermalink = false;
  }

  showPermalinkDialog() {
    this.displayPermalink = true;
  }

  showDate(date) {
    return new Date(date);
  }

  showDateSafari(date) {
    let newDate;
    if (typeof date == 'object' && date.length > 0) {
      newDate = date.join('/');
    } else if (typeof date == 'string' && date.length > 0) {
      newDate = date.substr(0, 10);
      newDate = newDate.replace(/-/g, '/');
      return new Date(newDate);

    } else {
      newDate = date;
    }
    return new Date(newDate);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {

    const numSelected = this.selection.selected.length;
    const numRows = this.riskCase.local_reporting.length;

    return numSelected === numRows;

  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.riskCase.local_reporting.forEach(row => this.selection.select(row));
    this.isSomeSelected();
  }

  isSomeSelected() {
    this.riskCase.local_reporting = this.selection.selected;
    return this.selection.selected.length > 0;
  }

  getSelectionLocalReporting() {
    this.riskCase.local_reporting.forEach(row => {
      this.selection.select(row);
    });
  }

  getList(list, listName) {
    this.riskCase[listName] = list;
  }

  setAdditionalInformationData(value, property) {
    this.riskCase[property] = value;
  }

  seteventTypes2(data) {


    if (data) {


      this.riskCase.event_type_level_1 = {id: data.level_1_id, name: data.level_1_name};

      this.baseService.get('event-types/level2/' + data.level_1_id).subscribe(res => {
        this.eventTypes2 = res.body.data;
      });
    } else {
      this.riskCase.event_type_level_1 = null;

      this.eventTypes2 = [];
    }

    this.setAdditionalInformationData(null, 'event_type_level_2');
  }

  SearchRCL2(payLoad: any): void {
    this.searchValueRCL2 = payLoad;
  }

  showButtonForUserRole(validUsersList) {
    if (validUsersList.find(x => x === this.userRole)) {
      return true;
    } else {
      return this.hasAnyOfRoles(validUsersList);
    }
  }

  private hasAnyOfRoles(validRoles: string[]) {
    let result = false;
    this.allUserRolesShort.forEach(role => {
      if (validRoles.includes(role)) {
        result = true;
      }
    });
    return result;
  }

  cantEditTextArea() {
    if (this.riskCase.status === 11 || this.riskCase.status === 10) {
      return true;

    }
    if (this.riskCase.status === 7 || this.riskCase.status === 8 || this.riskCase.status === 9) {
      if (!this.showButtonForUser([this.ownerId, this.delegate, this.ORM])) {
        if (this.showButtonForUser([this.creator]) && !this.showButtonForUserRole(['CORM', 'ORMII', 'ORM', 'GC'])) {
          return true;
        }
      }

    }
    return !(this.showButtonForUserRole(['CORM', 'ORM', 'ORMII', 'GC']) || this.showButtonForUser([this.creator, this.ownerId, this.delegate, this.ORM]));
  }

  nocomplianceRiskCategory(checked, others) {
    const noCRC = {
      description: null,
      enum_type: {id: 39, name: 'Compliance Risk Category', description: null},
      id: 427,
      name: 'Not Compliance and Legal Risk related'
    };
    if (checked) {
      this.riskCase.compliance_risk_category = [noCRC];
      others.selection.clear();
    } else {
      this.riskCase.compliance_risk_category = [];
    }
  }

  checkNotCC(list, id) {
    if (!list || list.length === 0) {
      return false;
    } else {
      for (const iterator of list) {
        if (iterator.id === id) {
          return true;
        }
      }
      return false;
    }

  }

  checkRequired(validStatusList, data) {
    if (data) {
      return false;

    }
    const status = this.riskCase.status;

    if (validStatusList.find(x => x === status)) {
      return true;
    } else {
      return false;

    }
  }

  copyGraceUrl(url) {
    this.clipboard.copy(url);
  }

  goOutsideApp(url) {
    window.open(url, '_blank');


  }

  tooltip(toolTipontant, position) {
    const tooltipData = {
      allowHTML: true,
      delay: [1200, null],
      animation: 'shift-away',
      placement: position,
      theme: 'light',
      content: toolTipontant
    };
    return tooltipData;
  }

  getControl(ctrlId) {
    let obj;
    if (this.controlRisksassociations.find(o => o.ctrl_id === ctrlId)) {
      obj = this.controlRisksassociations.find(o => o.ctrl_id === ctrlId);
    }
    return obj;

  }

  getResult() {

    if (this.riskCase.impact_ratings.find(x => x.ir_impact_id === 18)) {
      return {msg: 'High', validation: true};
    }
    if (this.riskCase.impact_ratings.find(x => x.ir_impact_id === 19)) {
      return {msg: 'Elevated', validation: true};

    }
    if (this.riskCase.impact_ratings.find(x => x.ir_impact_id === 20)) {
      return {msg: 'Moderate', validation: true};

    }
    if (this.riskCase.impact_ratings.find(x => x.ir_impact_id === 21)) {
      return {msg: 'Medium', validation: true};

    }
    if (this.riskCase.impact_ratings.find(x => x.ir_impact_id === 22)) {
      return {msg: 'Low', validation: true};

    }
    return {msg: '', validation: false};


  }

  getNameOfEnum(enumId, enumList) {
    for (const iterator of enumList) {
      if (iterator.id === enumId) {
        return iterator.name;
      }
    }
    return '';

  }

  addActionItem(actionItem) {
    if (!actionItem) {
      this.modalRef.hide();
    }
    this.actionItemData = null;
    const findItem = (element) => element.id === actionItem.id;
    if (actionItem) {
      if (actionItem.id) {
        this.baseService.put('risk-case/' + this.riskCaseId + '/action-item', actionItem, this.stepperService.etag).subscribe(data => {
            this.stepperService.etag = data.headers.get('ETag').replace(/"/g, '');
            this.loadRiskCaseById(this.riskCaseId);
          }, error => {
            console.log(error);
            this.Toast.fire({
              icon: 'error',
              title: `Sorry, action item not updated`,
            });
          },
          () => {
            console.log('update action item put complete ');
            this.riskCase.action_items[this.riskCase.action_items.findIndex(findItem)] = actionItem;
            this.modalRef.hide();
          });
      } else {
        this.baseService.postWithETag('risk-case/' + this.riskCaseId + '/action-item', actionItem, this.stepperService.etag).subscribe(data => {
            this.stepperService.etag = data.headers.get('ETag').replace(/"/g, '');
            actionItem.id = data.body.id;
            actionItem.creator = data.body.creator;
            this.loadRiskCaseById(this.riskCaseId);
          }, error => {
            console.log(error);
            this.Toast.fire({
              icon: 'error',
              title: `Sorry, action item not created`,
            });
          },
          () => {
            console.log('action item creation post complete ');
            this.riskCase.action_items.push(actionItem);
            this.modalRef.hide();
          });
      }
    }

  }

  removeAction(index) {
    const id = this.riskCase.action_items[index].id;
    this.baseService.delete('risk-case/' + this.riskCaseId + '/action-item/' + id, this.stepperService.etag).subscribe(res => {
      this.stepperService.etag = res.headers.get('ETag').replace(/"/g, '');
      this.riskCase.action_items.splice(index, 1);
      this.modalRef.hide();
    }, error => {
      console.log(error);
      this.Toast.fire({
        icon: 'error',
        title: `Sorry, action item not deleted`,
      });
    });
  }

  validationAction(validation) {
    this.showActionValidation = validation;
  }

  typeValidation() {
    const findItem = (element) => element.id === 298;
    if (this.riskCase.type) {
      return false;
    }
    if (this.resultGit.issueIcofrs[0]) {
      if (this.resultGit.issueIcofrs[0].icofr1Name == 'EVM' || this.resultGit.issueIcofrs[0].icofr2Name == 'EVM' || this.resultGit.issueIcofrs[0].icofr1Name == 'US GAAP' || this.resultGit.issueIcofrs[0].icofr2Name == 'US GAAP') {
        return true;
      }
    }
    if (this.resultGit.local_reporting.length > 0 && this.resultGit.local_reporting.findIndex(findItem) < 0) {
      return true;
    }
    return false;

  }

  removeYesAndNoo(textLod) {
    let newtext = '';
    if (textLod.search('Yes') >= 0) {
      newtext = textLod.replace('Yes, s', 'S');

    }
    if (textLod.search('No') >= 0) {
      newtext = textLod.replace('No, i', 'I');

    }

    return newtext;
  }

  overDueValue(overDue) {
    if (overDue) {
      if (overDue.id == 270) {
        return true;
      } else {
        return false;

      }
    }
    return false;

  }

  checkEditEntity(view, status?, notValid?) {
    if (!this.isDesktopDevice) {
      return false;
    }
    if (this.riskCase.status === this.IssueStatus.Cancelled || this.riskCase.status === this.IssueStatus.Completed) {
      return false;
    }

    if (!view) {
      return false;
    }
    if (notValid) {
      return false;

    }
    if (status) {
      if (this.riskCase.status >= status) {
        return false;

      }
    }
    if (view === 'complianceRiskCategory') {
      if (this.showButtonForUserRole(['Comp', 'CORM', 'UM'])) {
        return true;

      } else {
        return false;

      }
    }

    if (view === 'assurComment' || view === 'reOpened') {
      return true;
    }

    if (this.showButtonForUserRole(['NAN']) && !this.showButtonForUser([this.ownerId, this.delegate, this.creator, this.ORM].concat(this.GovernanceCoordinator).concat(this.actionItemAssigneAndDelegate))) {
      return false;

    }
    if (view !== 'complianceRiskCategory' && this.showButtonForUserRole(['Comp'])
      && !this.showButtonForUser([this.ownerId, this.delegate, this.ORM].concat(this.GovernanceCoordinator).concat(this.actionItemAssigneAndDelegate))
      && !this.hasAnyOfRoles(['GC'])) {
      return false;

    }

    if (view === 'Risks&Controls') {
      return true;
    }

    if (this.riskCase.status !== this.IssueStatus.Draft) {
      if (!this.showButtonForUser([this.ownerId, this.delegate, this.ORM].concat(this.GovernanceCoordinator).concat(this.actionItemAssigneAndDelegate)) && !this.showButtonForUserRole(['CORM', 'ORMII', 'ORM', 'GC', 'GIA'])) {
        return false;

      }
    }


    if (!this.showButtonForUser([this.ownerId, this.delegate, this.ORM].concat(this.GovernanceCoordinator).concat(this.actionItemAssigneAndDelegate))
      && !this.hasAnyOfRoles(['GC'])) {

      if (this.showButtonForUserRole(['Comp', 'UM']) && view !== 'complianceRiskCategory' && !this.showButtonForUser([this.creator])) {
        return false;


      }
      if (this.showButtonForUserRole(['Comp']) && !this.showButtonForUser([this.creator]) && (this.riskCase.status === this.IssueStatus.Draft || this.riskCase.status === 7 || this.riskCase.status === 37)) {
        return false;


      }

      if (this.showButtonForUserRole(['GC']) && view !== 'event') {
        return true;


      }

    }
    return true;
  }

  sortBy(list, prop) {
    return list.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
  }

  checkRisksANdRcsaId(listItem, primaryItem) {
    if (!listItem || !primaryItem) {
      return true;
    }
    if (listItem.id != primaryItem.id) {
      return true;

    }
    return false;
  }

  byteCount(textValue, maxlength) {
    if (!textValue) {
      return {valid: true, length: 0};

    }
    if (encodeURI(textValue).split(/%..|./).length - 1 > maxlength) {
      return {valid: false, length: encodeURI(textValue).split(/%..|./).length - 1};

    }
    return {valid: true, length: encodeURI(textValue).split(/%..|./).length - 1};
  }

  checkList(list) {
    if (!list) {
      return false;
    }
    if (list.length == 0) {
      return false;
    }
    return true;
  }

  hideAttachmentDialog() {
    this.modalRef.hide();
    this.baseService.get('risk-case/' + this.riskCaseId + '/attachment').subscribe(res => {
      this.uploadedList = res.body.data;
    });
  }

  private loadControlsAndAddInfoRecipients() {
    this.baseService.get('controls/' + this.stepperService.getNewRisk().mandate.risk_assessment_id).subscribe(res => {
      this.controlList = res.body.data;
    });

    this.baseService.get('additional-info-recipient?riskCaseId=' + this.riskCaseId).subscribe(res => {
      this.additionalInfoRecipients = res.body.data;
    });
  }

}
