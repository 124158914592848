import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BsModalService} from 'ngx-bootstrap/modal';
import {NewRisk} from 'src/app/shared/models/new-risk.model';
import {BaseService, MsalService, StepperService} from '../../../../shared/services';
import {DeviceDetectorService} from 'ngx-device-detector';
import swal from 'sweetalert2';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {

  riskCase: NewRisk;
  title = '';
  routeCaseId;
  caseType;
  isDesktopDevice: boolean;
  Toast = swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 10000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', swal.stopTimer);
      toast.addEventListener('mouseleave', swal.resumeTimer);
    }
  });
  private paramsID: any;

  constructor(private stepperService: StepperService,
              private deviceService: DeviceDetectorService,
              private routeParams: ActivatedRoute,
              private router: Router) {

  }

  ngOnInit(): void {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.router.navigate(['/unsupported-device']);
      return;
    }
    this.epicFunction();
    this.paramsID = this.routeParams.params.subscribe(params => {
      if (params['risk-case-id'] && params['case-type']) {
        if (!isNaN(+params['risk-case-id'])) {
          this.routeCaseId = +params['risk-case-id'];
          this.caseType = params['case-type'];
          this.stepperService.newRisk.riskCaseId = this.routeCaseId;
          console.log('ENTERING SUMMARY - STEPPER:', this.stepperService);
        }
      }
    });
  }

  epicFunction() {
    this.isDesktopDevice = this.deviceService.isDesktop();

  }
}
