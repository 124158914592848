export const environment = {
  baseUrl: 'https://oneorm-dev.gfitdnsdev.swissre.com/',
  prod: false,
  production: false,
  // apiUrl: 'https://sr-inf-one-orm-dev-we-op-events-api-01.azurewebsites.net/',
  apiUrl: 'https://oneorm-dev-api.gfitdnsdev.swissre.com/',
  // apiUrl: 'http://localhost/',
  scope: 'api://3c23d503-3dbb-45f1-b2f7-ea37c4b65293/user_impersonation',
  tqcScope: 'api://4b7a0a99-03da-4426-b790-f5094f3421d0/AllApi',
  dateFormat: 'DD-MM-YYYY',
  clientId: '226e1293-1aa5-4d8a-9123-b27b978b0b1f',
  tenantId: '45597f60-6e37-4be7-acfb-4c9e23b261ea',
  // tenantId: 'organizations',
  redirectUri: 'https://oneorm-dev.gfitdnsdev.swissre.com/',
  updateRCSA: 'https://graceop-ite.swissre.com/grb/app/jspview/flv?start=compliance.objectFolderView.RiskAssessment&target=RiskAssessment&efv=true&ctxtId=75&ctxt=RiskAssessment&prmtId=75&prmt=RiskAssessment',
  requestControlChange: 'https://graceop-ite.swissre.com/grb/services/ccmt/#/dashboard',
  graphUrl: 'https://graph.microsoft.com/beta/',
  viewIssue: true,
  envType: 'DEV',
  graceUrl: 'https://graceop-ite.swissre.com/grb/services/Issue/IssueWorkFlow.jsp?valueOfRCSA=CS&resourceId=',
  functionsEndpoint: 'https://oneorm-dev-func-app-text.azurewebsites.net/',
  textAnalysisEndpoint: 'https://oneorm-dev-func-app-text.azurewebsites.net/api/tqc/',
  rcsaUrl: 'https://oneorm-dev-rcsa.gfitdnsdev.swissre.com/',
  OperationalRiskDashboard: 'https://app.powerbi.com/Redirect?action=OpenApp&appId=2ac9c36d-bdb0-4593-bc17-65871cda74a8&ctid=45597f60-6e37-4be7-acfb-4c9e23b261ea',
  oneOrmUrl: 'https://oneorm-dev.gfitdnsdev.swissre.com/#/',
  azurewebsitesDomainRedirect: {
    enabled: false,
    pattern: /(.*)\\.azurewebsites\\.net/
  },
  faqUrl: 'https://swissre.sharepoint.com/sites/team-GRACE/SitePages/OneORM-RCSA---Frequently-Asked-Questions-%28FAQ%29.aspx',
};
