<h2 class="mb-2">
  <ng-container *ngIf='title'>{{title}}</ng-container>
  <ng-container *ngIf='!title'>Report new {{caseType | titlecase}}</ng-container>
</h2>
<app-selected-rcsa></app-selected-rcsa>
<div class="container row">
    <div class="p-0 h-100 overFlowY-auto stepperContainer mb-4 col-lg-2">
        <ng-container *ngFor="let step of steps; let i = index ">

            <div class="stepTitle" *ngIf='i == 5 && caseType == "event"'>
                <div class="vlup"></div>


            </div>
            <div class="stepTitle" style="height: 0px;" *ngIf='i == 5 && caseType != "event"'>
                <div class="vl"></div>


            </div>

            <div (click)='handleStepChange(i)' class="d-flex justify-content-between  stepContainer" class='stepStyle' [ngClass]="{'before': i <= stepperSelectedIndex , 'after':i > stepperSelectedIndex , 'current':i == selectedStep }">
                <div>
                    {{step.label}}
                </div>
                <div class="stepIcon" [ngStyle]="setMyStyles(i)">
                    <mat-icon *ngIf='i < stepperSelectedIndex && stepCheckData(i+1, step).icon && !checkCurrentUrl(i)'>done</mat-icon>
                    <span *ngIf='i < stepperSelectedIndex && !stepCheckData(i+1, step).icon && !checkCurrentUrl(i)'>{{i+1}}</span>

                    <mat-icon *ngIf='checkCurrentUrl(i)'>edit</mat-icon>

                    <div *ngIf='i >= stepperSelectedIndex && !checkCurrentUrl(i)' class="iconTxt">{{i+1}}</div>
                    <div class="vl" *ngIf='i < 10 && i!==4  &&caseType != "issue"'></div>
                    <div class="vl" *ngIf='i < 6   && caseType == "issue"'></div>

                </div>

            </div>
        </ng-container>



    </div>
    <mat-card class="d-flex h-100 overFlowY-auto justify-content-between col-lg-10">
        <div class="w-100">
            <router-outlet></router-outlet>
        </div>
        <div class="firstStep">
            <!-- <div [routerLink]="['/exit/', stepperSelectedIndex]">
                <mat-icon matTooltip="Save & close current case" [matTooltipPosition]="position">clear</mat-icon>
            </div> -->
            <div>
                <mat-icon matTooltip="Close current case" [matTooltipPosition]="position" (click)="closeCase(stepperSelectedIndex)">clear</mat-icon>
            </div>
            <div id="permalink" *ngIf='stepperSelectedIndex > 2' #permalink>
                <mat-icon (click)="showPermalinkDialog()" matTooltip="Share this case" [matTooltipPosition]="position">exit_to_app</mat-icon>
                <app-permalink-dialog [displayPermalink]="displayPermalink" (hideEvent)="hidePermalinkDialog()"></app-permalink-dialog>
            </div>
            <!-- <div *ngIf='stepperSelectedIndex > 2' routerLink='/summary'>
                <mat-icon matTooltip="Preview Page." [matTooltipPosition]="position">map
                </mat-icon>
            </div> -->
            <div *ngIf='stepperSelectedIndex > 4 && caseType == "event"'>
                <mat-icon matTooltip="Preview Page" [matTooltipPosition]="position" (click)="saveCase(stepperSelectedIndex)">map</mat-icon>
            </div>
        </div>
        <ngx-spinner name="oneOrm" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
            <p style="color: white"> Loading... </p>
        </ngx-spinner>
    </mat-card>

    <app-confirm-dialog [displayConfirmDialog]="displayConfirmDialog" (reloadRiskCase)="reloadRiskCase()"></app-confirm-dialog>
</div>
