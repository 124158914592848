import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UserValidationRoles } from '@shared/models/validation/userValidations';
import { BaseService } from '@shared/services';
import { HighlightTag } from 'angular-text-input-highlight';
import { BaseChartDirective } from 'ng2-charts';
import { environment } from 'src/environments/environment';
import { TextAnalysisResult } from '../../../../../shared/models/textAnalysisResult.model';
import { UserRoleConstants } from '../../../common-constants';
import { Utils } from '../../../utils';

@Component({
  selector: 'app-text-analysis',
  templateUrl: './text-analysis.component.html',
  styleUrls: ['./text-analysis.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TextAnalysisComponent {
  @Input() control: FormControl;

  Utils = Utils;
  UserValidationRoles = UserValidationRoles;
  UserRoleConstants = UserRoleConstants;
  textAnalysisEndpoint = environment.textAnalysisEndpoint;
  analysisResult: TextAnalysisResult;
  doughnutChartLabels = ['Readability'];
  doughnutChartData = [];
  options: any = {
    responsive: true,
    tooltips: {
      enabled: false
    },
    cutoutPercentage: 70,
    aspectRatio: 1,
    maintainAspectRatio:true
  }

  chartColors: any[] = [
    { 
      backgroundColor:["#39D633", "#FFFFFF"] 
    }];

  tags: HighlightTag[] = [];
  isRiskManager;
  isGovernanceCoord;

  @ViewChild(BaseChartDirective, { static: true })
  chartNgWrapper: BaseChartDirective;

  //this is used to draw the percentate score in the middle of the doughnut chart
  plugins = [
    {
      beforeDraw: function (chart: any) {
        let data = chart.data.datasets[0].data[0];
        
        let width = chart.chartArea.left + chart.chartArea.right,
          height = chart.chartArea.top + chart.chartArea.bottom,
          ctx = chart.chart.ctx;
        ctx.restore();
        ctx.font = '30px Roboto, sans-serif';
        ctx.textBaseline = 'middle';
        let text = data+'%',
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  constructor(private baseService: BaseService) {
    this.baseService.get('authorization/roles').subscribe(res => {
      this.isRiskManager = UserValidationRoles.isRiskManager(res.body.data);
      this.isGovernanceCoord = UserValidationRoles.isGovernanceCoord(res.body.data);
  });
   }

  analyze(description){
    console.log('analyzing...');
    let text = description;
    //escapes special chars that breaks json
    console.log("text lenght" +text.length)
    text = text.replace(/\n/gm, "\\n");
    text = text.replace(/['"]+/g, "\\\"");
    console.log("text lenght" +text.length)
    this.baseService.postWithHeaders(this.textAnalysisEndpoint, "{\"text\":\"" + text + "\"}", {"Content-Type":"application/x-www-form-urlencoded"}).subscribe( res => {
        console.log(res);
        this.analysisResult = JSON.parse(JSON.stringify(res));
        const percentage:number = Math.trunc(+this.analysisResult.Readability_score.replace('%', '')) ;
        this.analysisResult.Readability_score = ""+percentage +"%";
        this.doughnutChartLabels = [''];
        this.doughnutChartData = [percentage, 100 - percentage];
        this.addTags(this.analysisResult);
        console.log(JSON.stringify(this.analysisResult));
      },
      errorData => { console.log('error: ' + errorData) }
    );
  }

  addTags(analysisResult: TextAnalysisResult) {
    this.tags = [];
    analysisResult.abbreviations.forEach(abb => {
      this.tags.push({
        indices: {
          start: +abb[1],
          end: +abb[2]
        },
        data: abb[0]
      });
    })

    analysisResult.sensitive_information.forEach(si => {
        this.tags.push({
          indices: {
            start: +si[1][0],
            end: +si[1][1]
          },
          cssClass: 'bg-yellow',
          data: si[0]
        });
      });

    analysisResult.language_errors.forEach(le => {
        // adds lenguage errors just if does not overlap with other tags
        // le.forEach(element => {
          if(!this.tags.some(item => this.overlaps(item, le[1]))){
            this.tags.push({
              indices: {
                start: +le[1][0],
                end: +le[1][1]
              },
              cssClass: 'bg-pink',
              data: le[0]
            });
        }
        // });
        })
  }

  resetTags(){
    this.tags = [];
  }

  indexIsInsideTag(index, tag) {
    return tag.indices.start < index && index < tag.indices.end;
  }
  overlaps(tag, toBeAdded) {
    return (this.indexIsInsideTag(toBeAdded[0], tag) ||
        this.indexIsInsideTag(toBeAdded[1], tag));
  }

  addDarkClass(elm: HTMLElement) {
    if (elm.classList.contains('bg-blue')) {
      elm.classList.add('bg-blue-dark');
    } else if (elm.classList.contains('bg-pink')) {
      elm.classList.add('bg-pink-dark');
    }
  }

  removeDarkClass(elm: HTMLElement) {
    elm.classList.remove('bg-blue-dark');
    elm.classList.remove('bg-pink-dark');
  }

  tooltip(toolTipontant, position){
    const tooltipData = {allowHTML: true,   delay: [1200, null], animation: 'shift-away', placement: position,  theme: 'light', content: toolTipontant };
    return tooltipData;
  }
}
