import { Component } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-left-sidenav',
  templateUrl: './left-sidenav.component.html',
  styleUrls: ['./left-sidenav.component.scss']
})
export class LeftSideNavComponent {
  expanded = false;

  preventExpansion(pane: MatExpansionPanel) {
    pane.toggle();
  }

}
