export class Mandate {
    driver: string;  //'Organisation Unit'
    driver_name: string;  //'Risk Management IT'
    global_function: string; //rcsa global function
    division: string; //rcsa division
    owner_id: string;  //'SRZKRP'
    owner: string;  //'Philipp Krayenbühl'
    risk_assessment_id: number;
    risk_assm_name: string;  //'RA-CCIM'
    risk_assm_desc: string;  //'Risk Management IT'
    risk_manager: string;
    risk_manager_id: string;
    ra_source_id?: number;
    ra_folder?: string;
}
