import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlight'
})

export class HighlightSearch implements PipeTransform {

    transform(value: any, args: string[]): any {
        if (!args || args.every(x => x =='')) {return value;}
        args = args.filter(x=> x !=='');
        var re = new RegExp(args.join('|'),"gi");
        return value.replace(re,"<mark>$&</mark>");
    }
}