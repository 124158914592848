import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';

import {BaseService, StepperService} from '../../../../../shared/services';
import {AdditionalInformation, MonetaryImpact} from '../../../../../shared/models/monetaryImpact';
import {RootCause} from '../../../../../shared/common/textualDescriptions';
import {Router} from '@angular/router';
import {TooltipPosition} from '@angular/material/tooltip';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {dateFormat} from '@shared/common/constants';

@Component({
  selector: 'app-step10-event',
  templateUrl: './step10-event.component.html',
  styleUrls: ['./step10-event.component.scss']
})
export class Step10EventComponent implements OnInit, OnDestroy {
  @Input() viewButton: boolean = true;
  @Input() viewOnly: boolean = false;
  @Input() viewOnlyPdf: boolean = false;

  @Output() newData: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('templateMap') templateMap: TemplateRef<any>;
  monatryImpactItemsValue = 'NAN';
  noMI = {
    amount: 0,
    monetary_impact_type: {
      "id": 212,
      "enum_type": {"id": 12, "name": "Monetary Impact", "description": null},
      "name": "Cash-outs",
      "description": null
    },
    bus_date: new Date(),
    insert_date: new Date(),
    comment: '',
    fine_penalty: 'N',
    captured_recoveries: []
  }
  skipStep = true;
  FieldHelpData;
  viewStatus: string;
  selectedOutsourcing: string;
  modalRef: BsModalRef;
  showAddCashOutBtn = false;
  CaptureRecoveryItem: any;
  RootCause = RootCause;
  today = new Date();
  dateFormat = dateFormat;
  minDate;
  RootCause2 = [];
  manadtaryTooltipText = 'This section mandatory to fill in once you record a cash-out';
  selectedList: Array<MonetaryImpact>;
  position: TooltipPosition = 'above';
  searchValue: string;
  monetaryImpactItem: MonetaryImpact = new MonetaryImpact();
  AdditionalInformationData: AdditionalInformation = new AdditionalInformation();
  riskCaseId: number;
  addNew = true;
  monatryImpactItems = [];
  subscription: Subscription = new Subscription();
  private appData = new BehaviorSubject<any[]>([]);

  constructor(private baseService: BaseService, private stepperService: StepperService,
              private modalService: BsModalService, private router: Router) {
    this.AdditionalInformationData.rootCause1 = '';
    this.viewStatus = 'mainView';
    this.riskCaseId = this.stepperService.newRisk.riskCaseId;
    this.minDate = this.stepperService.newRisk.event ? this.stepperService.newRisk.event.discoveryDate : "";
    this.FieldHelpData = this.stepperService.FieldHelpData;

    this.selectedList = [];
    this.getMI_Items();
    this.baseService.get('enum/items/12').subscribe(res => {
      this.monatryImpactItems = res.body.data;

    });
    this.appData.subscribe((data) => {
    });
  }

  getMI_Items() {
    this.baseService.get('risk-case/' + this.riskCaseId + '/monetary-impacts').subscribe(res => {
      this.selectedList = res.body.data;
    });
  }

  ngOnInit(): void {
    this.subscription.add(this.stepperService.saveCurrentStep.subscribe(() => this.checkBefoerGoToSummary()));

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // }
  checkBefoerGoToSummary() {
    const attr = ['amount'];
    if (this.selectedList.length > 0) {

      for (const iterator of this.selectedList) {
        if (iterator.monetary_impact_type.id === 212) {
          this.goToOVP();

          return true;
        }

        for (const atr of attr) {
          if (!iterator[atr] || iterator[atr] < 1) {
            this.openModal(this.templateMap, true);

            return false;
          }
          if (iterator.captured_recoveries) {
            for (const iter of iterator.captured_recoveries) {

              for (const atrl of attr) {
                if (!iter[atrl] || iter[atrl] < 1) {
                  this.openModal(this.templateMap, true);

                  return false;
                }

              }
              if (new Date(iter.bus_date).getTime() < new Date(this.minDate).getTime() || new Date(iter.bus_date).getTime() > new Date().getTime()) {
                this.openModal(this.templateMap, true);

                return false;

              }
            }

          }
        }
        if (new Date(iterator.bus_date).getTime() < new Date(this.minDate).getTime() || new Date(iterator.bus_date).getTime() > new Date().getTime()) {
          this.openModal(this.templateMap, true);

          return false;

        }
      }
      this.goToOVP();

      return true;


    } else {
      this.goToOVP();
      return;

    }
  }

  goToOVP() {
    const checkList = this.checkValiationOffListItems(this.selectedList, ['amount']);
    let newListToSave = [];
    if (this.selectedList.length > 0) {
      if (checkList) {
        newListToSave = this.selectedList;

      } else {
        this.selectedList.forEach(element => {
          if (element.monetary_impact_type.id !== 91) {
            if (element.amount > 0) {
              newListToSave.push(element);
            }

          } else {
            if (element.amount > 0) {
              if (element.captured_recoveries.length === 0) {
                newListToSave.push(element);

              } else {
                if (element.captured_recoveries[0].amount > 0) {
                  newListToSave.push(element);

                }
              }
            }
          }
        });
      }
      if (newListToSave.length > 0) {
        this.baseService.postWithETag('risk-case/' + this.riskCaseId + '/monetary-impacts', this.fixDate(newListToSave), this.stepperService.etag).subscribe(res => {
          this.stepperService.etag = res.headers.get('ETag').replace(/"/g, '');
          this.stepperService.alreadySaved = false;
          this.router.navigate(['/risk-case/event/' + this.riskCaseId]);
        });
      } else {
        this.stepperService.alreadySaved = false;
        this.router.navigate(['/risk-case/event/' + this.riskCaseId]);
      }

    } else {
      this.baseService.delete('risk-case/' + this.riskCaseId + '/monetary-impacts', this.stepperService.etag).subscribe(res => {
      }, error => {
        console.log(error);
        if (error.status == '409') {
          // display reload dialog
          this.stepperService.rCChanged.next();
        } else {
          this.stepperService.alreadySaved = false;
          this.router.navigate(['/risk-case/event/' + this.riskCaseId]);
        }
      });

    }
  }

  chechEnableRadioBtn(value, array) {

    for (const iterator of array) {
      if (iterator.monetary_impact_type.id === value) {
        return true;
      }
    }
    return false;
  }

  sumCashOut(item) {

    let sumation = 0;
    let captureRecoverySumation = 0;
    if (item.captured_recoveries) {
      item.captured_recoveries.forEach(element => {
        captureRecoverySumation = captureRecoverySumation + element.amount;
      });
    }

    sumation = item.amount - captureRecoverySumation;

    const allData = {
      total: sumation,
      totalCaptureRecovery: captureRecoverySumation,
      totalAmount: item.amount,
    };
    return allData;
  }

  sumAllCashOut(list) {
    let allData = {
      total: 0,
      totalCaptureRecovery: 0,
      totalAmount: 0,
    };
    list.forEach(element => {
      if (element.monetary_impact_type.id === 91) {
        allData.total = allData.total + this.sumCashOut(element).total;
        allData.totalCaptureRecovery = allData.totalCaptureRecovery + this.sumCashOut(element).totalCaptureRecovery;
        allData.totalAmount = allData.totalAmount + this.sumCashOut(element).totalAmount;
      }
    });
    return allData;

  }

  tooltipText() {
    let tooltipText = '';
    if (this.additionalInformationMandatoryFlag()) {
      tooltipText = this.manadtaryTooltipText;
    }
    return tooltipText;
  }

  additionalInformationMandatoryFlag() {
    let flag = false;
    this.selectedList.forEach(element => {
      if (element.monetary_impact_type.id === 91) {
        flag = true;
      }
    });
    if (flag) {
      if (
        this.AdditionalInformationData.function &&
        this.AdditionalInformationData.location &&
        this.AdditionalInformationData.rootCause1 &&
        this.AdditionalInformationData.rootCause2
      ) {
        flag = false;
        return flag;
      }
    }
    return flag;
  }

  showAddCashOutButton(monetaryImpactItem, showAddCashOutBtn) {
    if (monetaryImpactItem && monetaryImpactItem.id === 91 && showAddCashOutBtn) {
      return true;
    } else {
      return false;

    }
  }

  setAdditionalInformationData(value, property) {
    this.AdditionalInformationData[property] = value;
    this.additionalInformationMandatoryFlag();
  }

  setRootCause2(data) {
    this.RootCause2 = data.RootCause2;
    this.AdditionalInformationData.rootCause1 = data;
    this.additionalInformationMandatoryFlag();

  }

  nextStep() {

    this.stepperService.changeIndex(9, null);

  }

  changeViewStatus(status: string) {
    this.viewStatus = status;
  }

  // search
  Search(payLoad: any): void {
    this.searchValue = payLoad;

  }

  addItem(monetaryImpactType) {
    if (monetaryImpactType.name !== 'Cash-out' && this.chechEnableRadioBtn(monetaryImpactType.id, this.selectedList)) {
      return;
    }
    this.showAddCashOutBtn = false;

    const monetaryImpactItem = {
      amount: 0,
      monetary_impact_type: monetaryImpactType,
      bus_date: new Date(),
      insert_date: new Date(),
      comment: '',
      fine_penalty: 'N',
      captured_recoveries: []
    };

    this.selectedList.push(monetaryImpactItem);
    this.stepperService.setmonetaryImpactList(this.selectedList);

    this.monetaryImpactItem = new MonetaryImpact();

  }

  cantEdit(event): boolean {
    return false;
  }

  dateChanged(evt, row) {
    const selectedDate = new Date(evt);
  }

  deletItem(index) {
    this.selectedList.splice(index, 1);
  }

  deletCaptureRecoveryItem(i, index) {
    this.selectedList[i].captured_recoveries.splice(index, 1);

  }

  addCaptureRecovery(i, dialog) {
    if (!this.selectedList[i].captured_recoveries) {
      this.selectedList[i].captured_recoveries = [];
    }
    // tslint:disable-next-line:max-line-length
    this.selectedList[i].captured_recoveries.push({
      monetary_impact_type: {id: 92},
      amount: 0,
      bus_date: new Date(),
      insert_date: new Date(),
      comment: ''
    });
    this.CaptureRecoveryItem = this.selectedList[i].captured_recoveries;

  }


  openModal(template: TemplateRef<any>, skip) {
    this.skipStep = skip;
    const configs = {
      keyboard: true,
      animated: true,
      class: ' modal-lg modal-primary RCSAModal',
      ignoreBackdropClick: false
    };
    this.modalRef = this.modalService.show(template, configs);
  }


  checkValiationOffListItems(list, attr) {
    if (list.length === 0) {

      return false;
    }

    for (const iterator of list) {
      if (iterator.monetary_impact_type.id === 212) {

        return true;
      }

      for (const atr of attr) {
        if (!iterator[atr] || iterator[atr] < 1) {

          return false;
        }
        if (iterator.captured_recoveries) {
          for (const iter of iterator.captured_recoveries) {

            for (const atrl of attr) {
              if (!iter[atrl] || iter[atrl] < 1) {

                return false;
              }

            }
            if (new Date(iter.bus_date).getTime() < new Date(this.minDate).getTime() || new Date(iter.bus_date).getTime() > new Date().getTime()) {

              return false;

            }
          }

        }
      }
      if (new Date(iterator.bus_date).getTime() < new Date(this.minDate).getTime() || new Date(iterator.bus_date).getTime() > new Date().getTime()) {

        return false;

      }
    }

    return true;
  }

  handelClick(list, miItem) {
    if (miItem.id === 91) {
      this.handelCashOut(list, miItem);
    } else {
      this.addItem(miItem);
    }
  }

  handelCashOut(list, item) {
    if (!this.chechEnableRadioBtn(91, list)) {
      this.monetaryImpactItem = new MonetaryImpact();
      this.addItem(item);

    }
    this.showAddCashOutBtn = true;
  }

  saveMI() {
    if (this.selectedList.length > 0) {
      this.baseService.postWithETag('risk-case/' + this.riskCaseId + '/monetary-impacts', this.fixDate(this.selectedList), this.stepperService.etag).subscribe(res => {
        this.stepperService.etag = res.headers.get('ETag').replace(/"/g, '');
        this.stepperService.changeIndex(9, null);
      });
    } else {
      this.baseService.delete('risk-case/' + this.riskCaseId + '/monetary-impacts', this.stepperService.etag).subscribe(res => {
        this.stepperService.etag = res.headers.get('ETag').replace(/"/g, '');
        this.stepperService.changeIndex(9, null);
      }, error => {
        console.log(error);
        if (error.status == '409') {
          //display reload dialog
          this.stepperService.rCChanged.next();
        }
      });
    }
  }

  approveSkip() {
    this.selectedList = [];
    this.saveMI();
    this.modalRef.hide();

  }

  changeData() {
    if (this.selectedList.length > 0) {
      this.baseService.postWithETag('risk-case/' + this.riskCaseId + '/monetary-impacts', this.fixDate(this.selectedList), this.stepperService.etag).subscribe(res => {
        this.stepperService.etag = res.headers.get('ETag').replace(/"/g, '');
        this.newData.emit();

      });
    } else {
      this.baseService.delete('risk-case/' + this.riskCaseId + '/monetary-impacts', this.stepperService.etag).subscribe(res => {
        this.stepperService.etag = res.headers.get('ETag').replace(/"/g, '');
        this.newData.emit();

      }, error => {
        console.log(error);
        if (error.status == '409') {
          //display reload dialog
          this.stepperService.rCChanged.next();
        }
      });


    }

  }

  MIViewList(list, cashFlag) {
    let newList = [];
    list.forEach(element => {
      if (cashFlag && (element.monetary_impact_type.id === 91 || element.monetary_impact_type.id === 212)) {
        newList.push(element);
      }
      if (!cashFlag && element.monetary_impact_type.id !== 91 && element.monetary_impact_type.id !== 212) {
        newList.push(element);
      }
    });
    return newList;
  }


  warningMsg(functionName) {
    this[functionName]();
  }


  haveMI(selectedList) {
    if (selectedList.length === 0) {
      this.monatryImpactItemsValue = 'NAN';
      return 'NAN';
    }
    for (const iterator of selectedList) {
      if (iterator.monetary_impact_type.id === 212) {
        this.monatryImpactItemsValue = 'No';

        return 'No';
      }
    }
    this.monatryImpactItemsValue = 'Yes';

    return 'Yes';

  }


  remveItemsWarning(selectedList, templet, skip) {


    if (selectedList.length === 0) {
      if (skip) {
        this.stepperService.changeIndex(9, null);

      } else {
        this.selectedList = [this.noMI];
      }
    } else {
      this.openModal(templet, skip);
    }


  }

  deleteData() {
    this.selectedList = [this.noMI];
    this.changeData();
    this.modalRef.hide();

  }


  cancelSkip() {
    this.modalRef.hide();
  }

  fixDate(list) {
    let newList = [];
    list.forEach(element => {
      if (element.monetary_impact_type.id !== 212) {
        element.bus_date = new Date(new Date(element.bus_date).setMinutes(new Date(element.bus_date).getMinutes() - new Date(element.bus_date).getTimezoneOffset()));
        element.insert_date = new Date(new Date(element.insert_date).setMinutes(new Date(element.insert_date).getMinutes() - new Date(element.insert_date).getTimezoneOffset()));
        if (element.monetary_impact_type.id === 91) {
          if (element.captured_recoveries.length > 0) {
            element.captured_recoveries[0].bus_date = new Date(new Date(element.captured_recoveries[0].bus_date).setMinutes(new Date(element.captured_recoveries[0].bus_date).getMinutes() - new Date(element.captured_recoveries[0].bus_date).getTimezoneOffset()));
            element.captured_recoveries[0].insert_date = new Date(new Date(element.captured_recoveries[0].insert_date).setMinutes(new Date(element.captured_recoveries[0].insert_date).getMinutes() - new Date(element.captured_recoveries[0].insert_date).getTimezoneOffset()));
          }
        }
        newList.push(element);
      } else {
        newList.push(element);

      }
    });
    return newList;
  }

  tooltip(toolTipontant) {
    const tooltipData = {
      allowHTML: true,
      delay: [1200, null],
      animation: 'shift-away',
      placement: 'right',
      theme: 'light',
      content: toolTipontant
    };
    return tooltipData;

  }

  btnTooltip(id) {
    if (id === 91) {
      const tooltipData = {
        allowHTML: true,
        delay: [1200, null],
        animation: 'shift-away',
        placement: 'bottom',
        theme: 'light',
        content: this.FieldHelpData.MonetaryImpact.Cashout
      };
      return tooltipData;
    }
    if (id === 95) {
      const tooltipData = {
        allowHTML: true,
        delay: [1200, null],
        animation: 'shift-away',
        placement: 'bottom',
        theme: 'light',
        content: this.FieldHelpData.MonetaryImpact.OperationalGains
      };
      return tooltipData;
    }
    if (id === 93) {
      const tooltipData = {
        allowHTML: true,
        delay: [1200, null],
        animation: 'shift-away',
        placement: 'bottom',
        theme: 'light',
        content: this.FieldHelpData.MonetaryImpact.AccountingAdjustments
      };
      return tooltipData;
    }
  }

  byteCount(textValue, maxlength) {
    if (!textValue) {
      return {valid: true, length: 0};

    }
    if (encodeURI(textValue).split(/%..|./).length - 1 > maxlength) {
      return {valid: false, length: encodeURI(textValue).split(/%..|./).length - 1};

    }
    return {valid: true, length: encodeURI(textValue).split(/%..|./).length - 1};
  }


  checkTextList(textList) {
    for (const iterator of textList) {
      if (encodeURI(iterator.comment).split(/%..|./).length - 1 > 265) {
        return true;

      }

    }
    return false;
  }

}
