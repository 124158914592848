import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BaseService, MsalService, StepperService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { RETURN_BACK_TO_RCSA_PARAM } from '@shared/common/constants';

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss']
})
export class TopNavbarComponent implements OnInit {
  environmentType = environment.envType;
  subscription: Subscription = new Subscription();
  call = true;
  @Output() closeRightBar = new EventEmitter();
  @Input() showRightBar: boolean;

  picture;
  constructor(public router: Router,
              public msalService: MsalService,
              public baseService: BaseService,
              public stepperService: StepperService) {
}

  ngOnInit(): void {
    this.subscription.add(this.msalService.getUserData.subscribe(userData => this.getImg(userData)));

  }
  getImg(userData){
    if (userData && this.call){
      this.call = false;
      this.baseService.getGraph('users/' +  userData.user_email_address).subscribe(res => {
        console.log(res);
        this.baseService.getGraphBlob('users/' + res.id + '/photo/$value').subscribe(res => {
          this.picture = res;
      });
  });
    }

  }
  onClick(){
      window.open(environment.rcsaUrl + RETURN_BACK_TO_RCSA_PARAM, '_self');
  }
  openBar(){
    this.closeRightBar.emit(!this.showRightBar);

  }
}
