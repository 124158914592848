import { Component, OnInit, Output, EventEmitter, Input, OnDestroy} from '@angular/core';
import { StepperService } from '../../../../shared/services/stepper.service';
import { NewRisk } from 'src/app/shared/models/new-risk.model';
import { BaseService } from 'src/app/shared/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-step9',
  templateUrl: './step9.component.html',
  styleUrls: ['./step9.component.scss']
})
export class Step9Component implements OnInit, OnDestroy{
  @Input() viewButton: boolean = true;
  @Output() newData: EventEmitter<any> = new EventEmitter<any>();
  changeFlag = false;
  hasOutsourcing = false;
  selectedOutsourcing: number;
  riskCase: NewRisk;
  outsourcingList: any[];
  displaySkipDialog = false;
  subscription: Subscription = new Subscription();
  riskCaseType = 'event';
  firstQuestionnaire;
  secondQuestionnaire;
  thirdQuestionnaire;
  secondQuestionnaireB;
  outsourcingQuestionnaireId = {
    external:0,
    Bratislava:0,
    Bangalore:0,
    no:0,
    Other:0
  }

  constructor(private baseService: BaseService ,
              private stepperService: StepperService) {
    this.riskCase = stepperService.getNewRisk();
    if(this.riskCase.outsourcing){
      this.selectedOutsourcing = this.riskCase.outsourcing;
      this.hasOutsourcing = true;

    }
    
    if(stepperService.newRisk.issue){
      this.riskCaseType = 'issue';
      this.getd(false);

      this.baseService.get('enum/items/37').subscribe(res => {
        this.outsourcingList = res.body.data;
      });
    }
    if(stepperService.newRisk.event){
      this.riskCaseType = 'event';
      this.getd(true);
      this.baseService.get('enum/items/11').subscribe(res => {
        this.outsourcingList = res.body.data;
      });
    }

  }

  ngOnInit(): void {
    this.subscription.add(this.stepperService.saveCurrentStep.subscribe(() =>  this.stepperService.changeIndex(8, this.selectedOutsourcing, 'outsourcing')));
    this.changeFlag = false;

  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  nextStep(data) {
    this.stepperService.changeIndex(8, data, 'outsourcing');
  }
  
  changeData(){
    this.newData.emit({prop: 'outsourcing', propValue: this.selectedOutsourcing});
  }
  outsourse(selectedOutsourcing, list){
    let name = '';
    if(list){
      list.forEach(element => {
        if(element.id === selectedOutsourcing){
          name = element.name;
        }
      });
    }
  
    return name;
  }

  toggleSkipDialog(){
    if(this.selectedOutsourcing){
      this.displaySkipDialog = !this.displaySkipDialog;
    } else {
      this.nextStep(null);
    }
  }

  clearData(){
    this.selectedOutsourcing = null;
    this.nextStep(this.selectedOutsourcing);
  }
  setOutsourcing(id){
    this.changeFlag = true;
    this.selectedOutsourcing = id;

  }
  getd(event){
    if(event){
    this.outsourcingQuestionnaireId = {
      external:90,
      Bratislava:87,
      Bangalore:86,
      no:85,
      Other:89
    }}else{

      this.outsourcingQuestionnaireId = {
        external:304,
        Bratislava:301,
        Bangalore:300,
        no:299,
        Other:303
      }

    }

  }
  canUpdate(){
    if(!this.changeFlag){
      return true;
    }
    if(!this.selectedOutsourcing){
      if(this.hasOutsourcing){
        return false;

      }
      return true;
    }
    if(this.changeFlag){
      return false;
    }

  }
}
