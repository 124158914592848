<div *ngIf='edit' class="containerDiv">
    <p class="t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.legalEntities.What)">
        What legal entities are affected by this event?
    </p>

    <div class="row mb-3 mt-1">
        <div class="col-md-4 ">
            <p-dropdown [options]="managerList" [(ngModel)]="managerFiltered" class="filter-input" appendTo="body" (onChange)="toggleFilterForManager($event.value)" autoWidth="false" [style]="{'width':'100%'}"></p-dropdown>
        </div>
        <div class="searchAnything">
            <app-custom-search [searchValue]="searchByWordString" (value)="searchByTerm($event)" [placeholder]="'Filter by free-text'"></app-custom-search>
        </div>
        <div class="searchCountry" *ngIf="regions">
            <p-autoComplete [(ngModel)]="selectedRegion" [suggestions]="filteredRegions" [dropdown]="true" [inputStyle]="{'width':'100%'}" field="name" [size]="50" placeholder="Filter by Region" [minLength]="2" (completeMethod)="filterByRegion($event)" (onSelect)="selectRegion(selectedRegion)"
                (onClear)="clearRegion()" [forceSelection]="true" [emptyMessage]="'No Region Found'"></p-autoComplete>
        </div>
        <div class="searchCountry" *ngIf="countries">
            <p-autoComplete [(ngModel)]="selectedCountry" [suggestions]="filteredCountries" [dropdown]="true" [inputStyle]="{'width':'100%'}" field="name" [size]="50" placeholder="Filter by Country" [minLength]="2" (completeMethod)="filterByCountry($event)" (onSelect)="selectCountry(selectedCountry)"
                (onClear)="clearCountry()" [forceSelection]="true" [emptyMessage]="'No Country Found'"></p-autoComplete>
        </div>
    </div>

    <div class="d-flex">
        <div class="w-50 pr-2">
            <div class="d-flex justify-content-between align-items-end px-1">
                <label class="label-tree t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.legalEntities.Available)" for="available">Available</label>
                <div class="d-flex justify-content-end t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.legalEntities.RCSA)">
                    <mat-checkbox class="mat-checkbox-small" (click)="$event.stopPropagation()" (change)="toggleFilterRcsa()" [checked]="rcsaFiltered" [value]="rcsaFiltered">filter by RCSA</mat-checkbox>
                </div>
            </div>
            <div class="treeContainer">
                <p-tree id="available" [value]="legalEntities" [(selection)]="checkedNodes" selectionMode="checkbox" [propagateSelectionUp]="false" [propagateSelectionDown]="true" (onNodeUnselect)="updateSelectedNodes();manageNodeParent($event, false)" (onNodeSelect)="updateSelectedNodes();manageNodeParent($event, true)">
                    <ng-template let-node pTemplate="default">
                        <label *ngIf="node.fsa_bp_no" class="d-flex align-item: center" style="margin-bottom: 0;" [innerHTML]="node.name+' ('+node.fsa_bp_no+')' | highlight : [searchByWordString]">{{node.name}} ({{node.fsa_bp_no}}) </label>
                        <label *ngIf="!node.fsa_bp_no" class="d-flex align-item: center" style="margin-bottom: 0;" [innerHTML]="node.name | highlight : [searchByWordString]">{{node.name}} </label>

                    </ng-template>
                </p-tree>
            </div>
        </div>
        <div class="w-50 pl-2">
            <div class="d-flex justify-content-between align-items-end px-1">
                <label class="label-tree t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.legalEntities.Selected)" for="selected">Selected</label>
                <div class="d-flex justify-content-end t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.legalEntities.SelectallRCSA)">
                    <mat-checkbox class="mat-checkbox-small" (click)="$event.stopPropagation()" (change)="toggleSelectRcsa()" [checked]="rcsaSelected" [value]="rcsaSelected">select all by RCSAs</mat-checkbox>
                </div>
            </div>
            <div class="treeContainer">
                <p-tree id="selected" [value]="selectedNodes" [propagateSelectionUp]="false" [propagateSelectionDown]="true" class="right-tree">
                    <ng-template let-node pTemplate="default">
                        <label *ngIf="node.fsa_bp_no" class="d-flex align-item: center" style="margin-bottom: 0;">{{node.name}} ({{node.fsa_bp_no}})                 <span *ngIf='node.legal_entity_status == "In-active" || node.legal_entity_status == "Historic" || node.legal_entity_status == "Discarded"'>(Historic)</span>
                        </label>
                        <label *ngIf="!node.fsa_bp_no" class="d-flex align-item: center" style="margin-bottom: 0;">{{node.name}}                 <span *ngIf='node.legal_entity_status == "In-active" || node.legal_entity_status == "Historic" || node.legal_entity_status == "Discarded"'>(Historic)</span>
                        </label>
                    </ng-template>
                </p-tree>
            </div>
        </div>
    </div>

    <div *ngIf='viewButton' class="row justify-content-end mb-5 w-100 mx-0 mt-2">






        <button class='primaryBtn mr-4 buttonWidth' pButton type="button" label="Deselect All" (click)="deselectAll()"></button>

        <button class='primaryBtn mr-4 buttonWidth' pButton type="button" label="Reload last Save" (click)="reset()"></button>


        <button class='primaryBtn mr-4 buttonWidth' pButton type="button" label="Skip" (click)="toggleSkipDialog()"></button>


        <button class='primaryBtn  buttonWidth' pButton type="button" label="Proceed " (click)="nextStep(checkedNodes)" [disabled]="checkedNodes.length == 0"></button>


        <app-information-dialog [accordionIndex]='6'></app-information-dialog>
    </div>
    <app-skip-dialog [displaySkipDialog]="displaySkipDialog" (hideDialog)="toggleSkipDialog()" (clearData)="clearData();"></app-skip-dialog>
</div>




<app-view-le [pdfView]='pdfView' [edit]='edit' [savedLE]='savedLE' [legalEntitiesAllStatus]='legalEntitiesAllStatus' [checkedNodes]='checkedNodes'></app-view-le>
