<div class="containerDiv">
    <div class="w-100" *ngIf="viewStatus === 'mainView'">
        <p>
            Please review and adapt roles if necessary:

        </p>

        <div class="row my-4">
            <div class='col-md-2 t-demo__btn' ngxTippy [tippyProps]="tooltip(FieldHelpData.IssueCreator)"> Issue Creator

            </div>
            <div class='col-md-8 ' *ngIf="loggedUser">
                <div style="width: max-content;">

                    <ng-container *ngIf="issueFromEventObject">
                        {{issueFromEventObject.creator.user_name}}
                    </ng-container>
                    <ng-container *ngIf="!issueFromEvent">
                        {{riskCase.creator.user_name}}
                    </ng-container>



                    <span class="readOnly">(read only)</span>

                </div>


            </div>
        </div>


        <div class="row my-4">
            <div class='col-md-2  t-demo__btn' ngxTippy [tippyProps]="tooltip(FieldHelpData.IssueOwner)"> Issue Owner

            </div>
            <div class='col-md-8 d-flex align-items-center'>
                <p-autoComplete [(ngModel)]="roles.eventOwner" [suggestions]="filterUsersSingleList" (completeMethod)="filterUsersSingle($event)" [field]="composeName" [size]="50" placeholder="Name of SR Employee (Mandatory)" [minLength]="1" [forceSelection]="true" emptyMessage="{{filterUsersSingleList && filterUsersSingleList.length >0 ? '' : 'User Not Found'}}">
                    <ng-template let-user pTemplate="item">
                        <div>
                            {{user.user_name}} ({{user.user_id}})
                        </div>
                    </ng-template>
                </p-autoComplete>

            </div>
        </div>


        <div class="row my-4">
            <div class='col-md-2  t-demo__btn' ngxTippy [tippyProps]="tooltip(FieldHelpData.IssueDelegate)"> Issue Delegate

            </div>
            <div class='col-md-8 '>
                <p-autoComplete [(ngModel)]="roles.eventDelegate" [suggestions]="filterUsersSingleList" (completeMethod)="filterUsersSingle($event)" [field]="composeName" [size]="50" placeholder="Name of SR Employee (Optional)" [minLength]="1" [forceSelection]="true"
                    emptyMessage="{{filterUsersSingleList && filterUsersSingleList.length >0 ? '' : 'User Not Found'}}">
                    <ng-template let-user pTemplate="item">
                        <div>
                            {{user.user_name}} ({{user.user_id}})
                        </div>
                    </ng-template>
                </p-autoComplete>
            </div>
        </div>


        <div class="row my-4">
            <div class='col-md-2  t-demo__btn' ngxTippy [tippyProps]="tooltip(FieldHelpData.Roles.GovernanceCoordinator)"> Governance Coordinator(s)
            </div>
            <div class='col-md-8 d-flex justify-content-start  align-items-center'>

                <div style="width: max-content;">


                    <label class="mr-1" *ngFor='let coord of roles.governanceCoordinators; index as i'>

                        {{coord.contact_name}}{{roles.governanceCoordinators.length > 1 && i < roles.governanceCoordinators.length-1 ? ',' : ' '}}
                    </label>
                    <span class="readOnly">(read only)</span>


                </div>






            </div>
        </div>
        <div class="row my-4">
            <div class='col-md-2  t-demo__btn' ngxTippy [tippyProps]="tooltip(FieldHelpData.Roles.RiskManager)"> Risk Manager
            </div>
            <div class='col-md-8 ' *ngIf="roles.riskManager">

                <div style="width: max-content;">
                    {{roles.riskManager.user_name}}
                    <span class="readOnly">(read only)</span>


                </div>











            </div>
        </div>

        <div class="d-flex justify-content-end mb-5 w-100">
            <div class='d-flex'>


                <div [matTooltip]="checkValidation().msg" [matTooltipPosition]="'above'">
                    <button *ngIf="!issueFromEvent" class='primaryBtn mr-4 buttonWidth' pButton type="button" label="Discard Issue" [routerLink]="['/discard']" [disabled]="riskCase.status!==RiskCaseConstants.Issue_DRAFT_TECHNICAL"></button>

                    <button class='primaryBtn  buttonWidth' pButton type="button" label="Proceed" [disabled]="checkValidation().disabled" (click)="nextStep()"></button>

                </div>

                <app-information-dialog [issue]="true" [accordionIndex]='5'></app-information-dialog>
            </div>


        </div>
    </div>
</div>
