import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NewRiskContainerComponent } from './components/new-risk-container/new-risk-container.component';
import { Step1Component } from './components/step1/step1.component';
import { Step2Component } from './components/step2/step2.component';
import { Step3Component } from './components/step3/step3.component';
import { Step4Component } from './components/step4/step4.component';
import { Step5Component } from './components/step5/step5.component';
import { Step6Component } from './components/step6/step6.component';
import { Step7Component } from './components/step7/step7.component';
import { Step8Component } from './components/step8/step8.component';
import { Step9Component } from './components/step9/step9.component';
import { Step10Component } from './components/step10/step10.component';
import { Step11Component } from './components/step11/step11.component';
import { AdditionalInformationStepComponent } from './components/step10/additional-information-step/additional-information-step.component';

const routes: Routes = [
  { path: 'load/:id', component: NewRiskContainerComponent },

  {
    path: '',
    component: NewRiskContainerComponent,
    children: [
      { path: 'mandate', component: Step1Component },
      { path: 'mandate/:rcsaId', component: Step1Component },
      {
        path: '',
        redirectTo: 'mandate',
        pathMatch: 'full',
      },
      { path: 'event', component: Step2Component },
      { path: 'description', component: Step3Component },
      { path: 'risk', component: Step4Component },
      { path: 'identification', component: Step4Component },
      { path: 'defineroles', component: Step5Component },
      { path: 'riskAndControl', component: Step5Component },
      { path: 'draftcreated', component: Step6Component },
      { path: 'businessimpact', component: Step7Component },
      { path: 'roles', component: Step7Component },
      { path: 'legalentities', component: Step8Component },
      { path: 'outsourcing', component: Step9Component },
      { path: 'monetaryimpact', component: Step10Component },
      { path: 'additionalInformation', component: AdditionalInformationStepComponent },
      { path: 'go-live', component: Step11Component },
    ]
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '',
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class NewRiskRoutingModule { }
