import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'src/app/material/material.module';
import { PrimengModule } from 'src/app/primeng/primeng.module';
import { NewRiskContainerComponent } from './components/new-risk-container/new-risk-container.component';
import { Step1Component } from './components/step1/step1.component';
import { Step2Component } from './components/step2/step2.component';
import { NewRiskRoutingModule } from './new-risk-routing.module';
import { Step3Component } from './components/step3/step3.component';
import { Step4Component } from './components/step4/step4.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Step5Component } from './components/step5/step5.component';
import { Step6Component } from './components/step6/step6.component';
import { Step7Component } from './components/step7/step7.component';
import { Step8Component } from './components/step8/step8.component';
import { Step9Component } from './components/step9/step9.component';
import { Step10Component } from './components/step10/step10.component';
import { Step11Component } from './components/step11/step11.component';
import { SummaryComponent } from './components/summary/summary.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SharedModule } from '../../shared/shared.module';
import { PermalinkDialogComponent } from './components/permalink-dialog/permalink-dialog.component';
import { CheckBoxItemsComponent } from './components/step7/check-box-items/check-box-items.component';
import { ExitComponent } from './components/exit/exit.component';
import { DiscardComponent } from './components/discard/discard.component';
import { AdditionalInformationListComponent } from './components/step10/additional-information-list/additional-information-list.component';
import { AdditionalInformationStepComponent } from './components/step10/additional-information-step/additional-information-step.component';
import { StatusBarComponent } from './components/summary/summary-event/status-bar/status-bar.component';
import { GeneratePdfComponent } from './components/summary/summary-event/generate-pdf/generate-pdf.component';
import { EnumItemSelectionComponent } from './components/summary/enum-item-selection/enum-item-selection.component';
import { SkipDialogComponent } from './components/skip-dialog/skip-dialog.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { Step3EventComponent } from './components/step3/step3-event/step3-event.component';
import { Step3IssueComponent } from './components/step3/step3-issue/step3-issue.component';
import { Step4EventComponent } from './components/step4/step4-event/step4-event.component';
import { Step4IssueComponent } from './components/step4/step4-issue/step4-issue.component';
import { Step5IssueComponent } from './components/step5/step5-issue/step5-issue.component';
import { Step5EventComponent } from './components/step5/step5-event/step5-event.component';
import { Step6EventComponent } from './components/step6/step6-event/step6-event.component';
import { Step6IssueComponent } from './components/step6/step6-issue/step6-issue.component';
import { Step7IssueComponent } from './components/step7/step7-issue/step7-issue.component';
import { Step7EventComponent } from './components/step7/step7-event/step7-event.component';
import { SummaryEventComponent } from './components/summary/summary-event/summary-event.component';
import { SummaryIssueComponent } from './components/summary/summary-issue/summary-issue.component';
import { IssueStatusBarComponent } from './components/summary/summary-issue/issue-status-bar/issue-status-bar.component';
import { IssuePdfComponent } from './components/summary/summary-issue/issue-pdf/issue-pdf.component';
import { Step10IssueComponent } from './components/step10/step10-issue/step10-issue.component';
import { Step10EventComponent } from './components/step10/step10-event/step10-event.component';
import { RiskImpactComponent } from './components/summary/summary-issue/risk-impact/risk-impact.component';
import { ActionItemComponent } from './components/summary/summary-issue/action-item/action-item.component';
import { ViewLEComponent } from './components/step8/view-le/view-le.component';
import { AttachmentDialogComponent } from './components/attachment-dialog-component/attachment-dialog-component';
import {FileUploadModule} from "primeng/fileupload";
import { IssueFromEventComponent } from './components/summary/summary-event/status-bar/issue-from-event/issue-from-event.component';
import { ChartsModule } from 'ng2-charts';
import { TextInputHighlightModule } from 'angular-text-input-highlight';
import { TextAnalysisComponent } from './components/step3/text-analysis/text-analysis.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SelectedRcsaComponent } from './components/selected-rcsa/selected-rcsa.component';
import {MessageModule} from 'primeng';
import { UnsupportedDeviceComponent } from './components/unsupported-device/unsupported-device.component';

@NgModule({
  declarations: [NewRiskContainerComponent, Step1Component, Step2Component,
                 Step3Component, Step4Component, Step5Component, Step6Component, Step7Component,
                 Step8Component, Step9Component, Step10Component, Step11Component,
                 SummaryComponent, PermalinkDialogComponent, CheckBoxItemsComponent, ExitComponent, DiscardComponent,
                 AdditionalInformationListComponent, SkipDialogComponent,
                 AdditionalInformationStepComponent,
                 StatusBarComponent,
                 GeneratePdfComponent,
                 EnumItemSelectionComponent,
                 ConfirmDialogComponent,
                 Step3EventComponent,
                 Step3IssueComponent,
                 Step4EventComponent,
                 Step4IssueComponent,
                 Step5IssueComponent,
                 Step5EventComponent,
                 Step6EventComponent,
                 Step6IssueComponent,
                 Step7IssueComponent,
                 Step7EventComponent,
                 SummaryEventComponent,
                 SummaryIssueComponent,
                 IssueStatusBarComponent,
                 IssuePdfComponent,
                 Step10IssueComponent,
                 Step10EventComponent,
                 RiskImpactComponent,
                 ActionItemComponent,
                 ViewLEComponent,
                 AttachmentDialogComponent,
                 IssueFromEventComponent,
                 TextAnalysisComponent, SelectedRcsaComponent, UnsupportedDeviceComponent],

  imports: [
    CommonModule,
    RouterModule, ModalModule.forRoot(),
    NewRiskRoutingModule,
    PrimengModule, SharedModule,
    MaterialModule, FormsModule, ReactiveFormsModule,
    FlexLayoutModule, FileUploadModule, ChartsModule, TextInputHighlightModule, MessageModule
  ],
  providers: [
    DeviceDetectorService
  ]
})
export class NewRiskModule { }
