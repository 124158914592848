<span (click)="openModal()" class="material-icons  informationIcon">
    info
</span>



<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title m-0">{{infoTitle}}</h4>
        <span (click)="modalRef.hide()" class="material-icons informationIcon">
            close
        </span>


    </div>
    <div class="modal-body">
        <mat-accordion class="fix-radio-position" hideToggle="true" [multi]="openmulti">
            <mat-expansion-panel *ngFor='let item of informationLists; let i = index' [expanded]="i === accordionIndex || i === secaccordionIndex">
                <mat-expansion-panel-header>
                    <mat-panel-description class='d-flex' style="align-items: center;">
                        {{item.title}}

                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div [innerHTML]="item.information">

                </div>


            </mat-expansion-panel>

        </mat-accordion>
    </div>
</ng-template>