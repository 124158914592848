import { Component, OnDestroy, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { Issue } from 'src/app/shared/models/issue.model';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { StepperService, BaseService } from '../../../../../shared/services';
import { Utils } from '../../../utils';
import { dateFormat } from '@shared/common/constants';

@Component({
  selector: 'app-step3-issue',
  templateUrl: './step3-issue.component.html',
  styleUrls: ['./step3-issue.component.scss']
})
export class Step3IssueComponent implements OnInit, OnDestroy {
  @Input() issueFromEvent: boolean = false;
  @Output() newIssueData: EventEmitter<any> = new EventEmitter<any>();

  viewStatus: string;
  issue = new Issue();
  today = new Date();
  showError = false;
  dateFormat = dateFormat;
  FieldHelpData;
  form: FormGroup;
  subscription: Subscription = new Subscription();
  MIList = [];
  Utils = Utils;
  constructor(builder: FormBuilder, private stepperService: StepperService, private baseService: BaseService) {
    this.viewStatus = 'mainView';
    this.FieldHelpData = this.stepperService.FieldHelpData;
    this.form = builder.group({
      title: new FormControl(null, [Validators.required]),
      description: new FormControl(null, [Validators.required]),
    });
    this.form.valueChanges.subscribe(value => {
    });
    const newRisk = stepperService.getNewRisk();
    // to be fixed
    if (newRisk.issue && newRisk.issue.title !== undefined && !this.issueFromEvent) {
      this.issue = newRisk.issue;
      const controls = this.form.controls;
      Object.keys(controls).forEach(key => {
        if (this.issue[key] != null) {
          this.form.get(key).setValue(this.issue[key]);
        }
      });
    }
  }
  checkDate() {
    this.showError = false;
    for (const iterator of this.MIList) {
      if (new Date(iterator.bus_date).getTime() < new Date(this.form.value.discoveryDate).getTime()) {
        this.showError = true;
        return;
      }
    }
  }
  ngOnInit(): void {
    this.subscription.add(this.stepperService.saveCurrentStep.subscribe(() => this.stepperService.changeIndex(3, this.form.value, 'issue')));
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  nextStep() {
    this.subscription.unsubscribe();
    if(this.issueFromEvent){
      this.newIssueData.emit({index:3,listOfData:[{attr:'issue',issueData:this.form.value}]})
    }else{
      this.stepperService.changeIndex(3, this.form.value, 'issue');
    }
  }
  cantEdit(issue): boolean {
    return false;
  }
  dateChanged(evt, row) {
    const selectedDate = new Date(evt);
    if (selectedDate.getTimezoneOffset() < 0) {
      selectedDate.setMinutes(selectedDate.getMinutes() - selectedDate.getTimezoneOffset());
    }
    this.form.get(row).setValue(selectedDate);
    if (this.MIList.length > 0 && this.form.value.discoveryDate) {
      this.checkDate();
    }
  }
  canGo() {
    if (this.form.status === "VALID") {
      return false;
    }
    return true;
  }
  tooltip(toolTipontant) {
    const tooltipData = { allowHTML: true, delay: [1200, null], animation: 'shift-away', placement: 'right', theme: 'light', content: toolTipontant };
    return tooltipData;
  }
}
