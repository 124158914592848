<div class="d-flex justify-content-between">


    <div class="mdl-card mdl-shadow--2dp">

        <div class="mdl-card__supporting-text">

            <div class="mdl-stepper-horizontal-alternative">
                <div *ngFor='let status of statusList; let i = index' class="mdl-stepper-step" [ngClass]="{'active-step step-done': (status.order < getStatusOrder(statusOrder)  && getStatusOrder(statusOrder)!=38) ,  'active-step editable-step':status.order == getStatusOrder(statusOrder) }">
                    <div class="mdl-stepper-circle"><span>{{i + 1}}</span></div>
                    <div class="mdl-stepper-title">{{status.name}}</div>
                    <div *ngIf="status.id != IssueStatus.Cancelled" class="mdl-stepper-bar-left"></div>
                    <div *ngIf="status.id == IssueStatus.Cancelled" class="mdl-stepper-bar-left  mdl-stepper-bar-right-dashed"></div>
                    <div *ngIf="status.id != IssueStatus.Completed" class="mdl-stepper-bar-right"></div>
                    <div *ngIf="status.id == IssueStatus.Completed" class="mdl-stepper-bar-right mdl-stepper-bar-right-dashed"></div>
                </div>


            </div>

        </div>

    </div>






    <!-- new blue arrow -->
    <ng-container *ngIf='(!this.showButtonForUserRole([ "NAN"]) || this.showButtonForUser([this.ownerId, rcsaOwner,creator, this.delegate, this.ORM].concat(this.GovernanceCoordinator).concat(this.actionItemAssigneAndDelegate)))&& isDesktopDevice'>

        <ng-container *ngIf='actionButtonText().show'>
            <img src="./assets/images/summary/actionbutton.png" class=" t-demo__btn  actionIcon" *ngIf="!existCheckFn(this.statusOrder,[38,IssueStatus.Completed,IssueStatus.Cancelled]) && !showMissing" ngxTippy [tippyProps]="tooltip(actionButtonText().msg , 'top')"
                (click)='checkValidation(riskCaseAllField,riskCase,statusOrder)' style="     z-index: 999;   height: 100%;   width: 150px;" alt="">
            <img src="./assets/images/summary/actionbuttondis.png" class=" t-demo__btn  actionIcon" *ngIf="!existCheckFn(this.statusOrder,[38,IssueStatus.Completed,IssueStatus.Cancelled]) && showMissing" ngxTippy [tippyProps]="tooltip(actionButtonText().msg , 'top')"
                (click)='showMissing=false;showActionForUsers=false' style="     z-index: 999;   height: 100%;   width: 150px;" alt="">

        </ng-container>




    </ng-container>


    <!-- old blue arrow -->


</div>
<div class="row">
    <div *ngIf='statusHistory.length > 0 && lastStatusChange.rcsc_status_after_id == IssueStatus.OpenDueDateChangePending' class="col-md-4">

        <div class="contantStyle">





            Proposed New Due Date (CET)


        </div>

        <div>
            {{ showDateSafari(riskCase.proposed_due_date) | oneOrmDate}}
        </div>

    </div>


    <div *ngIf='statusHistory.length > 0' class="col-md-4">

        <div class="contantStyle">

            <ng-container *ngIf='(lastStatusChange.rcsc_status_after_id == 24 && lastStatusChange.rcsc_status_id == 23 ) || (lastStatusChange.rcsc_status_after_id == 27)'>
                Submission Comment

            </ng-container>
            <ng-container *ngIf='(lastStatusChange.rcsc_status_after_id == 25 && lastStatusChange.rcsc_status_id < 25) || (  lastStatusChange.rcsc_status_id != 23 && lastStatusChange.rcsc_status_after_id == 24)  || ( lastStatusChange.rcsc_status_after_id == 23)'>
                Risk Manager Comment

            </ng-container>


            <ng-container *ngIf='(lastStatusChange.rcsc_status_after_id == 25 && lastStatusChange.rcsc_status_id > 25) || ( lastStatusChange.rcsc_status_after_id == 29)'>
                RCSA Owner Comment

            </ng-container>

            <ng-container *ngIf='lastStatusChange.rcsc_status_after_id == IssueStatus.OpenDueDateChangePending  '>
                Due Date Change Rationale

            </ng-container>
            <ng-container *ngIf='lastStatusChange.rcsc_status_after_id == 30 '>
                Closure Comment

            </ng-container>
            <ng-container *ngIf='lastStatusChange.rcsc_status_after_id == 36 '>
                Cancellation Comment

            </ng-container>
        </div>
        <div>
            {{lastStatusChange.rcsc_action_comment}}
        </div>
        <div>
            {{lastStatusChange.actor_name}}
        </div>
        <div>
            {{ showDateSafari(lastStatusChange.rcsc_action_date) | oneOrmDate}}
        </div>

    </div>

</div>

<div class='row' *ngIf='showMissing && statusOrder!=37 && statusOrder != IssueStatus.Cancelled && statusOrder != IssueStatus.Completed'>
    <div class='col-md-6'>
        <mat-accordion class="fix-radio-position " hideToggle="true">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-description class='d-flex' style="align-items: center;">

                        <div class="d-flex justify-content-between w-100">
                            <div [ngClass]="(requeredList.length>0)?'required':''">
                                Missing/Invalid <span class='noOfListItem'> ({{requeredList.length}})</span> Required Fields

                            </div>
                        </div>
                    </mat-panel-description>
                </mat-expansion-panel-header>


                <div class="modal-body row">

                    <ul class="mb-auto dUL bd-highlight requeredItemList">
                        <li *ngFor='let requeredItem of requeredList' (click)='openPopUp(requeredItem)'>
                            <ng-container *ngIf="requeredItem.title == 'RCSA'">
                                {{requeredItem.title}} : Contact your Op Risk Manager ({{riskCase.risk_manager.user_name}}) to correct
                            </ng-container>
                            <ng-container *ngIf="requeredItem.title != 'RCSA'">
                                {{requeredItem.title}}
                            </ng-container>
                        </li>

                    </ul>



                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class='col-md-6'>
        <mat-accordion class="fix-radio-position " hideToggle="true">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-description class='d-flex' style="align-items: center;">

                        <div class="d-flex justify-content-between w-100">
                            <div>
                                Missing <span class='noOfListItem'> ({{optionalList.length}})</span> Optional Fields

                            </div>
                        </div>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="modal-body row">


                    <ul class="mb-auto dUL bd-highlight optionalItemList">
                        <li *ngFor='let optionalItem of optionalList' (click)='openPopUp(optionalItem)'>
                            {{optionalItem.title}}
                        </li>

                    </ul>


                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>









    <div *ngIf='statusOrder==IssueStatus.Draft' class="col-md-12 d-flex justify-content-end mt-2">




        <ng-container *ngIf='!showButtonForUser([manager]) && !showButtonForUserRole(["CORM","ORM","ORMII","GIA"])'>



            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Sends a request to your op risk manager for review and approval' , 'top')">

                <button *ngIf='showButtonForUser([ ownerId,delegate])' class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" [label]="'Submit Issue for Review'" [disabled]='requeredList.length>0' (click)=' openCommentPopup(ActionMsg.issue.SubmitDraft,"SubmitDraft",true,commentAndAction)'></button>
            </div>

            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Cancels this issue and informs all stakeholders' , 'top')">

                <button *ngIf='showButtonForUser([ rcsaOwner,creator,ownerId,delegate,ORM]) || showButtonForUserRole(["CORM","ORM","ORMII","GIA","GC"])' class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" label="Cancel Issue" (click)='openCommentPopup(ActionMsg.issue.CancelDraft,"CancelDraft",true,commentAndAction)'></button>
            </div>
        </ng-container>

        <ng-container *ngIf='(showButtonForUser([manager]) || showButtonForUserRole(["CORM","ORM","ORMII","GIA"])) && lodId==215'>

            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Opens this issue and informs all stakeholders' , 'top')">

                <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" [label]="'Approve Issue '" [disabled]='requeredList.length>0 ' (click)="openCommentPopup(ActionMsg.issue.ApproveIssue,'ApproveDraft',false,commentAndAction)"></button>
            </div>

            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Cancels this issue and informs all stakeholders' , 'top')">

                <button *ngIf='showButtonForUser([ rcsaOwner,creator,ownerId,delegate,ORM].concat(GovernanceCoordinator)) || showButtonForUserRole(["CORM","ORM","ORMII","GC","GIA"])' class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" label="Cancel Issue"
                    (click)='openCommentPopup(ActionMsg.issue.CancelDraft,"CancelDraft",true,commentAndAction)'></button>
            </div>
        </ng-container>
        <ng-container *ngIf='(showButtonForUser([manager]) || showButtonForUserRole(["CORM","ORM","ORMII"])) && lodId!=215'>

            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Opens this issue and informs all stakeholders' , 'top')">

                <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" [label]="'Approve Issue '" [disabled]='requeredList.length>0 ' (click)="openCommentPopup(ActionMsg.issue.ApproveIssue,'ApproveDraft',false,commentAndAction)"></button>

            </div>
            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Cancels this issue and informs all stakeholders' , 'top')">

                <button *ngIf='showButtonForUser([ rcsaOwner,creator,ownerId,delegate,ORM].concat(GovernanceCoordinator)) || showButtonForUserRole(["CORM","ORM","ORMII","GC","GIA"])' class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" label="Cancel Issue"
                    (click)='openCommentPopup(ActionMsg.issue.CancelDraft,"CancelDraft",true,commentAndAction)'></button>
            </div>
        </ng-container>


    </div>









    <div *ngIf='statusOrder==IssueStatus.DraftPendingReview' class="col-md-12 d-flex justify-content-end mt-2 ">



        <ng-container *ngIf='!showButtonForUser([manager]) &&  !showButtonForUserRole(["CORM","ORM","ORMII","GIA"]) '>


        </ng-container>

        <ng-container *ngIf='(showButtonForUser([manager]) ||  showButtonForUserRole(["CORM","ORM","ORMII","GIA"])) && lodId==215'>

            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Opens this issue and informs all stakeholders' , 'top')">

                <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" [label]="'Approve Issue'" [disabled]='requeredList.length>0 ' (click)="openCommentPopup(ActionMsg.issue.ApproveIssueR,'ApproveDraftP',false,commentAndAction)"></button>
            </div>

            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Rejects the request, changes the status back to draft and informs the requestor that further updates need to be made' , 'top')">

                <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" label="Reject Request " (click)="openCommentPopup(ActionMsg.issue.RejectIssue,'RejectIssue',true,commentAndAction)"></button>


            </div>

        </ng-container>

        <ng-container *ngIf='(showButtonForUser([manager]) ||  showButtonForUserRole(["CORM","ORM","ORMII"])) && lodId!=215'>


            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Opens this issue and informs all stakeholders' , 'top')">

                <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" [label]="'Approve Issue'" [disabled]='requeredList.length>0 ' (click)="openCommentPopup(ActionMsg.issue.ApproveIssueR,'ApproveDraftP',false,commentAndAction)"></button>
            </div>
            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Rejects the request, changes the status back to draft and informs the requestor that further updates need to be made' , 'top')">


                <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" label="Reject Request " (click)="openCommentPopup(ActionMsg.issue.RejectIssue,'RejectIssue',true,commentAndAction)"></button>
            </div>


        </ng-container>
        <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Cancels this issue and informs all stakeholders' , 'top')">

            <button *ngIf='showButtonForUser([ rcsaOwner,creator,ownerId,delegate,ORM].concat(GovernanceCoordinator)) || showButtonForUserRole(["CORM","ORM","ORMII","GC","GIA"])' class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" label="Cancel Issue"
                (click)='openCommentPopup(ActionMsg.issue.CancelDraft,"CancelDraft",true,commentAndAction)'></button>
        </div>

    </div>


    <div *ngIf='statusOrder==IssueStatus.Open' class="col-md-12 d-flex justify-content-end mt-2 ">

        <ng-container *ngIf='showButtonForUser([ ownerId,delegate])|| (showButtonForUserRole(["GIA"]) &&  lodId==215 )'>

            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Send completion request to RCSA Owner for review and approval' , 'top')">

                <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" [label]="'Submit Issue for Completion Approval'" [disabled]='requeredList.length>0 ' (click)="openCommentPopup(ActionMsg.issue.SubmitIssueforCompletion,'SubmitIssueforCompletion',true,commentAndAction)"></button>
            </div>


            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Send due date change request to RCSA Owner for review and approval' , 'top')">

                <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" label="Request Due Date Change Approval" (click)='openModal(DueDateChange)'></button>
            </div>

        </ng-container>





    </div>
    <div *ngIf='statusOrder==IssueStatus.OpenDueDateChangePending' class="col-md-12 d-flex justify-content-end mt-2 ">

        <ng-container *ngIf='showButtonForUser([rcsaOwner]) '>



            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Extends the due date and informs all stakeholders' , 'top')">

                <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" label="Approve Due Date Change" [disabled]='requeredList.length>0' (click)="openCommentPopup(ActionMsg.issue.ApproveDueDateChange,'ApproveDueDateChange',false,commentAndAction)"></button>                </div>

            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Rejects the request, changes the status back to open and informs the requestor to work towards completing the issue by the last agreed issue due date' , 'top')">

                <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" label="Reject Due Date Change" (click)="openCommentPopup(ActionMsg.issue.RejectDueDateChange,'RejectDueDateChange',true,commentAndAction)"></button>
            </div>
        </ng-container>
    </div>






    <div *ngIf='statusOrder==IssueStatus.OpenDueDateExtended' class="col-md-12 d-flex justify-content-end mt-2 ">

        <ng-container *ngIf='showButtonForUser([ ownerId,delegate])|| (showButtonForUserRole(["GIA"]) &&  lodId==215 )'>

            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Send completion request to RCSA Owner for review and approval' , 'top')">

                <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" [label]="'Submit Issue for Completion Approval'" [disabled]='requeredList.length>0' (click)="openCommentPopup(ActionMsg.issue.SubmitIssueforCompletion,'SubmitIssueforCompletion',true,commentAndAction)"></button>
            </div>

            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Send due date change request to RCSA Owner for review and approval' , 'top')">


                <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" label="Request a New Due Date" (click)='openModal(DueDateChange)'></button>
            </div>

        </ng-container>





    </div>







    <div *ngIf='statusOrder==IssueStatus.OpenPendingCompletionApproval' class="col-md-12 d-flex justify-content-end mt-2 ">

        <ng-container *ngIf='(showButtonForUser([rcsaOwner]))'>


            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Completes the issue and informs all stakeholders' , 'top')">

                <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" label="Approve Issue Completion" [disabled]='requeredList.length>0' (click)="openCommentPopup(ActionMsg.issue.ApproveIssueCompletion,'ApproveIssueCompletion',false,commentAndAction)"></button>
            </div>


            <div class=' t-demo__btn align-self-end' ngxTippy [tippyProps]="tooltip('Rejects the request, changes the status back to open and informs the requestor that further updates need to be made' , 'top')">

                <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" label="Reject Issue Completion" (click)="openCommentPopup(ActionMsg.issue.RejectIssueCompletion,'RejectIssueCompletion',true,commentAndAction)"></button>
            </div>



        </ng-container>
    </div>







</div>




<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title m-0">WARNING!</h4>
        <span (click)="modalRef.hide()" class="material-icons informationIcon">
        close
    </span>

    </div>
    <div class="modal-body">
        <div>
            <ul class=" dUL bd-highlight mb-4">
                <li> Cancelled issues cannot be reactivated! </li>

            </ul>


            <div class="d-flex justify-content-end w-100">
                <button class='primaryBtn  mr-4 buttonWidth' pButton type="button" label="Proceed" (click)='changeStatus(IssueStatus.Cancelled,comment,"cancel_comment","cancelled_by","cancel_date",274);modalRef.hide()'></button>
                <button class='primaryBtn   buttonWidth' pButton type="button" label="Cancel" (click)="modalRef.hide()"></button>
            </div>
        </div>
    </div>
</ng-template>



<ng-template #DueDateChange>
    <div class="modal-header">
        <h4 class="modal-title m-0">Due Date Change Request</h4>
        <span (click)="modalRef.hide()" class="material-icons informationIcon">
      close
  </span>

    </div>
    <div class="modal-body">
        <div>
            <div class="row">
                <div class="col-md-12 mb-4">
                    You are about to <strong> Send Request </strong> to the RCSA Owner for changing the due date of this issue.

                </div>
                <div class="col-md-2 mb-4">Proposed New Due Date </div>
                <div class="col-md-10 mb-4">
                    <div class="input-container position-relative">
                        <div (click)='rcPastDueDateP.open()' class=" inputIcon material-icons">
                            date_range
                        </div>
                        <input (keypress)="cantEdit($event)" class="input-field" placeholder="DD-MMM-YYYY" [disabled]='true' class="notDisabledInput" [ngModel]="showDateSafari(rcPastDueDate) | date: dateFormat">

                        <input [matDatepicker]='rcPastDueDateP' [min]="dateToday" [ngModel]='rcPastDueDate' (dateChange)="dateChanged($event.value, 'rcPastDueDate')" class="input-field matrialInputH" placeholder="dd-mm-yyyy">
                        <mat-datepicker #rcPastDueDateP></mat-datepicker>
                    </div>
                </div>





                <div class="col-md-2 mb-4">Due Date Change Rationale </div>
                <div class="col-md-12 mb-4 d-flex flex-column ">



                    <small style=" text-align: right;" [ngClass]="(!byteCount(dueDateComment.value,4000).valid)?'warningTxt':''">{{byteCount(dueDateComment.value,4000).length}} / 4000 </small>


                    <textarea id="eventTitleInput" #dueDateComment cols="50" [(ngModel)]='comment' rows="3">
       </textarea>

                    <small>
        <span *ngIf='!byteCount(dueDateComment.value,4000).valid' class="warningTxt">
            The text entered exceeds the maximum number of characters allowed. Please reduce the text length up to the defined limit.
        </span>
         </small>


                </div>


            </div>




            <div class="d-flex justify-content-end w-100">



                <button class='primaryBtn mr-4  buttonWidth' pButton type="button" label="Update" [disabled]='comment == "" || !rcPastDueDate || !byteCount(dueDateComment.value,4000).valid || changeStatusRequestLoading' (click)='proccedChangDueDate();'></button>
                <button class='primaryBtn   buttonWidth' pButton type="button" label="Cancel" (click)="modalRef.hide()"></button>

            </div>
        </div>
    </div>
</ng-template>






<ng-template #commentAndAction>
    <div class="modal-header">
        <h4 class="modal-title m-0"></h4>
        <span (click)="modalRef.hide()" class="material-icons informationIcon">
        close
    </span>

    </div>
    <div class="modal-body">
        <div>

            <div [innerHTML]="popupMsg">

            </div>
            <div>
                <div class="col-md-12 py-4 contantStyle">

                    Comment <span *ngIf="commentStatus">
                        (Mandatory)

                    </span> <span *ngIf="!commentStatus">
                        (Optional)

                    </span>


                </div>
                <div class="col-md-8 mb-4 d-flex flex-column ">



                    <small style=" text-align: right;" [ngClass]="(!byteCount(commentAction.value,4000).valid)?'warningTxt':''">{{byteCount(commentAction.value,4000).length}} / 4000 </small>



                    <textarea id="eventTitleInput" #commentAction cols="50" [(ngModel)]='comment' rows="3">
                </textarea>

                    <small>
        <span *ngIf='!byteCount(commentAction.value,4000).valid' class="warningTxt">
            The text entered exceeds the maximum number of characters allowed. Please reduce the text length up to the defined limit.
        </span>
         </small>


                </div>


            </div>

            <div class="d-flex justify-content-end w-100">



                <ng-container *ngIf='actionTaken == "SubmitDraft"'>
                    <button *ngIf='showButtonForUser([ ownerId,delegate])' class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" [label]="'Update'" [disabled]='requeredList.length>0 || comment == "" ||!byteCount(commentAction.value,4000).valid || changeStatusRequestLoading' (click)='changeStatus(IssueStatus.DraftPendingReview,comment,"submit_comment","requestor","received_date",272);modalRef.hide()'></button>

                </ng-container>
                <ng-container *ngIf='actionTaken == "CancelDraft"'>
                    <button *ngIf='showButtonForUser([ rcsaOwner,creator,ownerId,delegate,ORM].concat(GovernanceCoordinator)) || showButtonForUserRole(["CORM","ORM","ORMII","GC","GIA"])' class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" label="Update"
                        (click)='changeStatus(IssueStatus.Cancelled,comment,"cancel_comment","cancelled_by","cancel_date",274);modalRef.hide()' [disabled]='comment == "" ||!byteCount(commentAction.value,4000).valid || changeStatusRequestLoading'></button>
                </ng-container>



                <ng-container *ngIf='actionTaken == "ApproveDraft"'>



                    <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" [label]="'Update'" [disabled]='requeredList.length>0 ||!byteCount(commentAction.value,4000).valid || changeStatusRequestLoading' (click)='changeStatus(IssueStatus.Open,comment,"risk_manager_comment" ,"approver","risk_manager_date",273)'></button>

                </ng-container>


                <ng-container *ngIf='actionTaken == "ApproveDraftP"'>



                    <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" [label]="'Update'" [disabled]='requeredList.length>0 ||!byteCount(commentAction.value,4000).valid || changeStatusRequestLoading' (click)='changeStatus(IssueStatus.Open,comment, "risk_manager_comment","approver","risk_manager_date",275 )'></button>

                </ng-container>

                <ng-container *ngIf='actionTaken == "RejectIssue"'>



                    <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" [label]="'Update'" [disabled]='comment == ""||!byteCount(commentAction.value,4000).valid || changeStatusRequestLoading' (click)='changeStatus(IssueStatus.Draft,comment, "risk_manager_comment","approver","risk_manager_date",276 )'></button>

                </ng-container>




                <ng-container *ngIf='actionTaken == "SubmitIssueforCompletion"'>



                    <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" [label]="'Update'" [disabled]='comment == ""||!byteCount(commentAction.value,4000).valid || changeStatusRequestLoading' (click)='changeStatus(IssueStatus.OpenPendingCompletionApproval,comment, "submit_comment" ,"requestor","received_date",277)'></button>

                </ng-container>



                <ng-container *ngIf='actionTaken == "RejectDueDateChange"'>



                    <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" [label]="'Update'" [disabled]='comment == ""||!byteCount(commentAction.value,4000).valid || changeStatusRequestLoading' (click)='changeStatus(getLastStatus(lastStatusChange.rcsc_status_id),comment, "rcsa_own_comment_dde","approver","rcsa_own_comment_dde_date",282,287 )'></button>

                </ng-container>
                <ng-container *ngIf='actionTaken == "RejectIssueCompletion"'>



                    <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" [label]="'Update'" [disabled]='comment == ""||!byteCount(commentAction.value,4000).valid || changeStatusRequestLoading' (click)='changeStatus(getLastStatus(lastStatusChange.rcsc_status_id),comment, "rcsa_own_comment","approver","rcsa_own_date",279,289 )'></button>

                </ng-container>
                <ng-container *ngIf='actionTaken == "ApproveIssueCompletion"'>



                    <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" [label]="'Update'" [disabled]='!byteCount(commentAction.value,4000).valid || changeStatusRequestLoading' (click)='changeStatus(IssueStatus.Completed,comment, "close_comment","approver","close_date",278,288 )'></button>

                </ng-container>
                <ng-container *ngIf='actionTaken == "ApproveDueDateChange"'>



                    <button class='primaryBtn  ml-4 buttonWidth align-self-end' pButton type="button" [disabled]='!byteCount(commentAction.value,4000).valid || changeStatusRequestLoading' [label]="'Update'" (click)='approveChangeDueDate()'></button>

                </ng-container>








                <button class='primaryBtn ml-4 buttonWidth' pButton type="button" label="Cancel" (click)='modalRef.hide()'></button>





            </div>



        </div>
    </div>
</ng-template>
