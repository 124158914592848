<div class="containerDiv">
    <p class=' t-demo__btn'   ngxTippy  [tippyProps]="tooltip(FieldHelpData.businessImpact)">
        In case any business units are impacted by this {{type}}, please select all that apply:
    </p>
    <div class="row my-1">
        <div *ngIf="type == 'issue'" class="col-md-12 checkBoxTitle">
            <mat-checkbox #noone *ngIf="noOneValue" (change)="toggleAllCheckboxes(noone, checkbox1, checkbox2, checkbox3)" [checked]="riskCase.businessImpact && riskCase.businessImpact['noValue'] && riskCase.businessImpact['noValue'].id == noOneValue.id">
                No Business Impact
            </mat-checkbox>
        </div>
        <div *ngIf="type == 'event'"  class="col-md-12 checkBoxTitle">
            <mat-checkbox #noone *ngIf="noOneValue" (change)="toggleAllCheckboxes(noone, checkbox1, checkbox2, checkbox3)" [checked]="riskCase.businessImpact && riskCase.businessImpact['noValue'] && riskCase.businessImpact['noValue'].bus_impact_id == noOneValue.bus_impact_id">
                No Business Impact
            </mat-checkbox>
        </div>
        <div class="col-md-6">
            <app-check-box-items #checkbox1 [allCbDisabled]='cb1Disabled' [list]='AllCorSo' [preselected]="riskCase.businessImpact['corso']" [fullWidth]='true' [title]='"All Corso"'
            (selectedList)='getList($event,"corso")' (uncheckNoBI)='uncheckNoBI()' [noone]='noone' ></app-check-box-items>

        </div>
        <div class="col-md-6">
            <app-check-box-items #checkbox2 [allCbDisabled]='cb2Disabled' [list]='AllLifeCapital' [preselected]="riskCase.businessImpact['lifeCapital']" [fullWidth]='true' [title]='"All IptiQ"'
            (selectedList)='getList($event,"lifeCapital")' (uncheckNoBI)='uncheckNoBI()' [noone]='noone'></app-check-box-items>

        </div>
        <div class="col-md-12">
            <app-check-box-items #checkbox3 [allCbDisabled]='cb2Disabled' [list]='AllReinsurance' [preselected]="riskCase.businessImpact['reinsurance']" [fullWidth]='false' [title]='"All Reinsurance"'
            (selectedList)='getList($event,"reinsurance")' (uncheckNoBI)='uncheckNoBI()' [noone]='noone'></app-check-box-items>


        </div>
    </div>

    <div class="row justify-content-end mb-5 w-100 mx-0" *ngIf='viewButton'>
        <button class='primaryBtn mr-4  buttonWidth' pButton type="button" label="Skip" (click)="toggleSkipDialog()"></button>

        <div class='d-flex'>
            <button class='primaryBtn  buttonWidth ' pButton type="button" label="Proceed " (click)="nextStep(riskCase.businessImpact)"
            [disabled]="riskCase.businessImpact.corso.length==0 && riskCase.businessImpact.reinsurance.length==0 && riskCase.businessImpact.lifeCapital.length==0 &&
            (!riskCase.businessImpact.noValue || !noone || (noone && noone['checked'] == false))"></button>
            <app-information-dialog [accordionIndex]='5'></app-information-dialog>
        </div>
    </div>

    <app-skip-dialog [displaySkipDialog]="displaySkipDialog" (hideDialog) ="toggleSkipDialog()" (clearData) = "clearData();"></app-skip-dialog>
</div>






