import { Component, OnInit } from '@angular/core';
import { StepperService } from '../../../../shared/services/stepper.service';
import { BaseService } from 'src/app/shared/services';
import { NewRisk } from 'src/app/shared/models/new-risk.model';

@Component({
  selector: 'app-step7',
  templateUrl: './step7.component.html',
  styleUrls: ['./step7.component.scss']
})
export class Step7Component implements OnInit{

  riskCase: NewRisk;


  constructor(private stepperService: StepperService,
              private baseService: BaseService) {

    this.riskCase = stepperService.newRisk;
   
  }
  ngOnInit(): void {
  }
 
 

  
}
