<div class="flexcolumn container">
    <ng-container *ngIf='risks.length >0'>
        <p-table sortField="deadline" [sortOrder]="1" [columns]="cols" scrollHeight="26.4rem" class="flexcolumn" [value]="risks" [paginator]="!isConfigView" [scrollable]="true" [reorderableColumns]="isConfigView" [rows]="10" #dt selectionMode="single" (onRowSelect)="onRowSelect($event)">
            <ng-template pTemplate="caption">
                <div style="display: flex; justify-content: space-around; align-items: center;">
                    <div>
                        <span *ngIf="!isConfigView"> Total Records: {{dt.totalRecords}}</span>
                    </div>
                    <div>




                    </div>
                    <div>
                        <button *ngIf="!isConfigView" pButton icon="pi pi-filter" id="clearFilterButton" class="accent-blue-button" label="CLEAR FILTER" [disabled]="!hasFilters" (click)="clearFilters()"></button>
                    </div>
                    <div>
                        <button pButton icon="pi pi-cog" id="configureTableButton" class="accent-blue-button" label="CONFIGURE TABLE" (click)="configureTable()"></button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr style="height:5rem" *ngIf="!isConfigView && !updating">
                    <ng-container *ngFor="let col of cols" [ngSwitch]="col.field">
                        <th *ngIf="col.isVisible" [ngStyle]="setDeleteStyle(col.field)">

                            <div class="centerItem" *ngSwitchCase="'deadline'">
                                {{col.header}}
                                <p-sortIcon [pSortableColumn]="col.field" [field]="'Name'"></p-sortIcon>
                            </div>


                            <div class="centerItem" *ngSwitchCase="'submission_date'">
                                {{col.header}}
                                <p-sortIcon [pSortableColumn]="col.field" [field]="'Name'"></p-sortIcon>
                            </div>
                            <div class="centerItem" *ngSwitchCase="'closure_date'">
                                {{col.header}}
                                <p-sortIcon [pSortableColumn]="col.field" [field]="'Name'"></p-sortIcon>
                            </div>
                            <div class="centerItem" *ngSwitchCase="'cancellation_date'">
                                {{col.header}}
                                <p-sortIcon [pSortableColumn]="col.field" [field]="'Name'"></p-sortIcon>
                            </div>

                            <span class="ui-float-label" *ngSwitchCase="'action'">
              <input id="float-input" type="text" size="30" [(ngModel)]="col.filter" class="filter-input"
                (input)="filterTable($event.target.value, 'action', 'contains')" pInputText>
              <label for="float-input">{{col.header}}</label>
            </span>
                            <span class="ui-float-label" *ngSwitchCase="'risk_manager_name'">
              <input id="float-input" type="text" size="30" [(ngModel)]="col.filter" class="filter-input"
                (input)="filterTable($event.target.value, 'risk_manager_name', 'contains')" pInputText>
              <label for="float-input">{{col.header}}</label>
            </span>
                            <span class="ui-float-label" *ngSwitchCase="'todos_trigger'">
              <input id="float-input" type="text" size="30" [(ngModel)]="col.filter" class="filter-input"
                (input)="filterTable($event.target.value, 'todos_trigger', 'contains')" pInputText>
              <label for="float-input">{{col.header}}</label>
            </span>
                            <span class="ui-float-label" *ngSwitchCase="'ownership'">
              <input id="float-input" type="text" size="30" [(ngModel)]="col.filter" class="filter-input"
                (input)="filterTable($event.target.value, 'ownership', 'contains')" pInputText>
              <label for="float-input">{{col.header}}</label>
            </span>
                            <span class="ui-float-label" *ngSwitchCase="'accounting_adjustments'">
              <input id="float-input" type="text" size="30" [(ngModel)]="col.filter" class="filter-input"
                (input)="filterTable($event.target.value, col.field, 'contains')" pInputText>
              <label for="float-input">{{col.header}}</label>
            </span>

                            <span class="ui-float-label" *ngSwitchCase="'operational_gains'">
              <input id="float-input" type="text" size="30" [(ngModel)]="col.filter" class="filter-input"
                (input)="filterTable($event.target.value, col.field, 'contains')" pInputText>
              <label for="float-input">{{col.header}}</label>
            </span>

                            <span class="ui-float-label" *ngSwitchCase="'rc_source_name'">
              <input id="float-input" type="text" size="30" [(ngModel)]="col.filter" class="filter-input"
                (input)="filterTable($event.target.value, col.field, 'contains')" pInputText>
              <label for="float-input">{{col.header}}</label>
            </span>








                            <span class="ui-float-label" *ngSwitchCase="'rc_title'">
              <input id="float-input" type="text" size="30" [(ngModel)]="col.filter" class="filter-input"
                (input)="filterTable($event.target.value, col.field, 'contains')" pInputText>
              <label for="float-input">{{col.header}}</label>
            </span>
                            <p-multiSelect *ngSwitchCase="'lod'" [options]="lodList" class="filter-input" [(ngModel)]="col.filter" [defaultLabel]="col.header" appendTo="body" (onChange)="filterTable($event.value, col.field, 'in')">
                            </p-multiSelect>

                            <span class="ui-float-label" *ngSwitchCase="'source'">
              <input id="float-input" type="text" size="30" [(ngModel)]="col.filter" class="filter-input"
                (input)="filterTable($event.target.value, col.field, 'contains')" pInputText>
              <label for="float-input">{{col.header}}</label>
            </span> <span class="ui-float-label" *ngSwitchCase="'impact_rating'">
              <input id="float-input" type="text" size="30" [(ngModel)]="col.filter" class="filter-input"
                (input)="filterTable($event.target.value, col.field, 'contains')" pInputText>
              <label for="float-input">{{col.header}}</label>
            </span>


                            <span class="ui-float-label" *ngSwitchCase="'loss'">
              <input id="loss-input" type="text" size="30" [(ngModel)]="col.filter" class="filter-input"
                (input)="filterTable($event.target.value, col.field, 'contains')" pInputText>
              <label for="loss-input">{{col.header}}</label>
            </span>
                            <div class="centerItem" *ngSwitchCase="'days_until_deadline'">
                                <div>
                                    {{col.header}} <br> in the next {{col.filter}} days
                                    <p-slider [(ngModel)]="col.filter" [max]="31" [style]="{'margin-top':'5px'}" (onChange)="onDeadlineChange($event, col.field,'lte')"></p-slider>
                                </div>

                            </div>


                            <p-dropdown *ngSwitchCase="'status_name'" [options]="statusList" class="filter-input" placeholder="Case Status" [(ngModel)]="col.filter" appendTo="body" (onChange)="filterTable($event.value, col.field, 'equals')">
                            </p-dropdown>
                            
                            <p-multiSelect *ngSwitchCase="'class_name'" [options]="classNames" class="filter-input" [(ngModel)]="col.filter" [defaultLabel]="col.header" appendTo="body" (onChange)="filterTable($event.value, col.field, 'in')">
                            </p-multiSelect>
                        </th>
                    </ng-container>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData" *ngIf="!isConfigView" class="cursor-pointer">
                    <ng-container *ngFor="let col of cols">
                        <td *ngIf="col.isVisible" [ngStyle]="setDeleteStyle(col.field)">
                            <ng-container *ngIf="col.field==='deadline'|| col.field==='submission_date'|| col.field==='closure_date'|| col.field==='cancellation_date' ">
                                <ng-container *ngIf="rowData[col.field]">
                                    {{ getDate(rowData[col.field]) | oneOrmDate}}
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="col.field==='action' || col.field==='ownership'  || col.field==='todos_trigger'  || col.field==='risk_manager_name' ">
                                {{rowData[col.field]}}
                            </ng-container>

                            <ng-container *ngIf="col.field==='status_name' || col.field==='class_name'">
                                {{rowData[col.field] }}
                            </ng-container>




                            <ng-container *ngIf="col.field==='loss' || col.field==='rc_title' || col.field==='accounting_adjustments' || col.field==='operational_gains' || col.field==='rc_source_name' ||  col.field==='lod' || col.field==='source' || col.field==='impact_rating' || col.field==='days_until_deadline'">
                                {{rowData[col.field]}}
                            </ng-container>









                        </td>
                    </ng-container>
                </tr>

            </ng-template>
        </p-table>
    </ng-container>
    <ngx-spinner name="myToDo" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="false">
        <p style="color: white"> Loading... </p>
    </ngx-spinner>

    <ng-container *ngIf='risks.length == 0'>
        <div class="noRecord">
            No records available!
        </div>
    </ng-container>


</div>
<div *ngIf="isConfigView" cdkDropList cdkDropListOrientation="horizontal" class="column-list" (cdkDropListDropped)="drop($event)">
    <ng-container *ngFor="let col of cols">
        <div class="column-configuration-placeholder" *ngIf="col.field != 'delete'" pTooltip="Drag me to the desired position" tooltipPosition="bottom" cdkDrag>
            <p-checkbox name="isVisibleCheckbox" [value]="col.field" [(ngModel)]="col.isVisible" [binary]="true"></p-checkbox>
            <span>
        {{col.header}}
      </span>
        </div>
    </ng-container>
</div>

<div class="configuration-buttons-container" *ngIf="isConfigView">
    <button pButton icon="pi pi-times" id="cancelChangesButton" class="accent-blue-button" label="DISCARD CHANGES" (click)="cancelConfiguration()"></button>

    <button pButton icon="pi pi-save" id="saveButton" class="accent-blue-button" label="SAVE AND CLOSE" (click)="saveConfiguration()"></button>
</div>

<p-confirmDialog #cd>
    <p-footer>
        <button type="button" pButton icon="pi pi-check" label="Yes" (click)="cd.accept()"></button>
        <button type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>
    </p-footer>
</p-confirmDialog>









<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title m-0">WARNING!</h4>
        <span (click)="closeModel()" class="material-icons informationIcon">
          close
      </span>

    </div>
    <div class="modal-body">
        <div>
            <ul class=" dUL bd-highlight mb-4">
                <li> Are you sure you want to cancel this case? </li>

                <li> Please note that cancelled events cannot be reactivated! </li>

            </ul>
            <div class="ml-4">
                <div class="w-100 py-4">

                    Comment *

                </div>
                <div class="col-md-12 mb-4 d-flex flex-column ">



                    <small style=" text-align: right;" [ngClass]="(!byteCount(commentAction.value,4000).valid)?'warningTxt':''">{{byteCount(commentAction.value,4000).length}} / 4000 </small>



                    <textarea id="commentInput" #commentAction cols="50" [(ngModel)]='comment' rows="3" maxlength="4000">
  </textarea>

                    <small>    
<span *ngIf='!byteCount(commentAction.value,4000).valid' class="warningTxt">
The text entered exceeds the maximum number of characters allowed. Please reduce the text length up to the defined limit.
</span>
</small>


                </div>
            </div>

            <div class="d-flex justify-content-end w-100">
                <div class="mr-4">
                    <button class='primaryBtn  mr-4 buttonWidth' pButton type="button" label="Proceed" [disabled]='comment==""||!byteCount(commentAction.value,4000).valid' (click)='cancelEvent(riskCaseId)'></button>

                </div>



                <button class='primaryBtn   buttonWidth' pButton type="button" label="Cancel" (click)="closeModel()"></button>

            </div>
        </div>
    </div>
</ng-template>