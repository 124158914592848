

import {SelectionModel } from '@angular/cdk/collections';
import {Component, Input , Output , EventEmitter, ViewChild, ElementRef, ChangeDetectorRef} from '@angular/core';


@Component({
  selector: 'app-enum-item-selection',
  templateUrl: './enum-item-selection.component.html',
  styleUrls: ['./enum-item-selection.component.scss']
})
export class EnumItemSelectionComponent{
  
  @Input()  list: [] = [];
  @Input()  title: string;
  @Input()  fullWidth: boolean;
  @Input() preselected: [];
  @Input() allCbDisabled: boolean;
  @Input() noone : ElementRef;

  @Output() selectedList: EventEmitter<any> = new EventEmitter<string>();
  @Output() uncheckNoBI: EventEmitter<any> = new EventEmitter();
  @ViewChild('ref') parentCheckBox: ElementRef;

  selection = new SelectionModel<any>(true, []);
  
  constructor(private cdr:ChangeDetectorRef){} 

  ngAfterViewChecked() {
    this.parentCheckBox['checked'] = this.selection.hasValue() && this.isAllSelected();
    this.parentCheckBox['indeterminate'] = this.selection.hasValue() && !this.isAllSelected();
    this.cdr.detectChanges();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.list.length;

    return numSelected === numRows;
  }

  masterToggle(ref) {
     this.isAllSelected() ? this.selection.clear() : this.list.forEach(row => this.selection.select(row));
     this.isSomeSelected();
  }

  isSomeSelected() {
    this.selectedList.emit(this.selection.selected);
    return this.selection.selected.length > 0;
  }

  isPreSelected(row:any){
    let isSelected = this.preselected.some((e:any)=> e.id == row.id);
    if(isSelected){
      this.selection.select(row);
      this.isSomeSelected();
    }
    return isSelected;
  }

  uncheckNone(){
    if(this.noone){
      this.noone['checked'] = false;
      this.uncheckNoBI.emit();
    }
  }
}


