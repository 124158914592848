export enum RiskCaseConstants {
  EVENT = 1,
  ISSUE = 2,
  UNDEFINED = 3,
  DRAFT_TECHNICAL = 37,
  Issue_DRAFT_TECHNICAL = 38,
  CANCELLED = 11,
  CLOSED = 10,
  OPEN_PENDING_CLOSURE_APPROVAL = 9,
  OPEN = 8,
  DRAFT_PENDING_REVIEW = 7,
  DRAFT = 6,
}

export enum UserRoleConstants {
  CORM = 'CORM',
  ORM = 'ORM',
  ORM_II = 'ORMII',
  Compliance = 'Comp',
  Governance_Coordinator = 'GC',
}

export const preDefineStepList = [
  {label: 'Mandate', data: null, url: '/new-risk/mandate'},
  {label: 'Event or Issue', data: null, url: '/new-risk/event'}
];

export const eventStepList = [
  {label: 'Mandate', data: null, url: '/new-risk/mandate'},
  {label: 'Event', data: null, url: '/new-risk/event'},
  {label: 'Description', data: null, url: '/new-risk/description'},
  {label: 'Risk', data: null, url: '/new-risk/risk'},
  {label: 'Define Roles', data: null, url: '/new-risk/defineroles'},
  {label: 'Business Impact', data: 'businessImpact', url: '/new-risk/businessimpact'},
  {label: 'Legal Entities', data: 'legalEntities', url: '/new-risk/legalentities'},
  {label: 'Outsourcing', data: 'outsourcing', url: '/new-risk/outsourcing'},
  {label: 'Monetary Impact', data: 'monetary_impacts', url: '/new-risk/monetaryimpact'},
  {label: 'Additional Information', data: 'location', url: '/new-risk/additionalInformation'},

  {label: 'Review Before Go-Live', data: null, url: '/new-risk/go-live'},
];

export const issueStepList = [
  {label: 'Mandate', data: null, url: '/new-risk/mandate'},
  {label: 'Issue', data: null, url: '/new-risk/event'},
  {label: 'Description', data: null, url: '/new-risk/description'},
  {label: 'Identification', data: null, url: '/new-risk/identification'},
  {label: 'Risk & Control', data: null, url: '/new-risk/riskAndControl'},
  {label: 'Define Roles', data: 'businessImpact', url: '/new-risk/roles'},
  {label: 'Finalize Before Go-Live', data: null, url: '/new-risk/draftcreated'},

  // { label: 'Legal Entities', data: 'legalEntities', url: '/new-risk/legalentities' },
  // { label: 'Business Impact', data: 'outsourcing', url: '/new-risk/outsourcing' },
  // { label: 'Outsourcing', data: 'monetary_impacts', url: '/new-risk/monetaryimpact' },
  // { label: 'Group Risk Impact', data: 'location', url: '/new-risk/additionalInformation' },
  // { label: 'Resolution', data: 'monetary_impacts', url: '' },

  // { label: 'Issue Overview', data: null, url: '/new-risk/go-live' },
];
