<div class="containerDiv">
    <div class="w-100  panelWidth">
        <div *ngIf="hasOutsourcing">
            <label id="example-radio-group-label">Internal and external Outsourcing</label>
            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="selectedOutsourcing">
                <mat-radio-button class="example-radio-button" *ngFor="let outsourc of outsourcingList" (click)='changeFlag = true' [value]="outsourc.id">
                    {{outsourc.name}}
                </mat-radio-button>
            </mat-radio-group>
        </div>


        <div *ngIf="!hasOutsourcing">
            <label id="example-radio-group-label">Internal and external Outsourcing</label>
            <div class="row my-5">
                <div class="col-md-9">
                    Was this {{riskCaseType}} caused by an <strong>external provider</strong>? (example - Genpact, SAP, MGA, IBM, ...) <br>If yes, indicate name in the risk case description.
                </div>
                <div class="col-md-3">
                    <mat-radio-group class="d-flex justify-content-start " aria-labelledby="example-radio-group-label" [(ngModel)]="firstQuestionnaire">
                        <mat-radio-button class=" mr-4" [value]="'yes'" (change)='setOutsourcing(outsourcingQuestionnaireId.external);secondQuestionnaire=null;secondQuestionnaireB=null;thirdQuestionnaire=null'>
                            Yes
                        </mat-radio-button>
                        <mat-radio-button class="" [value]="'no'" (change)='setOutsourcing(null)'>
                            No
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="row my-5" *ngIf="firstQuestionnaire == 'no'">
                <div class="col-md-9">
                    Was this {{riskCaseType}} caused by a team in <strong>Business Solutions Centers Bratislava</strong> or <strong>Bangalore</strong>? (examples of service provided - TA, Business services, Claims, ...)
                </div>
                <div class="col-md-3">
                    <mat-radio-group class="d-flex justify-content-start " aria-labelledby="example-radio-group-label" [(ngModel)]="secondQuestionnaire">
                        <mat-radio-button class=" mr-4" [value]="'yes'" (change)='setOutsourcing(null);secondQuestionnaireB=null;thirdQuestionnaire=null'>
                            Yes
                        </mat-radio-button>
                        <mat-radio-button class="" [value]="'no'" (change)='setOutsourcing(null);secondQuestionnaireB=null;thirdQuestionnaire=null'>
                            No
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>


            <div class="row m-2 " *ngIf="secondQuestionnaire == 'yes'">
                <div class="col-md-6">
                    Indicate the Business Solution Centre:
                </div>
                <div class="col-md-6 ">
                    <mat-radio-group class="d-flex justify-content-start " aria-labelledby="example-radio-group-label" [(ngModel)]="secondQuestionnaireB">
                        <mat-radio-button class=" mr-4" [value]="'yes'" (change)='setOutsourcing(outsourcingQuestionnaireId.Bratislava)'>
                            Bratislava
                        </mat-radio-button>
                        <mat-radio-button class="" [value]="'no'" (change)='setOutsourcing(outsourcingQuestionnaireId.Bangalore)'>
                            Bangalore
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>








            <div class="row my-5" *ngIf="secondQuestionnaire == 'no'">
                <div class="col-md-9">
                    Was this {{riskCaseType}} caused by <strong>a team providing services to different location/legal entity</strong>?
                    (examples of service provided - IT, Finance, Compliance, HR, ...)<br/>
                    Note: Majority of IT cases are considered internal outsourcing (as IT services are provided internally via Swiss Re Management Ltd)
                </div>
                <div class="col-md-3">
                    <mat-radio-group class="d-flex justify-content-start " aria-labelledby="example-radio-group-label" [(ngModel)]="thirdQuestionnaire">
                        <mat-radio-button class=" mr-4" [value]="'yes'" (change)='setOutsourcing(outsourcingQuestionnaireId.Other)'>
                            Yes
                        </mat-radio-button>
                        <mat-radio-button class="" [value]="'no'" (change)='setOutsourcing(outsourcingQuestionnaireId.no)'>
                            No
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>


        </div>






        <div *ngIf='selectedOutsourcing' class="mt-3  w-100 d-flex justify-content-start align-items-end">

            <div class="mr-4"> <span class="contantStyle">
                Your Selection:
            </span> </div>
            <div class="d-flex">
                <span>
                    {{outsourse(selectedOutsourcing,outsourcingList)}}
                </span>

                <span class="clearSelection ml-2 material-icons" matTooltip="Clear your selection" [matTooltipPosition]="'above'" (click)='selectedOutsourcing=null; firstQuestionnaire=null; secondQuestionnaire=null;secondQuestionnaireB=null;thirdQuestionnaire=null;changeFlag=true'>
                close
            </span></div>

        </div>
        <div class="row justify-content-end mb-5 w-100 mx-0" *ngIf='viewButton'>
            <button class='primaryBtn mr-4 buttonWidth' pButton type="button" label="Skip" (click)="toggleSkipDialog()"></button>
            <div class='d-flex'>

                <button class='primaryBtn  buttonWidth' pButton type="button" label="Proceed" [disabled]="!selectedOutsourcing" (click)="nextStep(selectedOutsourcing)"></button>
                <app-information-dialog [accordionIndex]='7'></app-information-dialog>
            </div>
        </div>
    </div>
    <app-skip-dialog [displaySkipDialog]="displaySkipDialog" (hideDialog)="toggleSkipDialog()" (clearData)="clearData();"></app-skip-dialog>
</div>
