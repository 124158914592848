<mat-card *ngIf='!riskCase' class="statusBar mb-2">
    <div class="d-flex justify-content-center align-items-center loading">Loading...</div>
</mat-card>
<div class="SystemNotice" *ngIf="riskCase && !isDesktopDevice">
    <strong>
        Important System Notice:
    </strong> Due to an underlying issue with the OneORM mobile application, this screen is currently read only on all mobile devices.
</div>
<ng-container *ngIf='riskCase'>
    <app-confirm-dialog [displayConfirmDialog]="displayConfirmDialog" (reloadRiskCase)="reloadRiskCase()"></app-confirm-dialog>
    <mat-card class="statusBar mb-2">
        <app-status-bar [isDesktopDevice]="isDesktopDevice" (newData)='updateData($event)' class='w-100' [userRole]='userRole' [owner]='true' [statusBarRiskCaseData]='statusBarRiskCaseData' [statusOrder]='riskCase.status' (openPopUpEdit)="editEntity(template, $event)"
            (changeStatusEvent)='changeStatus($event)' [cashOut]='step10_View.chechEnableRadioBtn(91,step10_View.selectedList)' #statusBar></app-status-bar>
    </mat-card>
    <mat-card class=" mb-2">
        <div class="d-flex justify-content-between w-100">
            <div class="contantStyle">Administration</div>
            <div (click)='hideAdministration=!hideAdministration'>
                <span *ngIf='hideAdministration' class="material-icons expandIcon">
                    expand_more
                </span>
                <span *ngIf='!hideAdministration' class="material-icons expandIcon">
                    expand_less
                </span>
            </div>
        </div>
        <div *ngIf='!hideAdministration' class="row mt-4">
            <div class="col-md-4">
                <div class="d-flex">
                    <div class=""> <span class="summaryIcon  material-icons">
                            account_circle
                        </span></div>
                    <div>
                        <p class="mb-0">
                            Created By
                        </p>
                        <p class="contantStyle">{{resultGit.creator.user_name}} ({{resultGit.creator.user_id}})
                            <span *ngIf='resultGit.creator.user_status == "INACTIVE"'>(Inactive)</span>
                        </p>
                        <p class="mb-0">Last Modified By</p>
                        <p class="contantStyle">
                            {{resultGit.modified_by.user_name}} ({{resultGit.modified_by.user_id}})
                            <span *ngIf='resultGit.modified_by.user_status == "INACTIVE"'>(Inactive)</span>
                        </p>
                        <p class="mb-0">
                            Subscribed Users
                            <app-information-dialog [accordionIndex]='17'></app-information-dialog>
                        </p>
                        <p class="contantStyle">
                            <ng-container *ngFor='let air of additionalInfoRecipients'>
                                {{air.contact_name}} ({{air.contact_id}}) <br>
                            </ng-container>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="d-flex">
                    <div class=""> <span class="summaryIcon  material-icons">
                            date_range
                        </span></div>
                    <div>
                        <p class="mb-0">
                            Creation Date (CET)
                        </p>
                        <p class="contantStyle">{{dateValidate(resultGit.creation_date) | oneOrmDate}}
                        </p>
                        <p class="mb-0">Last Modification Date (CET)
                        </p>
                        <p class="contantStyle">{{dateValidate(resultGit.modify_date) | oneOrmDate}}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="d-flex">
                    <mat-icon class="summaryIcon  material-icons" (click)='copyGraceUrl(resultGit.url)' matTooltip="Copy GRACE URL" [matTooltipPosition]="'above'">link</mat-icon>
                    <div>
                        <p class="contantStyle cursor_pointer">
                            <button class='primaryBtn   buttonWidth align-self-end' pButton type="button" [label]="'Go to Event in GRACE'" matTooltip="Only accessible by licensed GRACE users." (click)='goOutsideApp(resultGit.url)'></button>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>
    <mat-card class=" mb-2">
        <div class="d-flex justify-content-between w-100">
            <div class="contantStyle"> Associations </div>
            <div (click)='hideRisks=!hideRisks'>
                <span *ngIf='hideRisks' class="material-icons expandIcon">
                    expand_more
                </span>
                <span *ngIf='!hideRisks' class="material-icons expandIcon">
                    expand_less
                </span>
            </div>
        </div>
        <div *ngIf='!hideRisks' class="row mt-4">
            <div class="col-md-1 my-3">
                <span class="w-100 contantStyle">
                    RCSAs
                </span>
            </div>
            <div class="col-md-11 accordionContainerHeight   my-3">
                <ng-container>
                    <mat-accordion class="fix-radio-position" hideToggle="true">
                        <ng-container *ngIf='resultGitNew.risk_assessment'>
                            <mat-expansion-panel class='PRIMARYcontrolRisks'>
                                <mat-expansion-panel-header>
                                    <mat-panel-description class='d-flex' style="align-items: center;">
                                        <div class="PRIMARYcontrolRisksTitle"> {{resultGitNew.risk_assessment.name}}: {{resultGitNew.risk_assessment.description}} </div>
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <p class="PRIMARYcontrolRisksContant">RCSA Owner: {{resultGitNew.risk_assessment.rcsa_owner.user_name}}</p>
                                <p class="PRIMARYcontrolRisksContant">Folder: {{resultGitNew.risk_assessment.folder}}</p>
                            </mat-expansion-panel>
                        </ng-container>
                        <ng-container *ngIf='resultGitNew.risk_assessments_primary_path'>
                            <ng-container *ngFor='let item of resultGitNew.risk_assessments_primary_path '>
                                <mat-expansion-panel *ngIf=' checkRisksANdRcsaId(item,resultGitNew.risk_assessment) '>
                                    <mat-expansion-panel-header>
                                        <mat-panel-description class='d-flex' style="align-items: center;">
                                            <div class="PRIMARYcontrolRisksTitle"> {{item.name}}: {{item.description}} </div>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <p class="PRIMARYcontrolRisksContant">RCSA Owner: {{item.rcsa_owner.user_name}}</p>
                                    <p class="PRIMARYcontrolRisksContant">Folder: {{item.folder}}</p>
                                </mat-expansion-panel>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf='resultGitNew.risk_assessments_secondary_path'>
                            <ng-container *ngFor='let item of resultGitNew.risk_assessments_secondary_path '>
                                <mat-expansion-panel *ngIf=' checkRisksANdRcsaId(item,resultGitNew.risk_assessment) '>
                                    <mat-expansion-panel-header>
                                        <mat-panel-description class='d-flex' style="align-items: center;">
                                            <div class="PRIMARYcontrolRisksTitle"> {{item.name}}: {{item.description}} </div>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <p class="PRIMARYcontrolRisksContant">RCSA Owner: {{item.rcsa_owner.user_name}}</p>
                                    <p class="PRIMARYcontrolRisksContant">Folder: {{item.folder}}</p>
                                </mat-expansion-panel>
                            </ng-container>
                        </ng-container>
                    </mat-accordion>
                </ng-container>
            </div>
            <div class="col-md-1 my-3">
                <span class="w-100 contantStyle">
                    Risks
                </span>
            </div>
            <div class="col-md-11 accordionContainerHeight my-3">
                <mat-accordion class="fix-radio-position" hideToggle="true">
                    <ng-container *ngIf='resultGitNew.risk'>
                        <mat-expansion-panel class='PRIMARYcontrolRisks'>
                            <mat-expansion-panel-header>
                                <mat-panel-description class='d-flex' style="align-items: center;">
                                    <div class="PRIMARYcontrolRisksTitle"> {{resultGitNew.risk.name}}: {{resultGitNew.risk.description}} </div>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <p class="PRIMARYcontrolRisksContant">Description: {{resultGitNew.risk.detail_description}}</p>
                            <p class="PRIMARYcontrolRisksContant">Folder: {{resultGitNew.risk.folder}}</p>
                        </mat-expansion-panel>
                    </ng-container>
                    <ng-container *ngIf='resultGitNew.risks_primary_path'>
                        <ng-container *ngFor='let item of resultGitNew.risks_primary_path '>
                            <mat-expansion-panel *ngIf=' checkRisksANdRcsaId(item,resultGitNew.risk) '>
                                <mat-expansion-panel-header>
                                    <mat-panel-description class='d-flex' style="align-items: center;">
                                        <div class="PRIMARYcontrolRisksTitle"> {{item.name}}: {{item.description}} </div>
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <p class="PRIMARYcontrolRisksContant">Description: {{item.detail_description}}</p>
                                <p class="PRIMARYcontrolRisksContant">Folder: {{item.folder}}</p>
                            </mat-expansion-panel>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf='resultGitNew.risks_secondary_path'>
                        <ng-container *ngFor='let item of resultGitNew.risks_secondary_path '>
                            <mat-expansion-panel *ngIf=' checkRisksANdRcsaId(item,resultGitNew.risk) '>
                                <mat-expansion-panel-header>
                                    <mat-panel-description class='d-flex' style="align-items: center;">
                                        <div class="PRIMARYcontrolRisksTitle"> {{item.name}}: {{item.description}} </div>
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <p class="PRIMARYcontrolRisksContant">Description: {{item.detail_description}}</p>
                                <p class="PRIMARYcontrolRisksContant">Folder: {{item.folder}}</p>
                            </mat-expansion-panel>
                        </ng-container>
                    </ng-container>
                </mat-accordion>
            </div>
            <ng-container *ngIf='checkList(resultGit.controls) || checkList(resultGitNew.controls_secondary_path)'>
                <div class="col-md-1 my-3">
                    <span class="w-100 contantStyle">
                        Controls
                    </span>
                </div>
                <div class="col-md-11 accordionContainerHeight my-3">
                    <mat-accordion class="fix-radio-position" hideToggle="true">
                        <ng-container *ngIf='resultGit.controls'>
                            <ng-container *ngFor='let item of sortBy(resultGit.controls,"association_type") '>
                                <mat-expansion-panel [ngClass]="  item.association_type == 'PRIMARY'   ?'PRIMARYcontrolRisks':''">
                                    <mat-expansion-panel-header>
                                        <mat-panel-description class='d-flex' style="align-items: center;">
                                            <div class="PRIMARYcontrolRisksTitle"> {{getControl(item.ctrl_id).ctrl_control_name}}: {{getControl(item.ctrl_id).ctrl_description}} </div>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <p class="PRIMARYcontrolRisksContant">Description: {{getControl(item.ctrl_id).ctrl_detail_description}}</p>
                                    <p class="PRIMARYcontrolRisksContant">Folder: {{getControl(item.ctrl_id).ctrl_folder}}</p>
                                </mat-expansion-panel>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf='resultGitNew.controls_secondary_path'>
                            <ng-container *ngFor='let item of resultGitNew.controls_secondary_path'>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-description class='d-flex' style="align-items: center;">
                                            <div class="PRIMARYcontrolRisksTitle"> {{getControl(item.ctrl_id).ctrl_control_name}}: {{getControl(item.ctrl_id).ctrl_description}} </div>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <p class="PRIMARYcontrolRisksContant">Description: {{getControl(item.ctrl_id).ctrl_detail_description}}</p>
                                    <p class="PRIMARYcontrolRisksContant">Folder: {{getControl(item.ctrl_id).ctrl_folder}}</p>
                                </mat-expansion-panel>
                            </ng-container>
                        </ng-container>
                    </mat-accordion>
                </div>
            </ng-container>
        </div>
    </mat-card>
    <app-generate-pdf [riskStatus]='riskStatus' [refreshData]='loadDataFlag' [outsourcingList]='outsourcingList' [resultGit]='resultGit' [riskCaseOverview]='riskCaseOverview' [hidden]="true" [riskCase]='riskCase' #generatePdf></app-generate-pdf>
    <input id="refreshStatus" style="display:none;" (click)='statusBar.refreshMissingField(statusBarRiskCaseData)' />
    <mat-card id="detail-container">
        <div class="h-100 contactContainer">
            <div class="d-flex justify-content-between headerContainer">
                <div>
                    <p class="contantStyle">
                        <ng-container *ngIf='riskCase.source_name'>
                            {{riskCase.source_name}}</ng-container> {{riskCase.event.title}}
                        <span *ngIf='checkEditEntity("title")' class="EditIcon  material-icons" (click)="editEntity(template, 'title')">
                            edit
                        </span>
                    </p>
                    <p>
                        <span [ngClass]="(checkEditEntity('RCSA')&&checkRequired([ 6,7,8, 9, 10],riskCase.mandate))?'required':''">
                            RCSA<span class="required" *ngIf='checkEditEntity("RCSA")&&checkRequired([6,7, 8, 9, 10],riskCase.mandate)'>*</span>
                        </span>:
                        <ng-container *ngIf='!resultGitNew.risk_assessment'>
                            <span class="required" *ngIf='!resultGitNew.risk_assessment'>missing</span>
                        </ng-container>

                        <ng-container *ngIf='resultGitNew.risk_assessment'>{{resultGitNew.risk_assessment.name}}, {{resultGitNew.risk_assessment.description}}, {{resultGitNew.risk_assessment.rcsa_owner.user_name}}
                            <span *ngIf='checkEditEntity("RCSA")' class="EditIcon  material-icons" (click)="editEntity(template, 'RCSA')">
                                edit
                            </span>
                        </ng-container>
                    </p>
                    <p>
                        <ng-container *ngIf='resultGitNew.risk'>
                            Parent Risk: {{resultGitNew.risk.name}} {{resultGitNew.risk.description}}
                            <ng-container *ngIf='checkEditEntity("Risk")'>
                                <span *ngIf='resultGitNew.risks_primary_path' class="EditIcon  material-icons" (click)="editEntity(template, 'Risk')">
                                    edit
                                </span>
                            </ng-container>
                        </ng-container>
                    </p>
                    <p *ngIf='riskCaseOverview'>
                        Folder: {{riskCaseOverview.folder}}
                    </p>
                </div>
                <div>
                    <div style="display: none;">
                        <app-information-dialog #infoDialog></app-information-dialog>
                    </div>
                    <span (click)='infoDialog.openModal()' class="summaryIcon  material-icons">
                        info
                    </span>
                    <span (click)='generatePdf.generatePDF()' class="summaryIcon  material-icons">
                        picture_as_pdf
                    </span>
                    <span (click)="editEntity(template, 'attachment')" class="summaryIcon position-relative  material-icons">
                        attach_file <span class='attachmentLength'>
                            {{uploadedList.length}}
                        </span>
                    </span>
                    <span *ngIf='riskCase.lastStep!=99 && statusDisabled([6,37])' (click)='goToStep()' class="summaryIcon  material-icons">
                        map
                    </span>
                    <mat-icon class="summaryIcon  material-icons" (click)="showPermalinkDialog()" matTooltip="Share this case" [matTooltipPosition]="'above'">exit_to_app</mat-icon>
                    <app-permalink-dialog [displayPermalink]="displayPermalink" (hideEvent)="hidePermalinkDialog()">
                    </app-permalink-dialog>
                    <span [routerLink]="['/exit/', riskCase.lastStep]" class="summaryIcon  material-icons">
                        close
                    </span>
                </div>
            </div>
            <div class="hlup"></div>
            <div class=" contantContainer mt-4">
                <div class="row">
                    <div class="col-md-4">
                        <div class="d-flex">
                            <div class=""> <span class="summaryIcon  material-icons">
                                    account_circle
                                </span></div>
                            <div>
                                <p [ngClass]="(checkEditEntity('event')&&checkRequired([ 6,7,8, 9, 10],riskCase.roles.eventOwner))?'required':''" class="mb-0">
                                    Event Owner<span class="required" *ngIf='checkEditEntity("event")&&checkRequired([6,7, 8, 9, 10],riskCase.roles.eventOwner)'>*</span>
                                </p>
                                <p class="contantStyle">{{riskCase.roles.eventOwner.user_name}}
                                    <span *ngIf='riskCase.roles.eventOwner.user_status == "INACTIVE"'>(Inactive)</span>
                                    <span class="EditIcon  material-icons" *ngIf='checkEditEntity("event")' (click)="editEntity(template, 'event')">
                                        edit
                                    </span>
                                </p>
                                <p class="mb-0">Delegate</p>
                                <p *ngIf='riskCase.roles.eventDelegate' class="contantStyle">
                                    {{riskCase.roles.eventDelegate.user_name}}
                                    <span *ngIf='riskCase.roles.eventDelegate.user_status == "INACTIVE"'>(Inactive)</span>
                                    <span class="EditIcon  material-icons" *ngIf='checkEditEntity("event")' (click)="editEntity(template, 'event')">
                                        edit
                                    </span>
                                </p>
                                <p *ngIf='!riskCase.roles.eventDelegate' class="contantStyle">
                                    <span class="EditIcon  material-icons" *ngIf='checkEditEntity("event")' (click)="editEntity(template, 'event')">
                                        add
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-flex">
                            <div class=""> <span class="summaryIcon  material-icons">
                                    date_range
                                </span></div>
                            <div>
                                <p [ngClass]="(checkEditEntity('event') &&checkRequired([ 6,7,8, 9, 10],riskCase.event.discoveryDate))?'required':''" class="mb-0">
                                    Discovery Date (CET)<span class="required" *ngIf='checkEditEntity("event") &&checkRequired([6,7, 8, 9, 10],riskCase.event.discoveryDate)'>*</span>
                                </p>
                                <p class="contantStyle">{{dateValidate(riskCase.event.discoveryDate) | oneOrmDate}}
                                    <span class="EditIcon  material-icons" *ngIf='checkEditEntity("event")' (click)="editEntity(template, 'event')">
                                        edit
                                    </span>
                                </p>
                                <p [ngClass]="(checkEditEntity('event') &&checkRequired([ 6,7,8, 9, 10],riskCase.event.occurredDate))?'required':''" class="mb-0">Occurrence Date (CET)<span class="required" *ngIf='checkEditEntity("event") &&checkRequired([6,7, 8, 9, 10],riskCase.event.occurredDate)'>*</span>
                                </p>
                                <p class="contantStyle">{{dateValidate(riskCase.event.occurredDate) | oneOrmDate}}
                                    <span class="EditIcon  material-icons" *ngIf='checkEditEntity("event")' (click)="editEntity(template, 'event')">
                                        edit
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="d-flex">
                            <div class=""> <span class="summaryIcon  material-icons">
                                    daccess_time
                                </span></div>
                            <div>
                                <p class="mb-0">
                                    Status
                                </p>
                                <p class="contantStyle"> {{riskStatus}}
                                </p>
                                <p class="mb-0">Op Risk Manager * (read only)</p>
                                <p class="contantStyle">{{riskCase.mandate.risk_manager}}
                                    <span *ngIf='resultGit.risk_manager.user_status == "INACTIVE"'>(Inactive)</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="hlup"></div>
                <div class="hlup"></div>
                <div class="d-flex justify-content-between">
                    <div class="w-100">
                        <div class="row w-100  py-4">
                            <div class="col-md-4 ">
                                <div class="  contantStyle">
                                    <span [ngClass]="(checkEditEntity('description')&&checkRequired([6,7, 8, 9, 10],riskCase.event.description))?'required':''" class=" contantStyle">
                                        Description
                                    </span><span class="required" *ngIf='checkEditEntity("description")&&checkRequired([ 6,7,8, 9, 10],riskCase.event.description)'>*</span>
                                    <span *ngIf='checkEditEntity("description") ' (click)="editEntity(template, 'description')" class="EditIcon  material-icons">
                                        edit
                                    </span>
                                </div>
                                <div class="">
                                    <div class="mb-3 w-100 descriptionView" *ngIf="riskCase.event">
                                        <textarea cols="50" [disabled]='true' id="eventdescriptionInput" class="textBoxView" [(ngModel)]='riskCase.event.description' rows="8">
                                          </textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 ">
                                <span class="w-100 contantStyle" [ngClass]="(checkEditEntity('rootCauseDescription') && checkRequired([ 8, 9, 10],riskCase.root_cause_description))?'required':''">
                                    Root Cause Description<span class="required" *ngIf='checkEditEntity("rootCauseDescription") && checkRequired([ 8, 9, 10],riskCase.root_cause_description)'>*</span>
                                <span *ngIf='checkEditEntity("rootCauseDescription") ' (click)="editEntity(template, 'rootCauseDescription')" class="EditIcon  material-icons">
                                        edit
                                    </span>
                                </span>
                                <div class="mb-3 w-100 descriptionView">
                                    <textarea cols="50" [disabled]='true' id="eventroot_cause_descriptionInput" class="textBoxView" [(ngModel)]='riskCase.root_cause_description' rows="8">
                                       </textarea>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <span class="w-100 py-4 contantStyle" [ngClass]="(checkEditEntity('lessonLearnDescription')&&checkRequired([ 8, 9, 10],riskCase.lesson_learn_description))?'required':''">
                                    Lessons Learned and Actions Taken<span class="required"
                                        *ngIf='checkEditEntity("lessonLearnDescription")&&checkRequired([ 8, 9, 10],riskCase.lesson_learn_description)'>*</span>
                                <span *ngIf='checkEditEntity("lessonLearnDescription")' (click)="editEntity(template, 'lessonLearnDescription')" class="EditIcon  material-icons">
                                        edit
                                    </span>
                                </span>
                                <div class="mb-3 w-100 descriptionView">
                                    <textarea cols="50" [disabled]='true' id="eventLessonsInput" class="textBoxView" [(ngModel)]='riskCase.lesson_learn_description' rows="8">
                                           </textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sidTitleImg">
                        <img src="../../../../../assets/images/summary/eventNatratev.PNG" alt="">
                    </div>
                </div>
                <div class="hlup"></div>
                <div class="d-flex justify-content-between">
                    <div class="w-100">
                        <div class="row minH mt-2 ">
                            <div class=" col-md-4">
                                <span class="contantStyle" [ngClass]="(checkEditEntity('AdditionalInformation')&&checkRequired([ 8, 9, 10],riskCase.location))?'required':''">
                                    Event Location<span class="required" *ngIf='checkEditEntity("AdditionalInformation")&&checkRequired([ 8, 9, 10],riskCase.location)'>*</span>
                                </span><span class="EditIcon  material-icons" *ngIf='checkEditEntity("AdditionalInformation")' (click)="editEntity(template, 'AdditionalInformation')">
                                    edit
                                </span>
                                <p *ngIf='riskCase.location' class=""> {{riskCase.location.name}}</p>
                            </div>
                            <div class="bLeft col-md-4">
                                <span class="contantStyle" [ngClass]="(checkEditEntity('outsourcing')&&checkRequired([ 8, 9, 10],riskCase.outsourcing))?'required':''">
                                    Outsourcing<span class="required" *ngIf='checkEditEntity("outsourcing")&&checkRequired([ 8, 9, 10],riskCase.outsourcing)'>*</span>
                                </span><span class="EditIcon  material-icons" *ngIf='checkEditEntity("outsourcing")' (click)="editEntity(template, 'outsourcing')">
                                    edit
                                </span>
                                <div *ngIf='riskCase.outsourcing'>
                                    {{ outsourse(riskCase.outsourcing) }}
                                </div>
                            </div>
                        </div>
                        <div class="row minH mt-2 ">
                            <div class="col-md-4">
                                <span [ngClass]="(checkEditEntity('businessImpact')&&checkRequired([ 8, 9, 10],(BiLists(riskCase.businessImpact).length>0?BiLists(riskCase.businessImpact):null) ))?'required':''" class="contantStyle">
                                    Impacted Business Units<span class="required"
                                        *ngIf='checkEditEntity("businessImpact")&&checkRequired([ 8, 9, 10],(BiLists(riskCase.businessImpact).length>0?BiLists(riskCase.businessImpact):null))'>*</span>
                                </span><span class="EditIcon  material-icons" *ngIf='checkEditEntity("businessImpact")' (click)="editEntity(template, 'businessImpact')">
                                    edit
                                </span>
                                <ng-container *ngIf='loadDataFlag'>
                                    <div class="row" *ngIf='showBUI'>
                                        <div class="col-md-12" *ngFor="let Capital of  BiLists(riskCase.businessImpact); let i = index">
                                            <div class="showMoreLess" *ngIf="numberOfItems < BiLists(riskCase.businessImpact).length && i==3 " (click)='numberOfItems = BiLists(riskCase.businessImpact).length'>
                                                show more...
                                            </div>
                                            <div *ngIf="i < numberOfItems">
                                                {{Capital.bus_impact_name}}
                                            </div>
                                            <div class="showMoreLess" *ngIf=" BiLists(riskCase.businessImpact).length>3 && i==BiLists(riskCase.businessImpact).length-1  && numberOfItems > 3" (click)='numberOfItems = 3'>
                                                show less...
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="bLeft col-md-4">
                                <span [ngClass]="(checkEditEntity('legalEntities')&&checkRequired([ 6,7,8, 9, 10], (riskCase.legalEntities.length>0?riskCase.legalEntities:null)    ))?'required':''" class="contantStyle">
                                    Impacted Legal Entities<span class="required"
                                        *ngIf='checkEditEntity("legalEntities")&&checkRequired([ 6,7,8, 9, 10],(riskCase.legalEntities.length>0?riskCase.legalEntities:null) )'>*</span>
                                </span><span class="EditIcon  material-icons" *ngIf='checkEditEntity("legalEntities")' (click)="editEntity(template, 'legalEntities')">
                                    edit
                                </span>
                                <div *ngIf="riskCase.legalEntities.length > 0 && loadDataFlag">
                                    <app-step8 [pdfView]='false' [viewButton]='false' [edit]='false'></app-step8>
                                </div>
                            </div>
                        </div>
                        <div class="row minH my-2 ">
                            <div class=" col-md-4">
                                <span [ngClass]="(checkEditEntity('Function')&&checkRequired([ 8, 9, 10],riskCase.company_function))?'required':''" class="contantStyle">
                                    Function Code<span class="required" *ngIf='checkEditEntity("Function") && checkRequired([ 8, 9, 10],riskCase.company_function)'>*</span>
                                </span><span class="EditIcon  material-icons" *ngIf='checkEditEntity("Function")' (click)="editEntity(template, 'Function')">
                                    edit
                                </span>
                                <p *ngIf='riskCase.company_function' class=""> {{riskCase.company_function.name}}</p>
                            </div>
                            <div class="bLeft col-md-4">
                                <span [ngClass]="(checkEditEntity('RootCauses')&&checkRequired((step10_View.chechEnableRadioBtn(91,step10_View.selectedList))?[6, 7, 8, 9, 10]:[8,9, 10],riskCase.root_cause_level_2))?'required':''" class=" contantStyle">
                                    Root Cause Code<span class="required"
                                        *ngIf='checkEditEntity("RootCauses")&&checkRequired((step10_View.chechEnableRadioBtn(91,step10_View.selectedList))?[6, 7, 8, 9, 10]:[8,9, 10],riskCase.root_cause_level_2)'>*</span>
                                </span> <span class="EditIcon  material-icons" *ngIf='checkEditEntity("RootCauses")' (click)="editEntity(template, 'RootCauses')">
                                    edit
                                </span>
                                <p class="">Level 1: -
                                    <ng-container *ngIf='riskCase.root_cause_level_1'>
                                        {{riskCase.root_cause_level_1.name}}</ng-container>
                                </p>
                                <p class="">Level 2: -
                                    <ng-container *ngIf='riskCase.root_cause_level_2'>
                                        {{riskCase.root_cause_level_2.name}}</ng-container>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="sidTitleImg">
                        <img src="../../../../../assets/images/summary/ORGANISATIONALATTRIBUTES.png" alt="">
                    </div>
                </div>
                <div class="hlup"></div>
                <div class="d-flex justify-content-between">
                    <div class="row minH my-2 w-100">
                        <div class="col-md-4">
                            <span class="contantStyle t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.icofer , 'right')">
                                ICOFR
                            </span><span class="readOnly">
                                (read only)
                            </span>
                            <div *ngIf='riskCaseOverview'>
                                {{riskCaseOverview.icofr1_name}}
                            </div>
                            <div *ngIf='riskCaseOverview'>
                                {{riskCaseOverview.icofr2_name}}
                            </div>
                            <div *ngIf='riskCaseOverview'>
                                {{riskCaseOverview.icofr3_name}}
                            </div>
                        </div>
                        <div class="col-md-4 bLeft">
                            <span class="contantStyle">
                                Local Reporting
                            </span><span class="EditIcon  material-icons" *ngIf='checkEditEntity("localReporting")' (click)="editEntity(template, 'localReporting')">
                                edit
                            </span>
                            <ng-container *ngIf='riskCase.local_reporting'>
                                <div *ngFor='let item of riskCase.local_reporting'>
                                    {{item.name}}
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="sidTitleImg">
                        <img src="../../../../../assets/images/summary/frr.png" alt="">
                    </div>
                </div>
                <div class="hlup"></div>
                <div class="d-flex justify-content-between">
                    <div class="row  w-100">
                        <div class="col-md-12">
                            <span class="contantStyle" [ngClass]="(checkEditEntity('monetaryImpact')&&checkRequired([ 8, 9, 10],(AllMIList.length>0?AllMIList:null) ))?'required':''">
                                Monetary Impact<span class="required" *ngIf='checkEditEntity("monetaryImpact")&&checkRequired([ 8, 9, 10],(AllMIList.length>0?AllMIList:null))'>*</span>
                            </span><span class="EditIcon  material-icons" *ngIf='checkEditEntity("monetaryImpact")' (click)="editEntity(template, 'monetaryImpact')">
                                edit
                            </span>
                            <div class="w-100">
                                <app-step10-event #step10_View [viewOnly]='true' (newData)='updateData($event)'></app-step10-event>
                            </div>
                        </div>
                    </div>
                    <div class="sidTitleImg">
                        <img src="../../../../../assets/images/summary/MONETARYIMPACT.png" alt="">
                    </div>
                </div>
                <div class="hlup"></div>
                <div class="d-flex justify-content-between">
                    <div class="row  w-100">
                        <div class="row minH m-2 w-100">
                            <div class="col-md-4">
                                <span *ngIf='!showButtonForUserRole(["CORM","ORM","ORMII"])' ngxTippy [tippyProps]="tooltip(FieldHelpData.EventType , 'right')" class="contantStyle t-demo__btn">
                                    Event Type
                                </span>
                                <span *ngIf='showButtonForUserRole(["CORM","ORM","ORMII"])' [ngClass]="(checkEditEntity('EventType') &&checkRequired((step10_View.chechEnableRadioBtn(91,step10_View.selectedList))?[6, 7, 8, 9, 10]:[8,9, 10],riskCase.event_type_level_2))?'required':''"
                                    ngxTippy [tippyProps]="tooltip(FieldHelpData.EventType , 'right')" class="contantStyle t-demo__btn">
                                    Event Type<span class="required"
                                        *ngIf='checkEditEntity("EventType") && checkRequired((step10_View.chechEnableRadioBtn(91,step10_View.selectedList))?[ 6,7, 8, 9, 10]:[8,9, 10],riskCase.event_type_level_2)'>*</span>
                                </span>
                                <span class="readOnly" *ngIf='!showButtonForUserRole(["CORM","ORM","ORMII"])'>
                                    (read only)
                                </span>
                                <ng-container *ngIf='checkEditEntity("EventType")'>
                                    <span *ngIf='showButtonForUserRole(["CORM","ORM","ORMII"])' class="EditIcon  material-icons" (click)="editEntity(template, 'EventType')">
                                        edit
                                    </span>
                                </ng-container>
                                <p class="">Level 1: -
                                    <ng-container *ngIf='riskCase.event_type_level_1'>
                                        {{riskCase.event_type_level_1.name}}</ng-container>
                                </p>
                                <p class="">Level 2: -
                                    <ng-container *ngIf='riskCase.event_type_level_2'>
                                        {{riskCase.event_type_level_2.name}}</ng-container>
                                </p>
                            </div>
                            <div class=" bLeft col-md-6">
                                <span *ngIf='!showButtonForUserRole(["CORM","ORM","ORMII"])' class="contantStyle t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.scenarioLink , 'right')">
                                    Scenario Linkage
                                </span>
                                <span *ngIf='showButtonForUserRole(["CORM","ORM","ORMII"])' [ngClass]="(checkEditEntity('EventType') && checkRequired((step10_View.chechEnableRadioBtn(91,step10_View.selectedList))?[ 6,7, 8, 9, 10]:[8,9, 10],riskCase.scenario_link))?'required':''" ngxTippy
                                    [tippyProps]="tooltip(FieldHelpData.scenarioLink , 'right')" class="contantStyle t-demo__btn">
                                    Scenario Linkage<span class="required"
                                        *ngIf='checkEditEntity("EventType") && checkRequired((step10_View.chechEnableRadioBtn(91,step10_View.selectedList))?[6, 7, 8, 9, 10]:[8,9, 10],riskCase.scenario_link)'>*</span>
                                </span>
                                <span class="readOnly" *ngIf='!showButtonForUserRole(["CORM","ORM","ORMII"])'> (read only)
                                </span>
                                <ng-container *ngIf='checkEditEntity("scenarioLink")'>
                                    <span *ngIf='showButtonForUserRole(["CORM","ORM","ORMII"])' class="EditIcon  material-icons" (click)="editEntity(template, 'scenarioLink')">
                                        edit
                                    </span>
                                </ng-container>
                                <ng-container *ngIf='riskCase.scenario_link'>
                                    <p>
                                        {{riskCase.scenario_link.name}}
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                        <div class="hlup w-100"></div>
                        <div class="row w-100  minH mt-2 mx-2">
                            <div class="col-md-8">
                                <span *ngIf='!showButtonForUserRole(["CORM","Comp"])' ngxTippy [tippyProps]="tooltip(FieldHelpData.complianceRiskCategory , 'right')" class="contantStyle t-demo__btn">
                                    Compliance and Legal Risk Category
                                </span>
                                <span *ngIf='showButtonForUserRole(["CORM","Comp"])' [ngClass]="(checkEditEntity('complianceRiskCategory') && checkRequired([  9, 10],(riskCase.compliance_risk_category.length>0?riskCase.compliance_risk_category:null)))?'required':''" ngxTippy [tippyProps]="tooltip(FieldHelpData.complianceRiskCategory , 'right')"
                                    class="contantStyle t-demo__btn">
                                    Compliance and Legal Risk Category<span class="required"
                                        *ngIf='checkEditEntity("complianceRiskCategory") && checkRequired([9, 10],(riskCase.compliance_risk_category.length>0?riskCase.compliance_risk_category:null))'>*</span>
                                </span>
                                <span *ngIf='!showButtonForUserRole(["CORM","Comp","UM"])' class="readOnly">
                                    (read only)
                                </span>
                                <span *ngIf='showButtonForUserRole(["CORM","Comp","UM"]) && (riskCase.status != 11 && riskCase.status != 10)' class="EditIcon  material-icons" (click)="editEntity(template, 'complianceRiskCategory')">
                                    edit
                                </span>
                                <ng-container *ngIf='riskCase.compliance_risk_category'>
                                    <div *ngFor='let item of riskCase.compliance_risk_category'>
                                        <ng-container *ngIf='item.id == 168'>
                                            Not Compliance and Legal Risk related
                                        </ng-container>
                                        <ng-container *ngIf='item.id != 168'>
                                            {{item.name}}
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="sidTitleImg">
                        <img src="../../../../../assets/images/summary/ADDITIONAATTRIBUTES.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </mat-card>
</ng-container>
<ng-template #template>
    <div class="modal-body">
        <div class="w-100">
            <ng-container *ngIf="modelView=='event'">
                <div style="max-width: 1000px;">
                    <div class="row  p-4">
                        <div class='col-md-12 mb-4'></div>
                        <div class='col-md-2 mb-4 t-demo__btn' ngxTippy [tippyProps]="tooltip(FieldHelpData.Roles.EventOwner,'right')"> Event Owner
                        </div>
                        <div class='col-md-10 mb-4'>
                            <p-autoComplete [(ngModel)]="riskCase.roles.eventOwner" [suggestions]="filterUsersSingleList" (completeMethod)="filterUsersSingle($event)" [field]="composeName" [size]="50" (onSelect)="selectUser($event,'eventOwner')" placeholder="Name of SR Employee (Mandatory)"
                                [minLength]="1" [forceSelection]="true" emptyMessage="{{filterUsersSingleList && filterUsersSingleList.length >0 ? '' : 'User Not Found'}}">
                                <ng-template let-user pTemplate="item">
                                    <div>
                                        {{user.user_name}} ({{user.user_id}})
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                        </div>
                        <div class='col-md-2 mb-4 t-demo__btn' ngxTippy [tippyProps]="tooltip(FieldHelpData.Roles.EventDelegate,'right')"> Event Delegate
                        </div>
                        <div class='col-md-10 mb-4'>
                            <p-autoComplete [(ngModel)]="riskCase.roles.eventDelegate" [suggestions]="filterUsersSingleList" (completeMethod)="filterUsersSingle($event)" [field]="composeName" [size]="50" placeholder="Name of SR Employee (Optional)" [minLength]="1" [forceSelection]="true"
                                emptyMessage="{{filterUsersSingleList && filterUsersSingleList.length >0 ? '' : 'User Not Found'}}">
                                <ng-template let-user pTemplate="item">
                                    <div>
                                        {{user.user_name}} ({{user.user_id}})
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                        </div>
                        <ng-container>
                            <div class="col-md-2 mb-4  t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.Description.DiscoveryDate, 'right')">Discovery Date (CET)</div>
                            <div class="col-md-10 mb-4">
                                <div class="input-container position-relative">
                                    <div (click)='!statusDisabled([8,9]) && discoveryDate.open()' [ngClass]="(!statusDisabled([8,9]))?'':'DisabledDatePicker'" class=" inputIcon material-icons">
                                        date_range
                                    </div>
                                    <input class="input-field" (keypress)="cantEdit($event)" placeholder="DD-MMM-YYYY" [disabled]='true' [ngClass]="(statusDisabled([8,9]))?'':'notDisabledInput'" [ngModelOptions]="{standalone: true}" [ngModel]="dateValidate(riskCase.event.discoveryDate) | date: dateFormat"
                                        name="event.discoveryDate">
                                    <input [max]="today" [matDatepicker]='discoveryDate' class="input-field matrialInputH" placeholder="dd-mm-yyyy" (dateChange)="dateChanged($event.value, 'discoveryDate')" name="event.discoveryDate">
                                    <mat-datepicker #discoveryDate></mat-datepicker>
                                </div>
                            </div>
                            <div class="col-md-2 mb-4  t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.Description.OccurrenceDate, 'right')">Occurrence Date (CET)</div>
                            <div class="col-md-10 mb-4">
                                <div class="input-container position-relative">
                                    <div (click)='occurredDate.open()' class=" inputIcon material-icons">
                                        date_range
                                    </div>
                                    <input (keypress)="cantEdit($event)" class="input-field" placeholder="DD-MMM-YYYY" [disabled]='true' class="notDisabledInput" [ngModelOptions]="{standalone: true}" [ngModel]="dateValidate(riskCase.event.occurredDate) | date: dateFormat" name="event.occurredDate">
                                    <input [max]="riskCase.event.discoveryDate" [matDatepicker]='occurredDate' (dateChange)="dateChanged($event.value, 'occurredDate')" class="input-field matrialInputH" placeholder="dd-mm-yyyy">
                                    <mat-datepicker #occurredDate></mat-datepicker>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div class="ml-4 errorMsg">
                            <ng-container *ngIf='showError'>
                                <p class="mb-1">
                                    You have already entered one or more monetary impact items with ‘Date of Acknowledgement’ BEFORE this Discovery Date.
                                </p>
                                <p class="mb-1">
                                    You need to update the ‘Date of Acknowledgement’ of these items before you can proceed with this ‘Discovery Date’.
                                </p>
                            </ng-container>
                        </div>
                        <div class="d-flex justify-content-end">
                            <button class='primaryBtn mr-4   buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>
                            <div [matTooltip]="checkValidation().msg" [matTooltipPosition]="'above'">
                                <button class='primaryBtn   buttonWidth' pButton type="button" label="Update" [disabled]="checkValidation().disabled" (click)="update()"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="modelView=='businessImpact'">
                <app-step7-event [BUInput]='riskCase.businessImpact' [viewButton]='false' (newData)='updateData($event)'></app-step7-event>
                <div class="d-flex justify-content-end">
                    <button class='primaryBtn  mr-4   buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>
                    <button class='primaryBtn buttonWidth' pButton type="button" label="Update" (click)="update()"></button>
                    <div class='d-flex'>
                        <app-information-dialog [accordionIndex]='5'></app-information-dialog>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="modelView=='RCSA'">
                <p>
                    Changing the RCSA may impact selected risks and controls. Please contact your Op Risk Manager to assess the impact and perform the required changes in GRACE. </p>
                <div class="d-flex justify-content-end">
                    <button class='primaryBtn mr-4   buttonWidth' pButton type="button" label="Close" (click)="cancel()"></button>
                </div>
            </ng-container>
            <ng-container *ngIf="modelView=='Risk'">
                <app-step4-event [viewButton]='false' (newData)='updateData($event)'></app-step4-event>
                <div class="d-flex justify-content-end">
                    <button class='primaryBtn   mr-4   buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>
                    <button class='primaryBtn  buttonWidth' pButton type="button" label="Update" (click)="update()"></button>
                    <div class='d-flex'>
                        <app-information-dialog [accordionIndex]='3'></app-information-dialog>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="modelView=='Risks&ControlsLowProfile'">
                <p>
                    Risk and Control changes can only be done in GRACE. Please contact your Op Risk Manager or Governance Coordinator for this. </p>
                <div class="d-flex justify-content-end">
                    <button class='primaryBtn mr-4   buttonWidth' pButton type="button" label="Close" (click)="cancel()"></button>
                </div>
            </ng-container>
            <ng-container *ngIf="modelView=='Risks&ControlsHighProfile'">
                <p>
                    Risk and Control changes can only be done in GRACE. Please open the case in GRACE here to take action:</p>
                <p class="contantStyle cursor_pointer" (click)='goOutsideApp(resultGit.url)'> {{resultGit.url}}</p>
                <div class="d-flex justify-content-end">
                    <button class='primaryBtn mr-4   buttonWidth' pButton type="button" label="Close" (click)="cancel()"></button>
                </div>
            </ng-container>
            <ng-container *ngIf="modelView=='outsourcing'">
                <app-step9 [viewButton]='false' #step9 (newData)='updateDataAndSave($event)'></app-step9>
                <div class="d-flex justify-content-end">
                    <button class='primaryBtn    mr-4  buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>
                    <button class='primaryBtn  buttonWidth' [disabled]='step9.canUpdate()' pButton type="button" label="Update" (click)="step9.changeData()"></button>
                    <div class='d-flex'>
                        <app-information-dialog [accordionIndex]='7'></app-information-dialog>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="modelView=='monetaryImpact'">
                <app-step10-event #step10 [viewButton]='false' (newData)='updateMI_view()'></app-step10-event>
                <div class="d-flex justify-content-end">
                    <button class='primaryBtn   mr-4  buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>
                    <button class='primaryBtn  buttonWidth' pButton type="button" label="Update" [disabled]="!step10.checkValiationOffListItems(step10.selectedList,['amount']) || step10.checkTextList(step10.selectedList)" (click)="step10.changeData()"></button>
                    <div class='d-flex'>
                        <app-information-dialog [accordionIndex]='9'></app-information-dialog>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="modelView=='RootCauses'">
                <app-additional-information-step [viewButton]='false' [rootCause]='true' [location]='false' [Function]='false' (newData)='updateData($event)'></app-additional-information-step>
                <div class="d-flex justify-content-end">
                    <button class='primaryBtn mr-4  buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>
                    <button class='primaryBtn   buttonWidth' pButton type="button" label="Update" (click)="update()"></button>
                    <app-information-dialog [accordionIndex]='12'></app-information-dialog>
                </div>
            </ng-container>
            <ng-container *ngIf="modelView=='AdditionalInformation'">
                <app-additional-information-step [viewButton]='false' [rootCause]='false' [location]='true' [Function]='false' (newData)='updateData($event)'></app-additional-information-step>
                <div class="d-flex justify-content-end">
                    <button class='primaryBtn  mr-4  buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>
                    <button class='primaryBtn  buttonWidth' pButton type="button" label="Update" (click)="update()"></button>
                </div>
            </ng-container>
            <ng-container *ngIf="modelView=='Function'">
                <app-additional-information-step [viewButton]='false' [rootCause]='false' [location]='false' [Function]='true' (newData)='updateData($event)'></app-additional-information-step>
                <div class="d-flex justify-content-end">
                    <button class='primaryBtn mr-4  buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>
                    <button class='primaryBtn   buttonWidth' pButton type="button" label="Update" (click)="update()"></button>
                </div>
            </ng-container>
            <ng-container *ngIf="modelView=='legalEntities'">
                <app-step8 [viewButton]='false' #step8 (newData)='updateDataAndSave($event)'></app-step8>
                <div class="d-flex justify-content-end">
                    <button class='primaryBtn mr-4   buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>
                    <button class='primaryBtn  buttonWidth' pButton type="button" label="Update" (click)="step8.changeData()"></button>
                    <div class='d-flex'>
                        <app-information-dialog [accordionIndex]='6'></app-information-dialog>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="modelView=='localReporting'">
                <p class="t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.LocalReporting, 'right')">
                    Local Reporting
                </p>
                <app-enum-item-selection #checkbox2 [list]='localReporting' [preselected]="riskCase.local_reporting" [fullWidth]='true' [title]='"Local Reporting"' (selectedList)='getList($event,"local_reporting")'>
                </app-enum-item-selection>
                <div class="d-flex justify-content-end">
                    <button class='primaryBtn  mr-4  buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>
                    <button class='primaryBtn  buttonWidth' pButton type="button" label="Update" (click)="update()"></button>
                </div>
            </ng-container>
            <ng-container *ngIf="modelView=='scenarioLink'">
                <div class="row">
                    <div class="  col-md-12   my-3">
                        <div class="borderLine d-flex align-items-start flex-column h-100">
                            <p class="mb-0 t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.ScenarioLinkage,'right')">
                                Scenario Linkage:
                            </p>
                            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group">
                                <mat-radio-button (change)='setAdditionalInformationData(scenario,"scenario_link" )' class="example-radio-button  " *ngFor="let scenario of scenarioLink" [value]="scenario.id" [checked]="riskCase.scenario_link ? scenario.id === riskCase.scenario_link.id : false">
                                    <span>
                                        {{scenario.name}}
                                    </span>
                                </mat-radio-button>
                            </mat-radio-group>
                            <div *ngIf='riskCase.scenario_link' class="mt-auto  w-100 d-flex justify-content-start align-items-end">
                                <div class="mr-4"> <span class="contantStyle">
                                        Your Selection:
                                    </span> </div>
                                <div class="d-flex">
                                    <span>
                                        {{riskCase.scenario_link.name}}
                                    </span>
                                    <span class="clearSelection ml-2 material-icons" matTooltip="Clear your selection" [matTooltipPosition]="'above'" (click)='setAdditionalInformationData(null,"scenario_link" )'>
                                        close
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <button class='primaryBtn  mr-4 buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>
                    <button class='primaryBtn   buttonWidth' pButton type="button" label="Update" (click)="update()"></button>
                </div>
            </ng-container>
            <ng-container *ngIf="modelView=='complianceRiskCategory'">
                <p class="mb-0 ">
                    Compliance and Legal Risk Category:
                </p>
                <mat-checkbox (change)="nocomplianceRiskCategory($event.checked,complianceRiskCategorycheckbox)" [checked]='checkNotCC(riskCase.compliance_risk_category,168)'>
                    Not Compliance and Legal Risk related <strong class='ml-1'> (Note: Legal Risk in this context only refers to Fair Competition and Licensing risks.)</strong>
                </mat-checkbox>
                <app-enum-item-selection [list]='complianceRiskCategory' #complianceRiskCategorycheckbox [preselected]="riskCase.compliance_risk_category" [fullWidth]='true' [title]='"Compliance and Legal Risk Category"' (selectedList)='getList($event,"compliance_risk_category")'></app-enum-item-selection>
                <div class="d-flex justify-content-end">
                    <button class='primaryBtn  mr-4 buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>
                    <button class='primaryBtn  buttonWidth' pButton type="button" label="Update" (click)="update()"></button>
                    <div class='d-flex'>
                        <app-information-dialog [accordionIndex]='14'></app-information-dialog>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="modelView=='cantEdit'">
                <p>
                    You cannot edit this item.
                </p>
                <div class="d-flex justify-content-end">
                    <button class='primaryBtn   buttonWidth' pButton type="button" label="OK" (click)="modalRef.hide()"></button>
                </div>
            </ng-container>
            <ng-container *ngIf="modelView=='notComp'">
                <p>
                    To be filled in by Compliance.
                </p>
                <div class="d-flex justify-content-end">
                    <button class='primaryBtn   buttonWidth' pButton type="button" label="OK" (click)="modalRef.hide()"></button>
                </div>
            </ng-container>
            <ng-container *ngIf="modelView=='EventType'">
                <div class="row">
                    <div class="  col-md-6   my-3">
                        <div class="borderLine d-flex align-items-start flex-column h-100">
                            <p class="mb-0">
                                Event Type Level 1:
                            </p>
                            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group">
                                <mat-radio-button (change)='seteventTypes2(rootCauseItem)' class="example-radio-button" *ngFor="let rootCauseItem of eventTypes1" [value]="rootCauseItem.level_1_id" [checked]="riskCase.event_type_level_1 ? rootCauseItem.level_1_id === riskCase.event_type_level_1.id : false">
                                    {{rootCauseItem.level_1_name}}
                                </mat-radio-button>
                            </mat-radio-group>
                            <div *ngIf='riskCase.event_type_level_1' class="mt-auto  w-100 d-flex justify-content-start align-items-end">
                                <div class="mr-4"> <span class="contantStyle">
                                        Your Selection:
                                    </span> </div>
                                <div class="d-flex">
                                    <span>
                                        {{riskCase.event_type_level_1.name}}
                                    </span>
                                    <span class="clearSelection ml-2 material-icons" matTooltip="Clear your selection" [matTooltipPosition]="'above'" (click)='seteventTypes2(null)'>
                                        close
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-container>
                        <div class="  col-md-6   my-3" *ngIf='eventTypes2.length!=0'>
                            <div class="borderLine d-flex align-items-start flex-column h-100">
                                <div class="containerDiv p-1 w-100">
                                    <p>
                                        Event Type Level 2:
                                    </p>
                                    <app-custom-search [placeholder]='"Search Root Cause Code Level 2 Search"' class="d-flex justify-content-center" (value)="SearchRCL2($event)">
                                    </app-custom-search>
                                    <div class="radioBtnContainer">
                                        <div *ngIf="eventTypes2.length == 0" class="d-flex justify-content-center align-items-center loading">Loading...
                                        </div>
                                        <mat-accordion class="fix-radio-position" hideToggle="true">
                                            <mat-radio-group aria-label="Select an option">
                                                <mat-expansion-panel *ngFor='let item of eventTypes2 | searchPipe: {  level_2_name: searchValueRCL2}'>
                                                    <mat-expansion-panel-header>
                                                        <mat-panel-description class='d-flex' style="align-items: center;">
                                                            <mat-radio-button (change)='setAdditionalInformationData({id:item.level_2_id,name:item.level_2_name},"event_type_level_2" )' [value]="item.level_2_id" [checked]="riskCase.event_type_level_2 ? item.level_2_id === riskCase.event_type_level_2.id : false">
                                                            </mat-radio-button>
                                                            <div>{{item.level_2_name}} </div>
                                                        </mat-panel-description>
                                                    </mat-expansion-panel-header>
                                                </mat-expansion-panel>
                                            </mat-radio-group>
                                        </mat-accordion>
                                    </div>
                                </div>
                                <div *ngIf='riskCase.event_type_level_2' class="mt-auto  w-100 d-flex justify-content-start align-items-end">
                                    <div class="mr-4"> <span class="contantStyle">
                                            Your Selection:
                                        </span> </div>
                                    <div class="d-flex">
                                        <span>
                                            {{riskCase.event_type_level_2.name}}
                                        </span>
                                        <span class="clearSelection ml-2 material-icons" matTooltip="Clear your selection" [matTooltipPosition]="'above'" (change)='setAdditionalInformationData(null,"event_type_level_2" )'>
                                            close
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="d-flex justify-content-end">
                    <button class='primaryBtn  mr-4   buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>
                    <button class='primaryBtn buttonWidth' pButton type="button" label="Update" (click)="update()"></button>
                    <app-information-dialog [accordionIndex]='13'></app-information-dialog>
                </div>
            </ng-container>
            <ng-container *ngIf="modelView=='lessonLearnDescription'">
                <div class="w-100">
                    <div class=" py-4 contantStyle  t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.LessonsLearned,'right')">
                        Lessons Learned and Actions Taken
                    </div>
                    <div class="mb-3 w-100 inputField">
                        <small class="counterText" [ngClass]="(!byteCount(riskCase.lesson_learn_description,4000).valid)?'warningTxt':''">{{byteCount(riskCase.lesson_learn_description,4000).length}} /
                            4000 </small>
                        <textarea cols="50" [disabled]='cantEditTextArea()' id="eventLessonsInput" [(ngModel)]='riskCase.lesson_learn_description' rows="8" maxlength="4000">
                        </textarea>
                        <span *ngIf='!byteCount(riskCase.lesson_learn_description,4000).valid' class="warningTxt">
                            The text entered exceeds the maximum number of characters allowed. Please reduce the text length up to the defined limit.
                        </span>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <button class='primaryBtn   mr-4 buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>
                    <button class='primaryBtn  buttonWidth' pButton type="button" label="Update" [disabled]='!byteCount(riskCase.lesson_learn_description,4000).valid' (click)="update()"></button>
                </div>
            </ng-container>
            <ng-container *ngIf="modelView=='title'">
                <div class="w-100">
                    <div class="w-100 py-4 contantStyle t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.Description.Title,'right')">
                        Event Title:
                    </div>
                    <div class="mb-3 w-50 inputField">
                        <small class="counterText" [ngClass]="(!byteCount(eventTitleInput.value,120).valid)?'warningTxt':''">{{byteCount(eventTitleInput.value,120).length}} / 120 </small>
                        <textarea id="eventTitleInput" #eventTitleInput cols="50" maxlength='120' rows="2" placeholder="(Event Title)" [(ngModel)]='riskCase.event.title'>
                        </textarea>
                        <span *ngIf='!byteCount(eventTitleInput.value,120).valid' class="warningTxt">
                            The text entered exceeds the maximum number of characters allowed. Please reduce the text length up to the defined limit.
                        </span>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <button class='primaryBtn mr-4  buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>
                    <button class='primaryBtn   buttonWidth' pButton type="button" label="Update" [disabled]='eventTitleInput.value.length == 0|| !byteCount(eventTitleInput.value,120).valid' (click)="update()"></button>
                </div>
            </ng-container>
            <ng-container *ngIf="modelView=='rootCauseDescription'">
                <div class="w-100">
                    <div class=" py-4 contantStyle  t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.RootCauseDescription,'right')">
                        Root Cause Description
                    </div>
                    <div class="mb-3 w-100 inputField">
                        <small class="counterText" [ngClass]="(!byteCount(riskCase.root_cause_description,4000).valid)?'warningTxt':''">{{byteCount(riskCase.root_cause_description,4000).length}} /
                            4000 </small>
                        <textarea cols="50" [disabled]='cantEditTextArea()' id="eventroot_cause_descriptionInput" [(ngModel)]='riskCase.root_cause_description' rows="8" maxlength="4000">
                        </textarea>
                        <span *ngIf='!byteCount(riskCase.root_cause_description,4000).valid' class="warningTxt">
                            The text entered exceeds the maximum number of characters allowed. Please reduce the text length up to the defined limit.
                        </span>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <button class='primaryBtn mr-4  buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>
                    <button class='primaryBtn   buttonWidth' pButton type="button" label="Update" [disabled]='!byteCount(riskCase.root_cause_description,4000).valid' (click)="update()"></button>
                </div>
            </ng-container>
            <ng-container *ngIf="modelView=='description'">
                <div class="w-100">
                    <div class=" py-4 contantStyle  t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.Description.DetailDescription,'right')">
                        Description
                    </div>
                    <div class="mb-3 w-100 inputField">
                        <small class="counterText" [ngClass]="(!byteCount(riskCase.event.description,4000).valid)?'warningTxt':''">{{byteCount(riskCase.event.description,4000).length}} / 4000 </small>
                        <textarea cols="50" [disabled]='cantEditTextArea()' id="eventdescriptionInput" [(ngModel)]='riskCase.event.description' rows="8" maxlength="4000">
                    </textarea>
                        <span *ngIf='!byteCount(riskCase.event.description,4000).valid' class="warningTxt">
                            The text entered exceeds the maximum number of characters allowed. Please reduce the text length up to the defined limit.
                        </span>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <button class='primaryBtn mr-4  buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>
                    <button class='primaryBtn   buttonWidth' pButton type="button" label="Update" [disabled]='!byteCount(riskCase.event.description,4000).valid' (click)="update()"></button>
                </div>
            </ng-container>
            <ng-container *ngIf="modelView=='attachment'">
                <app-attachment-dialog [isDesktopDevice]="isDesktopDevice" [creator]='creator' [authUsers]='[ownerId,delegate]' [riskManagers]='riskManagers' [userId]='loggedUser' [userRole]='allUserRoles' [status]='riskCase.status' [rcsaOwner]='rcsaOwner.user_id' #attachmentDialog
                    (hideAttachmentDialog)="hideAttachmentDialog()" [attachedFiles]="attachedFiles">
                </app-attachment-dialog>
            </ng-container>
        </div>
    </div>
    <ngx-spinner name="oneOrm" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
        <p style="color: white"> Loading... </p>
    </ngx-spinner>
</ng-template>

<ngx-spinner name="oneOrm" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
