
import { Component, Input, Output, EventEmitter, TemplateRef, OnInit, OnDestroy } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { StepperService, BaseService} from '../../../../shared/services';
import { Mandate } from 'src/app/shared/models/mandate.model';
import { Subscription } from 'rxjs';
import { PrimaryParentType } from '../../../../shared/common/constants';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.scss']
})
export class Step1Component  implements OnInit, OnDestroy {
  viewStatus: string;
  filterUsersSingleList: any[];
  modalRef: BsModalRef;
  @Input() viewButton: boolean = true;
  @Output() newData: EventEmitter<any> = new EventEmitter<any>();
  FieldHelpData;
  loading = false;
  PrimaryParentType = PrimaryParentType;

  selectedUser;
  mandate: Mandate = new Mandate();
  rcsaList = [];
  userId = '';
  brand: string;
  searchValue: string;
  selectedRCSA: Mandate;
  lastStep: number;
  MandateViewValue = '';
  transferData: boolean;
  newRisk = this.stepperService.getNewRisk();
  subscription: Subscription = new Subscription();
  routeSubscribtion: Subscription;
  rcsaId: number;
  constructor(
    public stepperService: StepperService,
    private baseService: BaseService,
    private modalService: BsModalService,
    private route: ActivatedRoute,

  ) {
    this.stepperService.setSelectedRcsa(undefined);
    this.routeSubscribtion = this.route.params.subscribe((params) => {
      if(!params['rcsaId']){
        return;
      }
      this.rcsaId = params['rcsaId'];
      this.createFromRcsa(this.rcsaId);
    });


    this.FieldHelpData = this.stepperService.FieldHelpData;
    if (this.newRisk == null || (this.newRisk != null && !this.newRisk.mandate)){
      this.getRCSAData('start');
    } else {
      this.mandate = this.newRisk.mandate;
      this.lastStep = this.newRisk.lastStep;

      const url = 'rcsa-selection/all';
      this.loading = true;

      this.baseService.get(url).subscribe(res => {
        this.loading = false;

        this.rcsaList = res.body.data;
        const obj = res.body.data.find(o => o.risk_assessment_id == this.mandate.risk_assessment_id);
        this.mandate = obj;
        if(this.mandate){
          if (!this.stepperService.getSelectedRcsa()) {
            this.stepperService.setSelectedRcsa(this.mandate);
          }
          this.rcsaList = [this.mandate];
          this.changeViewStatus('existed');
        }else{
          this.baseService.get('rcsa/'+this.mandate.risk_assessment_id).subscribe(resRcsa => {
            this.mandate.owner_id = resRcsa.body.rcsa_owner.user_id;
            this.mandate.owner = resRcsa.body.rcsa_owner.user_name;
            this.mandate.risk_assm_name = resRcsa.body.name;
            this.mandate.risk_assm_desc = resRcsa.body.description;
            this.mandate.global_function = resRcsa.body.global_function;
            this.mandate.risk_assessment_id = resRcsa.body.id;
            this.mandate.risk_manager_id = resRcsa.body.risk_manager.user_id;
            this.mandate.risk_manager = resRcsa.body.risk_manager.user_name;
            this.mandate.ra_source_id = resRcsa.body.source_id;
            this.rcsaList = [this.mandate];
            this.changeViewStatus('existed');
            if (!this.stepperService.getSelectedRcsa()) {
              this.stepperService.setSelectedRcsa(this.mandate);
            }
          });
        }

      });



    }
  }
  ngOnInit(): void {
    this.subscription.add(this.stepperService.saveCurrentStep.subscribe(() =>  this.stepperService.changeIndex(1, this.mandate, 'mandate')));
  }
  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
  changeMandateView(data){
    this.MandateViewValue = data;

  }
  createFromRcsa(rcsaId){
    if(!rcsaId){
      return
    }
    const url = 'rcsa-selection/all';
    this.loading = true;

    this.baseService.get(url).subscribe(res => {
      this.loading = false;

      this.rcsaList = res.body.data;
      const obj = res.body.data.find(o => o.risk_assessment_id == rcsaId);
      this.mandate = obj;
      if(this.mandate){
        this.stepperService.newRisk.primary_parent_type_id = this.PrimaryParentType.RiskAssessment;
        this.stepperService.newRisk.primary_parent_id = rcsaId;
        this.stepperService.newRisk.risk_assessments = [{id:rcsaId}];
        this.stepperService.changeIndex(1, this.mandate, 'mandate');
      }else{
        this.baseService.get('rcsa/'+rcsaId).subscribe(resRcsa => {
          this.mandate.owner_id = resRcsa.body.rcsa_owner.user_id;
          this.mandate.owner = resRcsa.body.rcsa_owner.user_name;
          this.mandate.risk_assm_name = resRcsa.body.name;
          this.mandate.risk_assm_desc = resRcsa.body.description;
          this.mandate.global_function = resRcsa.body.global_function;
          this.mandate.risk_assessment_id = resRcsa.body.id;
          this.mandate.risk_manager_id = resRcsa.body.risk_manager.user_id;
          this.mandate.risk_manager = resRcsa.body.risk_manager.user_name;
          this.mandate.ra_source_id = resRcsa.body.source_id;
          this.stepperService.newRisk.primary_parent_type_id = this.PrimaryParentType.RiskAssessment;
          this.stepperService.newRisk.primary_parent_id = rcsaId;
          this.stepperService.newRisk.risk_assessments = [{id:rcsaId}];
          this.stepperService.changeIndex(1, this.mandate, 'mandate');
        });
      }

    });



  }
  nextStep() {

    this.stepperService.newRisk.primary_parent_type_id = this.PrimaryParentType.RiskAssessment;
    this.stepperService.newRisk.primary_parent_id = this.mandate.risk_assessment_id;
    this.stepperService.newRisk.risk_assessments = [{id:this.mandate.risk_assessment_id}];

    this.stepperService.changeIndex(1, this.mandate, 'mandate');
    this.stepperService.setSelectedRcsa(this.mandate);
  }

  getRCSAData(status) {
    this.rcsaList = [];
    this.loading = true;
    this.baseService.get('rcsa-selection/user').subscribe(res => {
      this.rcsaList = res.body.data;
      this.loading = false;

      if (this.rcsaList.length === 1) {
        this.mandate = JSON.parse(JSON.stringify(this.rcsaList[0]));
        this.newData.emit({prop: 'mandate', propValue: this.mandate});

      } else {
        this.mandate = null;
      }
    });
    this.changeViewStatus(status);
  }

  getRCSADepartmentData(status: string, userId?: string) {
    this.rcsaList = [];
    this.loading = true;

    const url = userId ? 'rcsa-selection/user?userId=' + userId : 'rcsa-selection/department';
    this.baseService.get(url).subscribe(res => {
      this.loading = false;
      this.rcsaList = res.body.data;
      if (this.rcsaList.length === 1){
        this.mandate = this.rcsaList[0];
        this.newData.emit({prop: 'mandate', propValue: this.mandate});
      }else{
        this.mandate = null;
      }
    });
    this.changeViewStatus(status);
  }

  getAllRCSAData(status: string){
    this.rcsaList = [];
    const url = 'rcsa-selection/all';
    this.loading = true;

    this.baseService.get(url).subscribe(res => {
      this.loading = false;

      this.rcsaList = res.body.data;
    });
    this.changeViewStatus(status);
  }

  changeViewStatus(status: string) {
    this.viewStatus = status;
  }

  selectUser(data){
    this.mandate = data;
    this.newData.emit({prop: 'mandate', propValue: this.mandate});

    if (this.selectedUser === undefined){
      this.selectedUser = data;

    }
  }

  filterUsersSingle(event) {
    const query = event.query;
    if (query.length > 2) {
      this.filterUsersSingleList = [];
      this.baseService.getUsers('user?q=' + query).then(users => {
        this.filterUsersSingleList.push(...users);
      });
    } else {
      this.filterUsersSingleList = [];
    }
  }

  composeName(user: any){
    return user.user_name + ' (' + user.user_id + ')';
  }

  // search
  Search(payLoad: any): void {
    this.searchValue = payLoad;
  }

  onChangeRB(event) {
    this.mandate = this.selectedRCSA = event.value;
    this.newData.emit({prop: 'mandate', propValue: this.mandate});

  }

  openChangeiaolg(dialog){
    this.openModal(dialog);
  }


  openModal(template: TemplateRef<any>) {
    const configs = {
      keyboard: true,
      animated: true,
      class: ' modal-lg modal-primary RCSAModal',
      ignoreBackdropClick: false
    };
    this.modalRef = this.modalService.show(template, configs);
  }
  changeRCSA(){
    this.newRisk.risk = null;
    this.newRisk.roles = null;
    this.stepperService.currentStepData.currentstep = true;
    this.stepperService.setNewRisk(this.newRisk);
    this.stepperService.save();
    this.getRCSAData('start');
    this.modalRef.hide();
  }

  tooltip(toolTipontant){
    const tooltipData = {allowHTML: true,   delay: [1200, null], animation: 'shift-away', placement: 'bottom',  theme: 'light', content: toolTipontant };
    return tooltipData;

  }
}
