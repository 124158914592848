import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent
} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AuthenticationResult, InteractionType} from '@azure/msal-browser';
import {Minimatch} from 'minimatch';
import {EMPTY, Observable, throwError} from 'rxjs';

import {MSAL_INTERCEPTOR_CONFIG} from '../services/constants';
import {MsalService} from '../services/msal.service';
import {MsalInterceptorConfig} from './msal.interceptor.config';
import {NgxSpinnerService} from 'ngx-spinner';
import {catchError, finalize, switchMap, tap} from 'rxjs/operators';

@Injectable()
export class MsalInterceptor implements HttpInterceptor {
  count = 0;

  constructor(
    @Inject(MSAL_INTERCEPTOR_CONFIG) private msalInterceptorConfig: MsalInterceptorConfig,
    private spinner: NgxSpinnerService, private authService: MsalService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any> | HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {
    if (req.url.indexOf('risk-case') > -1) {
      this.spinner.show('oneOrm');
    }
    this.count++;
    const scopes = this.getScopesForEndpoint(req.url);
    const account = this.authService.getAllAccounts()[0];

    if (!scopes || scopes.length === 0) {
      this.spinner.hide('oneOrm');

      return next.handle(req);
    }

    return this.authService.acquireTokenSilent({scopes, account})
    .pipe(
      catchError(() => {
        this.spinner.hide('oneOrm');
        this.count = 0;

        if (this.msalInterceptorConfig.interactionType === InteractionType.Popup) {
          return this.authService.acquireTokenPopup({
            ...this.msalInterceptorConfig.authRequest,
            scopes
          });
        }
        const redirectStartPage = window.location.href;
        this.authService.acquireTokenRedirect({
          ...this.msalInterceptorConfig.authRequest,
          scopes,
          redirectStartPage
        });
        return EMPTY;
      }),
      switchMap((result: AuthenticationResult) => {

        this.authService.extractLoggedUser(result);
        const headers = req.headers
        .set('Authorization', `Bearer ${result.accessToken}`);

        const requestClone = req.clone({headers});
        return next.handle(requestClone)
        .pipe(
          catchError(err => {
            if (err instanceof HttpErrorResponse) {
              this.spinner.hide('oneOrm');

              return throwError(err);
            }
          }))

        .pipe(tap(
            //   event => console.log(event),

            //   error => console.log( error )

          ), finalize(() => {
            if (this.count > 0) {
              this.count--;
            }

            if (this.count === 0) {
              this.spinner.hide('oneOrm');
            }
          })
        )
          ;
      })
    );
  }


  private getScopesForEndpoint(endpoint: string): Array<string> | null {
    const protectedResourcesArray = Array.from(this.msalInterceptorConfig.protectedResourceMap.keys());
    const keyMatchesEndpointArray = protectedResourcesArray.filter(key => {
      const minimatch = new Minimatch(key);
      return minimatch.match(endpoint) || endpoint.indexOf(key) > -1;
    });

    // process all protected resources and send the first matched resource
    if (keyMatchesEndpointArray.length > 0) {
      const keyForEndpoint = keyMatchesEndpointArray[0];
      if (keyForEndpoint) {
        return this.msalInterceptorConfig.protectedResourceMap.get(keyForEndpoint);
      }
    }

    return null;
  }

}
