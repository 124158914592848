


<div *ngIf="riskCase.event;else other_content">
    <app-step6-event></app-step6-event>
</div>

<ng-template #other_content>
    <app-step-6-issue></app-step-6-issue>

</ng-template>
