import {Inject, Injectable} from "@angular/core";
import {
  AccountInfo,
  AuthenticationResult,
  AuthorizationUrlRequest,
  EndSessionRequest,
  IPublicClientApplication,
  PopupRequest,
  RedirectRequest,
  SilentRequest
} from "@azure/msal-browser";
import {from, Observable, Subject} from 'rxjs';
import {Location} from '@angular/common';
import {IMsalService} from "./IMsalService";
import {User} from '../models/user.model';
import {MSAL_INSTANCE} from './constants';
import {BaseService} from './base.service';
import {RISK_CASE_ID_MAX_LENGTH, RISK_CASE_ID_PARAM_MARK} from '@shared/common/constants';

@Injectable()
export class MsalService implements IMsalService {
  riskCaseId: number | undefined;
  riskCaseType: string | undefined;
  loggedUser: User = new User();
  checkLogin = true;
  private redirectHash: string;
  private userData = new Subject<any>();
  getUserData = this.userData.asObservable();

  constructor(
    @Inject(MSAL_INSTANCE) public instance: IPublicClientApplication,
    private baseService: BaseService,
    private loc: Location
  ) {
    console.log('MSAL LOCATION', location);
    console.log('MSAL RISK CASE  ID', this.riskCaseId);
    if (this.riskCaseId === undefined) {
      if (location.hash !== '') {
        const locationHash = location.hash;
        const pattern = /^#\/risk-case\/(\w+)\/(\d+).*$/;
        const match = locationHash.match(pattern);
        if (match) {
          this.riskCaseId = +match[2];
          this.riskCaseType = match[1];
        } else {
          this.riskCaseId = +locationHash.substring(locationHash.lastIndexOf('%7c') + RISK_CASE_ID_MAX_LENGTH, locationHash.lastIndexOf('&session_state'));
        }
      } else if (location.href.indexOf('risk-case') !== -1) {
        this.riskCaseId = +location.href.substr(location.href.lastIndexOf('/') + 1);
      } else {
        this.riskCaseId = +location.search.replace(RISK_CASE_ID_PARAM_MARK, '');
      }
    }

    console.log('MSAL SERVICE RISK CASE ID', this.riskCaseId);
    console.log('MSAL SERVICE RISK CASE TYPE', this.riskCaseType);
    // Cache the code hash before Angular router clears it
    const hash = this.loc.path(true).split('#').pop();
    if (hash) {
      this.redirectHash = `#${hash}`;
    }
  }

  newUserData(currentStep) {
    this.userData.next(currentStep);
  }

  acquireTokenPopup(request: PopupRequest): Observable<AuthenticationResult> {
    return from(this.instance.acquireTokenPopup(request));
  }

  acquireTokenRedirect(request: RedirectRequest): Observable<void> {
    return from(this.instance.acquireTokenRedirect(request));
  }

  acquireTokenSilent(silentRequest: SilentRequest): Observable<AuthenticationResult> {
    return from(this.instance.acquireTokenSilent(silentRequest));
  }

  getAccountByUsername(userName: string): AccountInfo {
    return this.instance.getAccountByUsername(userName);
  }

  getAllAccounts(): AccountInfo[] {
    return this.instance.getAllAccounts();
  }

  handleRedirectObservable(): Observable<AuthenticationResult> {
    return from(this.instance.handleRedirectPromise(this.redirectHash));
  }

  loginPopup(request?: PopupRequest): Observable<AuthenticationResult> {
    return from(this.instance.loginPopup(request));
  }

  loginRedirect(request?: RedirectRequest): Observable<void> {
    return from(this.instance.loginRedirect(request));
  }

  logout(logoutRequest?: EndSessionRequest): Observable<void> {
    return from(this.instance.logout(logoutRequest));
  }

  ssoSilent(request: AuthorizationUrlRequest): Observable<AuthenticationResult> {
    return from(this.instance.ssoSilent(request));
  }

  public getLoggedUser(): User {
    return this.loggedUser;
  }

  public setLoggedUser(loggedUser: User): void {
    this.loggedUser = loggedUser;
  }

  extractLoggedUser(result: AuthenticationResult): boolean {
    // console.log('extractLoggedUser: ' + JSON.stringify(result))
    const token = result.idTokenClaims;
    this.loggedUser.user_email_address = result.account.username;
    this.newUserData(this.loggedUser);

    if (token['onpremsam']) {
      this.loggedUser.user_id = token['onpremsam'];
      this.loggedUser.user_name = token['name'];
    } else {
      if (this.checkLogin) {
        this.checkLogin = false;
        this.baseService.get('user-profile?email=' + this.loggedUser.user_email_address).subscribe(res => {

          this.loggedUser.user_id = res.body.user_id;
          this.loggedUser.user_name = res.body.user_name;
        });

      }

    }
    return this.loggedUser.user_id == '';
  }

  resetRiskCaseId(): void {
    this.riskCaseId = undefined;
  }
}
