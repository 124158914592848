<p-dialog header="WARNING!" [(visible)]="displayConfirmDialog" [position]="'center'" [dismissableMask]="true" [modal]="true" styleClass="skipDialog"
    [closable]="false">
    <div>
        Your data are being updated. Please refresh to proceed.

        <div  class="d-flex justify-content-end w-100">
            <button class='primaryBtn  mr-4 buttonWidth' pButton type="button" label="Reload" (click)="reload()"></button>
        </div>
    </div>
</p-dialog>

