import { Component, OnInit, TemplateRef, Input, OnChanges } from '@angular/core';
import { StepperService, BaseService } from '@shared/services';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { RiscCaseRequest as RiskCaseMessage } from '@shared/models/dto/risk-case-request.dto';
import * as html2pdf from 'html2pdf.js';
import { dateFormat } from '@shared/common/constants';

@Component({
  selector: 'app-generate-pdf',
  templateUrl: './generate-pdf.component.html',
  styleUrls: ['./generate-pdf.component.scss']
})
export class GeneratePdfComponent implements OnInit, OnChanges {
  @Input() riskCase;
  @Input() resultGit;
  @Input() outsourcingList;
  @Input() refreshData;
  @Input() riskCaseOverview;
  @Input() riskStatus;



  viewStatus: string;
  today30 = new Date(); // Now
  modalRef: BsModalRef;
  RootCause = false;
  Lessons = false;
  filterUsersSingleList: any[];
  today = new Date();
  numberOfItems = 3;
  numberOfItemsL = 3;
  dateFormat = dateFormat;
  showBUI = false;
 modelView = '';
 riskCaseId;
 MIList = [];
 nonMIList = [];
 statusList = [{id : 6, name: 'Draft', description :  null, buttonName: 'Submit Draft for Review'},
 {id : 7, name: 'Draft - Pending Review', description :  null, buttonName: 'Submit Draft for Review'},
 {id : 8, name: 'Open', description :  null, buttonName: 'Submit Draft for Review'},
 {id : 9, name: 'Open - Pending Closure Approval', description :  null, buttonName: 'Submit Draft for Review'},
 {id : 10, name: 'Closed', description :  null, buttonName: 'Submit Draft for Review'},
 {id : 11, name: 'Cancelled', description :  null, buttonName: 'Submit Draft for Review'},
];
  constructor(private stepperService: StepperService,
              private modalService: BsModalService,
              private baseService: BaseService,
              private router: Router) {
    this.viewStatus = 'mainView';
    this.today30.setDate(this.today30.getDate() + 30); // Set now + 30 days as the new date
    this.riskCaseId = this.stepperService.newRisk.riskCaseId;
    this.loadData();

  }
  ngOnChanges(){
    if(this.BiLists(this.riskCase.businessImpact).length > 0){
      this.showBUI = true;

    }
  }
  loadData(){
  
    this.baseService.get('risk-case/' + this.riskCaseId + '/monetary-impacts').subscribe(res => {
        this.MIList = [];
        this.nonMIList = [];
        res.body.data.forEach(element => {
        if (element.monetary_impact_type.id === 91){
          this.MIList.push(element);
        }else{
          this.nonMIList.push(element);

        }
         });
        });
  }
  outsourse(selectedOutsourcing) {
    let name = '';
    if (this.outsourcingList) {
      this.outsourcingList.forEach(element => {
        if (element.id === selectedOutsourcing) {
          name = element.name;
        }
      });
    }

    return name;
  }
  loadRiskCaseById(id: number) {
    this.baseService.get('risk-case/' + id).subscribe(res => {
        this.stepperService.etag = res.headers.get('ETag').replace(/"/g, '');
        this.riskCase = RiskCaseMessage.toModel(res.body.risk_case);

      });
}


  openModal(template: TemplateRef<any>) {
    const configs = {
      keyboard: false,
      ignoreBackdropClick: true,
      animated: true,
      class: ' modal-lg modal-primary informationModalSummary',
    };
    this.modalRef = this.modalService.show(template, configs);
  }
  ngOnInit(): void {
  }
  nextStep(){}
  newCase() {
    this.stepperService.resetRiskCase();
    this.router.navigate(['/new-risk']);
  }
  editEntity(templet, view){
    this.openModal(templet);
    this.modelView = view;
  }
  cantEdit(event): boolean {
    return false;
  }
BiLists(allLists){

  let newList = [];
  if(!allLists){
    return newList;

  }
  newList = newList.concat(allLists.lifeCapital);
  newList = newList.concat(allLists.corso);
  newList = newList.concat(allLists.reinsurance);
  return newList;
}
  selectUser(data, field){
    this.riskCase.roles[field] = data;
  }

  filterUsersSingle(event) {

    const query = event.query;
    if (query.length > 2) {
      this.filterUsersSingleList = [];
      this.baseService.getUsers('user?q=' + query).then(users => {
        this.filterUsersSingleList.push(...users);
      });
      if (query.length == 6) {
        this.baseService.get('user/' + query.toUpperCase()).subscribe( user => {
          this.filterUsersSingleList.push(user);
        })
      }
    } else {
      this.filterUsersSingleList = [];

    }
  }

  composeName(user: any){
    return user.user_name + ' (' + user.user_id + ')';
  }

  cancel(){
    this.riskCase = this.stepperService.getNewRisk();
    this.modalRef.hide();
  }
  update(){

  this.modalRef.hide();

  }
  updateMI_view(step){
    step.getMI_Items();
    this.modalRef.hide();

  }
updateData(data){
  this.riskCase[data.prop] = data.propValue;
}
updateDataAndSave(data){
  this.riskCase[data.prop] = data.propValue;
  this.update();
}
  dateChanged(evt,  row){
    const selectedDate = new Date(evt);
    selectedDate.setMinutes(selectedDate.getMinutes() - selectedDate.getTimezoneOffset());
    this.riskCase.event[row] =  selectedDate;
   }
   changeStatus(statusData){
      this.riskCase.status = statusData.status.id;
      this.riskCase[statusData.commentAttr] = statusData.comment;
      this.update();
   }


   generatePDF() {
    const detailContainer = document.querySelector('#detail-container');
    this.insertBreaks(detailContainer);

    const opt = {
      margin: [20, 1, 1, 20],
      filename: this.riskCaseId + '-One-ORM-risk-Case.pdf',
      image: {
        type: 'jpeg',
        quality: 0.98
      },
      html2canvas: {
        scale: 2,
        windowWidth: '990',
        windowHeight: '1263',
        dpi: 192,
        letterRendering: true
      },
      jsPDF: { unit: 'px', format: [950, 1263] }
    };
    // must use 'fit to page' when printing
    html2pdf().set(opt).from(detailContainer).save();

    setTimeout(function () {
      html2pdf().from(detailContainer).set(opt).to('pdf').get('pdf').then(
        ok => {
        },
        err => {
        })
    }, 50);
  }

  insertBreaks(detailContainer: any) {
    const rows: HTMLCollection = (detailContainer as Element).children;

    let current_page_height = 0; //
    let max_page_height = 1803; //Is the max size  of page i want

    for (let i = 0; i < rows.length; i++) {

      const row_height = (rows[i] as HTMLElement).offsetHeight;
      //height until the matcard
      current_page_height = current_page_height + row_height
      if (i === 7) {
        const matCardChildren = (rows[i]).children;
        for (let j = 1; j < matCardChildren.length; j++) {

          const child_height = (matCardChildren[j] as HTMLElement).offsetHeight;
          current_page_height = current_page_height + child_height
          if (j === matCardChildren.length - 1) {
            (matCardChildren[j] as HTMLElement).style.cssText = 'margin-bottom:0px;padding-bottom:0px;' + (matCardChildren[j] as HTMLElement).style.cssText;
          }
          if (current_page_height > max_page_height) {
            current_page_height = 0;
            const newPageDiv = document.createElement('div');
            newPageDiv.classList.add('html2pdf__page-break');
            newPageDiv.style.cssText = 'height:0px;';
            if (matCardChildren[j - 1]) {
              (matCardChildren[j - 1] as any).appendChild(newPageDiv);
            }
          }
        }
      }
    }
  }
  dateValidate(date){
    if(!date){
      return;
    }
    let newDate;
    if(typeof date == "object" && date.length > 0){
      newDate = date.join('/');
    }else if(typeof date == "string" && date.length > 0){
      newDate = date.substring(0, 10);
      newDate = newDate.replace(/-/g, "/");
      return new Date(newDate);

    }else{
      newDate = date;
    }
    return new Date(newDate);

  }
}
