<app-top-navbar (closeRightBar)="showRightBar = $event" [showRightBar]="showRightBar"></app-top-navbar>

<mat-drawer-container class="drawer-container">
    <mat-drawer-content>
        <div class="drawer-content" [fxLayout]="'row'" fxLayoutAlign="center">
            <div class="mx-2 mainContainerCard">
                <router-outlet *ngIf="!isIframe"></router-outlet>
            </div>
            <div [ngClass]="{'showRightBar': showRightBar ,  'hideRightBar':!showRightBar }" class="right-sidenav">
                <app-right-sidenav (closeRightBar)="showRightBar = false"></app-right-sidenav>
            </div>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
<ngx-spinner name="oneOrm" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
