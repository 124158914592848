import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FavIconService {
  private faviconElement: HTMLLinkElement;

  constructor() {
    this.faviconElement = document.querySelector('link[id="faviconTag"]');
  }

  setFavicon(url: string) {
    if (this.faviconElement) {
      this.faviconElement.href = url;
    }
  }
}
