import { Injectable } from '@angular/core';
import {FavIconService } from './favicon.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeDetectionService {
  constructor(private faviconService: FavIconService) {}

  init() {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

    const faviconUrl = prefersDark ? 'assets/images/WhiteIcon.svg' : 'assets/images/OneORM_ICON.svg';
    this.faviconService.setFavicon(faviconUrl);
  }
}
