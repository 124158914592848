<mat-card *ngIf='!riskCase' class="statusBar mb-2">
    <div class="d-flex justify-content-center align-items-center loading">Loading...</div>


</mat-card>
<div class="SystemNotice" *ngIf="riskCase && !isDesktopDevice">
    <strong>
        Important System Notice:
    </strong> Due to an underlying issue with the OneORM mobile application, this screen is currently read only on all mobile devices.
</div>
<ng-container *ngIf='riskCase'>
    <app-confirm-dialog [displayConfirmDialog]="displayConfirmDialog" (reloadRiskCase)="reloadRiskCase()">
    </app-confirm-dialog>



    <mat-card class="statusBar mb-2">
        <app-issue-status-bar [isDesktopDevice]="isDesktopDevice" class='w-100' [actionItemAssigneAndDelegate]='actionItemAssigneAndDelegate' [userRole]='userRole' [owner]='true'
            [statusBarRiskCaseData]='statusBarRiskCaseData' [overDue]="overDueValue(resultGit.overdue)" [typeValidation]="resultGit" (actionItemValidationCheck)='validationAction($event) '
            [statusOrder]='riskCase.status' (newData)='updateData($event)' [lodId]='resultGit.identify_LOD.id' (openPopUpEdit)="editEntityfromPopUp(template, $event)"
            (changeStatusEvent)='changeStatus($event)' [rcsaOwner]='rcsaOwner.user_id' #statusBar>
        </app-issue-status-bar>


    </mat-card>
    <mat-card class=" mb-2">

        <div class="d-flex justify-content-between w-100">
            <div class="contantStyle">Administration</div>
            <div (click)='hideAdministration=!hideAdministration'>
                <span *ngIf='hideAdministration' class="material-icons expandIcon">
                    expand_more
                </span>

                <span *ngIf='!hideAdministration' class="material-icons expandIcon">
                    expand_less
                </span>
            </div>
        </div>

        <div *ngIf='!hideAdministration' class="row mt-4">
            <div class="col-md-3">
                <div class="d-flex">
                    <div class=""> <span class="summaryIcon  material-icons">
                            account_circle
                        </span></div>
                    <div>
                        <p class="mb-0">
                            Created By
                        </p>
                        <p class="contantStyle">{{resultGit.creator.user_name}} ({{resultGit.creator.user_id}})
                            <span *ngIf='resultGit.creator.user_status == "INACTIVE"'>(Inactive)</span>



                        </p>
                        <p class="mb-0">Last Modified By</p>
                        <p class="contantStyle">
                            {{resultGit.modified_by.user_name}} ({{resultGit.modified_by.user_id}})
                            <span *ngIf='resultGit.modified_by.user_status == "INACTIVE"'>(Inactive)</span>

                        </p>

                        <p class="mb-0">Subscribed Users
                            <app-information-dialog [accordionIndex]='15'></app-information-dialog>
                        </p>
                        <p class="contantStyle">
                            <ng-container *ngFor='let air of additionalInfoRecipients'>
                                {{air.contact_name}} ({{air.contact_id}}) <br>
                            </ng-container>
                        </p>

                    </div>
                </div>


            </div>
            <div class="col-md-3">
                <div class="d-flex">
                    <div class=""> <span class="summaryIcon  material-icons">
                            date_range
                        </span></div>
                    <div>
                        <p class="mb-0">

                            Creation Date (CET)

                        </p>
                        <p class="contantStyle">{{showDateSafari(resultGit.creation_date) | oneOrmDate}}

                        </p>
                        <p class="mb-0">Last Modification Date (CET)
                        </p>
                        <p class="contantStyle">{{showDateSafari(resultGit.modify_date) | oneOrmDate}}

                        </p>


                    </div>
                </div>





            </div>
            <div class="col-md-3">
                <div class="d-flex">
                    <div class=""> <span class="summaryIcon  material-icons">
                            date_range
                        </span></div>
                    <div>
                        <p class="mb-0">

                            Initial Due Date (CET)

                        </p>
                        <p class="contantStyle" *ngIf='resultGit.initial_due_date'>
                            {{showDateSafari(resultGit.initial_due_date) | oneOrmDate}}

                        </p>
                        <p class="mb-0">Past Due Date (CET)
                        </p>
                        <p class="contantStyle" *ngIf='resultGit.past_due_date'>
                            {{showDateSafari(resultGit.past_due_date) | oneOrmDate}}

                        </p>


                    </div>
                </div>





            </div>
            <div class="col-md-3">
                <div class="d-flex">


                    <mat-icon class="summaryIcon  material-icons" (click)='copyGraceUrl(resultGit.url)' matTooltip="Copy GRACE URL" [matTooltipPosition]="'above'">link</mat-icon>


                    <div>

                        <p class="contantStyle cursor_pointer">

                            <button class='primaryBtn   buttonWidth align-self-end' pButton type="button" [label]="'Go to Issue in GRACE'" matTooltip="Only accessible by licensed GRACE users." (click)='goOutsideApp(resultGit.url)'></button>

                        </p>

                        <p class="mb-0">

                            Overdue

                        </p>
                        <p class="contantStyle cursor_pointer" *ngIf='resultGit.overdue'>
                            {{resultGit.overdue.id == 270 ? 'Yes' : 'No'}}

                        </p>
                        <p class="contantStyle cursor_pointer" *ngIf='!resultGit.overdue'>
                            No

                        </p>

                    </div>
                </div>





            </div>




        </div>
    </mat-card>


    <mat-card class=" mb-2">

        <div class="d-flex justify-content-between w-100">
            <div class="contantStyle"> Associations </div>
            <div (click)='hideRisks=!hideRisks'>
                <span *ngIf='hideRisks' class="material-icons expandIcon">
                    expand_more
                </span>

                <span *ngIf='!hideRisks' class="material-icons expandIcon">
                    expand_less
                </span>
            </div>
        </div>

        <div *ngIf='!hideRisks' class="row mt-4">




            <div class="col-md-1 my-3">
                <span class="w-100 contantStyle">

                    RCSAs



                </span>




            </div>





            <div class="col-md-11 accordionContainerHeight   my-3">





                <ng-container>
                    <mat-accordion class="fix-radio-position" hideToggle="true">

                        <ng-container *ngIf='resultGitNew.risk_assessment'>

                            <mat-expansion-panel class='PRIMARYcontrolRisks'>
                                <mat-expansion-panel-header>
                                    <mat-panel-description class='d-flex' style="align-items: center;">

                                        <div class="PRIMARYcontrolRisksTitle"> {{resultGitNew.risk_assessment.name}}: {{resultGitNew.risk_assessment.description}} </div>


                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <p class="PRIMARYcontrolRisksContant">RCSA Owner: {{resultGitNew.risk_assessment.rcsa_owner.user_name}}</p>
                                <p class="PRIMARYcontrolRisksContant">Folder: {{resultGitNew.risk_assessment.folder}}</p>


                            </mat-expansion-panel>
                        </ng-container>

                        <ng-container *ngIf='resultGitNew.risk_assessments_primary_path'>
                            <ng-container *ngFor='let item of resultGitNew.risk_assessments_primary_path '>

                                <mat-expansion-panel *ngIf=' checkRisksANdRcsaId(item,resultGitNew.risk_assessment) '>
                                    <mat-expansion-panel-header>
                                        <mat-panel-description class='d-flex' style="align-items: center;">

                                            <div class="PRIMARYcontrolRisksTitle"> {{item.name}}: {{item.description}} </div>


                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <p class="PRIMARYcontrolRisksContant">RCSA Owner: {{item.rcsa_owner.user_name}}</p>
                                    <p class="PRIMARYcontrolRisksContant">Folder: {{item.folder}}</p>


                                </mat-expansion-panel>
                            </ng-container>

                        </ng-container>

                        <ng-container *ngIf='resultGitNew.risk_assessments_secondary_path'>
                            <ng-container *ngFor='let item of resultGitNew.risk_assessments_secondary_path '>

                                <mat-expansion-panel *ngIf=' checkRisksANdRcsaId(item,resultGitNew.risk_assessment) '>
                                    <mat-expansion-panel-header>
                                        <mat-panel-description class='d-flex' style="align-items: center;">

                                            <div class="PRIMARYcontrolRisksTitle"> {{item.name}}: {{item.description}} </div>

                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <p class="PRIMARYcontrolRisksContant">RCSA Owner: {{item.rcsa_owner.user_name}}</p>
                                    <p class="PRIMARYcontrolRisksContant">Folder: {{item.folder}}</p>


                                </mat-expansion-panel>
                            </ng-container>

                        </ng-container>

                    </mat-accordion>
                </ng-container>



            </div>






            <div class="col-md-1 my-3">
                <span class="w-100 contantStyle">

                    Risks



                </span>




            </div>





            <div class="col-md-11 accordionContainerHeight my-3">






                <mat-accordion class="fix-radio-position" hideToggle="true">
                    <ng-container *ngIf='resultGitNew.risk'>
                        <mat-expansion-panel class='PRIMARYcontrolRisks'>
                            <mat-expansion-panel-header>
                                <mat-panel-description class='d-flex' style="align-items: center;">

                                    <div class="PRIMARYcontrolRisksTitle"> {{resultGitNew.risk.name}}: {{resultGitNew.risk.description}} </div>

                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <p class="PRIMARYcontrolRisksContant">Description: {{resultGitNew.risk.detail_description}}</p>
                            <p class="PRIMARYcontrolRisksContant">Folder: {{resultGitNew.risk.folder}}</p>
                        </mat-expansion-panel>
                    </ng-container>
                    <ng-container *ngIf='resultGitNew.risks_primary_path'>

                        <ng-container *ngFor='let item of resultGitNew.risks_primary_path '>

                            <mat-expansion-panel *ngIf=' checkRisksANdRcsaId(item,resultGitNew.risk) '>


                                <mat-expansion-panel-header>
                                    <mat-panel-description class='d-flex' style="align-items: center;">

                                        <div class="PRIMARYcontrolRisksTitle"> {{item.name}}: {{item.description}} </div>

                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <p class="PRIMARYcontrolRisksContant">Description: {{item.detail_description}}</p>
                                <p class="PRIMARYcontrolRisksContant">Folder: {{item.folder}}</p>
                            </mat-expansion-panel>
                        </ng-container>

                    </ng-container>


                    <ng-container *ngIf='resultGitNew.risks_secondary_path'>

                        <ng-container *ngFor='let item of resultGitNew.risks_secondary_path '>

                            <mat-expansion-panel *ngIf=' checkRisksANdRcsaId(item,resultGitNew.risk) '>


                                <mat-expansion-panel-header>
                                    <mat-panel-description class='d-flex' style="align-items: center;">

                                        <div class="PRIMARYcontrolRisksTitle"> {{item.name}}: {{item.description}} </div>

                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <p class="PRIMARYcontrolRisksContant">Description: {{item.detail_description}}</p>
                                <p class="PRIMARYcontrolRisksContant">Folder: {{item.folder}}</p>
                            </mat-expansion-panel>
                        </ng-container>
                    </ng-container>


                </mat-accordion>


            </div>







            <ng-container *ngIf='checkList(resultGit.controls) || checkList(resultGitNew.controls_secondary_path)'>

                <div class="col-md-1 my-3">
                    <span class="w-100 contantStyle">

                        Controls



                    </span>




                </div>





                <div class="col-md-11 accordionContainerHeight my-3">








                    <mat-accordion class="fix-radio-position" hideToggle="true">
                        <ng-container *ngIf='resultGit.controls'>
                            <ng-container *ngFor='let item of sortBy(resultGit.controls,"association_type") '>
                                <mat-expansion-panel [ngClass]="  item.association_type == 'PRIMARY'   ?'PRIMARYcontrolRisks':''">
                                    <mat-expansion-panel-header>
                                        <mat-panel-description class='d-flex' style="align-items: center;">

                                            <div class="PRIMARYcontrolRisksTitle"> {{getControl(item.ctrl_id).ctrl_control_name}}: {{getControl(item.ctrl_id).ctrl_description}} </div>

                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <p class="PRIMARYcontrolRisksContant">Description: {{getControl(item.ctrl_id).ctrl_detail_description}}</p>
                                    <p class="PRIMARYcontrolRisksContant">Folder: {{getControl(item.ctrl_id).ctrl_folder}}</p>

                                </mat-expansion-panel>

                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf='resultGitNew.controls_secondary_path'>
                            <ng-container *ngFor='let item of resultGitNew.controls_secondary_path'>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-description class='d-flex' style="align-items: center;">

                                            <div class="PRIMARYcontrolRisksTitle"> {{getControl(item.ctrl_id).ctrl_control_name}}: {{getControl(item.ctrl_id).ctrl_description}} </div>

                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <p class="PRIMARYcontrolRisksContant">Description: {{getControl(item.ctrl_id).ctrl_detail_description}}</p>
                                    <p class="PRIMARYcontrolRisksContant">Folder: {{getControl(item.ctrl_id).ctrl_folder}}</p>

                                </mat-expansion-panel>

                            </ng-container>
                        </ng-container>


                    </mat-accordion>



                </div>



            </ng-container>

            <ng-container *ngIf='checkList(resultGitNew.op_events)'>

                <div class="col-md-1 my-3">
                    <span class="w-100 contantStyle">

                        Events



                    </span>




                </div>





                <div class="col-md-11 accordionContainerHeight   my-3">






                    <mat-accordion class="fix-radio-position" hideToggle="true">
                        <ng-container>
                            <ng-container *ngFor='let item of resultGitNew.op_events '>
                                <mat-expansion-panel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-description class='d-flex' style="align-items: center;">

                                            <div class="PRIMARYcontrolRisksTitle"> {{item.opEvent.source_name}} {{item.opEvent.title}} </div>

                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <p class="PRIMARYcontrolRisksContant">Description: {{item.opEvent.description}}</p>
                                    <p class="PRIMARYcontrolRisksContant">Owner: {{item.opEvent.owner.user_name}} ({{item.opEvent.owner.user_id}})</p>
                                    <p class="PRIMARYcontrolRisksContant">Folder: {{item.opEvent.folder}}</p>

                                </mat-expansion-panel>

                            </ng-container>
                        </ng-container>


                    </mat-accordion>


                </div>

            </ng-container>


        </div>
    </mat-card>



    <app-issue-pdf [controlRisks]='controlRisks' [irTypeList]='irTypeList' [irImpactList]='irImpactList' [primaryParent]='primaryParent' [FieldHelpData]='FieldHelpData' [riskStatus]='riskStatus'
        [refreshData]='loadDataFlag' [outsourcingList]='outsourcingList' [resultGit]='resultGit' [riskCaseOverview]='riskCaseOverview' [hidden]="true" [riskCase]='riskCase' #generatePdf>
    </app-issue-pdf>
    <input id="refreshStatus" style="display:none;" (click)='statusBar.refreshMissingField(statusBarRiskCaseData)' />
    <mat-card id="detail-container">
        <div class="h-100 contactContainer">




            <div class="d-flex justify-content-between headerContainer">
                <div>
                    <p class="contantStyle">
                        <ng-container *ngIf='riskCase.source_name'>
                            {{riskCase.source_name}}</ng-container> {{riskCase.issue.title}}

                        <span *ngIf="checkEditEntity('title')" class="EditIcon  material-icons" (click)="editEntity(template, 'title')">
                            edit
                        </span>


                    </p>
                    <p>
                        <span
                            [ngClass]="(checkEditEntity('RCSA')&&checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.mandate))?'required':''">
                            RCSA<span class="required"
                                *ngIf='checkEditEntity("RCSA")&&checkRequired([IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.mandate)'>*</span>
                        </span>:

                        <ng-container *ngIf='!resultGitNew.risk_assessment'>
                            <span class="required" *ngIf='!resultGitNew.risk_assessment'>missing</span>
                        </ng-container>

                        <ng-container *ngIf='resultGitNew.risk_assessment'>{{resultGitNew.risk_assessment.name}}, {{resultGitNew.risk_assessment.description}},
                            {{resultGitNew.risk_assessment.rcsa_owner.user_name}}

                            <span *ngIf="checkEditEntity('RCSA')" class="EditIcon  material-icons" (click)="editEntity(template, 'RCSA')">
                                edit
                            </span>
                        </ng-container>

                    </p>



                    <p>
                        <ng-container *ngIf='primaryParent'>


                            <ng-container *ngIf='resultGitNew.risk && primaryParent.riskImpactDriver != "CONTROL" '>
                                Parent Risk: {{resultGitNew.risk.name}} {{resultGitNew.risk.description}}

                            </ng-container>



                            <ng-container *ngIf='primaryParent.riskImpactDriver == "CONTROL"'>
                                Primary Control: {{primaryParent.ctrlControlName}}: {{primaryParent.ctrlDescription}}
                            </ng-container>
                            <ng-container *ngIf="checkEditEntity('Risks&Controls')">
                                <span *ngIf='primaryParent.riskImpactDriver == "RISK"  || primaryParent.riskImpactDriver == "CONTROL"' class="EditIcon  material-icons"
                                    (click)="editEntity(template, 'Risks&Controls')">
                                    edit
                                </span>

                            </ng-container>


                        </ng-container>



                    </p>


                    <p *ngIf='resultGit.folder'>
                        Folder: {{resultGit.folder}}

                    </p>

                </div>






                <div>
                    <div style="display: none;">
                        <app-information-dialog #infoDialog></app-information-dialog>

                    </div>
                    <span (click)='infoDialog.openModal()' class="summaryIcon  material-icons">
                        info
                    </span>
                    <span (click)='generatePdf.generatePDF()' class="summaryIcon  material-icons">
                        picture_as_pdf
                    </span>
                    <span (click)="editEntity(template, 'attachment')" class="summaryIcon position-relative  material-icons">
                        attach_file <span class='attachmentLength'>
                            {{uploadedList.length}}
                        </span>
                    </span>

                    <mat-icon class="summaryIcon  material-icons" (click)="showPermalinkDialog()" matTooltip="Share this case" [matTooltipPosition]="'above'">exit_to_app</mat-icon>

                    <app-permalink-dialog [displayPermalink]="displayPermalink" (hideEvent)="hidePermalinkDialog()">
                    </app-permalink-dialog>

                    <span [routerLink]="['/exit/', riskCase.lastStep]" class="summaryIcon  material-icons">
                        close
                    </span>
                </div>


            </div>

            <div class="hlup"></div>


            <div class=" contantContainer mt-4">


                <div class="row">
                    <div class="col-md-4">
                        <div class="d-flex">
                            <div class=""> <span class="summaryIcon  material-icons">
                                    account_circle
                                </span></div>
                            <div>
                                <p [ngClass]="(checkEditEntity( 'event')&&checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.roles.eventOwner))?'required':''"
                                    class="mb-0">
                                    Issue Owner<span class="required"
                                        *ngIf='checkEditEntity( "event")&&checkRequired([IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.roles.eventOwner)'>*</span>

                                </p>
                                <p class="contantStyle">{{riskCase.roles.eventOwner.user_name}}
                                    <span *ngIf='riskCase.roles.eventOwner.user_status == "INACTIVE"'>(Inactive)</span>

                                    <span *ngIf="checkEditEntity( 'event')" class="EditIcon  material-icons" (click)="editEntity(template, 'event')">
                                        edit
                                    </span>


                                </p>
                                <p class="mb-0">Delegate</p>
                                <p *ngIf='riskCase.roles.eventDelegate' class="contantStyle">
                                    {{riskCase.roles.eventDelegate.user_name}}
                                    <span *ngIf='riskCase.roles.eventDelegate.user_status == "INACTIVE"'>(Inactive)</span>
                                    <span *ngIf="checkEditEntity( 'event')" class="EditIcon  material-icons" (click)="editEntity(template, 'event')">
                                        edit
                                    </span>
                                </p>
                                <p *ngIf='!riskCase.roles.eventDelegate' class="contantStyle">
                                    <span *ngIf="checkEditEntity( 'event')" class="EditIcon  material-icons" (click)="editEntity(template, 'event')">
                                        add
                                    </span>
                                </p>
                                <p [ngClass]="(checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.roles.eventOwner))?'required':''"
                                    class="mb-0   t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.RCSAOwner, 'right')">
                                    RCSA Owner <span class="required"
                                        *ngIf='checkRequired([IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.roles.eventOwner)'>*</span>

                                </p>
                                <p class="contantStyle" *ngIf='rcsaOwner'>{{rcsaOwner.user_name}}
                                    <span *ngIf='rcsaOwner.user_status == "INACTIVE"'>(Inactive)</span>




                                </p>

                            </div>
                        </div>


                    </div>

                    <div class="col-md-4">
                        <div class="d-flex">
                            <div class=""> <span class="summaryIcon  material-icons">
                                    date_range
                                </span></div>
                            <div>

                                <p class="mb-0"
                                    [ngClass]="(checkEditEntity( 'target_due_date',IssueStatus.Open)&&checkRequired([IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.target_due_date))?'required':''">
                                    Target Due Date (CET) <span class="required"
                                        *ngIf='checkEditEntity( "target_due_date",IssueStatus.Open)&&checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed ],riskCase.target_due_date)'>*</span>

                                </p>
                                <p class="contantStyle" *ngIf='riskCase.target_due_date'>
                                    {{showDateSafari(riskCase.target_due_date) | oneOrmDate}}
                                    <span *ngIf="checkEditEntity( 'target_due_date',IssueStatus.Open)" class="EditIcon  material-icons"
                                        (click)="editEntity(template, 'target_due_date',IssueStatus.Open)">
                                        edit
                                    </span>

                                </p>
                                <ng-container *ngIf="checkEditEntity( 'target_due_date',IssueStatus.Open)">
                                    <span *ngIf='!riskCase.target_due_date' class="EditIcon  material-icons" (click)="editEntity(template, 'target_due_date',IssueStatus.Open)">
                                        add
                                    </span>
                                </ng-container>

                                <p *ngIf='resultGit.submission_date' class="mb-0">
                                    Submission Date (CET)
                                </p>
                                <p *ngIf='resultGit.submission_date' class="contantStyle">
                                    <ng-container *ngIf='resultGit.submission_date'>
                                        {{showDateSafari(resultGit.submission_date) | oneOrmDate}}</ng-container>

                                </p>

                                <p *ngIf='resultGit.close_date' class="mb-0">
                                    Completion Date (CET)
                                </p>
                                <p *ngIf='resultGit.close_date' class="contantStyle">
                                    <ng-container *ngIf='resultGit.close_date'> {{showDateSafari(resultGit.close_date) | oneOrmDate}}
                                    </ng-container>
                                </p>
                                <p *ngIf='resultGit.cancel_date' class="mb-0">
                                    Cancellation Date (CET)
                                </p>
                                <p *ngIf='resultGit.cancel_date' class="contantStyle">
                                    <ng-container *ngIf='resultGit.cancel_date'> {{showDateSafari(riskCase.cancel_date) | oneOrmDate}}

                                    </ng-container>
                                </p>





                            </div>
                        </div>










                    </div>






                    <div class="col-md-4">
                        <div class="d-flex">
                            <div class=""> <span class="summaryIcon  material-icons">
                                    daccess_time
                                </span></div>
                            <div>
                                <p class="mb-0">
                                    Status
                                </p>
                                <p class="contantStyle"> {{riskStatus}}

                                </p>

                                <p class="mb-0">
                                    Op Risk Manager * (read only)

                                </p>
                                <p class="contantStyle">
                                    {{riskCase.mandate.risk_manager}}
                                    <span *ngIf='resultGit.risk_manager.user_status == "INACTIVE"'>(Inactive)</span>

                                </p>
                                <ng-container *ngIf='resultGit.identify_LOD.id == 215'>
                                    <p [ngClass]="(checkEditEntity('assurOwner',false)&&checkRequired((resultGit.identify_LOD.id!= 213 )?[ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed]:[],riskCase.assurOwner ))?'required':''"
                                        class="mb-0">
                                        Assurance Owner <span class="required"
                                            *ngIf='checkEditEntity("assurOwner",false)&&checkRequired((resultGit.identify_LOD.id!= 213)?[ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed]:[],riskCase.assurOwner )'>*</span>

                                    </p>
                                    <p *ngIf='riskCase.assurOwner' class="contantStyle">
                                        {{riskCase.assurOwner.user_name}}
                                        <span *ngIf='riskCase.assurOwner.user_status == "INACTIVE"'>(Inactive)</span>
                                        <span *ngIf="checkEditEntity('assurOwner',false)" class="EditIcon  material-icons"
                                            (click)="editEntity(template, 'assurOwner',false)">
                                            edit
                                        </span>
                                    </p>
                                    <p *ngIf='!riskCase.assurOwner' class="contantStyle">
                                        <span *ngIf="checkEditEntity('assurOwner',false)" class="EditIcon  material-icons"
                                            (click)="editEntity(template, 'assurOwner',false)">
                                            add
                                        </span>
                                    </p>

                                </ng-container>

                                <ng-container *ngIf='resultGit.identify_LOD.id == 214'>
                                    <p [ngClass]="(checkEditEntity('assurOwner',false)&&checkRequired((resultGit.identify_LOD.id!= 213 )?[ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed]:[],riskCase.assurOwner ))?'required':''"
                                        class="mb-0">
                                        Assurance Owner <span class="required"
                                            *ngIf='checkEditEntity("assurOwner",false)&&checkRequired((resultGit.identify_LOD.id!= 213)?[ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed]:[],riskCase.assurOwner )'>*</span>

                                    </p>
                                    <p *ngIf='riskCase.assurOwner' class="contantStyle">
                                        {{riskCase.assurOwner.user_name}}
                                        <span *ngIf='riskCase.assurOwner.user_status == "INACTIVE"'>(Inactive)</span>
                                        <span *ngIf="checkEditEntity('assurOwner',false)" class="EditIcon  material-icons" (click)="editEntity(template, 'assurOwner',false)">
                                            edit
                                        </span>
                                    </p>
                                    <p *ngIf='!riskCase.assurOwner' class="contantStyle">
                                        <span *ngIf="checkEditEntity('assurOwner',false)" class="EditIcon  material-icons" (click)="editEntity(template, 'assurOwner',false)">
                                            add
                                        </span>
                                    </p>

                                </ng-container>

                            </div>
                        </div>


                    </div>



                </div>
                <div class="hlup"></div>




                <div class="hlup"></div>


                <div class="d-flex justify-content-between">
                    <div class="w-100">
                        <div class="row w-100  py-4">
                            <div class="col-md-12 dateTitle"></div>

                            <div class="col-md-12 ">

                                <div class="  contantStyle">
                                    <span
                                        [ngClass]="(checkEditEntity( 'description',IssueStatus.Open) && checkRequired([IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.issue.description))?'required':''"
                                        class=" contantStyle">
                                        Description
                                    </span>
                                    <span class="required"
                                        *ngIf='checkEditEntity( "description",IssueStatus.Open) && checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed ],riskCase.issue.description)'>*</span>

                                    <span *ngIf="checkEditEntity( 'description',IssueStatus.Open)" (click)="editEntity(template, 'description',IssueStatus.Open)" class="EditIcon  material-icons">
                                        edit
                                    </span>
                                </div>
                                <div class="">
                                    <div class="mb-3 w-100 descriptionView" *ngIf="riskCase.issue">
                                        <textarea cols="50" [disabled]='true' id="eventdescriptionInput" class="textBoxView" [(ngModel)]='riskCase.issue.description' rows="8">
                                          </textarea>

                                    </div>
                                </div>
                            </div>















                        </div>

                    </div>



                    <div class="sidTitleImg">
                        <img src="../../../../../assets/images/summary/Narrative.png" alt="">
                    </div>
                </div>
                <div class="hlup"></div>

                <div class="d-flex justify-content-between">
                    <div class="w-100">
                        <div class="row w-100  py-4">









                            <div class="col-md-3 my-3">
                                <span class="w-100 contantStyle   t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.ImpactedRisks, 'right')">

                                    Impacted Risks


                                    <span class="readOnly">
                                        (read only)
                                    </span>
                                </span>




                            </div>





                            <div class="col-md-9 accordionContainerHeight my-3">





                                <ng-container>
                                    <mat-accordion class="fix-radio-position" hideToggle="true">

                                        <mat-expansion-panel *ngFor='let item of sortBy(controlRisks,"associationType") ' [ngClass]="  item.associationType == 'PRIMARY'   ?'PRIMARYcontrolRisks':''">
                                            <mat-expansion-panel-header>
                                                <mat-panel-description class='d-flex' style="align-items: center;">

                                                    <div class="PRIMARYcontrolRisksTitle"> {{item.rkName}}: {{item.rkDesc}} </div>

                                                </mat-panel-description>
                                            </mat-expansion-panel-header>
                                            <p class="PRIMARYcontrolRisksContant">Description: {{item.rkDetailDesc}}</p>
                                            <p class="PRIMARYcontrolRisksContant" *ngIf="item.folder">Folder: {{item.folder}}</p>

                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </ng-container>


                            </div>


                            <div class="col-md-3 my-3">
                                <span class="w-100 py-4 contantStyle  "
                                    [ngClass]="(checkEditEntity( 'impactRating',IssueStatus.Open)&&checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed], riskCase.overall_impact_rating    ))?'required':''">

                                    Impact Rating<span class="required"
                                        *ngIf='checkEditEntity( "impactRating",IssueStatus.Open)&&checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.overall_impact_rating )'>*</span>


                                    <span *ngIf="checkEditEntity( 'impactRating',IssueStatus.Open)" (click)="editEntity(template, 'impactRating',IssueStatus.Open)" class="EditIcon  material-icons">
                                        edit
                                    </span>
                                </span>




                            </div>

                            <div class='col-md-9 my-3'>
                                <ng-container *ngIf='riskCase.impact_ratings.length > 0  && riskCase.overall_impact_rating'>

                                    <mat-accordion class="fix-radio-position" hideToggle="true">

                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                                <mat-panel-description class='d-flex' style="align-items: center;">

                                                    <div class="PRIMARYcontrolRisksTitle"> Issue Impact (calculated):
                                                        <span class="IRResult">{{getResult().msg}}

                                                        </span>
                                                    </div>

                                                </mat-panel-description>
                                            </mat-expansion-panel-header>
                                            <div *ngFor='let item of riskCase.impact_ratings' class='d-flex justify-content-start w-100'>
                                                <div class="mr-3">{{getNameOfEnum(item.ir_type_id,irTypeList)}}: </div>
                                                <div>{{getNameOfEnum(item.ir_impact_id,irImpactList)}}</div>

                                            </div>

                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </ng-container>
                                <ng-container *ngIf='riskCase.impact_ratings.length == 0  && riskCase.overall_impact_rating'>

                                    <mat-accordion class="fix-radio-position" hideToggle="true">

                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                                <mat-panel-description class='d-flex' style="align-items: center;">

                                                    <div class="PRIMARYcontrolRisksTitle"> Issue Impact (calculated):
                                                        <span class="IRResult">{{riskCase.overall_impact_rating.name}}

                                                        </span>
                                                    </div>

                                                </mat-panel-description>
                                            </mat-expansion-panel-header>

                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </ng-container>

                            </div>

                            <div class="col-md-12 my-3 ">

                                <div class="  contantStyle">
                                    <span
                                        [ngClass]="(checkEditEntity('rcLatestAssessmDesc')&&checkRequired([IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],notYet))?'required':''"
                                        class=" contantStyle">
                                        Latest Assessment
                                    </span><span class="required"
                                        *ngIf='checkEditEntity("rcLatestAssessmDesc")&&checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed ],notYet)'>*</span>

                                    <span *ngIf="checkEditEntity('rcLatestAssessmDesc')" (click)="editEntity(template, 'rcLatestAssessmDesc')" class="EditIcon  material-icons">
                                        edit
                                    </span>
                                </div>
                                <div class="">
                                    <div class="mb-3 w-100 descriptionView" *ngIf="riskCase.rcLatestAssessmDesc">
                                        <textarea cols="50" [disabled]='true' id="eventdescriptionInput" class="textBoxView" [(ngModel)]='riskCase.rcLatestAssessmDesc' rows="8">
                                          </textarea>

                                    </div>
                                </div>
                            </div>





                        </div>

                    </div>



                    <div class="sidTitleImg">
                        <img src="../../../../../assets/images/summary/Risks.png" alt="">
                    </div>
                </div>

                <div class="hlup"></div>







                <div class="d-flex justify-content-between ">
                    <div class="w-100">
                        <div class="row w-100  py-4">

                            <div class="col-md-12 ">

                                <div class="  contantStyle">
                                    <span
                                        [ngClass]="(checkEditEntity('resolutionApproach',IssueStatus.Open)&&checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.resolutionApproach))?'required':''"
                                        class="mb-0 contantStyle">
                                        Agreed Resolution Approach <span class="required"
                                            *ngIf='checkEditEntity("resolutionApproach",IssueStatus.Open)&&checkRequired([IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.resolutionApproach)'>*</span>
                                        <span *ngIf="checkEditEntity('resolutionApproach',IssueStatus.Open)" (click)="editEntity(template, 'resolutionApproach',IssueStatus.Open)"
                                            class="EditIcon m-0  material-icons">
                                            edit
                                        </span>
                                    </span>
                                </div>
                                <div class="">
                                    <div class="mb-3 w-100 descriptionView" *ngIf="riskCase.resolutionApproach">
                                        <textarea cols="50" [disabled]='true' id="eventdescriptionInput" class="textBoxView" [(ngModel)]='riskCase.resolutionApproach' rows="8">
                                           </textarea>

                                    </div>
                                </div>
                            </div>


                            <div class="col-md-12 d-flex justify-content-between my-3">
                                <span class=" contantStyle"
                                    [ngClass]="(checkEditEntity('action-itemOVP')&&checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed], (riskCase.action_items.length>0?riskCase.action_items:null)    ))?'required':''">

                                    Actions<span class="required"
                                        *ngIf='checkEditEntity("action-itemOVP")&&checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],(riskCase.action_items.length>0?riskCase.action_items:null) )'>*</span>




                                </span>
                                <div *ngIf="checkEditEntity('action-itemOVP')" (click)="editEntity(template, 'action-itemOVP')" class="addActionItem">
                                    +ADD ACTION ITEM


                                </div>



                            </div>





                            <div class="col-md-12 my-3">





                                <ng-container *ngIf='riskCase.action_items.length > 0'>
                                    <mat-accordion class="fix-radio-position" hideToggle="true">

                                        <mat-expansion-panel *ngFor='let item of riskCase.action_items; let i = index '>
                                            <mat-expansion-panel-header>
                                                <mat-panel-description class='d-flex' style="align-items: center;">

                                                    <div class="d-flex justify-content-between w-100">

                                                        <div class="d-flex justify-content-between ">
                                                          <div class="mr-2"><b>{{item.sourceName}}:</b></div>
                                                            <div class='mr-2'>{{item.title}}</div>
                                                            <div class='mr-2'>{{showDateSafari(item.dueDate) | oneOrmDate}}
                                                            </div>
                                                            <div class='mr-2' *ngIf='item.assignee'>{{item.assignee.user_name}} ({{item.assignee.user_id}})</div>

                                                            <div class='mr-2' *ngIf='item.delegate'> {{item.delegate.user_name}} ({{item.delegate.user_id}})</div>

                                                            <div class='mr-2'>{{item.status.name}}</div>
                                                        </div>
                                                        <div>

                                                            <span *ngIf="checkEditEntity('action-itemOVP')" (click)="actionItemData = item;editEntity(template, 'action-itemOVP')"
                                                                class="actionIcon  material-icons">
                                                                edit
                                                            </span>
                                                            <span *ngIf=' riskCase.status == 24 || riskCase.status == 23  ' (click)='removeAction(i)' class="actionIcon  material-icons">
                                                                close
                                                            </span>
                                                        </div>
                                                    </div>

                                                </mat-panel-description>
                                            </mat-expansion-panel-header>
                                            <div class="d-flex justify-content-start w-100">

                                                <p class="w-100" *ngIf='item.sourceId'>Description: {{item.desc}}</p>
                                            </div>

                                            <div class="d-flex justify-content-start w-100">
                                                <p class="w-50" *ngIf='item.assignee'> Assignee: {{item.assignee.user_name}} ({{item.assignee.user_id}})
                                                    <span *ngIf='item.assignee.user_status == "INACTIVE"'>(Inactive)</span>
                                                </p>
                                                <p class="w-50" *ngIf='item.delegate'>Delegate: {{item.delegate.user_name}} ({{item.delegate.user_id}})
                                                    <span *ngIf='item.delegate.user_status == "INACTIVE"'>(Inactive)</span>
                                                </p>
                                            </div>

                                            <div class="d-flex justify-content-start w-100">
                                                <div *ngIf='item.actionTaken' class="w-50 mx-3">
                                                    <p> Action Taken:</p>
                                                    <pre>{{item.actionTaken}}</pre>
                                                </div>
                                                <div *ngIf='item.comments' class="w-50 mx-3">
                                                    <p> Comments:</p>
                                                    <pre>{{item.comments}}</pre>

                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-start w-100">


                                                <p class="w-50" *ngIf='item.dueDate'> Due Date (CET): {{showDateSafari(item.dueDate) | oneOrmDate}}</p>
                                                <p class="w-50" *ngIf='item.completionDate'>Completion Date (CET): {{showDateSafari(item.completionDate) | oneOrmDate}}</p>


                                            </div>


                                            <div class="d-flex justify-content-start w-100">
                                                <p class="w-50" *ngIf='item.status'>Status: {{item.status.name}}</p>

                                            </div>
                                            <div class="d-flex justify-content-start w-100">
                                                <p class="w-50" *ngIf='item.creator'> Created By: {{item.creator.user_name}} ({{item.creator.user_id}})
                                                    <span *ngIf='item.creator.user_status == "INACTIVE"'>(Inactive)</span>
                                                </p>
                                                <p class="w-50" *ngIf='item.creationDate'> Creation Date (CET): {{showDateSafari(item.creationDate) | oneOrmDate}}</p>
                                            </div>
                                            <div class="d-flex justify-content-start w-100">

                                                <p class="w-50" *ngIf='item.modifier'>Last Modified By: {{item.modifier.user_name}} ({{item.modifier.user_id}})
                                                    <span *ngIf='item.modifier.user_status == "INACTIVE"'>(Inactive)</span>
                                                </p>
                                                <p class="w-50" *ngIf='item.modifDate'> Last Modified Date (CET): {{showDateSafari(item.modifDate) | oneOrmDate}}</p>
                                            </div>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </ng-container>



                            </div>



                        </div>

                    </div>



                    <div class="sidTitleImg">
                        <img src="../../../../../assets/images/summary/managementActions.png" alt="">
                    </div>
                </div>

                <div class="hlup"></div>

                <div class="d-flex justify-content-between ">
                    <div class="w-100">
                        <div class="row w-100  py-4">


                            <div class="col-md-3 ">

                                <div class="  contantStyle">
                                    <span
                                        [ngClass]="(checkEditEntity('issueSource',IssueStatus.Open)&&checkRequired([IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.issue_source_id))?'required':''"
                                        class=" contantStyle">
                                        Source
                                    </span><span class="required"
                                        *ngIf='checkEditEntity("issueSource",IssueStatus.Open)&&checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed ],riskCase.issue_source_id)'>*</span>

                                    <span *ngIf="checkEditEntity('issueSource',IssueStatus.Open)" (click)="editEntity(template, 'issueSource',IssueStatus.Open)" class="EditIcon  material-icons">
                                        edit
                                    </span>
                                </div>
                                <div class="">
                                    <div class="mb-3 w-100 descriptionView" *ngIf="riskCase.issue_source_id">
                                        <p class="">
                                            {{riskCase.issue_source_id.name}}
                                        </p>

                                    </div>
                                </div>
                            </div>



                            <div class="col-md-3 ">
                                <div class="  contantStyle">
                                    <span
                                        [ngClass]="(checkEditEntity( 'Identified',IssueStatus.Open)&&checkRequired([IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.identify_LOD))?'required':''"
                                        class=" contantStyle">
                                        Identifying LoD
                                    </span><span class="required"
                                        *ngIf='checkEditEntity( "Identified",IssueStatus.Open)&&checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.identify_LOD)'>*</span>

                                    <span *ngIf="checkEditEntity( 'Identified',IssueStatus.Open)" (click)="editEntity(template, 'Identified',IssueStatus.Open)" class="EditIcon  material-icons">
                                        edit
                                    </span>
                                </div>
                                <div *ngIf='riskCase.identify_LOD'>
                                    {{removeYesAndNoo(resultGit.identify_LOD.name)}}
                                </div>

                            </div>











                        </div>

                    </div>



                    <div class="sidTitleImg">
                        <img src="../../../../../assets/images/summary/identification.png" alt="">
                    </div>
                </div>




                <div class="hlup"></div>
                <div class="d-flex justify-content-between">
                    <div class="w-100">
                        <div class="row minH mt-2 ">

                            <div class="col-md-4 ">

                                <div class="  contantStyle">
                                    <span [ngClass]="(typeValidation())?'required':''" class=" contantStyle">
                                        Type
                                    </span><span class="required" *ngIf='typeValidation()'>*</span>

                                    <span *ngIf="checkEditEntity('issuetype')" (click)="editEntity(template, 'issuetype')" class="EditIcon  material-icons">
                                        edit
                                    </span>
                                </div>
                                <div class="">
                                    <div class="mb-3 w-100 descriptionView" *ngIf="riskCase.type">
                                        <p class="">
                                            {{riskCase.type.name}}
                                        </p>

                                    </div>
                                </div>
                            </div>



                            <div class="bLeft col-md-7">

                                <span
                                    [ngClass]="(checkEditEntity( 'legalEntities')&&checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed], (riskCase.legalEntities.length>0?riskCase.legalEntities:null)    ))?'required':''"
                                    class="contantStyle">
                                    Impacted Legal Entities<span class="required"
                                        *ngIf='checkEditEntity( "legalEntities")&&checkRequired([ IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],(riskCase.legalEntities.length>0?riskCase.legalEntities:null) )'>*</span>
                                </span><span *ngIf="checkEditEntity( 'legalEntities')" class="EditIcon  material-icons" (click)="editEntity(template, 'legalEntities')">
                                    edit
                                </span>


                                <div *ngIf="riskCase.legalEntities.length > 0 && loadDataFlag">
                                    <app-step8 #legalEntitiesView [pdfView]='false' [viewButton]='false' [edit]='false'>
                                    </app-step8>




                                </div>


                            </div>


                        </div>


                        <div class="row minH my-2 ">
                            <div class="col-md-4">
                                <span
                                    [ngClass]="(checkEditEntity('businessImpact')&&checkRequired([IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],(BiLists(riskCase.businessImpact).length>0?BiLists(riskCase.businessImpact):null) ))?'required':''"
                                    class="contantStyle">
                                    Impacted Business Units<span class="required"
                                        *ngIf='checkEditEntity("businessImpact")&&checkRequired([IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],(BiLists(riskCase.businessImpact).length>0?BiLists(riskCase.businessImpact):null))'>*</span>
                                </span><span *ngIf="checkEditEntity('businessImpact')" class="EditIcon  material-icons"
                                    (click)="editEntity(template, 'businessImpact')">
                                    edit
                                </span>

                                <ng-container *ngIf='loadDataFlag'>



                                    <div class="row" *ngIf='showBUI'>
                                        <div class="col-md-12" *ngFor="let Capital of  BiLists(riskCase.businessImpact); let i = index">
                                            <div class="showMoreLess" *ngIf="numberOfItems < BiLists(riskCase.businessImpact).length && i==3 "
                                                (click)='numberOfItems = BiLists(riskCase.businessImpact).length'>
                                                show more...
                                            </div>
                                            <div *ngIf="i < numberOfItems">
                                                {{Capital.name}}

                                            </div>
                                            <div class="showMoreLess" *ngIf=" BiLists(riskCase.businessImpact).length>3 && i==BiLists(riskCase.businessImpact).length-1  && numberOfItems > 3"
                                                (click)='numberOfItems = 3'>
                                                show less...
                                            </div>

                                        </div>

                                    </div>



                                </ng-container>









                            </div>
                            <div class="bLeft col-md-4">

                                <span class="contantStyle"
                                    [ngClass]="(checkEditEntity('outsourcing')&&checkRequired([IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.outsourcing))?'required':''">
                                    Outsourcing<span class="required"
                                        *ngIf='checkEditEntity("outsourcing")&&checkRequired([IssueStatus.Draft, IssueStatus.DraftPendingReview, IssueStatus.Open, IssueStatus.OpenPendingCompletionApproval, IssueStatus.OpenDueDateChangePending,IssueStatus.OpenDueDateExtended,  IssueStatus.Completed],riskCase.outsourcing)'>*</span>
                                </span><span *ngIf="checkEditEntity('outsourcing')" class="EditIcon  material-icons" (click)="editEntity(template, 'outsourcing')">
                                    edit
                                </span>

                                <div *ngIf='riskCase.outsourcing'>
                                    {{ outsourse(riskCase.outsourcing) }}
                                </div>


                            </div>

                        </div>



                    </div>
                    <div class="sidTitleImg">
                        <img src="../../../../../assets/images/summary/ORGANISATIONALATTRIBUTES.png" alt="">
                    </div>
                </div>





                <div class="hlup"></div>

                <div class="d-flex justify-content-between">
                    <div class="row minH my-2 w-100">
                        <div class="col-md-4">


                            <span class="contantStyle t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.icoferIssue , 'right')">
                                ICOFR
                            </span><span class="readOnly">
                                (read only)
                            </span>

                            <div *ngIf='resultGit.issueIcofrs[0]'>
                                {{resultGit.issueIcofrs[0].icofr1Name}}

                            </div>
                            <div *ngIf='resultGit.issueIcofrs[0]'>
                                {{resultGit.issueIcofrs[0].icofr2Name}}
                            </div>
                          <div *ngIf='resultGit.issueIcofrs[0] && resultGit.issueIcofrs[0].icofr3Name'>
                            {{resultGit.issueIcofrs[0].icofr3Name}}
                          </div>


                        </div>
                        <div class="col-md-4 bLeft">



                            <span class=" contantStyle">
                                Local Reporting
                            </span>

                            <span *ngIf="checkEditEntity('localReporting')" (click)="editEntity(template, 'localReporting')" class="EditIcon  material-icons">
                                edit
                            </span>




                            <ng-container *ngIf='riskCase.local_reporting'>
                                <div *ngFor='let item of riskCase.local_reporting'>
                                    {{item.name}}

                                </div>
                            </ng-container>


                        </div>
                    </div>

                    <div class="sidTitleImg">
                        <img src="../../../../../assets/images/summary/frr.png" alt="">
                    </div>
                </div>












                <div class="hlup"></div>

                <div class="d-flex justify-content-between">

                    <div class="row  w-100">

                        <div class="row w-100  minH mt-2 mx-2">

                            <div class="col-md-8">

                                <span *ngIf='!showButtonForUserRole(["CORM","Comp"])' ngxTippy [tippyProps]="tooltip(FieldHelpData.complianceRiskCategory , 'right')" class="contantStyle t-demo__btn">
                                    Compliance and Legal Risk Category
                                </span>
                                <span *ngIf='showButtonForUserRole(["CORM","Comp"])' ngxTippy [tippyProps]="tooltip(FieldHelpData.complianceRiskCategory , 'right')" class="contantStyle t-demo__btn">
                                    Compliance and Legal Risk Category
                                </span>
                                <span *ngIf='!showButtonForUserRole(["CORM","Comp", "UM"])' class="readOnly">
                                    (read only)
                                </span>
                                <span *ngIf='showButtonForUserRole(["CORM","Comp", "UM"]) && (riskCase.status != IssueStatus.Cancelled && riskCase.status != IssueStatus.Completed)'
                                    class="EditIcon  material-icons" (click)="editEntity(template, 'complianceRiskCategory')">
                                    edit
                                </span>
                                <ng-container *ngIf='riskCase.compliance_risk_category'>
                                    <div *ngFor='let item of riskCase.compliance_risk_category'>
                                        <ng-container *ngIf='item.id == 427'>
                                            Not Compliance and Legal Risk related
                                        </ng-container>
                                        <ng-container *ngIf='item.id != 427'>
                                            {{item.name}}
                                        </ng-container>
                                    </div>
                                </ng-container>



                            </div>
                        </div>
                        <div *ngIf='resultGit.identify_LOD.id!= selfIdentifiedIssueLodId' class="hlup w-100"></div>

                        <div *ngIf='resultGit.identify_LOD.id!= selfIdentifiedIssueLodId' class="row minH m-2 w-100">

                          <ng-template #editIcon let-data>
                            <span *ngIf="checkEditEntity(data.view,false,!showButtonForUserRole(data.roles))" class="EditIcon  material-icons" (click)="editEntity(template, data.view,false,!showButtonForUserRole(data.roles))">
                              edit
                            </span>
                          </ng-template>

                            <div *ngIf='resultGit.identify_LOD.id!= selfIdentifiedIssueLodId' class="col-md-4 bLeft">


                                <span class="contantStyle">
                                    Assurance Function Comments & Follow-up Review
                                </span>
                              <ng-container *ngIf="resultGit.identify_LOD.id === secondLineIssueLodID" [ngTemplateOutlet]="editIcon" [ngTemplateOutletContext]="{$implicit: {view: 'assurComment', roles: ['CORM', 'ORMII', 'ORM', 'Comp']}}"></ng-container>
                              <ng-container *ngIf="resultGit.identify_LOD.id === thirdLineIssueLodID" [ngTemplateOutlet]="editIcon" [ngTemplateOutletContext]="{$implicit: {view: 'assurComment', roles: ['CORM', 'GIA']}}"></ng-container>

                                <div class="">
                                    <div class="mb-3 w-100 descriptionView" *ngIf="riskCase.assurComment">
                                        <textarea cols="50" [disabled]='true' id="eventdescriptionInput" class="textBoxView" [(ngModel)]='riskCase.assurComment' rows="8">
                                              </textarea>

                                    </div>
                                </div>



                            </div>

                            <div *ngIf='resultGit.identify_LOD.id!== selfIdentifiedIssueLodId' class="col-md-4 bLeft">


                                <span class="contantStyle">
                                    Re Opened
                                </span>
                              <ng-container *ngIf="resultGit.identify_LOD.id === secondLineIssueLodID" [ngTemplateOutlet]="editIcon" [ngTemplateOutletContext]="{$implicit: {view: 'reOpened', roles: ['CORM', 'ORMII', 'ORM', 'Comp']}}"></ng-container>
                              <ng-container *ngIf="resultGit.identify_LOD.id === thirdLineIssueLodID" [ngTemplateOutlet]="editIcon" [ngTemplateOutletContext]="{$implicit: {view: 'reOpened', roles: ['CORM', 'GIA']}}"></ng-container>

                                <div class="">
                                    <div class="mb-3 w-100 descriptionView" *ngIf="riskCase.reOpened">
                                        <textarea cols="50" [disabled]='true' id="eventdescriptionInput" class="textBoxView" [(ngModel)]='riskCase.reOpened' rows="8">
                                              </textarea>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                    <div class="sidTitleImg">
                        <img src="../../../../../assets/images/summary/secLine.png" alt="">
                    </div>
                </div>

















            </div>

        </div>




    </mat-card>



</ng-container>
















<ng-template #template>

    <div class="modal-body">

        <div class="w-100">


            <ng-container *ngIf="modelView=='assurOwner'">
                <div style="max-width: 1000px;">
                    <div class="row  p-4">
                        <div class='col-md-12 mb-4'></div>

                        <div class='col-md-3 mb-4 t-demo__btn' ngxTippy [tippyProps]="tooltip(FieldHelpData.assuranceOwner, 'right')"> Assurance Owner

                        </div>
                        <div class='col-md-9 mb-4'>


                            <p-autoComplete [(ngModel)]="riskCase.assurOwner" [suggestions]="filterUsersSingleList" (completeMethod)="filterUsersSingle($event)" [field]="composeName" [size]="50"
                                (onSelect)="selectUser($event,'assurOwner')" placeholder="Name of SR Employee (Mandatory)" [minLength]="1" [forceSelection]="true"
                                emptyMessage="{{filterUsersSingleList && filterUsersSingleList.length >0 ? '' : 'User Not Found'}}">
                                <ng-template let-user pTemplate="item">
                                    <div>
                                        {{user.user_name}} ({{user.user_id}})
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                        </div>








                        <ng-container>







                        </ng-container>

                    </div>


                    <div class="d-flex justify-content-between">

                        <div class="ml-4 errorMsg">
                            <ng-container *ngIf='showError'>
                                <p class="mb-1">
                                    You have already entered one or more monetary impact items with ‘Date of Acknowledgement’ BEFORE this Discovery Date.

                                </p>
                                <p class="mb-1">
                                    You need to update the ‘Date of Acknowledgement’ of these items before you can proceed with this ‘Discovery Date’.
                                </p>
                            </ng-container>

                        </div>
                        <div class="d-flex justify-content-end">
                            <button class='primaryBtn mr-4   buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>
                            <div [matTooltip]="checkValidation().msg" [matTooltipPosition]="'above'">

                                <button class='primaryBtn   buttonWidth' pButton type="button" label="Update" [disabled]="checkValidation().disabled" (click)="update()"></button>
                            </div>

                        </div>





                    </div>
                </div>
            </ng-container>






            <ng-container *ngIf="modelView=='event'">
                <div style="max-width: 1000px;">
                    <div class="row  p-4">
                        <div class='col-md-12 mb-4'></div>

                        <div class='col-md-2 mb-4 t-demo__btn' ngxTippy [tippyProps]="tooltip(FieldHelpData.IssueOwner, 'right')"> Issue Owner

                        </div>
                        <div class='col-md-10 mb-4'>

                            <p-autoComplete [(ngModel)]="riskCase.roles.eventOwner" [suggestions]="filterUsersSingleList" (completeMethod)="filterUsersSingle($event)" [field]="composeName" [size]="50"
                                (onSelect)="selectUser($event,'eventOwner')" placeholder="Name of SR Employee (Mandatory)" [minLength]="1" [forceSelection]="true"
                                emptyMessage="{{filterUsersSingleList && filterUsersSingleList.length >0 ? '' : 'User Not Found'}}">
                                <ng-template let-user pTemplate="item">
                                    <div>
                                        {{user.user_name}} ({{user.user_id}})
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                        </div>




                        <div class='col-md-2 mb-4 t-demo__btn' ngxTippy [tippyProps]="tooltip(FieldHelpData.IssueDelegate, 'right')"> Issue Delegate

                        </div>
                        <div class='col-md-10 mb-4'>
                            <p-autoComplete [(ngModel)]="riskCase.roles.eventDelegate" [suggestions]="filterUsersSingleList" (completeMethod)="filterUsersSingle($event)" [field]="composeName"
                                [size]="50" placeholder="Name of SR Employee (Optional)" [minLength]="1" [forceSelection]="true"
                                emptyMessage="{{filterUsersSingleList && filterUsersSingleList.length >0 ? '' : 'User Not Found'}}">
                                <ng-template let-user pTemplate="item">
                                    <div>
                                        {{user.user_name}} ({{user.user_id}})
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                        </div>




                        <ng-container>







                        </ng-container>

                    </div>


                    <div class="d-flex justify-content-between">

                        <div class="ml-4 errorMsg">
                            <ng-container *ngIf='showError'>

                            </ng-container>

                        </div>
                        <div class="d-flex justify-content-end">
                            <button class='primaryBtn mr-4   buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>
                            <div [matTooltip]="checkValidation().msg" [matTooltipPosition]="'above'">

                                <button class='primaryBtn   buttonWidth' pButton type="button" label="Update" [disabled]="checkValidation().disabled" (click)="update()"></button>
                            </div>

                        </div>





                    </div>
                </div>
            </ng-container>



            <ng-container *ngIf="modelView=='businessImpact'">

                <app-step7-event [BUInput]='riskCase.businessImpact' [viewButton]='false' (newData)='updateData($event)'></app-step7-event>

                <div class="d-flex justify-content-end">
                    <button class='primaryBtn  mr-4   buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>

                    <button class='primaryBtn buttonWidth' pButton type="button" label="Update" (click)="update()"></button>

                    <div class='d-flex'>
                        <app-information-dialog [accordionIndex]='8'></app-information-dialog>
                    </div>



                </div>
            </ng-container>

            <ng-container *ngIf="modelView=='RCSA'">


                <p>
                    Changing the RCSA may impact selected risks and controls. Please contact your Op Risk Manager to assess the impact and perform the required changes in GRACE. </p>

                <div class="d-flex justify-content-end">


                    <button class='primaryBtn mr-4   buttonWidth' pButton type="button" label="Close" (click)="cancel()"></button>



                </div>
            </ng-container>

            <ng-container *ngIf="modelView=='outsourcing'">

                <app-step9 [viewButton]='false' #step9 (newData)='updateDataAndSave($event)'></app-step9>
                <div class="d-flex justify-content-end">

                    <button class='primaryBtn    mr-4  buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>

                    <button class='primaryBtn  buttonWidth' [disabled]='step9.canUpdate()' pButton type="button" label="Update" (click)="step9.changeData()"></button>
                    <div class='d-flex'>
                        <app-information-dialog [accordionIndex]='10'></app-information-dialog>
                    </div>

                </div>
            </ng-container>

            <ng-container *ngIf="modelView=='impactRating'">

                <app-risk-impact [riskCase]='riskCase' [controlRisks]='controlRisks' [primaryControl]='primaryControl' [impact_ratings]='riskCase.impact_ratings' [riskCaseId]='riskCaseId'
                    (newData)='updateData($event)'>
                </app-risk-impact>

                <div class="d-flex justify-content-end">

                    <button class='primaryBtn    mr-4  buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>

                    <button class='primaryBtn  buttonWidth' pButton type="button" label="Update" [disabled]='!getResult().validation' (click)="update()"></button>
                    <div class='d-flex'>
                        <app-information-dialog [accordionIndex]='6'></app-information-dialog>
                    </div>

                </div>
            </ng-container>
            <ng-container *ngIf="modelView=='RootCauses'">

                <app-additional-information-step [viewButton]='false' [rootCause]='true' [location]='false' [Function]='false' (newData)='updateData($event)'></app-additional-information-step>
                <div class="d-flex justify-content-end">


                    <button class='primaryBtn mr-4  buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>

                    <button class='primaryBtn   buttonWidth' pButton type="button" label="Update" (click)="update()"></button>

                </div>
            </ng-container>

            <ng-container *ngIf="modelView=='AdditionalInformation'">

                <app-additional-information-step [viewButton]='false' [rootCause]='false' [location]='true' [Function]='false' (newData)='updateData($event)'></app-additional-information-step>
                <div class="d-flex justify-content-end">

                    <button class='primaryBtn  mr-4  buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>

                    <button class='primaryBtn  buttonWidth' pButton type="button" label="Update" (click)="update()"></button>


                </div>
            </ng-container>
            <ng-container *ngIf="modelView=='Identified'">

                <app-step4-issue [viewButton]='false' (newData)='updateData($event)'></app-step4-issue>

                <div class="d-flex justify-content-end">

                    <button class='primaryBtn  mr-4  buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>

                    <button class='primaryBtn  buttonWidth' pButton type="button" label="Update" (click)="update()"></button>
                    <div class='d-flex'>
                        <app-information-dialog [accordionIndex]='3'></app-information-dialog>
                    </div>


                </div>
            </ng-container>
            <ng-container *ngIf="modelView=='Risks&Controls'">

                <app-step5-issue [viewButton]='false' (newData)='updateData($event)'></app-step5-issue>

                <div class="d-flex justify-content-end">

                    <button class='primaryBtn  mr-4  buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>

                    <button class='primaryBtn  buttonWidth' pButton type="button" label="Update" (click)="update()"></button>
                    <div class='d-flex'>
                        <app-information-dialog [accordionIndex]='4'></app-information-dialog>
                    </div>

                </div>
            </ng-container>

            <ng-container *ngIf="modelView=='Risks&ControlsLowProfile'">


                <p>
                    Risk and Control changes can only be done in GRACE. Please contact your Op Risk Manager or Governance Coordinator for this. </p>

                <div class="d-flex justify-content-end">


                    <button class='primaryBtn mr-4   buttonWidth' pButton type="button" label="Close" (click)="cancel()"></button>
                </div>
            </ng-container>

            <ng-container *ngIf="modelView=='Risks&ControlsHighProfile'">


                <p>
                    Risk and Control changes can only be done in GRACE. Please open the case in GRACE here to take action:</p>
                <p class="contantStyle cursor_pointer" (click)='goOutsideApp(resultGit.url)'> {{resultGit.url}}</p>

                <div class="d-flex justify-content-end">


                    <button class='primaryBtn mr-4   buttonWidth' pButton type="button" label="Close" (click)="cancel()"></button>
                </div>
            </ng-container>

            <ng-container *ngIf="modelView=='Function'">

                <app-additional-information-step [viewButton]='false' [rootCause]='false' [location]='false' [Function]='true' (newData)='updateData($event)'></app-additional-information-step>
                <div class="d-flex justify-content-end">



                    <button class='primaryBtn mr-4  buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>
                    <button class='primaryBtn   buttonWidth' pButton type="button" label="Update" (click)="update()"></button>

                </div>
            </ng-container>
            <ng-container *ngIf="modelView=='legalEntities'">

                <app-step8 [viewButton]='false' #step8 (newData)='updateDataAndSave($event)'></app-step8>
                <div class="d-flex justify-content-end">



                    <button class='primaryBtn mr-4   buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>
                    <button class='primaryBtn  buttonWidth' pButton type="button" label="Update" (click)="step8.changeData()"></button>
                    <div class='d-flex'>
                        <app-information-dialog [accordionIndex]='9'></app-information-dialog>
                    </div>
                </div>

            </ng-container>




            <ng-container *ngIf="modelView=='localReporting'">

                <p class="t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.LocalReporting, 'right')">
                    Local Reporting
                </p>
                <app-enum-item-selection #checkbox2 [list]='localReporting' [preselected]="riskCase.local_reporting" [fullWidth]='true' [title]='"Local Reporting"'
                    (selectedList)='getList($event,"local_reporting")'>
                </app-enum-item-selection>




                <div class="d-flex justify-content-end">
                    <button class='primaryBtn  mr-4  buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>

                    <button class='primaryBtn  buttonWidth' pButton type="button" label="Update" (click)="update()"></button>





                </div>
            </ng-container>
            <ng-container *ngIf="modelView=='issuetype'">


                <div class="row">
                    <div class="  col-md-12   my-3">
                        <div class="borderLine d-flex align-items-start flex-column ">

                            <p class="mb-0">
                                Type:
                            </p>
                            <div style="    height: 560px;overflow-y: auto;width: 100%;margin-bottom: 20px;">
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group">
                                    <mat-radio-button (change)='setAdditionalInformationData(issueSource,"type" )' class="example-radio-button  " *ngFor="let issueSource of issueTypeList"
                                        [value]="issueSource.id" [checked]="riskCase.type ? issueSource.id === riskCase.type.id : false">
                                        <span>
                                            {{issueSource.name}}
                                        </span>

                                    </mat-radio-button>
                                </mat-radio-group>

                            </div>



                            <div *ngIf='riskCase.type' class="mt-auto  w-100 d-flex justify-content-start align-items-end">

                                <div class="mr-4"> <span class="contantStyle">
                                        Your Selection:
                                    </span> </div>
                                <div class="d-flex">
                                    <span>
                                        {{riskCase.type.name}}
                                    </span>

                                    <span class="clearSelection ml-2 material-icons" matTooltip="Clear your selection" [matTooltipPosition]="'above'"
                                        (click)='setAdditionalInformationData(null,"type" )'>
                                        close
                                    </span>
                                </div>

                            </div>
                        </div>

                        <div class="d-flex justify-content-end">

                            <button class='primaryBtn  mr-4 buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>

                            <button class='primaryBtn   buttonWidth' pButton type="button" label="Update" (click)="update()"></button>


                        </div>

                    </div>

                </div>










            </ng-container>


            <ng-container *ngIf="modelView=='issueSource'">


                <div class="row">
                    <div class="  col-md-12   my-3">
                        <div class="borderLine d-flex align-items-start flex-column ">

                            <p class="mb-0  t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.Source, 'right')">
                                Source:
                            </p>
                            <div style="    height: 560px;overflow-y: auto;width: 100%;margin-bottom: 20px;">
                                <mat-radio-group *ngIf="issueSourceList" aria-labelledby="example-radio-group-label" class="example-radio-group">
                                  <span class="contantStyle">{{sourceIdLabel.first}}</span>
                                  <ng-container *ngFor="let issueSource of issueSourceList.first">
                                    <mat-radio-button (change)='setAdditionalInformationData(issueSource,"issue_source_id" )' class="example-radio-button"
                                                      [value]="issueSource.id" [checked]="riskCase.issue_source_id ? issueSource.id === riskCase.issue_source_id.id : false">
                                        <span>
                                            {{issueSource.name}}
                                        </span>

                                    </mat-radio-button>
                                  </ng-container>

                                  <span class="contantStyle">{{sourceIdLabel.second}}</span>
                                  <ng-container *ngFor="let issueSource of issueSourceList.second">
                                    <mat-radio-button (change)='setAdditionalInformationData(issueSource,"issue_source_id" )' class="example-radio-button"
                                                      [value]="issueSource.id" [checked]="riskCase.issue_source_id ? issueSource.id === riskCase.issue_source_id.id : false">
                                        <span>
                                            {{issueSource.name}}
                                        </span>

                                    </mat-radio-button>
                                  </ng-container>

                                  <span class="contantStyle">{{sourceIdLabel.third}}</span>
                                  <ng-container *ngFor="let issueSource of issueSourceList.third">
                                    <mat-radio-button (change)='setAdditionalInformationData(issueSource,"issue_source_id" )' class="example-radio-button"
                                                      [value]="issueSource.id" [checked]="riskCase.issue_source_id ? issueSource.id === riskCase.issue_source_id.id : false">
                                        <span>
                                            {{issueSource.name}}
                                        </span>

                                    </mat-radio-button>
                                  </ng-container>
                                </mat-radio-group>

                            </div>



                            <div *ngIf='riskCase.issue_source_id' class="mt-auto  w-100 d-flex justify-content-start align-items-end">

                                <div class="mr-4"> <span class="contantStyle">
                                        Your Selection:
                                    </span> </div>
                                <div class="d-flex">
                                    <span>
                                        {{riskCase.issue_source_id.name}}
                                    </span>

                                    <span class="clearSelection ml-2 material-icons" matTooltip="Clear your selection" [matTooltipPosition]="'above'"
                                        (click)='setAdditionalInformationData(null,"issue_source_id" )'>
                                        close
                                    </span>
                                </div>

                            </div>
                        </div>

                        <div class="d-flex justify-content-end">

                            <button class='primaryBtn  mr-4 buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>

                            <button class='primaryBtn   buttonWidth' pButton type="button" label="Update" (click)="update()"></button>


                        </div>

                    </div>

                </div>










            </ng-container>





            <ng-container *ngIf="modelView=='complianceRiskCategory'">

                <p class="mb-0">
                    Compliance and Legal Risk Category:

                </p>

                <mat-checkbox (change)="nocomplianceRiskCategory($event.checked,complianceRiskCategorycheckbox)" [checked]='checkNotCC(riskCase.compliance_risk_category,427)'>
                    Not Compliance and Legal Risk related <strong class='ml-1'> (Note: Legal Risk in this context only refers to Fair Competition and Licensing risks.)</strong>


                </mat-checkbox>

                <app-enum-item-selection [list]='complianceRiskCategory' #complianceRiskCategorycheckbox [preselected]="riskCase.compliance_risk_category" [fullWidth]='true'
                    [title]='"Compliance and Legal Risk Category"' (selectedList)='getList($event,"compliance_risk_category")'></app-enum-item-selection>




                <div class="d-flex justify-content-end">

                    <button class='primaryBtn  mr-4 buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>
                    <button class='primaryBtn  buttonWidth' pButton type="button" label="Update" (click)="update()"></button>
                    <div class='d-flex'>
                        <app-information-dialog [accordionIndex]='12'></app-information-dialog>
                    </div>


                </div>
            </ng-container>

            <ng-container *ngIf="modelView=='cantEdit'">


                <p>
                    You cannot edit this item.
                </p>




                <div class="d-flex justify-content-end">

                    <button class='primaryBtn   buttonWidth' pButton type="button" label="OK" (click)="modalRef.hide()"></button>




                </div>
            </ng-container>


            <ng-container *ngIf="modelView=='notComp'">


                <p>
                    To be filled in by Compliance.
                </p>




                <div class="d-flex justify-content-end">

                    <button class='primaryBtn   buttonWidth' pButton type="button" label="OK" (click)="modalRef.hide()"></button>




                </div>
            </ng-container>



            <ng-container *ngIf="modelView=='EventType'">
                <div class="row">
                    <div class="  col-md-6   my-3">
                        <div class="borderLine d-flex align-items-start flex-column h-100">
                            <p class="mb-0">
                                Event Type Level 1:
                            </p>

                            <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group">
                                <mat-radio-button (change)='seteventTypes2(rootCauseItem)' class="example-radio-button" *ngFor="let rootCauseItem of eventTypes1" [value]="rootCauseItem.level_1_id"
                                    [checked]="riskCase.event_type_level_1 ? rootCauseItem.level_1_id === riskCase.event_type_level_1.id : false">
                                    {{rootCauseItem.level_1_name}}
                                </mat-radio-button>
                            </mat-radio-group>


                            <div *ngIf='riskCase.event_type_level_1' class="mt-auto  w-100 d-flex justify-content-start align-items-end">

                                <div class="mr-4"> <span class="contantStyle">
                                        Your Selection:
                                    </span> </div>
                                <div class="d-flex">
                                    <span>
                                        {{riskCase.event_type_level_1.name}}
                                    </span>

                                    <span class="clearSelection ml-2 material-icons" matTooltip="Clear your selection" [matTooltipPosition]="'above'" (click)='seteventTypes2(null)'>
                                        close
                                    </span>
                                </div>

                            </div>
                        </div>

                    </div>
                    <ng-container>
                        <div class="  col-md-6   my-3" *ngIf='eventTypes2.length!=0'>
                            <div class="borderLine d-flex align-items-start flex-column h-100">
                                <div class="containerDiv p-1 w-100">
                                    <p>
                                        Event Type Level 2:
                                    </p>


                                    <app-custom-search [placeholder]='"Search Root Cause Code Level 2 Search"' class="d-flex justify-content-center" (value)="SearchRCL2($event)">
                                    </app-custom-search>

                                    <div class="radioBtnContainer">
                                        <div *ngIf="eventTypes2.length == 0" class="d-flex justify-content-center align-items-center loading">Loading...
                                        </div>
                                        <mat-accordion class="fix-radio-position" hideToggle="true">
                                            <mat-radio-group aria-label="Select an option">

                                                <mat-expansion-panel *ngFor='let item of eventTypes2 | searchPipe: {  level_2_name: searchValueRCL2}'>
                                                    <mat-expansion-panel-header>
                                                        <mat-panel-description class='d-flex' style="align-items: center;">
                                                            <mat-radio-button (change)='setAdditionalInformationData({id:item.level_2_id,name:item.level_2_name},"event_type_level_2" )'
                                                                [value]="item.level_2_id" [checked]="riskCase.event_type_level_2 ? item.level_2_id === riskCase.event_type_level_2.id : false">
                                                            </mat-radio-button>
                                                            <div>{{item.level_2_name}} </div>

                                                        </mat-panel-description>
                                                    </mat-expansion-panel-header>

                                                </mat-expansion-panel>
                                            </mat-radio-group>
                                        </mat-accordion>
                                    </div>


                                </div>

                                <div *ngIf='riskCase.event_type_level_2' class="mt-auto  w-100 d-flex justify-content-start align-items-end">

                                    <div class="mr-4"> <span class="contantStyle">
                                            Your Selection:
                                        </span> </div>
                                    <div class="d-flex">
                                        <span>
                                            {{riskCase.event_type_level_2.name}}
                                        </span>

                                        <span class="clearSelection ml-2 material-icons" matTooltip="Clear your selection" [matTooltipPosition]="'above'"
                                            (change)='setAdditionalInformationData(null,"event_type_level_2" )'>
                                            close
                                        </span>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="d-flex justify-content-end">

                    <button class='primaryBtn  mr-4   buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>

                    <button class='primaryBtn buttonWidth' pButton type="button" label="Update" (click)="update()"></button>
                </div>
            </ng-container>
            <ng-container *ngIf="modelView=='resolutionApproach'">
                <div class="w-100">
                    <div class=" py-4 contantStyle  t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.AgreedResolutionApproach, 'right')">

                        Agreed Resolution Approach


                    </div>
                    <div class="mb-3 w-100 inputField">
                        <small class="counterText" [ngClass]="(!byteCount(riskCase.resolutionApproach,4000).valid)?'warningTxt':''">{{byteCount(riskCase.resolutionApproach,4000).length}} / 4000
                        </small>

                        <textarea cols="50" id="eventLessonsInput" [(ngModel)]='riskCase.resolutionApproach' rows="8" maxlength="4000">
                        </textarea>
                        <span *ngIf='!byteCount(riskCase.resolutionApproach,4000).valid' class="warningTxt">
                            The text entered exceeds the maximum number of characters allowed. Please reduce the text length up to the defined limit.
                        </span>



                    </div>

                </div>




                <div class="d-flex justify-content-end">

                    <button class='primaryBtn   mr-4 buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>

                    <button class='primaryBtn  buttonWidth' pButton type="button" [disabled]='!byteCount(riskCase.resolutionApproach,4000).valid' label="Update" (click)="update()"></button>


                </div>
            </ng-container>




            <ng-container *ngIf="modelView=='action-item'">


                <ng-container *ngIf='riskCase.target_due_date'>
                    <div class="modal-header">
                        <h4 class="modal-title m-0">WARNING!</h4>
                        <span (click)="modalRef.hide()" class="material-icons informationIcon">
                            close
                        </span>

                    </div>
                    <div>
                        <ul class=" dUL bd-highlight mb-4">
                            <li> {{validationActionItemText}} </li>

                        </ul>


                        <div class="d-flex justify-content-end w-100">

                            <button class='primaryBtn   buttonWidth' pButton type="button" label="OK" (click)="modalRef.hide()"></button>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf='!riskCase.target_due_date'>
                    <div class="modal-header">
                        <h4 class="modal-title m-0">WARNING!</h4>
                        <span (click)="modalRef.hide()" class="material-icons informationIcon">
                            close
                        </span>

                    </div>
                    <div>
                        <ul class=" dUL bd-highlight mb-4">
                            <li> Target Due Date is required before Action Items can be created. </li>

                        </ul>


                        <div class="d-flex justify-content-end w-100">

                            <button class='primaryBtn   buttonWidth' pButton type="button" label="OK" (click)="modalRef.hide()"></button>
                        </div>
                    </div>
                </ng-container>

            </ng-container>





            <ng-container *ngIf="modelView=='action-itemOVP'">


                <app-action-item *ngIf='riskCase.target_due_date' [caseDueDate]='riskCase.target_due_date' [actionItem]='actionItemData' [riskCaseId]='riskCaseId' (newData)='addActionItem($event)'>
                </app-action-item>
                <ng-container *ngIf='!riskCase.target_due_date'>
                    <div class="modal-header">
                        <h4 class="modal-title m-0">WARNING!</h4>
                        <span (click)="modalRef.hide()" class="material-icons informationIcon">
                            close
                        </span>

                    </div>
                    <div>
                        <ul class=" dUL bd-highlight mb-4">
                            <li> Target Due Date is required before Action Items can be created. </li>

                        </ul>


                        <div class="d-flex justify-content-end w-100">

                            <button class='primaryBtn   buttonWidth' pButton type="button" label="OK" (click)="modalRef.hide()"></button>
                        </div>
                    </div>
                </ng-container>

            </ng-container>

















            <ng-container *ngIf="modelView=='target_due_date'">

                <div style="max-width: 1000px;">
                    <div class="row mb-4  p-4">
                        <ng-container>







                            <div class="px-3  t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.TargetDueDate, 'right')"> Target Due Date
                            </div>
                            <div class="col-md-9 ">
                                <div class="input-container position-relative">
                                    <div (click)='occurredDate.open()' class=" inputIcon material-icons">
                                        date_range
                                    </div>
                                    <input (keypress)="cantEdit($event)" class="input-field" placeholder="DD-MMM-YYYY" [disabled]='true' class="notDisabledInput" [ngModelOptions]="{standalone: true}"
                                        [ngModel]="riskCase.target_due_date | date: dateFormat" name="target_due_date">

                                    <input [matDatepicker]='occurredDate' [min]='today' [(ngModel)]='riskCase.target_due_date' name='target_due_date'
                                        (dateChange)="dateChanged($event.value, 'target_due_date')" class="input-field matrialInputH" placeholder="dd-mm-yyyy">
                                    <mat-datepicker #occurredDate></mat-datepicker>
                                </div>
                            </div>
                            <span *ngIf='overDueValue(resultGit.overdue)' class="warningTxt col-md-12">
                                The Target Due Date of this Issue is in the past. Please review your selection before proceeding to the next steps.
                            </span>

                        </ng-container>

                    </div>



                    <div class="d-flex justify-content-end">
                        <button class='primaryBtn mr-4   buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>
                        <div [matTooltip]="checkValidation().msg" [matTooltipPosition]="'above'">

                            <button class='primaryBtn   buttonWidth' pButton type="button" label="Update" [disabled]="!riskCase.target_due_date" (click)="update()"></button>
                        </div>

                    </div>





                </div>
            </ng-container>

            <ng-container *ngIf="modelView=='title'">
                <div class="w-100">

                    <div class=" py-4 contantStyle t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.IssueTitle, 'right')">

                        Issue Title:

                    </div>
                    <div class="mb-3 w-50 inputField">
                        <small class="counterText" [ngClass]="(!byteCount(eventTitleInput.value,120).valid)?'warningTxt':''">{{byteCount(eventTitleInput.value,120).length}} / 120 </small>

                        <textarea id="eventTitleInput" #eventTitleInput cols="50" maxlength='120' rows="2" placeholder="(Issue Title)" [(ngModel)]='riskCase.issue.title'>
                           </textarea>
                        <span *ngIf='!byteCount(eventTitleInput.value,120).valid' class="warningTxt">
                            The text entered exceeds the maximum number of characters allowed. Please reduce the text length up to the defined limit.
                        </span>

                    </div>


                </div>
                <div class="d-flex justify-content-end">

                    <button class='primaryBtn mr-4  buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>

                    <button class='primaryBtn   buttonWidth' pButton type="button" label="Update" [disabled]='eventTitleInput.value.length == 0 || !byteCount(eventTitleInput.value,120).valid'
                        (click)="update()"></button>
                </div>
            </ng-container>





            <ng-container *ngIf="modelView=='rcLatestAssessmDesc'">
                <div class="w-100">
                    <div class=" py-4 contantStyle t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.LatestAssessment, 'right')">

                        Latest Assessment

                    </div>
                    <div class="mb-3 w-100 inputField">
                        <small class="counterText" [ngClass]="(!byteCount(riskCase.rcLatestAssessmDesc,4000).valid)?'warningTxt':''">{{byteCount(riskCase.rcLatestAssessmDesc,4000).length}} / 4000
                        </small>

                        <textarea cols="50" id="eventLessonsInput" [(ngModel)]='riskCase.rcLatestAssessmDesc' rows="8" maxlength="4000">
                        </textarea>
                        <span *ngIf='!byteCount(riskCase.rcLatestAssessmDesc,4000).valid' class="warningTxt">
                            The text entered exceeds the maximum number of characters allowed. Please reduce the text length up to the defined limit.
                        </span>





                    </div>

                </div>




                <div class="d-flex justify-content-end">

                    <button class='primaryBtn   mr-4 buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>

                    <button class='primaryBtn  buttonWidth' pButton type="button" label="Update" [disabled]='!byteCount(riskCase.rcLatestAssessmDesc,4000).valid' (click)="update()"></button>


                </div>
            </ng-container>







            <ng-container *ngIf="modelView=='assurComment'">
                <div class="w-100">
                    <div class="w-100 py-4 contantStyle">

                        Assurance Function Comments

                    </div>
                    <div class="mb-3 w-100 inputField">

                        <small class="counterText" [ngClass]="(!byteCount(riskCase.assurComment,4000).valid)?'warningTxt':''">{{byteCount(riskCase.assurComment,4000).length}} / 4000 </small>

                        <textarea cols="50" id="eventLessonsInput" [(ngModel)]='riskCase.assurComment' rows="8" maxlength="4000">
                        </textarea>
                        <span *ngIf='!byteCount(riskCase.assurComment,4000).valid' class="warningTxt">
                            The text entered exceeds the maximum number of characters allowed. Please reduce the text length up to the defined limit.
                        </span>





                    </div>

                </div>




                <div class="d-flex justify-content-end">

                    <button class='primaryBtn   mr-4 buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>

                    <button class='primaryBtn  buttonWidth' [disabled]='!byteCount(riskCase.assurComment,4000).valid' pButton type="button" label="Update" (click)="update()"></button>


                </div>
            </ng-container>









            <ng-container *ngIf="modelView=='reOpened'">
                <div class="w-100">
                    <div class="w-100 py-4 contantStyle">

                        Re Opened


                    </div>
                    <div class="mb-3 w-100 inputField">



                        <small class="counterText" [ngClass]="(!byteCount(riskCase.reOpened,4000).valid)?'warningTxt':''">{{byteCount(riskCase.reOpened,4000).length}} / 4000 </small>

                        <textarea cols="50" id="eventLessonsInput" [(ngModel)]='riskCase.reOpened' rows="8" maxlength="4000">
                           </textarea>
                        <span *ngIf='!byteCount(riskCase.reOpened,4000).valid' class="warningTxt">
                            The text entered exceeds the maximum number of characters allowed. Please reduce the text length up to the defined limit.
                        </span>



                    </div>

                </div>




                <div class="d-flex justify-content-end">

                    <button class='primaryBtn   mr-4 buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>

                    <button class='primaryBtn  buttonWidth' pButton type="button" label="Update" [disabled]='!byteCount(riskCase.reOpened,4000).valid' (click)="update()"></button>


                </div>
            </ng-container>


























            <ng-container *ngIf="modelView=='description'">
                <div class="w-100">

                    <div class=" py-4 contantStyle   t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.IssueDescription, 'right')">


                        Description

                    </div>
                    <div class="mb-3 w-100 inputField">

                        <small class="counterText" [ngClass]="(!byteCount(riskCase.issue.description,4000).valid)?'warningTxt':''">{{byteCount(riskCase.issue.description,4000).length}} / 4000 </small>

                        <textarea cols="50" [disabled]='cantEditTextArea()' id="eventdescriptionInput" [(ngModel)]='riskCase.issue.description' rows="8" maxlength="4000">
                    </textarea>
                        <span *ngIf='!byteCount(riskCase.issue.description,4000).valid' class="warningTxt">
                            The text entered exceeds the maximum number of characters allowed. Please reduce the text length up to the defined limit.
                        </span>


                    </div>
                </div>
                <div class="d-flex justify-content-end">

                    <button class='primaryBtn mr-4  buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>
                    <button class='primaryBtn   buttonWidth' [disabled]='!byteCount(riskCase.issue.description,4000).valid' pButton type="button" label="Update" (click)="update()"></button>
                </div>
            </ng-container>



            <ng-container *ngIf="modelView=='attachment'">
                <app-attachment-dialog [isDesktopDevice]="isDesktopDevice" [creator]='creator' [riskManagers]='riskManagers' [riskCaseData]='resultGit' [authUsers]='[ownerId,delegate]'
                    [userId]='loggedUser' [userRole]='allUserRoles' [status]='riskCase.status' [rcsaOwner]='rcsaOwner.user_id' #attachmentDialog (hideAttachmentDialog)="hideAttachmentDialog()"
                    [attachedFiles]="attachedFiles">
                </app-attachment-dialog>
            </ng-container>







        </div>



    </div>
    <ngx-spinner name="oneOrm" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
        <p style="color: white"> Loading... </p>
    </ngx-spinner>
</ng-template>
<ngx-spinner name="oneOrm" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
