<div class="containerDiv">
  <div *ngIf="viewStatus === 'mainView'">
        <form [formGroup]="form">
            <p class="mb-2 t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.IssueTitle)">
                Please provide a meaningful title for this issue:
            </p>

            <div class="mb-2 w-50 inputField">
                <small class="form-text  text-right" [ngClass]="(!Utils.byteCount(issueTitleInput.value,120).valid)?'warningTxt':''">{{issueTitleInput.value.length}} / 120 </small>

                <textarea id="issueTitleInput" #issueTitleInput cols="50" maxlength='120' rows="2" placeholder="(Issue Title)" formControlName="title">
                   </textarea>
                <small>
                    <span *ngIf='!Utils.byteCount(issueTitleInput.value,120).valid' class="warningTxt">
                        The text entered exceeds the maximum number of characters allowed. Please reduce the text length up to the defined limit.
                    </span>
                     </small>

            </div>

            <p class="mb-2  t-demo__btn" ngxTippy [tippyProps]="tooltip(FieldHelpData.IssueDescription)">
                Please provide a more detailed description of the problem and its impact:
            </p>

            <small>(please do not include personal and sensitive information such as names, gender, salaries, etc.)    </small>
            <app-text-analysis [control]="form.controls['description']"></app-text-analysis>
            <div class="d-flex justify-content-end w-100 mb-5">
                <button pButton type="button" label="Proceed" class='primaryBtn  buttonWidth ' [disabled]='canGo()||!Utils.byteCount(form.controls["description"].value,4000).valid||!Utils.byteCount(issueTitleInput.value,120).valid' (click)="nextStep()"></button>
                <app-information-dialog [issue]="true" [accordionIndex]='2'></app-information-dialog>


            </div>
        </form>

    </div>
</div>
