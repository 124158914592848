<div class="sidenav-container">
  <mat-card>
    <span>
      YOUR NEXT ITEM TO DO
    </span>
    <span class="todo-box-middle">
      <mat-icon class="todo-icon" aria-hidden="false" aria-label="warning-icon">warning</mat-icon>
      <span>Complete Issue <strong>Unsatisfactory DV01 Attribution explanation</strong> </span>
    </span>
    <span>
      <button mat-raised-button color="warn">OPEN ISSUE</button>
    </span>
  </mat-card>

  <mat-accordion style="margin-top: 15px" displayMode="flat">
    <mat-expansion-panel [hideToggle]="true" (click)="preventExpansion(homePane)" #homePane>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span>HOME</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel [hideToggle]="true" (click)="preventExpansion(coursePane)" #coursePane>
      <mat-expansion-panel-header>
        <mat-panel-title>
          TAKE COURSE
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          DASHBOARD AND REPORTS
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-nav-list role="list">
        <a mat-list-item [routerLink]="['/']">
          <span matLine>ISSUE MANAGEMENT</span>
        </a>
        <a mat-list-item [routerLink]="['/']">
          <span matLine>EVENT REPORTING</span>
        </a>
        <a mat-list-item [routerLink]="['/']">
          <span matLine>RCSA</span>
        </a>
        <a mat-list-item [routerLink]="['/']">
          <span matLine>RESIDUAL RISK ASSESSMENT</span>
        </a>
      </mat-nav-list>
    </mat-expansion-panel>

    <mat-expansion-panel [hideToggle]="true" (click)="preventExpansion(guidelinesPane)" #guidelinesPane>
      <mat-expansion-panel-header>
        <mat-panel-title>
          GUIDELINES AND POLICIES
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>

  </mat-accordion>
</div>
