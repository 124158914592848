export class Utils {
    static byteCount(textValue, maxlength) {
        if (!textValue) {
            return { valid: true, length: 0 };

        }
        if (encodeURI(textValue).split(/%..|./).length - 1 > maxlength) {
            return { valid: false, length: encodeURI(textValue).split(/%..|./).length - 1 };

        }
        return { valid: true, length: encodeURI(textValue).split(/%..|./).length - 1 };
    }
}