import { Component, OnDestroy, OnInit } from '@angular/core';
import { StepperService, BaseService, MsalService} from '../../../../shared/services';
import { Roles } from 'src/app/shared/models/roles.model';
import { Router } from '@angular/router';
import { User } from 'src/app/shared/models/user.model';
import { NewRisk } from 'src/app/shared/models/new-risk.model';
import { RiskCaseConstants } from '../../common-constants';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-step5',
  templateUrl: './step5.component.html',
  styleUrls: ['./step5.component.scss']
})

export class Step5Component implements OnInit, OnDestroy{
  viewStatus: string;
  filterUsersSingleList: any[];
  roles = new Roles();
  loggedUser: User = new User();
  riskCase: NewRisk;
  RiskCaseConstants = RiskCaseConstants;
  subscription: Subscription = new Subscription();


  constructor(private stepperService: StepperService,
              private router: Router,
              private baseService: BaseService,
              private authService: MsalService) {
    this.viewStatus = 'mainView';
    this.loggedUser = this.roles.eventOwner = authService.getLoggedUser();
    this.riskCase = stepperService.newRisk;
    if(this.riskCase.roles){
      this.roles = this.riskCase.roles;
    }
    // at this step the rcsa has been already chosen
    const rcsa = stepperService.getRcsa()[0];
    this.roles.riskManager = !this.roles.riskManager ? {user_id: rcsa.risk_manager_id, user_name: rcsa.risk_manager} : this.roles.riskManager;
    this.roles.governanceCoordinators = this.roles.governanceCoordinators && this.roles.governanceCoordinators.length == 0 ? this.stepperService.getGovernanceCoords() : this.roles.governanceCoordinators;
  }

  ngOnInit(): void {
    this.subscription.add(this.stepperService.closeCurrentStep.subscribe(index => this.stepperService.updateModel(index, this.roles, 'roles')));
    this.subscription.add(this.stepperService.saveCurrentStep.subscribe(() =>  this.stepperService.changeIndex(5, this.roles, 'roles', true)));
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  nextStep() {
    this.stepperService.changeIndex(5, this.roles, 'roles', true);
    this.router.navigate(['/new-risk/draftcreated']);
  }

  changeViewStatus(status: string) {
    this.viewStatus = status;
  }
  selectUser(data, field){
    this.roles[field] = data;
  }
  handleOutput() {
    this.viewStatus = 'mainView';
  }
  onChangeRB(event) {

  }

  filterUsersSingle(event) {

    const query = event.query;
    if (query.length > 2) {
      this.filterUsersSingleList = [];
      this.baseService.getUsers('user?q=' + query).then(users => {
        this.filterUsersSingleList.push(...users);
      });
      if (query.length == 6) {
        this.baseService.get('user/' + query.toUpperCase()).subscribe( user => {
          this.filterUsersSingleList.push(user);
        })
      }
    } else {
      this.filterUsersSingleList = [];
    }
  }

  composeName(user: any){
    return user.user_name + ' (' + user.user_id + ')';
  }
checkValidation(){
  if(!this.roles.eventOwner){
    return {disabled:true, msg:'Event Owner is Mandatory'};
  }
  if(this.roles.eventOwner &&  this.roles.eventDelegate){
    if( this.roles.eventOwner.user_id === this.roles.eventDelegate.user_id){
      return {disabled:true, msg:"Event Owner and Event Delegate can't be the same person"};
    }
  }

  return {disabled:false, msg:''};
}
}
