<p-dialog header="WARNING!" [(visible)]="displaySkipDialog" [position]="'center'" [dismissableMask]="true" [modal]="true" styleClass="skipDialog">
    <div>
        <ul class=" dUL bd-highlight mb-4">
            <li>       You have already entered some data on the current step.

            </li>
            <li> If you now choose to skip, all the information you have entered will be lost. </li>
        </ul>

        <div  class="d-flex justify-content-end w-100">
            <button class='primaryBtn  mr-4 buttonWidth' pButton type="button" label="Proceed" (click)="skip()"></button>

            <button class='primaryBtn   buttonWidth' pButton type="button" label="Cancel" (click)="cancel()"></button>
                <app-information-dialog [accordionIndex]='10'></app-information-dialog>
        </div>
    </div>
</p-dialog>
