import {Injectable} from '@angular/core';
import {environment} from '../environments/environment';
import {RETURN_BACK_TO_RCSA_PARAM} from "@shared/common/constants";

@Injectable()
export class AppService {

  domainRedirect() {
    if (environment.azurewebsitesDomainRedirect.enabled) {
      const pattern = environment.azurewebsitesDomainRedirect.pattern;
      if (pattern.test(window.location.hostname)) {
        const baseUrl = environment.baseUrl.substring(0, environment.baseUrl.length - 1);
        window.location.href = baseUrl + window.location.pathname + window.location.search;
      }
    }

    const blankUrl = (window.location.hash.length === 0 && window.location.pathname.length === 1);
    console.log("BLANK URL CHECK", window.location);
    console.log("BLANK URL CHECK RESULT", blankUrl);
    if (blankUrl || window.location.href.indexOf('?source=footer') > -1) {
      window.location.href = environment.rcsaUrl + RETURN_BACK_TO_RCSA_PARAM;
    } else if(window.location.href.indexOf("risk-case") > -1
      && window.location.href.indexOf("%3F") > -1) {
      console.log("ENCODED URL PARAM - REMOVING URL PARAMETER");
      window.location.href = window.location.href.substring(0, window.location.href.indexOf("%3F"));
      return;
    }

  }
}
