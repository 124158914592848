<mat-card class="statusBar mb-2">
  <div class="d-flex justify-content-center align-items-center loading">
    <div class="SystemNotice">
      <strong>
        Important:
      </strong> OneORM is not accessible on mobile devices, please use Swiss Re notebook instead.
    </div>

  </div>
</mat-card>

