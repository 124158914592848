<div *ngIf='!edit' [ngClass]="{'viewItems': !pdfView }" class="">


    <p-tree id="selected" [value]="selectedNodesView" [propagateSelectionUp]="false" [propagateSelectionDown]="true" class="right-tree" expanded="true">
        <ng-template let-node pTemplate="default">
            <label *ngIf="node.fsa_bp_no" class="d-flex align-item: center" style="margin-bottom: 0;">{{node.name}} ({{node.fsa_bp_no}})                 <span *ngIf='node.legal_entity_status == "In-active" || node.legal_entity_status == "Historic" || node.legal_entity_status == "Discarded"'>(Historic)</span>
            </label>
            <label *ngIf="!node.fsa_bp_no" class="d-flex align-item: center" style="margin-bottom: 0;">{{node.name}}                 <span *ngIf='node.legal_entity_status == "In-active" || node.legal_entity_status == "Historic" || node.legal_entity_status == "Discarded"'>(Historic)</span>
            </label>
        </ng-template>
    </p-tree>
</div>