<div class="containerDiv">
    <div class="w-100" *ngIf="viewStatus === 'mainView'">
        <ul class="mb-3 dUL draftList">
            <li> Thank you for recording this event. It is now in status <span>draft</span>.

            </li>
            <li>
                We encourage you to complete the remaining steps, while your memory is fresh.

            </li>
            <li> Skip questions where you do not have the answers. Your Risk Manager will help you to close any gaps during the review.

            </li>
            <li>
                Note that
                <ng-container *ngIf='riskCase.roles.eventOwner.user_id === loggedUser'> you</ng-container>, <span>{{riskCase.roles.eventOwner.user_name}}</span> <span *ngIf='riskCase.roles.eventDelegate'> or {{ riskCase.roles.eventDelegate.user_name}}
                </span> should submit the event to the assigned Risk Manager <span>{{riskCase.roles.riskManager.user_name}}</span> by <span>{{today30 | oneOrmDate}}</span>.

            </li>
            <li>
                Do not hesitate to contact your colleagues from Governance or Risk Management in case you need support.
            </li>
        </ul>
        <div class="row justify-content-end mb-5 w-100 mx-0">


            <button class='primaryBtn mr-4 buttonWidth' pButton type="button" label="Continue" (click)="nextStep()"></button>

        </div>
    </div>
</div>
